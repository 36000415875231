import { useEffect, useState } from "react";
import Header from "../../components/shared/Header";
import "../../styles/Donation/Donate.scss";
import { usePaystackPayment } from "react-paystack";
import { Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { countries } from "../../data/countries";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import DonationPdf from "../ThirdParty/DonationPdf";
import moment from "moment";
import { donateFund, sendDonationCertificate, verifyDonation } from "../../apis/General/users";
import { pinLinksToIPFS as greenpinFileToIPFSAsync } from "../../instantiateTransaction/basicGreenProject";
import {
  getTotalDonations,
  storeCertificateHash,
} from "../../instantiateTransaction/donationCertificate";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function Donate() {
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [nairaDisplayAmount, setDisplayNairaAmount] = useState("");
  const [ccrt, setCcrt] = useState("");
  const [userType, setUserType] = useState(null);
  const [terms, setTerms] = useState(false);
  const [visibility, setVisibility] = useState(null);
  const [currency, setCurrency] = useState("NGN");
  const [currencyOpen, setCurrencyOpen] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState("");
  const [userPdf, setUserPdf] = useState("");
  const [uploadLoading, setUploadLoading] = useState("");

  const generatePdfBlob = async (companyName, ccrt, date) => {
    const doc = (
      <DonationPdf companyName={companyName} ccrt={ccrt} date={date} />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    return blob;
  };

  const fileToUri = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadToServer = async (fileUri) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/upload`,
      {
        media_type: "pdf",
        folder: "documents",
        user_id: auth?.userInfo?.id,
        contents: [fileUri],
      },
      {
        headers: {
          Authorization: `Bearer ${auth ? auth.token : ""}`,
        },
      }
    );
  };

  const fileUpload = async (e, documentTypeSent) => {
    setUploadLoading(true);

    try {
      const files = e.target.files;
      if (!files || files.length === 0) {
        throw new Error("No files selected");
      }

      const file = files[0]; // We're only processing the first file
      const fileUri = await fileToUri(file);
      console.log("got result", fileUri);

      const response = await uploadToServer(fileUri);
      console.log("got response", response);

      if (response?.data?.body?.success !== true) {
        throw new Error("Server upload failed");
      }

      setUserPdf(response.data.body.data[0]);
      const ipfsResponse = await handleUploadToIPFS(response.data.body.data[0]);
      handleSendDonationCertificate(`https://${response.data.body.data[0]}`);
      console.log("handleUploadToIPFS", ipfsResponse);

      return ipfsResponse;
    } catch (error) {
      console.log("error uploading certificate", error);
      // toast.error("Unable to upload donation certificate to IPFS");
      return {
        status: "error uploading certificate",
        statusCode: 500,
      };
    } finally {
      setUploadLoading(false);
    }
  };

  const handlePdf = async () => {
    try {
      // setLoading(true)
      const blob = await generatePdfBlob(
        businessName || `${firstName} ${lastName}`,
        ccrt,
        Date.now()
      );

      // For downloading
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${
        businessName || `${firstName} ${lastName}`
      }-CCRT donation certificate ${moment(Date.now()).format(
        "DD-MM-YYYY"
      )}.pdf`;
      link.click();

      // For uploading
      const file = new File(
        [blob],
        `${
          businessName || `${firstName} ${lastName}`
        }-CCRT donation certificate ${moment(Date.now()).format(
          "DD-MM-YYYY"
        )}.pdf`,
        { type: "application/pdf" }
      );

      // Create a mock event object
      const mockEvent = {
        target: {
          files: [file],
        },
      };

      // Call the fileUpload function
      const response = await fileUpload(mockEvent, "donation_certificate");
      console.log("fileUpload", response);

      return response;
      // setLoading(false)
    } catch (error) {
      console.log("error creating certificate:", error);
      return {
        status: "error creating certificate",
        statusCode: 500,
      };
    }
  };

  // const config = {
  //     reference: (new Date()).getTime().toString(),
  //     email: "saleemjibril5@gmail.com",
  //     amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
  //     // currency: "NGN",
  //     publicKey: 'pk_test_0a82f4a59d34f747e8b3a5207e79a263692f46b4',
  //   };

  const configPaystack = {
    // reference: (new Date()).getTime().toString(),
    email,
    amount: nairaDisplayAmount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    metadata: {
      firstName,
      lastName,
      email,
      phone,
    },
    // publicKey: "pk_test_3f715236ee25c842649abc6a3c013258a37f9b1c",
    publicKey: "pk_live_6a7e7c0b5677117392f596dff24695cbd0dd2eb0",
    text: "Pay Now",
  };

  const initializePayment = usePaystackPayment(configPaystack);

  // const handleVerifyDonation = async () => {
  //   const res = await verifyDonation(response?.reference, "paystack", email)
  //   console.log('response', res);
  // }

  const onSuccess = (response) => {
    console.log("response response", response);
    // handleVerifyDonation();
    handleCallBack(response?.reference);
  };

  const onClose = () => alert("Are you want to cancel this transaction");

  const handleCallBack = async (ref) => {
    setLoading(true);
    try {
      const res = await verifyDonation(ref, "paystack", email);
      console.log("response", res);
      if (res?.data?.statusCode === 200) {
        const res2 = await donateFund(
          res?.data?.depositId,
          userType,
          firstName,
          lastName,
          businessName,
          email,
          phone,
          visibility,
          city,
          state,
          zipCode,
          country
        );

        if (res?.data?.statusCode === 200) {
          const res2 = await handlePdf();
          console.log("handlePdf", res2);

          // if (res2?.statusCode === 200) {
            setModalOpen(true);
          // } 
          // else {
          //   alert(
          //     "Transaction successful but unfortunately we were unable to issue a certificate. Please reach out to our support team"
          //   );
          // }
        } else {
          alert(
            "Transaction successful but unfortunately we were unable to record your donation. Please reach out to our support team"
          );
        }

        console.log("res2", res2);
      } else {
        alert("We were unable to verify your payment");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
    setLoading(false);
    console.log("reached here on time");
  };
  useEffect(() => {
    if (currency === "USD") {
      setDisplayNairaAmount(amount * 1500);
      setCcrt(amount * 0.62);
    } else if (currency === "NGN") {
      setDisplayNairaAmount(amount);
      setCcrt((amount / 1500) * 0.62);
    } else if (currency === "GHC") {
      setDisplayNairaAmount(amount * 98);
      setCcrt((amount / 16) * 0.62);
    }
  }, [amount, currency]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userType) {
      alert("Please indicate if you are donating as a business or individual");
    } else if (!visibility) {
      alert("Please indicate if you will be donating anonymously");
    }
    // else if (!terms) {
    //   alert("Please confirm that you have read our terms and conditions");
    // }
    else {
      initializePayment(onSuccess, onClose);
    }
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("donate__modal")) {
      setModalOpen(false);
    }
  };

  const handleUploadToIPFS = async (document) => {
    try {
      const response = await greenpinFileToIPFSAsync(document);
      console.log("res1", response);

      if (response?.IpfsHash) {
        return {
          status: "success",
          statusCode: 200,
        };
      }
      // const res2 = await storeCertificateHash(response?.IpfsHash)
      // console.log('res2', res2);
    } catch (error) {
      console.log("error uploading to ipfs:", error);
      return {
        status: "error uploading to ipfs",
        statusCode: 500,
      };
    }
  };

  const handleSendDonationCertificate = async (link) => {
const response = await sendDonationCertificate(email, link, firstName?.length > 0 ? `${firstName} ${lastName}` : businessName);
console.log('sent email', response);

  }
  return (
    <div className="donate">
      <Header />
      <div className="donate__hero">
        <div className="donate__hero__title">Voluntary Donation</div>
        <div className="donate__hero__subtitle">
          Every activity impacts our environment, but together, we can make a
          difference. Make a significant difference by joining our community of
          organisations and individuals, who are committed to supporting
          top-tier carbon reduction projects through donations.
        </div>
      </div>

      <form className="donate__card" onSubmit={handleSubmit}>
        <div className="donate__card__title">
          Your contribution will support CloseCarbon projects aimed at reducing
          carbon emissions and achieving net zero targets.
        </div>

        <div className="donate__card__subtitle">
          <div>1.</div>
          <div>Donation type</div>
        </div>
        <div className="donate__card__subsubtitle">I am donating as</div>

        <div className="donate__card__check-group">
          <div>
            {userType === "individual" ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setUserType(null)}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#0D924F" />
                <circle cx="12" cy="12" r="6" fill="#0D924F" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setUserType("individual")}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#898989" />
              </svg>
            )}
            An individual
          </div>
          <div>
            {userType === "business" ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setUserType(null)}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#0D924F" />
                <circle cx="12" cy="12" r="6" fill="#0D924F" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setUserType("business")}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#898989" />
              </svg>
            )}
            A business
            <Tooltip
              title={
                <Typography variant="h6">
                  This updates the name on your voluntary donation certificate
                  to a business name.
                </Typography>
              }
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2189_24323)">
                  <g clip-path="url(#clip1_2189_24323)">
                    <path
                      d="M8.00065 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00065 14.6673C4.31865 14.6673 1.33398 11.6827 1.33398 8.00065C1.33398 4.31865 4.31865 1.33398 8.00065 1.33398ZM8.00065 2.66732C6.58616 2.66732 5.22961 3.22922 4.22941 4.22941C3.22922 5.22961 2.66732 6.58616 2.66732 8.00065C2.66732 9.41514 3.22922 10.7717 4.22941 11.7719C5.22961 12.7721 6.58616 13.334 8.00065 13.334C9.41514 13.334 10.7717 12.7721 11.7719 11.7719C12.7721 10.7717 13.334 9.41514 13.334 8.00065C13.334 6.58616 12.7721 5.22961 11.7719 4.22941C10.7717 3.22922 9.41514 2.66732 8.00065 2.66732ZM7.99398 6.66732C8.36598 6.66732 8.66732 6.96865 8.66732 7.34065V10.7567C8.7944 10.83 8.89373 10.9433 8.94988 11.0789C9.00604 11.2145 9.01589 11.3648 8.9779 11.5065C8.93992 11.6483 8.85623 11.7735 8.73981 11.8629C8.62338 11.9522 8.48073 12.0006 8.33398 12.0007H8.00732C7.91889 12.0007 7.83134 11.9832 7.74964 11.9494C7.66795 11.9156 7.59372 11.866 7.5312 11.8034C7.46867 11.7409 7.41908 11.6667 7.38524 11.585C7.3514 11.5033 7.33398 11.4157 7.33398 11.3273V8.00065C7.15717 8.00065 6.9876 7.93041 6.86258 7.80539C6.73756 7.68036 6.66732 7.5108 6.66732 7.33398C6.66732 7.15717 6.73756 6.9876 6.86258 6.86258C6.9876 6.73756 7.15717 6.66732 7.33398 6.66732H7.99398ZM8.00065 4.66732C8.17746 4.66732 8.34703 4.73756 8.47205 4.86258C8.59708 4.9876 8.66732 5.15717 8.66732 5.33398C8.66732 5.5108 8.59708 5.68036 8.47205 5.80539C8.34703 5.93041 8.17746 6.00065 8.00065 6.00065C7.82384 6.00065 7.65427 5.93041 7.52925 5.80539C7.40422 5.68036 7.33398 5.5108 7.33398 5.33398C7.33398 5.15717 7.40422 4.9876 7.52925 4.86258C7.65427 4.73756 7.82384 4.66732 8.00065 4.66732Z"
                      fill="#0D924F"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2189_24323">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_2189_24323">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Tooltip>
          </div>
        </div>

        <div className="donate__card__subtitle">
          <div>2.</div>
          <div>Input amount</div>
        </div>

        <div
          className={
            amount
              ? "donate__card__amount donate__card__amount-active"
              : "donate__card__amount"
          }
        >
          <div className="donate__card__amount__inner">
            <div
              className="donate__card__amount__inner__currency"
              onClick={() => setCurrencyOpen(!currencyOpen)}
            >
              {currency}
              <svg
                width="15"
                height="9"
                viewBox="0 0 15 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 1L7.5 7L1 1" stroke="#3B3B3B" stroke-width="1.5" />
              </svg>

              {currencyOpen && (
                <div className="donate__card__amount__inner__currency__info">
                  <div onClick={() => setCurrency("NGN")}>NGN</div>
                  <div onClick={() => setCurrency("USD")}>USD</div>
                  <div onClick={() => setCurrency("GHC")}>GHC</div>
                </div>
              )}
            </div>

            <input
              type="text"
              placeholder="0.00"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </div>

          {amount && (
            <div className="donate__card__amount__info">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM9 6C9 4.34 10.34 3 12 3C13.66 3 15 4.34 15 6V8H9V6ZM18 20H6V10H18V20ZM12 17C13.1 17 14 16.1 14 15C14 13.9 13.1 13 12 13C10.9 13 10 13.9 10 15C10 16.1 10.9 17 12 17Z"
                  fill="#0D924F"
                />
              </svg>

              <div>
                At today's rate, your{" "}
                <span>
                  {currency} {amount}
                </span>{" "}
                donation converts to <span>{ccrt} CCRT</span>.Payment is sent in
                naira, which is equivalent to{" "}
                <span>NGN {nairaDisplayAmount}</span>.
              </div>
            </div>
          )}
        </div>

        <div className="donate__card__subtitle">
          <div>3.</div>
          <div>Contact information</div>
        </div>

        <div className="donate__card__input-grid donate__card__single-grid">
          {userType === "business" && (
            <div>
              <label htmlFor="">Business name</label>
              <input
                type="text"
                required
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="donate__card__input-grid">
          {userType !== "business" && (
            <div>
              <label htmlFor="">First name*</label>
              <input
                type="text"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
          )}
          {userType !== "business" && (
            <div>
              <label htmlFor="">Last name*</label>
              <input
                type="text"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          )}
          <div>
            <label htmlFor="">Email address*</label>
            <input
              type="text"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="">Phone number*</label>
            <input
              type="text"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>

        <div className="donate__card__check-group">
          <div>
            {visibility === "anonymous" ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVisibility(null)}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#0D924F" />
                <circle cx="12" cy="12" r="6" fill="#0D924F" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVisibility("anonymous")}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#898989" />
              </svg>
            )}
            Anonymous donor
            <Tooltip
              title={
                <Typography variant="h6">
                  The voluntary donation certificate will not display your name
                </Typography>
              }
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2189_24323)">
                  <g clip-path="url(#clip1_2189_24323)">
                    <path
                      d="M8.00065 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00065 14.6673C4.31865 14.6673 1.33398 11.6827 1.33398 8.00065C1.33398 4.31865 4.31865 1.33398 8.00065 1.33398ZM8.00065 2.66732C6.58616 2.66732 5.22961 3.22922 4.22941 4.22941C3.22922 5.22961 2.66732 6.58616 2.66732 8.00065C2.66732 9.41514 3.22922 10.7717 4.22941 11.7719C5.22961 12.7721 6.58616 13.334 8.00065 13.334C9.41514 13.334 10.7717 12.7721 11.7719 11.7719C12.7721 10.7717 13.334 9.41514 13.334 8.00065C13.334 6.58616 12.7721 5.22961 11.7719 4.22941C10.7717 3.22922 9.41514 2.66732 8.00065 2.66732ZM7.99398 6.66732C8.36598 6.66732 8.66732 6.96865 8.66732 7.34065V10.7567C8.7944 10.83 8.89373 10.9433 8.94988 11.0789C9.00604 11.2145 9.01589 11.3648 8.9779 11.5065C8.93992 11.6483 8.85623 11.7735 8.73981 11.8629C8.62338 11.9522 8.48073 12.0006 8.33398 12.0007H8.00732C7.91889 12.0007 7.83134 11.9832 7.74964 11.9494C7.66795 11.9156 7.59372 11.866 7.5312 11.8034C7.46867 11.7409 7.41908 11.6667 7.38524 11.585C7.3514 11.5033 7.33398 11.4157 7.33398 11.3273V8.00065C7.15717 8.00065 6.9876 7.93041 6.86258 7.80539C6.73756 7.68036 6.66732 7.5108 6.66732 7.33398C6.66732 7.15717 6.73756 6.9876 6.86258 6.86258C6.9876 6.73756 7.15717 6.66732 7.33398 6.66732H7.99398ZM8.00065 4.66732C8.17746 4.66732 8.34703 4.73756 8.47205 4.86258C8.59708 4.9876 8.66732 5.15717 8.66732 5.33398C8.66732 5.5108 8.59708 5.68036 8.47205 5.80539C8.34703 5.93041 8.17746 6.00065 8.00065 6.00065C7.82384 6.00065 7.65427 5.93041 7.52925 5.80539C7.40422 5.68036 7.33398 5.5108 7.33398 5.33398C7.33398 5.15717 7.40422 4.9876 7.52925 4.86258C7.65427 4.73756 7.82384 4.66732 8.00065 4.66732Z"
                      fill="#0D924F"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2189_24323">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_2189_24323">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Tooltip>
          </div>
          <div>
            {visibility === "named" ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVisibility(null)}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#0D924F" />
                <circle cx="12" cy="12" r="6" fill="#0D924F" />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setVisibility("named")}
              >
                <circle cx="12" cy="12" r="11.5" stroke="#898989" />
              </svg>
            )}
            Named donor
            <Tooltip
              title={
                <Typography variant="h6">
                  The voluntary donation certificate will display your name
                </Typography>
              }
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2189_24323)">
                  <g clip-path="url(#clip1_2189_24323)">
                    <path
                      d="M8.00065 1.33398C11.6827 1.33398 14.6673 4.31865 14.6673 8.00065C14.6673 11.6827 11.6827 14.6673 8.00065 14.6673C4.31865 14.6673 1.33398 11.6827 1.33398 8.00065C1.33398 4.31865 4.31865 1.33398 8.00065 1.33398ZM8.00065 2.66732C6.58616 2.66732 5.22961 3.22922 4.22941 4.22941C3.22922 5.22961 2.66732 6.58616 2.66732 8.00065C2.66732 9.41514 3.22922 10.7717 4.22941 11.7719C5.22961 12.7721 6.58616 13.334 8.00065 13.334C9.41514 13.334 10.7717 12.7721 11.7719 11.7719C12.7721 10.7717 13.334 9.41514 13.334 8.00065C13.334 6.58616 12.7721 5.22961 11.7719 4.22941C10.7717 3.22922 9.41514 2.66732 8.00065 2.66732ZM7.99398 6.66732C8.36598 6.66732 8.66732 6.96865 8.66732 7.34065V10.7567C8.7944 10.83 8.89373 10.9433 8.94988 11.0789C9.00604 11.2145 9.01589 11.3648 8.9779 11.5065C8.93992 11.6483 8.85623 11.7735 8.73981 11.8629C8.62338 11.9522 8.48073 12.0006 8.33398 12.0007H8.00732C7.91889 12.0007 7.83134 11.9832 7.74964 11.9494C7.66795 11.9156 7.59372 11.866 7.5312 11.8034C7.46867 11.7409 7.41908 11.6667 7.38524 11.585C7.3514 11.5033 7.33398 11.4157 7.33398 11.3273V8.00065C7.15717 8.00065 6.9876 7.93041 6.86258 7.80539C6.73756 7.68036 6.66732 7.5108 6.66732 7.33398C6.66732 7.15717 6.73756 6.9876 6.86258 6.86258C6.9876 6.73756 7.15717 6.66732 7.33398 6.66732H7.99398ZM8.00065 4.66732C8.17746 4.66732 8.34703 4.73756 8.47205 4.86258C8.59708 4.9876 8.66732 5.15717 8.66732 5.33398C8.66732 5.5108 8.59708 5.68036 8.47205 5.80539C8.34703 5.93041 8.17746 6.00065 8.00065 6.00065C7.82384 6.00065 7.65427 5.93041 7.52925 5.80539C7.40422 5.68036 7.33398 5.5108 7.33398 5.33398C7.33398 5.15717 7.40422 4.9876 7.52925 4.86258C7.65427 4.73756 7.82384 4.66732 8.00065 4.66732Z"
                      fill="#0D924F"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_2189_24323">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_2189_24323">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Tooltip>
          </div>
        </div>

        <div className="donate__card__input-grid">
          <div>
            <label htmlFor="">City*</label>
            <input
              type="text"
              required
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="">State*</label>
            <input
              type="text"
              required
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </div>
          {/* <div>
            <label htmlFor="">Zip code</label>
            <input
              type="text"
              required
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div> */}
          <div>
            <label htmlFor="">Country*</label>
            <select
              name=""
              id=""
              required
              onChange={(e) => setCountry(e.target.value)}
              value={country}
            >
              {countries?.map((country) => (
                <option value={country?.label}>{country?.label}</option>
              ))}
            </select>
          </div>
        </div>

        {/* <div className="donate__card__term">
          {terms ? (
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setTerms(false)}
            >
              <rect
                x="1"
                y="1"
                width="24"
                height="24"
                rx="6"
                stroke="url(#paint0_linear_2189_24815)"
                stroke-width="2"
              />
              <path
                d="M7.75 13.75L11.25 17.25L18.25 9.75"
                stroke="url(#paint1_linear_2189_24815)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2189_24815"
                  x1="1"
                  y1="1.55078"
                  x2="29.6033"
                  y2="15.6829"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#006633" />
                  <stop offset="1" stop-color="#02A4CC" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2189_24815"
                  x1="7.75"
                  y1="9.92212"
                  x2="18.2804"
                  y2="17.206"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#006633" />
                  <stop offset="1" stop-color="#02A4CC" />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setTerms(true)}
            >
              <rect
                x="1"
                y="1"
                width="24"
                height="24"
                rx="6"
                stroke="url(#paint0_linear_2189_24094)"
                stroke-width="2"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2189_24094"
                  x1="1"
                  y1="1.55078"
                  x2="29.6033"
                  y2="15.6829"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#006633" />
                  <stop offset="1" stop-color="#02A4CC" />
                </linearGradient>
              </defs>
            </svg>
          )}

          <div>
            I confirm that I have read and agree to the{" "}
            <span
             onClick={() => {
              window.open('/terms-and-conditions', '_blank', 'noopener,noreferrer')
            }}
            >Terms and Conditions</span> of the CloseCarbon voluntary
            donation program.
          </div>
        </div> */}

        <button className="donate__card__button" type="submit">
          Donate Now
        </button>
      </form>

      {modalOpen && (
        <div className="donate__modal" onClick={handleModalClose}>
          <div className="donate__modal__inner">
            <div className="donate__modal__inner__close">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setModalOpen(false)}
              >
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                  fill="#141414"
                />
              </svg>
            </div>
            <svg
              className="donate__modal__inner__logo"
              width="88"
              height="88"
              viewBox="0 0 88 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_2175_7378)">
                <path
                  d="M39.4554 5.62202C41.7943 2.72859 46.2057 2.72859 48.5446 5.62202L51.8073 9.65826C53.2606 11.456 55.6369 12.2281 57.8693 11.6279L62.8813 10.2803C66.4743 9.31426 70.0432 11.9072 70.2347 15.6228L70.5018 20.806C70.6208 23.1146 72.0895 25.136 74.2483 25.9626L79.0953 27.8184C82.5698 29.1487 83.933 33.3442 81.904 36.4627L79.0735 40.813C77.8129 42.7507 77.8129 45.2493 79.0735 47.187L81.904 51.5373C83.933 54.6558 82.5698 58.8513 79.0953 60.1816L74.2483 62.0374C72.0895 62.864 70.6208 64.8854 70.5018 67.194L70.2347 72.3772C70.0432 76.0928 66.4743 78.6857 62.8813 77.7197L57.8693 76.3721C55.6369 75.7719 53.2606 76.544 51.8073 78.3417L48.5446 82.378C46.2057 85.2714 41.7943 85.2714 39.4554 82.378L36.1927 78.3417C34.7394 76.544 32.3631 75.7719 30.1307 76.3721L25.1187 77.7197C21.5257 78.6857 17.9568 76.0928 17.7653 72.3772L17.4982 67.194C17.3792 64.8854 15.9105 62.864 13.7517 62.0374L8.90473 60.1816C5.43015 58.8513 4.06697 54.6558 6.09601 51.5373L8.92647 47.187C10.1871 45.2493 10.1871 42.7507 8.92647 40.813L6.09601 36.4627C4.06697 33.3442 5.43015 29.1487 8.90473 27.8184L13.7517 25.9626C15.9105 25.136 17.3792 23.1146 17.4982 20.806L17.7653 15.6228C17.9568 11.9072 21.5257 9.31426 25.1187 10.2803L30.1307 11.6279C32.3631 12.2281 34.7394 11.456 36.1927 9.65826L39.4554 5.62202Z"
                  fill="#0D924F"
                />
              </g>
              <g filter="url(#filter1_dddd_2175_7378)">
                <circle cx="44" cy="44" r="22" fill="white" />
              </g>
              <path
                d="M42.265 35.0525C43.1189 33.9962 44.7294 33.9962 45.5833 35.0525C46.1139 35.7088 46.9814 35.9907 47.7964 35.7716C49.1082 35.4189 50.4111 36.3656 50.481 37.7221C50.5245 38.5649 51.0607 39.3029 51.8488 39.6047C53.1174 40.0904 53.615 41.6221 52.8743 42.7606C52.414 43.468 52.414 44.3803 52.8743 45.0877C53.615 46.2262 53.1174 47.7579 51.8488 48.2436C51.0607 48.5454 50.5245 49.2834 50.481 50.1262C50.4111 51.4827 49.1082 52.4294 47.7964 52.0767C46.9814 51.8575 46.1139 52.1394 45.5833 52.7958C44.7294 53.8521 43.1189 53.8521 42.265 52.7958C41.7344 52.1394 40.8668 51.8575 40.0518 52.0767C38.7401 52.4294 37.4372 51.4827 37.3672 50.1262C37.3238 49.2834 36.7876 48.5454 35.9994 48.2436C34.7309 47.7579 34.2332 46.2262 34.974 45.0877C35.4343 44.3803 35.4343 43.468 34.974 42.7606C34.2332 41.6221 34.7309 40.0904 35.9994 39.6047C36.7876 39.3029 37.3238 38.5649 37.3672 37.7221C37.4372 36.3656 38.7401 35.4189 40.0518 35.7716C40.8668 35.9907 41.7344 35.7089 42.265 35.0525Z"
                fill="#0D924F"
              />
              <path
                d="M47.2828 41L41.9919 46.7669L40 44.5986"
                stroke="white"
                stroke-width="0.995933"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <filter
                  id="filter0_d_2175_7378"
                  x="3.77344"
                  y="3.45117"
                  width="80.4531"
                  height="83.8477"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1.375" />
                  <feGaussianBlur stdDeviation="0.6875" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.0509804 0 0 0 0 0.572549 0 0 0 0 0.309804 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2175_7378"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2175_7378"
                    result="shape"
                  />
                </filter>
                <filter
                  id="filter1_dddd_2175_7378"
                  x="19.25"
                  y="19.25"
                  width="49.5"
                  height="49.5"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1.375" />
                  <feGaussianBlur stdDeviation="0.6875" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2175_7378"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1.375" />
                  <feGaussianBlur stdDeviation="0.6875" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2175_7378"
                    result="effect2_dropShadow_2175_7378"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="-1.375" />
                  <feGaussianBlur stdDeviation="0.6875" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect2_dropShadow_2175_7378"
                    result="effect3_dropShadow_2175_7378"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="-1.375" />
                  <feGaussianBlur stdDeviation="0.6875" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect3_dropShadow_2175_7378"
                    result="effect4_dropShadow_2175_7378"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect4_dropShadow_2175_7378"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
            <div className="donate__modal__inner__title">
              Donation successful
            </div>
            <div className="donate__modal__inner__subtitle">
              Your voluntary donation of {currency} {amount}, equivalent to{" "}
              {ccrt} CCRT, has been successfully processed.
            </div>

            <button
              className="donate__modal__inner__button"
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}

              // onClick={handleUploadToIPFS}
            >
              Continue to website
            </button>

            {/* <div className="donate__modal__inner__link"
            onClick={handlePdf}
            >
              Download your voluntary donation certificate!
            </div> */}
          </div>
        </div>
      )}

      {loading || uploadLoading ? (
        <div className="donate__loading-modal">
          <div>Loading...</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
