import { useEffect, useState } from "react";
import "../../styles/shared/TwoFa.scss";
import { verify2fa } from "../../apis/General/users";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function TwoFa() {
  const auth = useSelector((state) => state.auth);

  const [tfaModalOpen, setTfaModalOpen] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [loading, setLoading] = useState(false);

  function getRandomBinaryValue() {
    return Math.round(Math.random());
  }

  // useEffect(() => {
  //   if (auth?.userInfo?.twoFaEnabled) {
  //     const response = getRandomBinaryValue();
  //     console.log("eredfd", response);
  //     if (response === 1) {
  //       setTfaModalOpen(true);
  //     }
  //   }
  // }, []);

  const handle2fa = async (e) => {
    setLoading(true);
    e.preventDefault();
    const code = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
    const response = await verify2fa(code, auth?.token);
    console.log("response", response);
    if (response?.data?.statusCode === 200) {
      setTfaModalOpen(false);
    } else {
      toast.error(response?.data?.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp_input");

    otpInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => {
        const inputValue = event.target.value;

        if (inputValue && index < otpInputs.length - 1) {
          otpInputs[index + 1].focus();
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && index > 0 && !input.value) {
          otpInputs[index - 1].focus();
          event.preventDefault();
        }
      });
    });
  }, [tfaModalOpen]);

  return (
    <>
      {tfaModalOpen && (
        <div className="tfa-modal-container">
          <form className="tfa-modal" onSubmit={handle2fa}>
            <div className="tfa-modal__svg"></div>
            <div className="tfa-modal__title">Enter your 2FA Code</div>
            <div className="tfa-modal__subtitle">
              Enter your 5 digit 2FA code to gain access to your account
            </div>

            <div className="otp_box_group">
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp1}
                  name="otp1"
                  onChange={(e) => {
                    setOtp1(e.target.value);
                    // setOtpError(false);
                  }}
                />
              </div>

              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp2}
                  name="otp2"
                  onChange={(e) => {
                    setOtp2(e.target.value);
                    // setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp3}
                  name="otp3"
                  onChange={(e) => {
                    setOtp3(e.target.value);
                    // setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp4}
                  name="otp4"
                  onChange={(e) => {
                    setOtp4(e.target.value);
                    // setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp5}
                  name="otp5"
                  onChange={(e) => {
                    setOtp5(e.target.value);
                    // setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp6}
                  name="otp6"
                  onChange={(e) => {
                    setOtp6(e.target.value);
                    // setOtpError(false);
                  }}
                />
              </div>
            </div>

            <button
              className="password_modal_submit"
              type="submit"
              disabled={loading}
            >
              <div>{loading ? "Loading..." : "Submit"}</div>
            </button>
          </form>
        </div>
      )}
    </>
  );
}
