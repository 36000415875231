import { ethers } from "ethers";
// import dotenv from "dotenv";
import pinataSDK from "@pinata/sdk";
import fs from "fs";
import { getContracts } from "../utils/getContracts";
import pinJSONToIPFS from "@pinata/sdk/types/commands/pinning/pinJSONToIPFS";

// dotenv.config();

const { REACT_APP_pinataJWT } = process.env;

const pinata = new pinataSDK({
  pinataJWTKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI2NDgyZjkxZS0zNjNlLTQ5OWYtYjQ1ZC0wNzNmYzA4NmQxYTUiLCJlbWFpbCI6InRlaGlsYWZhdm91cml0ZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiNTczMGRiYjgwNjhiZjFjYjdkYTUiLCJzY29wZWRLZXlTZWNyZXQiOiI1MTkyMmJmNDM1ZGUwYWM5YWMwN2UzYmE4NzQ1OGZiYzU5N2FjOTI3MzJhOTJjMGFlNjQyYmQ1OWNhZWRkMzk4IiwiaWF0IjoxNzAwMjA4NzQzfQ.t850Nq1NQGZTnpw-f-NEhVUrk892-UBFUWOp6hkiqnk",
});
const abiCoder = new ethers.AbiCoder();

function encodeStaticData(
  OrganizationDescription: string,
  location: string,
  personResponsible: string,
  descriptionOfRenewableEnergySources: string,
  baseline: number
): string {
  const encodedStaticAttributes = abiCoder.encode(
    ["string", "string", "string", "string", "uint256"],
    [
      OrganizationDescription,
      location,
      personResponsible,
      descriptionOfRenewableEnergySources,
      baseline,
    ]
  );
  // console.log(encodedStaticAttributes);
  return encodedStaticAttributes;
}

/**** For Backend ****/

function encodedDynamicAttributes(
  reportingPeriod: string,
  energyConsumptionInKWh: number,
  quantifiedGHGEmissionsInBaselineScenario: number,
  totalGHGEmission: number
): any {
  const convertedEnergyConsumptionInKWh = ethers.parseEther(
    energyConsumptionInKWh.toString()
  );
  const convertedQuantifiedGHGEmissionsInBaselineScenario =
    ethers.parseEther(
      quantifiedGHGEmissionsInBaselineScenario.toString()
    );
  const convertedTotalGHGEmission = ethers.parseEther(
    totalGHGEmission.toString()
  );

  const encodedDynamicAttributes = abiCoder.encode(
    ["string", "uint256", "uint256", "uint256"],
    [
      reportingPeriod,
      convertedEnergyConsumptionInKWh,
      convertedQuantifiedGHGEmissionsInBaselineScenario,
      convertedTotalGHGEmission,
    ]
  );
  // console.log(encodedDynamicAttributes);

  return encodedDynamicAttributes;
}

/**** For Backend ****/

async function pinLinksToIPFS(link: string[]) {
  let links: any;

  links = {
    link: link,
  };

  let options: any;
  options = {
    pinataMetadata: {
      name: "Netzence",
      keyvalues: {
        customKey: "Netzence",
        customKey2: "Netzence",
      },
    },
    pinataOptions: {
      cidVersion: 0,
    },
  };
  console.log(links);

  const result = await pinata.pinJSONToIPFS(links, options);
  console.log(result);

  return result;
}

// async function grantRole(role: number, addressToGrant: string) {
//   const { signer, basicGreenContractInstance } = await getContracts();
//   const VALIDATOR_ROLE = await basicGreenContractInstance.VALIDATOR_ROLE();
//   const MASTER_NODE = await basicGreenContractInstance.MASTER_NODE();
//   let roleToGrant;
//   switch (role) {
//     case 1:
//       roleToGrant = VALIDATOR_ROLE;
//       break;
//     case 2:
//       roleToGrant = MASTER_NODE;
//       break;
//     default:
//       throw new Error("Invalid role");
//   }
//   const tx = await basicGreenContractInstance
//     .connect(signer)
//     .grantRole(roleToGrant, addressToGrant, true);
//   return {
//     tx,
//   };
// }

// async function performKYC(
//   addressToRecord: string,
//   bytesEncodedStaticAttributes: string,
//   Name: string,
//   Scenario: string
// ) {
//   const { signer, basicGreenContractInstance } = await getContracts();

//   const txKYC = await basicGreenContractInstance
//     .connect(signer)
//     .performKYC(addressToRecord, bytesEncodedStaticAttributes, Name, Scenario);
//   return {
//     txKYC,
//   };
// }

// async function recordPlant(
//   addressToRecord: string[],
//   bytesDynamicAttributes: string[]
// ) {
//   const { signer, basicGreenContractInstance } = await getContracts();

//   const tx = await basicGreenContractInstance
//     .connect(signer)
//     .recordPlant(addressToRecord, bytesDynamicAttributes);
//   return {
//     tx,
//   };
// }

// async function rejectValidation(addressToReject: string) {
//   const { signer, basicGreenContractInstance } = await getContracts();

//   const tx = await basicGreenContractInstance
//     .connect(signer)
//     .rejectValidation(addressToReject);
//   return {
//     tx,
//   };
// }

// async function validate(addressToValidate: string) {
//   const { signer, basicGreenContractInstance } = await getContracts();

//   const tx = await basicGreenContractInstance
//     .connect(signer)
//     .validate(addressToValidate);
//   return {
//     tx,
//   };
// }

// async function verifyAndMint(addressToVerify: string) {
//   const { signer, basicGreenContractInstance } = await getContracts();

//   const tx = await basicGreenContractInstance
//     .connect(signer)
//     .verifyAndMint(addressToVerify);
//   return {
//     tx,
//   };
// }

async function conditions(address: string, index: number) {
  const { basicGreenContractInstance } = await getContracts();

  const conditions = await basicGreenContractInstance.conditions(
    address,
    index
  );
  return {
    isValidated: conditions.isValidated,
    isVerified: conditions.isVerified,
    isRejected: conditions.isRejected,
    isCarbonCreditIssued: conditions.isCarbonCreditIssued,
  };
}

// async function issueCarbonCredits(address: string) {
//   const { signer, basicGreenContractInstance } = await getContracts();

//   const tx = await basicGreenContractInstance
//     .connect(signer)
//     .issueCarbonCredits(address);
//   return {
//     tx,
//   };
// }

async function getAllEmissionValues(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const allEmissionValues =
    await basicGreenContractInstance.allEmissionValues(address);
  return {
    energyConsumptionInKWh: ethers.formatEther(
      allEmissionValues.energyConsumptionInKWh.toString()
    ),
    quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
      allEmissionValues.quantifiedGHGEmissionsInBaselineScenario.toString()
    ),
    reportingPeriod: allEmissionValues.reportingPeriod,

    totalGHGEmission: ethers.formatEther(
      allEmissionValues.totalGHGEmission.toString()
    ),
  };
}



async function userEmission(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  // Get the length of emission data for the user
  const emissionDataLength: number = await basicGreenContractInstance.getAllPlantEmissionLength(
    address
  );

  console.log('emissionDataLength', emissionDataLength);
  

  // Retrieve all emission data for the user
  const allEmissionData: any[] = [];

  for (let i = 0; i < emissionDataLength; i++) {
    const allEmission = await basicGreenContractInstance.allEmission(
      address,
      i
    );
    
    

    allEmissionData.push({
      energyConsumptionInKWh: ethers.formatEther(
        allEmission.energyConsumptionInKWh.toString()
      ),
      quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
        allEmission.quantifiedGHGEmissionsInBaselineScenario.toString()
      ),
      reportingPeriod: allEmission.reportingPeriod,
      totalGHGEmission: ethers.formatEther(
        allEmission.totalGHGEmission.toString()
      ),
      isVerified: allEmission.isVerified,
    });
  }

  return allEmissionData;
}

async function allEmission(address: string, index: number) {
  const { basicGreenContractInstance } = await getContracts();
  const allEmission = await basicGreenContractInstance.allEmission(
    address,
    index
  );
  return {
    reportingPeriod: allEmission.reportingPeriod,
    energyConsumptionInKWh: ethers.formatEther(
      allEmission.energyConsumptionInKWh.toString()
    ),
    quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
      allEmission.quantifiedGHGEmissionsInBaselineScenario.toString()
    ),
    totalGHGEmission: ethers.formatEther(
      allEmission.totalGHGEmission.toString()
    ),
    isVerified: allEmission.isVerified,
  };
}


async function allEmissionValues(address: string) {
  const { basicGreenContractInstance } = await getContracts();
  const allEmissionValues = await basicGreenContractInstance.allEmissionValues(
    address
  );
  return {
    reportingPeriod: allEmissionValues.reportingPeriod,
    energyConsumptionInKWh: ethers.formatEther(
      allEmissionValues.energyConsumptionInKWh.toString()
    ),
    quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
      allEmissionValues.quantifiedGHGEmissionsInBaselineScenario.toString()
    ),
    totalGHGEmission: ethers.formatEther(
      allEmissionValues.totalGHGEmission.toString()
    ),
    isVerified: allEmissionValues.isVerified,
  };
}

async function getAllUserEmissionLength(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const allUserEmissionLength =
    await basicGreenContractInstance.getAllUserEmissionLength(address);
  return {
    allUserEmissionLength: ethers.formatEther(
      allUserEmissionLength.toString()
    ),
  };
}


async function dynamicAttributes(address: string, index: number) {
  const { basicGreenContractInstance } = await getContracts();

  const dynamicAttributes =
    await basicGreenContractInstance.dynamicAttributes(address, index);
    const conditions = await basicGreenContractInstance.conditions(
      address,
      index
    );
  return {
    energyConsumptionInKWh: ethers.formatEther(
      dynamicAttributes.energyConsumptionInKWh.toString()
    ),
    quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
      dynamicAttributes.quantifiedGHGEmissionsInBaselineScenario.toString()
    ),
    reportingPeriod: dynamicAttributes.reportingPeriod,
    totalGHGEmission: ethers.formatEther(
      dynamicAttributes.totalGHGEmission.toString()
    ),
    isValidated: conditions.isValidated,
    isVerified: conditions.isVerified,
    isRejected: conditions.isRejected,
    isCarbonCreditIssued: conditions.isCarbonCreditIssued,
  };
}

async function staticPlantAttributes(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const staticPlantAttributes =
    await basicGreenContractInstance.staticPlantAttributes(address);
  // console.log(staticPlantAttributes);
  return {
    OrganizationDescription: staticPlantAttributes.organizationDescription,
    location: staticPlantAttributes.location,
    personResponsible: staticPlantAttributes.personResponsible,
    descriptionOfRenewableEnergySources:
      staticPlantAttributes.descriptionOfRenewableEnergySources,
    baseline: ethers.formatEther(
      staticPlantAttributes.baseline.toString()
    ),
  };
}

async function getStaticAttributes(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const staticAttributes =
    await basicGreenContractInstance.getStaticAttributes(address);
  return {
    staticAttributes,
  };
}

async function carbonCredits(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const carbonCredits = await basicGreenContractInstance.carbonCredits(
    address
  );
  return {
    carbonCredits: ethers.formatEther(carbonCredits.toString()),
  };
}

async function isPlantRecorded(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const isRecorded = await basicGreenContractInstance.isPlantRecorded(
    address
  );
  return {
    isRecorded,
  };
}

async function plantInfo(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const plantInfo = await basicGreenContractInstance.plantInfo(address);
  return {
    name: plantInfo.name,
    scenario: plantInfo.scenario,
  };
}

async function getAllPlantsLength() {
  const { basicGreenContractInstance } = await getContracts();

  const allPlantsLength = await basicGreenContractInstance.getAllPlantsLength();
  return {
    allPlantsLength: ethers.formatEther(allPlantsLength.toString()),
  };
}

async function getPlantEmissionData(address: string) {
  const { basicGreenContractInstance } = await getContracts();

  const plantEmissionData =
    await basicGreenContractInstance.getPlantEmissionData(address);
  return {
    plantEmissionData,
  };
}


// async function getAllEmissionDataWithInfo(): Promise<any[]> {
//   const { basicGreenContractInstance } = await getContracts();

//   const allPlantsLength: number = await basicGreenContractInstance.getAllPlantsLength();
//   const result: any[] = [];

//   for (let i = 0; i < allPlantsLength; i++) {
//     const plantAddress: string = await basicGreenContractInstance.allPlants(i);

//     // Get plant emission data
//     const emissionData: any[] = await basicGreenContractInstance.getPlantEmissionData(
//       plantAddress
//     );

//     // Get plant conditions
//     const conditions: any[] = [];

//     for (let j = 0; j < emissionData.length; j++) {
//       const condition = await basicGreenContractInstance.conditions(
//         plantAddress,
//         j
//       );
//       // console.log(condition);
//       conditions.push({
//         isValidated: condition.isValidated,
//         isVerified: condition.isVerified,
//         isRejected: condition.isRejected,
//         isCarbonCreditIssued: condition.isCarbonCreditIssued,
//       });
//     }

//     // Get plant info
//     const plantInfo: any = await basicGreenContractInstance.plantInfo(plantAddress);

//     // Combine emission data, conditions, and plant info
//     const combinedData = { emissionData, conditions, plantInfo };
//     result.push(combinedData);
//   }

//   return result;
// }

async function getAllPlantsData() {
  const { basicGreenContractInstance } = await getContracts();

  const getAllInfo =
    await basicGreenContractInstance.getAllPlantsData();
    return {
      getAllInfo,
    };
}
async function getPlantsData(start: number, count: number) {
  const { basicGreenContractInstance } = await getContracts();

  const getAllInfo =
    await basicGreenContractInstance.getPlantsData(start, count);
    return {
      getAllInfo,
    };
}
async function getAllEmissionData() {
  const { basicGreenContractInstance } = await getContracts();
  const allPlantsLength = await basicGreenContractInstance.getAllPlantsLength();
  const allEmissionData = [];

  for (let i = 0; i < allPlantsLength; i++) {
    const plantAddress = await basicGreenContractInstance.allPlants(i);
    const plantEmissionData = await basicGreenContractInstance.getPlantEmissionData(plantAddress);

    // Get plant conditions
    const conditions = [];
    for (let j = 0; j < plantEmissionData.length; j++) {
      const condition = await basicGreenContractInstance.conditions(plantAddress, j);
      conditions.push({
        isValidated: condition.isValidated,
        isVerified: condition.isVerified,
        isRejected: condition.isRejected,
        isCarbonCreditIssued: condition.isCarbonCreditIssued,
      });
    }

    // Get plant info
    const plantInfo = await basicGreenContractInstance.plantInfo(plantAddress);

    allEmissionData.push({
      plantAddress,
      emissionData: plantEmissionData,
      conditions: conditions,
      plantInfo: plantInfo,
    });
  }

  return allEmissionData;
}




async function getAllPlantEmissionLength(address: string) {
  const { basicGreenContractInstance } = await getContracts();
  const allPlantEmissionLength =
    await basicGreenContractInstance.getAllPlantEmissionLength(address);
  return {
    allPlantEmissionLength: ethers.formatEther(
      allPlantEmissionLength.toString()
    ),
  };
}

async function balanceOf(address: string) {
  const { closeCarbonTokenContractInstance } = await getContracts();
  const balance = await closeCarbonTokenContractInstance.balanceOf(address);
  return balance;
}

async function burn(
  amountToBurn: number
) {
  const { signer, closeCarbonTokenContractInstance } = await getContracts();

  const txKYC = await closeCarbonTokenContractInstance.burn(amountToBurn);
  return {
    txKYC,
  };
}

export {
  // performKYC,
  // recordPlant,
  // rejectValidation,
  encodeStaticData,
  encodedDynamicAttributes,
  // encodeGreenIndirectDynamicAttributes,
  pinLinksToIPFS,
  // grantRole,
  // validate,
  // verifyAndMint,
  // issueCarbonCredits,
  getAllEmissionValues,
  userEmission,
  allEmission,
  allEmissionValues,
  dynamicAttributes,
  staticPlantAttributes,
  carbonCredits,
  getPlantEmissionData,
  conditions,
  getAllPlantEmissionLength,
  plantInfo,
  // getAllPlantsData,
  isPlantRecorded,
  getAllUserEmissionLength,
  getAllPlantsLength,
  // getUserEmissions,
  balanceOf,
  getStaticAttributes,
  getPlantsData
};


getPlantEmissionData("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
  .then((result) => {
    // console.log(result);
  })
  .catch((err) => {
    console.log(err);
  });


// getAllUserEmissionLength("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// getUserEmissions()
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   }); 

// isPlantRecorded("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
// .then((result) => {
//   console.log(result);
// })
// .catch((err) => {
//   console.log(err);
// });

// getAllEmissionDataWithInfo()
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// getAllEmissionData()
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// plantInfo("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// getAllPlantEmissionLength("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// issueCarbonCredits("0x56Fec7a6027603fcaB2867A41eD30a094b05d62A")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// conditions("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538", 0)
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// validate("0x56Fec7a6027603fcaB2867A41eD30a094b05d62A")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// verifyAndMint("0x56Fec7a6027603fcaB2867A41eD30a094b05d62A")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// getAllEmissionData()
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// userEmission("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// performKYC(
//   "0x56Fec7a6027603fcaB2867A41eD30a094b05d62A",

//   "0x00000000000000000000000000000000000000000000000000000000000000c000000000000000000000000000000000000000000000000000000000000001000000000000000000000000000000000000000000000000000000000000000140000000000000000000000000000000000000000000000000000000000000018000000000000000000000000000000000000000000000000000000000000001c00000000000000000000000000000000000000000000000000000000000001388000000000000000000000000000000000000000000000000000000000000000b4167726963756c747572650000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000005496e6469610000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000005526168756c0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000003516d5a0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000c536f6c617220456e657267790000000000000000000000000000000000000000",
//   "Agriculture",
//   "Green Project"
// )
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });
// recordPlant(
//   ["0x56Fec7a6027603fcaB2867A41eD30a094b05d62A"],
//   [
//     "0x00000000000000000000000000000000000000000000000000000000000000800000000000000000000000000000000000000000000000056bc75e2d6310000000000000000000000000000000000000000000000000000ad78ebc5ac620000000000000000000000000000000000000000000000000010f0cf064dd5920000000000000000000000000000000000000000000000000000000000000000000043230323100000000000000000000000000000000000000000000000000000000",
//   ]
// )
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// carbonCredits("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// getAllEmissionValues("0xc06fB8C480B0B7A5a4D96B7822618e8A9C734323")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// staticPlantAttributes("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// grantRole(1, "0xf7de326EF2898962C879DF0e7e5Acc599cF03907")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// dynamicAttributes("0x9923a33D6Aa8e6De61A3322c5348d3b05966f538", 0)
//   // "0x9923a33D6Aa8e6De61A3322c5348d3b05966f538"
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// encodeStaticData("Agriculture", "India", "Rahul", "QmZ", "Solar Energy", 5000);
// encodedDynamicAttributes("2021", 100, 200, 5000);
