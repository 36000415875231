import SideBar from "../../components/UserDashboard/SideBar";
import "../../styles/UserDashboard/Profile.scss";
import { useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Tabs, Tab } from "@mui/material";
import {
  getBanks,
  getKyc,
  getUserInfo,
  saveBankDetails,
  updateUserInfo,
  verifyAccount,
} from "../../apis/General/users";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Kyc from "../../components/UserDashboard/Kyc";
import Search from "../../components/UserDashboard/Search";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import {
  updateUserKyc,
  updateUserKycUser,
} from "../../apis/AdminDashboard/users";
import { countries } from "../../data/countries";

// function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`simple-tabpanel-${index}`}
//         aria-labelledby={`simple-tab-${index}`}
//         {...other}
//       >
//         {value === index && (
//           <Box sx={{ p: 3 }}>
//             <Typography>{children}</Typography>
//           </Box>
//         )}
//       </div>
//     );
//   }

//   TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
//   };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  const auth = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  console.log("pathname", pathname);

  const [value, setValue] = useState(0);
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUserName] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(false);
  const [organizationDescription, setOrganizationDescription] = useState("");
  const [quantifiedBasedYearInventory, setQuantifiedBasedYearInventory] =
    useState("");
  const [publicKey, setPublicKey] = useState("");
  const [pdrDoc, setPdrDoc] = useState("");
  const [facilityCategory, setFacilityCategory] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [uplaodLoading, setUploadLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [banks, setBanks] = useState([]);
  const [accountNum, setAccountNum] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [accName, setAccName] = useState("");
  const [justAddedBankDetails, setJustAddedBankDetails] = useState(false);
  const [bankDetailsFilled, setBankDetailsFilled] = useState(false);
  const [kycStatus, setKycStatus] = useState(false);

  const [facilityName, setFacilityName] = useState("");
  const [facilityDescription, setFacilityDescription] = useState("");
  const [location, setLocation] = useState("");
  const [greenProjectLicenseId, setGreenProjectLicenseId] = useState("");
  const [projectStartDate, setProjectStartDate] = useState("");
  const [projectEndDate, setProjectEndDate] = useState("");
  const [fuelSource, setFuelSource] = useState("");
  const [renewableEnergySolutions, setRenewableEnergySolutions] = useState("");
  const [baseline, setBaseline] = useState("");
  const [fuelSourceBaseline, setFuelSourceBaseline] = useState("");
  const [additonalInfo, setAdditonalInfo] = useState("");
  const [sustainabilitySolution, setSustainabilitySolution] = useState("");
  const [doubleCounting, setDoubleCounting] = useState("");

  const open2 = Boolean(anchorEl2);
  const mediaRef = useRef(null);

  const clickFileUpload = (e, documentTypeSent) => {
    setUploadLoading(true);

    let files = e.target.files;
    let allUploadedFiles = images;

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            console.log("result", result);
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                {
                  media_type: "docx",
                  folder: "word_documents",
                  user_id: auth?.userInfo?.id,
                  contents: [result],
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth ? auth.token : ""}`,
                  },
                }
              )
              .then((response) => {
                console.log("response file uploaded", response);
                if (response?.data?.body?.success === true) {
                  if (documentTypeSent === "projectDescriptionReport") {
                    setPdrDoc(response?.data?.body?.data[0]);
                    handleClose2();
                    alert("Project description report uploaded");
                  } else if (
                    documentTypeSent === "quantifiedBaseYearInventory"
                  ) {
                    setQuantifiedBasedYearInventory(
                      response?.data?.body?.data[0]
                    );
                    handleClose();
                    alert("Quantified base year inventory uploaded");
                  } else {
                    toast.error("document type not specified");
                  }
                }
                setUploadLoading(false);
              })
              .catch((err) => {
                setUploadLoading(false);
              });
          }
        });
      }
    }
  };

  useEffect(() => {
    console.log("pdrDoc", pdrDoc);
  }, [pdrDoc]);
  useEffect(() => {
    console.log("quantifiedBasedYearInventory", quantifiedBasedYearInventory);
  }, [quantifiedBasedYearInventory]);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const fetchUserInfo = async () => {
    const response = await getUserInfo(auth?.userInfo?.id, auth?.token);
    console.log('getUser', response);
    const response2 = await getKyc(auth?.userInfo?.id, auth?.token);
    console.log("getkyc", response2);
    setUser(response?.data?.message);
    setFirstName(response?.data?.message?.firstName);
    setLastName(response?.data?.message?.lastName);
    setUserName(response?.data?.message?.username);
    setAddress(response?.data?.message?.address);
    setCountryCode(response?.data?.message?.countryCode);
    setPhone(response?.data?.message?.phoneNumber);
    setCompanyName(response?.data?.message?.companyName);
    setBankDetailsFilled(response?.data?.message?.bankDetailsFilled);
    setCountry(response?.data?.message?.bankDetails?.bankCountry);
    setSelectedBank(response?.data?.message?.bankDetails?.bankCode);
    setAccountNum(response?.data?.message?.bankDetails?.accountNumber);
    setAccName(response?.data?.message?.bankDetails?.bankFullName);
    setKycStatus(response?.data?.message?.kycStatus);


    setFacilityName(response2?.data?.message?.companyName);
    setFacilityDescription(response2?.data?.message?.facilityCategory === "green" ? response2?.data?.message?.greenProjectDescription : response2?.data?.message?.facilityDescription);
    setLocation(response2?.data?.message?.facilityCategory === "green" ? response2?.data?.message?.greenProjectLocation : response2?.data?.message?.facilityLocation);
    setGreenProjectLicenseId(response2?.data?.message?.greenProjectLicenseId);
    setProjectStartDate(response2?.data?.message?.facilityCategory === "green" ? response2?.data?.message?.greenProjectStartDate : response2?.data?.message?.projectStartDate);
    setProjectEndDate(response2?.data?.message?.facilityCategory === "green" ? response2?.data?.message?.greenProjectEndDate : response2?.data?.message?.facilityEndDate);
    setFuelSource(response2?.data?.message?.projectFuelSource);
    setRenewableEnergySolutions(response2?.data?.message?.renewableEnergySolutions);
    setBaseline(response2?.data?.message?.baseLineScenario);
    setFuelSourceBaseline(response2?.data?.message?.facilityCategory === "green" ? response2?.data?.message?.baseLineEnergySource : response2?.data?.message?.baseLineFuelSource);
    setAdditonalInfo(response2?.data?.message?.additionalInfomation);
    setSustainabilitySolution(response2?.data?.message?.sustainabilitySolution);
    setFacilityCategory(response2?.data?.message?.facilityCategory);
    setPublicKey(response2?.data?.message?.walletAddress);
  };

  const handleGetBank = async () => {
    if (!!country) {
      setBankLoading(true);
      const response = await getBanks(country, auth?.userInfo?.id);
      console.log("countries", response);

      if (response?.data?.status === true) {
        setBanks(response?.data?.data);
      } else {
        alert("Unable to fetch banks");
      }
      setBankLoading(false);
    }
  };

  useEffect(() => {
    handleGetBank();
  }, [country]);

  useEffect(() => {
    fetchUserInfo();
  }, []);
  useEffect(() => {
    console.log('accountNum', accountNum);
    
  }, [accountNum]);

  const handleUserUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("username", username);

    if(justAddedBankDetails) {
      await handleSaveBankDetails();
    }
    const response = await updateUserInfo(
      firstName,
      lastName,
      username,
      address,
      phone,
      countryCode,
      auth?.token
    );

    console.log("update user", response);
    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message);
    } else {
      alert(response?.data?.message);
    }
    setLoading(false);
  };

  const handleKycUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await updateUserKycUser(
      "user update",
      location,
      quantifiedBasedYearInventory,
      pdrDoc,
      auth?.userInfo?.id,
      auth?.token
    );

    console.log('updateUserKycUser', response);

    if (response?.data?.statusCode === 200) {
      // update(ref(db, `kycStatus/${kycKeys[0]}`), {
      //   status: "completed",
      // });
      toast.success("Kyc updated successfully");
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const handleVerifyAccount = async () => {
    setVerifyLoading(true);
    const response = await verifyAccount(accountNum, selectedBank, auth?.token);
    console.log("respo", response);

    if (response?.data?.status === true) {
      setAccName(response?.data?.data?.account_name);
      setJustAddedBankDetails(true)
    } else {
      alert("Unable verify account number");
    }

    setVerifyLoading(false);
  };

  const handleSaveBankDetails = async () => {
    setLoading(true);
    console.log({
      accountNum,
      id: auth?.userInfo?.id,
      country,
      selectedBank,
      token: auth?.token
    });
    
    const response = await saveBankDetails(
      accountNum,
      auth?.userInfo?.id,
      country,
      selectedBank,
      auth?.token
    );
    console.log("respo", response);

    if (response?.data?.statusCode === 200) {
      setAccName(response?.data?.data?.account_name);
    } else {
      alert("Unable to save bank details");
    }

    setLoading(false);
  };

  return (
    <div className="profile">
      <div className="sidebar_container">
        <SideBar />
      </div>
      <div className="main">
        <Search />

        <div className="name">
          <div className="abv">
            {firstName?.split("")[0]}
            {lastName?.split("")[0]}
          </div>
          <div className="name_inner">
            <div className="">
              {firstName?.split("")[0]}
              {lastName?.split("")[0]}
            </div>
            <div>{auth?.userInfo?.email}</div>
          </div>
        </div>

        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          className="tab_label_container"
        >
          <Tab
            className={value === 0 ? "tab_label" : "tab_label tab_active"}
            label="Personal Details"
            {...a11yProps(0)}
          />
          <Tab
            className="tab_label"
            label="Company Details"
            {...a11yProps(1)}
          />
          <Tab className="tab_label" label="KYC" {...a11yProps(2)} />
          <Tab className="tab_label" label="AI Update" {...a11yProps(3)} />
        </Tabs>
        {/* <TabPanel value={value} index={0}> */}
        {value === 0 && (
          <form onSubmit={handleUserUpdate} className="profile_form">
            <div className="input_container">
              <div>First Name*</div>
              <input
                type="text"
                placeholder="First name"
                value={firstName}
                name="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="input_container">
              <div>Last Name*</div>
              <input
                type="text"
                placeholder="Last name"
                value={lastName}
                name="lastName"
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="input_container">
              <div>User Name*</div>
              <input
                type="text"
                placeholder="Username"
                value={username}
                name="username"
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div className="input_container">
              <div>Address*</div>
              <input
                type="text"
                placeholder="Postal Address"
                value={address}
                name="address"
                onChange={(e) => setAddress(e.target.value)}
                required
              />
            </div>
            <div className="input_container">
              <div>Phone Number*</div>
              <div className="input_group">
                <input
                  type="text"
                  className="cc-input"
                  placeholder="Country Code"
                  value={countryCode}
                  name="countryCode"
                  onChange={(e) => setCountryCode(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={phone}
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="input_container">
              <div>Country</div>
              <select
                name=""
                id=""
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                required
                disabled={bankDetailsFilled}
              >
                <option value="">Country</option>
                <option value="NGN">Nigeria</option>
                <option value="GHS">Ghana</option>
                <option value="KES">Kenya</option>
                <option value="XOF">Côte d'Ivoire</option>
                <option value="EGP">Egypt</option>
                <option value="ZAR">South Africa</option>
              </select>
            </div>
            <div className="input_container">
              <div>Select bank</div>
              <select
                name=""
                id=""
                value={selectedBank}
                onChange={(e) => setSelectedBank(e.target.value)}
                required
                disabled={bankDetailsFilled}
              >
                <option value="">{bankLoading ? "Loading..." : !country ? "Select country first" : "Bank"}</option>
                {/* {banks?.length < 1 && 
                  <option value="">Please select country first</option>
                  } */}
                {banks?.map((bank) => (
                  <option value={bank?.code}>{bank?.name}</option>
                ))}
              </select>
            </div>
            <div className="input_container">
              <div>Account number</div>
              <div className="input_container__verify">
                <input
                  type="text"
                  placeholder="Enter account number"
                  value={accountNum}
                  name="address"
                  onChange={(e) => setAccountNum(e.target.value)}
                  required
                  disabled={bankDetailsFilled}
                />
                <button onClick={handleVerifyAccount} disabled={verifyLoading || bankDetailsFilled}>
                  {verifyLoading ? "Loading..." : "Verify"}
                </button>
              </div>
            </div>

            <div className="input_container">
              <div>Account name</div>
              <input
                type="text"
                placeholder="Enter account name"
                value={accName}
                name="address"
                // onChange={(e) => setAddress(e.target.value)}
                // required
                disabled
              />
            </div>

            <button
              type="submit"
              className="settings_submit"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save Changes"}
            </button>
          </form>
        )}
        {value === 1 && (
          <form onSubmit={handleUserUpdate} className="profile_form">
            <div className="input_container">
              <div>Company Name</div>
              <input
                type="text"
                placeholder="Company name"
                value={companyName}
                name="companyName"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            {/* <div className="input_container">
            <div>Organisation Desc.</div>
            <textarea type="text" placeholder="Organisation Description"></textarea>
          </div> */}

            {/* <div className="input_container">
            <div>Phone Number</div>
            <div className="input_group">
              <input type="text" placeholder="Country Code" />
              <input
                type="text"
                placeholder="Phone Number"
              />
            </div>
          </div> */}
            {/* <div className="input_container">
            <div>Postal Address</div>
            <input type="text" placeholder="Postal Address" />
          </div> */}

            <button type="submit" className="settings_submit">
              Save Changes
            </button>
          </form>
        )}
        {value === 2 && (
          <form className="profile_form" onSubmit={handleKycUpdate}>
            <div className="input_container">
              <div>
              Project/Facility name
              </div>
              <input
                type="text"
                value={facilityName}
                name="location"
                onChange={(e) => setFacilityName(e.target.value)}
                disabled={!pathname.includes("editable")}
              />
            </div>
            <div className="input_container">
              <div>
              {facilityCategory === "non-green" ? "Facility/Project description" : facilityCategory === "carbon-monitoring" ? "Facility description" : "Green project description"}

              </div>
              <input
                type="text"
                value={facilityDescription}
                name="location"
                onChange={(e) => setFacilityDescription(e.target.value)}
                disabled={!pathname.includes("editable")}
              />
            </div>
            
            <div className="input_container">
              <div>
              {facilityCategory === "non-green" ? "Facility/Project location" : facilityCategory === "carbon-monitoring" ? "Facility location" : "Green project location"}

              </div>
              <input
                type="text"
                value={location}
                name="location"
                onChange={(e) => setLocation(e.target.value)}
                disabled={!pathname.includes("editable")}
              />
            </div>

            {facilityCategory === "green" && <div className="input_container">
              <div>
              Green project license/ID
              </div>
              <input
                type="text"
                value={greenProjectLicenseId}
                name="location"
                onChange={(e) => setGreenProjectLicenseId(e.target.value)}
                disabled={!pathname.includes("editable")}
              />
            </div>}

            <div className="input_container">
              <div>
              {facilityCategory === "non-green" ? "Facility/Project start date" : facilityCategory === "carbon-monitoring" ? "Facility start date" : "Green project start date"}
              </div>
              <input
                type="date"
                placeholder="wallet Address"
                onChange={(e) => setProjectStartDate(e.target.value)}
                value={projectStartDate}
                name="publicKey"
                disabled
              />
            </div>
            <div className="input_container">
              <div>
              {facilityCategory === "non-green" ? "Facility/Project end date" : facilityCategory === "carbon-monitoring" ? "Facility end date" : "Green project end date"}
              </div>
              <input
                type="date"
                placeholder="wallet Address"
                onChange={(e) => setProjectEndDate(e.target.value)}
                value={projectEndDate}
                name="publicKey"
                disabled
              />
            </div>
            {facilityCategory === "non-green" && <div className="input_container">
              <div>
              Facility/Project fuel source
                            </div>
              <input
                type="text"
                placeholder="wallet Address"
                onChange={(e) => setFuelSource(e.target.value)}
                value={fuelSource}
                name="publicKey"
                disabled
              />
            </div>}
            {facilityCategory === "green" && <div className="input_container">
              <div>
              Green project/ Renewable energy

                            </div>
              <input
                type="text"
                placeholder="wallet Address"
                onChange={(e) => setRenewableEnergySolutions(e.target.value)}
                value={renewableEnergySolutions}
                name="publicKey"
                disabled
              />
            </div>}
            {facilityCategory !== "carbon-monitoring" && <div className="input_container">
              <div>
              Baseline scenario

                            </div>
              <input
                type="text"
                placeholder="wallet Address"
                value={baseline}
                onChange={(e) =>
                  setBaseline(
                    e?.target?.value
                  )
                }
                name="publicKey"
                disabled
              />
            </div>}
            {facilityCategory !== "carbon-monitoring" && <div className="input_container">
              <div>
              {facilityCategory === "non-green" ? "Fuel source in baseline scenario" : "Energy source in baseline scenario"}


                            </div>
              <input
                type="text"
                placeholder="wallet Address"
                value={fuelSourceBaseline}
                onChange={(e) =>
                  setFuelSourceBaseline(e?.target?.value)
                }
                name="publicKey"
                disabled
              />
            </div>}
            {facilityCategory !== "carbon-monitoring" && <div className="input_container">
              <div>
              Additional information relevant to the project

                            </div>
              <input
                type="text"
                placeholder="wallet Address"
                value={additonalInfo}
                  onChange={(e) =>
                    setAdditonalInfo(e?.target?.value)
                  }
                name="publicKey"
                disabled
              />
            </div>}
            {facilityCategory === "non-green" && <div className="input_container">
              <div>
              Sustainability solution

                            </div>
              <input
                type="text"
                placeholder="wallet Address"
                value={sustainabilitySolution}
                onChange={(e) => setSustainabilitySolution(e.target.value)}
                name="publicKey"
                disabled
              />
            </div>}
            <div className="input_container">
              <div>Wallet Address</div>
              <input
                type="text"
                placeholder="wallet Address"
                value={publicKey}
                name="publicKey"
                disabled
              />
            </div>

{kycStatus === "rejected" &&
            <button type="submit" className="settings_submit" disabled={loading}>
              {loading ? "Loading..." : "Save Changes"}
            </button>
}
          </form>
        )}

{value === 3 && (
          <form onSubmit={handleUserUpdate} className="profile_form">
            <div className="input_container">
              <div>Total power consumed (kWh)</div>
              <input
                type="text"
                placeholder="Total power consumed (kWh)"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="input_container">
              <div>Production volume (units)</div>
              <input
                type="text"
                placeholder="Production volume (units)"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="input_container">
              <div>Lighting power consumption (Watt)</div>
              <input
                type="text"
                placeholder="Lighting power consumption (Watt)"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="input_container">
              <div>Type of machinery and their consumptions</div>
              <input
                type="text"
                placeholder="Type of machinery and their consumptions"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>

            <div className="profile_form__input-grid">
              <div>
              <div>Numbers of Heaters</div>
              <input
                type="text"
                placeholder="Numbers of Heaters"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
              </div>
              <div>
              <div>Numbers of Ventilation</div>
              <input
                type="text"
                placeholder="Numbers of Ventilation"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
              </div>
            </div>
            <div className="profile_form__input-grid">
              <div>
              <div>Numbers of Air Conditioning</div>
              <input
                type="text"
                placeholder="Numbers of Air Conditioning"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
              </div>
              <div>
              <div>Operating hours (hours)</div>
              <input
                type="text"
                placeholder="Operating hours (hours)"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
              </div>
            </div>
            <div className="profile_form__input-grid">
              <div>
              <div>Number of employees</div>
              <input
                type="text"
                placeholder="Number of employees"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
              </div>
              <div>
              <div>Number of shifts</div>
              <input
                type="text"
                placeholder="Number of shifts"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
              </div>
            </div>
            <div className="profile_form__input-grid">
              <div>
              <div>Number of employees per shift</div>
              <input
                type="text"
                placeholder="Number of employees per shift"
                // value={firstName}
                name="firstName"
                // onChange={(e) => setFirstName(e.target.value)}
                required
              />
              </div>
              
            </div>
            </form>
)}
        {/* </TabPanel> */}
      </div>

    </div>
  );
};

export default Settings;
