import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useEffect } from "react";
import comicSansFont from "../../fonts/Handlee-Regular.ttf";

const SlaAgreement = ({ firstSignature, secondSignature, companyName, address }) => {
  console.log("firstSignature", firstSignature);
  console.log("secondSignature", secondSignature);

  useEffect(() => {
    Font.register({ family: "comicSansO", src: comicSansFont });
  }, []);
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="portrait">
        <View style={styles.section}>
          <Text style={styles.largeLineHeight}>
            This Service Level Agreement is a legally binding agreement that
            governs the relationship between Netzence Sustainability Limited and
            the User.
          </Text>
          <Text>
            THIS <Text style={styles.bold}>SERVICE LEVEL AGREEMENT</Text> is
            made <Text style={styles.bold}>BETWEEN:</Text>
          </Text>
          <Text>
           
                1. <Text style={styles.bold}>NETZENCE SUSTAINABILITY LIMITED</Text>
                , a limited liability company incorporated under the laws of the
                Federal Republic of Nigeria and having its principal place of
                business at 7, Tema Street, Wuse Zone 6, Abuja, FCT, Nigeria
                (hereinafter referred to as the{" "}
                <Text style={styles.bold}>“SERVICE PROVIDER”</Text> which
                expression shall where the context so admits include its
                successors-in-title and assigns), of the first part.
          </Text>
          <Text>AND</Text>
          <Text>
               2.  {companyName}, a registered company or organisation with
                its registered office at {address} (hereinafter
                referred to as the “USER”, which expression shall where the
                context so admits include its successors-in-title and assigns),
                of the second part.
          </Text>
          <Text>
            The <Text style={styles.bold}>SERVICE PROVIDER</Text> and the{" "}
            <Text style={styles.bold}>USER</Text> shall each be referred to as a{" "}
            <Text style={styles.bold}>“Party”</Text> and collectively the{" "}
            <Text style={styles.bold}>“Parties”</Text>.
          </Text>
          <Text style={styles.space}></Text>
          <Text>WHEREAS</Text>
          <Text>
            A. The Parties are desirous of entering into this Agreement for the
            provision of real-time carbon emission data monitoring services on
            the USER’s facility. The SERVICE PROVIDER has created an IoT-enabled
            system (the “Device”) for the transmission of carbon emission data,
            and an automated solution (“CloseCarbon Platform”) to monitor and
            track carbon emission data securely in a simplified manner. The
            Device and the CloseCarbon Platform altogether are hereafter known
            as the “Solution”.
          </Text>
          <Text>
            B. The SERVICE PROVIDER undertakes to provide the Service (more
            particularly detailed in Schedule 1 below) to the USER subject to
            the underlisted terms and conditions.{" "}
          </Text>
          <Text style={styles.space}></Text>

          <Text>NOW IT IS AGREED as follows: </Text>
          <Text style={styles.space}></Text>

          <Text>
            1. <Text style={styles.bold}>DEFINITIONS</Text>{" "}
          </Text>
          <Text>
            In this Agreement, unless expressly indicated otherwise, the
            following words and expressions have the meanings specified below:
          </Text>
          <Text>
            a. <Text style={styles.bold}>“Agreement”</Text> means this Service
            Level Agreement.{" "}
          </Text>
          <Text>
            b. <Text style={styles.bold}>“Business Day”</Text> means any day
            except any Saturday, any Sunday, or any day which is declared by the
            Federal or State government of Nigeria to be a public holiday.
          </Text>
          <Text>
            c. <Text style={styles.bold}>“CCRT”</Text> is an incentive issued to
            USER based on their emission reduction. 1 tonne of CO2 reduced = 1
            CCRT.
          </Text>
          <Text>
            d. <Text style={styles.bold}>“CCV”</Text> means an accredited
            verification body registered on CloseCarbon.
          </Text>
          <Text>
            e. <Text style={styles.bold}>“Confidential Information”</Text> means
            all information relating to the Disclosing Party which is obtained,
            whether in writing, pictorially, in machine readable form or orally
            or by observation in connection with this Agreement, including but
            without limitation, financial information, know-how, processes,
            ideas, intellectual property (irrespective of its registration or
            patentability status), schematics, trade secrets, technology, USER
            list (potential or actual) and other USER or customer-related
            information, sales statistics, market, market intelligence,
            marketing and other business strategies and other commercial
            information of a confidential nature, but does not include
            information which is known to the Receiving Party without any
            limitation or restriction on use or disclosure before receipt of
            such information from or on behalf of the Disclosing Party or
            becomes publicly available, other than as a breach of this
            Agreement.
          </Text>
          <Text>
            f. <Text style={styles.bold}>“Disclosing Party”</Text> means any one
            of the Parties under this Agreement who discloses Confidential
            Information to the other Party under this Agreement.
          </Text>
          <Text>
            g. <Text style={styles.bold}>"Effective Date"</Text> shall mean the
            date of execution of this Agreement by both Parties.
          </Text>
          <Text>
            h. <Text style={styles.bold}>“Facility”</Text> means the physical
            location(s), including but not limited to the{" "}
            <Text style={styles.bold}>USER’s</Text> premises or site(s),
            designated by the <Text style={styles.bold}>USER</Text> for the
            purpose of installing and utilising the Device as outlined in this
            Agreement.
          </Text>
          <Text>
            i. <Text style={styles.bold}>“Intellectual Property Rights”</Text>{" "}
            means all intellectual property of whatever nature owned and/or
            controlled by either Party, including without limiting the
            generality of the foregoing, a Party’s right, title and interest in
            and to all trademarks, technology, software, application, web pages,
            trade secrets, logos, systems, methods, procedures, processes,
            design, layout, trade names, domain names, styles, insignias,
            compilations, designs, patents, and copyrights whether registered or
            unregistered.
          </Text>
          <Text>
            j. <Text style={styles.bold}>“Receiving Party”</Text> means any
            person receiving Confidential Information from a Party under this
            Agreement.
          </Text>
          <Text>
            k. <Text style={styles.bold}>“Representatives”</Text> shall include
            the Parties’ directors, subsidiaries, holding company, affiliates,
            officers, employees, duly authorised agents, consultants, attorneys,
            accountants, advisors, associates or other authorised persons that
            are professionally involved in the Service and who are obligated to
            hold such information in trust and confidence.
          </Text>
          <Text>
            l. <Text style={styles.bold}>“Solution”</Text> means the Device
            installed on the <Text style={styles.bold}>USER’s</Text> facility,
            and the CloseCarbon Platform <Text style={styles.bold}>USER</Text>{" "}
            account altogether as provided by the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text>.
          </Text>
          <Text>
            m. <Text style={styles.bold}>“Sub-CCV"</Text> means a verifier
            registered under a CCV.
          </Text>
          <Text style={styles.space}></Text>

          <Text>In this Agreement, a reference to: </Text>
          <Text>
            i. A statutory provision includes a reference to the statutory
            provision as modified from time to time (whether before or after the
            date of this Agreement) and any subsidiary legislation made under
            the statutory provision (whether before or after the date of this
            Agreement).
          </Text>
          <Text>
            ii. A person includes a reference to any individual, body corporate,
            unincorporated association or partnership; and in the case of an
            individual, to that person’s legal personal representatives,
            successors or assigns.
          </Text>
          <Text>
            iii. A clause, or schedule is, unless the context otherwise
            requires, a reference to a clause, or schedule to this Agreement. A
            schedule shall form part of this Agreement and shall be read as if
            herein specifically incorporated.
          </Text>
          <Text>
            iv. All references to the singular shall include the plural and vice
            versa and one gender shall include the other gender.
          </Text>
          <Text>
            v. The words “including” and “includes” shall be deemed to be
            followed by the expression “(but not limited to)”.
          </Text>
          <Text>
            vi. If any definition is a substantive provision conferring rights
            or imposing obligations on any Party, effect shall be given to it as
            if it were a substantive clause in the body of the Agreement,
            notwithstanding that it is only contained in the interpretation
            clause;
          </Text>
          <Text>
            vii. A person who is not a party to this Agreement has no right to
            enforce any term of this Agreement.{" "}
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            2. <Text style={styles.bold}>TERM</Text>{" "}
          </Text>
          <Text>
            This Agreement shall commence from the Effective Date and shall
            continue until terminated by either Party in accordance with the
            terms contained in clause 8 of this Agreement.
          </Text>
          <Text>
            3. <Text style={styles.bold}>RESPONSIBILITIES OF THE PARTIES</Text>
          </Text>
          <Text>
            <Text style={styles.bold}>3.1.</Text> Obligations of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> are as follows:
          </Text>
          <Text>
            a. Provide the <Text style={styles.bold}>USER</Text> with the
            Solution and Services as outlined in Schedule.
          </Text>
          <Text>
            b. Subject to the Terms and Conditions governing the CloseCarbon
            Platform, it shall issue CCRT to the{" "}
            <Text style={styles.bold}>USER</Text>.
          </Text>
          <Text>c. It shall ensure scheduled maintenance of the Solution.</Text>
          <Text>
            d. It shall provide the <Text style={styles.bold}>USER</Text> with a
            detailed handbook for the maintenance of the Device.
          </Text>
          <Text>
            <Text style={styles.bold}>3.2.</Text> Obligations of the USER are as
            follows:{" "}
          </Text>
          <Text>
            a. To abide strictly by the Terms and Conditions governing the
            CloseCarbon Platform.
          </Text>
          <Text>
            b. To maintain the Device installed on its property in good
            condition and shall not sell, transfer, or remove the device
            installed on its facility, or any component thereof without the
            prior written approval of the SERVICE PROVIDER.
          </Text>
          <Text>
            c. It will not by itself or through any third party dismantle,
            modify, reverse engineer or attempt repairs of the Device.
          </Text>
          <Text>
            d. To notify the <Text style={styles.bold}>SERVICE PROVIDER</Text>{" "}
            immediately in writing, with a detailed description and the
            circumstances in which it arose of any fault, loss, theft or damage
            that occurs to the Device within 24 hours upon becoming aware of the
            same.
          </Text>
          <Text>
            e. To grant access to the SERVICE PROVIDER and its representatives
            to conduct scheduled maintenance or repairs of the installed Device
            on its facility.
          </Text>
          <Text>
            f. To abide strictly by the guidelines as contained in the
            maintenance handbook.
          </Text>
          <Text>
            <Text style={styles.bold}>3.3.</Text> The Parties each agree that
            the carbon emission data as transmitted by the Solution is subject
            to verification by a CCV or Sub-CCV as the case may be, which
            decision shall be final.
          </Text>
          <Text>
            <Text style={styles.bold}>3.4.</Text> The Parties acknowledge and
            agree that the carbon emission data tracked and provided through our
            Solution will be publicly accessible on the Platform. This data is
            available for viewing and is not subject to confidentiality
            restrictions under this Agreement.
          </Text>
          <Text>
            <Text style={styles.bold}>3.5.</Text> The USER acknowledges that the
            Solution is provided at no cost to the USER. As such, the SERVICE
            PROVIDER shall not be liable for any claims, damages, losses, or
            expenses arising from or related to the use of the Solution.
          </Text>
          <Text>
            <Text style={styles.bold}>3.6.</Text> The Parties each agree to
            fulfil their respective responsibilities as outlined under this
            Agreement.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>4. REPRESENTATIONS AND WARRANTIES </Text>
          </Text>
          <Text>4.1. The SERVICE PROVIDER warrants that:</Text>
          <Text>
            a. It is duly registered and has the full capacity and corporate
            authorisation to enter into this Agreement and discharge the
            obligations and responsibilities created herein.
          </Text>
          <Text>
            b. It developed and owns the Solution, free of all third-party
            claims and encumbrances.
          </Text>
          <Text>
            c. It has the necessary expertise to provide the required support
            for use of the Solution.
          </Text>
          <Text>
            d. The Solution is fit for the purpose contemplated in this
            Agreement, and relevant digital security protection infrastructure
            has been incorporated into the framework of the Solution.
          </Text>
          <Text>
            <Text style={styles.bold}>4.2.</Text> The{" "}
            <Text style={styles.bold}>USER</Text> warrants that:
          </Text>
          <Text>
            a. It is duly registered and has the full capacity and corporate
            authorisation to enter into this Agreement and discharge the
            obligations and responsibilities created herein.
          </Text>
          <Text>
            b. The performance of its obligations will not be in breach of any
            laws, rules, or regulations.
          </Text>
          <Text>
            c. Its facility is in good working condition, and suitable for the
            installation and use of the Solution.
          </Text>
          <Text>
            <Text style={styles.bold}>5. INDEMNITY </Text>
          </Text>
          <Text>
            5.1. The <Text style={styles.bold}>USER</Text> shall indemnify and
            hold the SERVICE PROVIDER harmless from and against any damage,
            loss, liability or expense with respect to:
          </Text>
          <Text>
            a. Negligent acts or omissions by, or wilful misconducts of, the
            USER, its employees, agents or personnel.
          </Text>
          <Text>
            b. Loss, damage or unauthorised use of the Device and any parts or
            components of the Device after installation.
          </Text>
          <Text>
            c. Violations of any applicable law, statute or regulation by the
            USER or its employees, agents or personnel.
          </Text>
          <Text>
            d. All actions, claims, proceedings and all legal cost or other
            expenses arising out of any breach of the USER’s warranties or out
            of any claim by a third party based on any facts which if
            substantiated would constitute such a breach or a breach of other
            relevant legal or contractual duty by the USER.
          </Text>
          <Text>
            e. Any claims, damages, or losses resulting from or related to any
            damage to the User’s property or facilities that occurs as a result
            of the use of the IoT device or software solution.
          </Text>
          <Text>
            <Text style={styles.bold}>5.2.</Text> The{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> shall indemnify
            and hold the <Text style={styles.bold}>USER</Text> harmless from
            negligent acts or omissions by or wilful misconduct of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER’s</Text> employees,
            agents, or personnel.
          </Text>
          <Text>
            <Text style={styles.bold}>6. LIMITATION OF LIABILITY </Text>
          </Text>
          <Text>
            The <Text style={styles.bold}>SERVICE PROVIDER</Text> will not be
            liable for the actions or inactions of any third party not acting on
            its instructions; neither will a Party be liable for any actions or
            inactions not directly traceable to it.
          </Text>
          <Text>
            <Text style={styles.bold}>7. TERMINATION </Text>
          </Text>
          <Text>
            <Text style={styles.bold}>7.1.</Text> The Parties may terminate this
            Agreement in either of the following circumstances:
          </Text>
          <Text>
            a. Forthwith by the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> on giving written
            notice if the USER is in breach of the terms.
          </Text>
          <Text>
            b. At any time on giving 30 (thirty) days written notice to the
            other Party.
          </Text>
          <Text>
            <Text style={styles.bold}>7.2.</Text> On termination, the Parties
            shall be discharged from any liability for further performance of
            their obligations under this Agreement.
          </Text>
          <Text>
            Any termination of this Agreement (however occasioned) shall not
            affect any accrued rights or liabilities of any of the Parties, nor
            shall it affect the coming into force or the continuance in force of
            any provision hereof which is expressly or by implication intended
            to come into or continue in force on or after such termination.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>8. CONFIDENTIALITY </Text>
          </Text>
          <Text>
            <Text style={styles.bold}>8.1.</Text> The Parties shall treat as
            confidential, all Confidential Information and shall not divulge
            such Confidential Information to any person (except to such Party’s
            own employees on a need to know basis) without the other Parties’
            prior written consent (such consent not to be unreasonably withheld)
            provided that this Clause shall not extend to information which was
            rightfully in the possession of such Party prior to the commencement
            of the negotiations leading to this Agreement, which is already
            public knowledge or becomes so at a future date (otherwise than as a
            result of a breach of this clause).
          </Text>
          <Text>
            <Text style={styles.bold}>8.2.</Text> The Parties shall ensure that
            their employees are aware of and comply with the provisions of this
            clause. The obligations as to confidentiality shall survive the
            termination of this Agreement.
          </Text>
          <Text>
            <Text style={styles.bold}>8.3.</Text> The Receiving Party may
            disclose Confidential Information only to its Representatives who
            need to know it strictly for the purpose of fulfilling its
            obligations, on the basis that such Representatives will keep the
            same confidential, on the terms of this Agreement.
          </Text>
          <Text>
            <Text style={styles.bold}>8.4.</Text> Disclosure of Confidential
            Information as required by law or by any regulation or similar
            provision shall not amount to a breach of any obligation of
            confidentiality contained in this this Agreement, provided that the
            Receiving Party, where possible and only to the extent permitted by
            law, gives the Disclosing Party not less than five (5) business
            days’ written notice of such disclosure and shall, to the extent
            possible, disclose only that information necessary.
          </Text>
          <Text>
            <Text style={styles.bold}>8.5.</Text> Upon the termination of this
            Agreement, or at any time upon the request of the other Party, each
            Party shall return all Confidential Information in its possession or
            in the possession of a third party (over which such Party has or may
            exercise control).
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>9. FORCE MAJEURE </Text>
          </Text>
          <Text>
            <Text style={styles.bold}>9.1.</Text> Neither Party will be liable
            for any failure or delay in performing an obligation under this
            Agreement that is due to any of the following causes (which events
            and/or circumstances are hereinafter referred to as )
            <Text style={styles.bold}>“Force Majeure”</Text>, to the extent
            beyond its reasonable control: acts of God, accident, riots, war,
            terrorist acts, epidemic, pandemic, quarantine, civil commotion,
            breakdown of communication facilities, breakdown of web host,
            servers, breakdown of internet{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text>, natural
            catastrophes, governmental acts or omissions, changes in laws or
            regulations, national strikes, fire, explosion, generalised lack of
            availability of raw materials or energy.
          </Text>
          <Text>
            <Text style={styles.bold}>9.2.</Text> If the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER’s</Text> performance of
            the Agreement is delayed by a Force Majeure occurrence, the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> shall provide the{" "}
            <Text style={styles.bold}>USER</Text> with notification of the delay
            and its estimated duration within twenty-four (24) hours of the
            occurrence, via email. Within seven (7) calendar days after
            cessation of any force majeure occurrence, The{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> shall notify the{" "}
            <Text style={styles.bold}>USER</Text> in writing specifying the
            actual duration of the delay and describing its plan to minimise and
            mitigate the effect of the Force Majeure occurrence on the provision
            of the Service.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>10. RELATIONSHIP BETWEEN PARTIES </Text>
          </Text>
          <Text>
            <Text style={styles.bold}>10.1.</Text> The Parties agree that
            nothing in this Agreement shall be construed as creating a joint
            venture, partnership, franchise, employer/employee, or similar
            relationship between the Parties, or as authorising either Party to
            act as the agent of the other.
          </Text>
          <Text>
            <Text style={styles.bold}>10.2.</Text> Subject to the Terms and
            Conditions governing the use of the CloseCarbon Platform, this
            Agreement is not intended to confer on any person other than the
            SERVICE PROVIDER and the USER, any express or implied benefit or
            burden.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>11. INTELLECTUAL PROPERTY </Text>
          </Text>
          <Text>
            <Text style={styles.bold}>11.1.</Text> Nothing set forth in this
            Agreement shall constitute a transfer or assignment by one Party to
            another Party of any Intellectual Property Rights owned or otherwise
            controlled by such Party, and each Party hereby retains all its
            rights, title and interest in such Intellectual Property Rights.
          </Text>
          <Text>
            <Text style={styles.bold}>11.2.</Text> All Intellectual Property
            Rights in or related to the Solution and any other related services
            are and will remain the exclusive property of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text>, whether or not
            specifically recognised or perfected under the laws of Nigeria. This
            intellectual property includes information, hardware, source codes,
            formatting, and templates created by, or selected, coordinated, and
            arranged by the <Text style={styles.bold}>SERVICE PROVIDER</Text>{" "}
            through the exercise of authorship and by the application of
            editorial standards and judgments, involving the expenditure of
            considerable work, time, and money. The{" "}
            <Text style={styles.bold}>USER</Text> shall not take any action that
            jeopardises The <Text style={styles.bold}>SERVICE PROVIDER’s</Text>{" "}
            proprietary rights or acquire any right in the Solution, or the
            Confidential Information, as defined herein. The SERVICE PROVIDER
            will own all rights in any copy, translation, modification,
            adaptation or derivation of the Solution or other items of
            Confidential Information, including any improvement or development
            thereof as it relates to the Solution.
          </Text>
          <Text>
            <Text style={styles.bold}>11.3.</Text> All associated documentation,
            and other materials, products and modifications developed for this
            transaction is also the exclusive property of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> having the right
            to obtain and to hold in its own name, copyrights, registrations or
            such other protection as may be appropriate to the subject matter,
            and any extensions and renewals thereof. All techniques, algorithms
            and methods or rights thereto owned by the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> at the time of
            this Agreement which is executed and employed by the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> in connection with
            the Solution shall be and remain the sole property of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text>.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>12. DATA PROTECTION </Text>
          </Text>
          <Text>
            <Text style={styles.bold}>12.1.</Text> The USER hereby gives its
            consent to the SERVICE PROVIDER, its employees, staff, agents and
            sub-contractors to process its data.
          </Text>
          <Text>
            <Text style={styles.bold}>12.2.</Text> The USER excludes the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> from any and all
            liability arising from any claims, demands, suits or otherwise,
            which occur due to omission, negligence or failure of the USER in
            obtaining proper consent of its Representatives to the processing of
            their personal data by third parties, including the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text>, its employees,
            staff, agents, and sub-contractors.
          </Text>
          <Text>
            <Text style={styles.bold}>12.3.</Text> The{" "}
            <Text style={styles.bold}>USER</Text> understands that the personal
            data referenced in 13.1 above may include name, address and
            telephone number, or other personal data provided for the use of the
            Service.
          </Text>
          <Text>
            <Text style={styles.bold}>12.4.</Text> The{" "}
            <Text style={styles.bold}>USER</Text> understands that the data may
            be transferred to and processed by third parties assisting in the
            implementation, administration, and management of the Solution and
            may be stored in a location outside the jurisdiction of the data
            subject (subject to the applicable laws, regulations and rules
            enforced in Nigeria and governing such transfer and processing of
            personal data).
          </Text>
          <Text>
            <Text style={styles.bold}>12.5.</Text> The{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> undertakes to
            implement appropriate technical and organisational measures to
            protect the data against unauthorised or unlawful processing and
            against accidental loss, destruction, damage, alteration, or
            disclosure. These measures shall be appropriate to the harm which
            might result from any unauthorised or unlawful processing,
            accidental loss, destruction, or damage to the data and considering
            the nature of the data which is to be protected.
          </Text>
          <Text>
            <Text style={styles.bold}>12.6.</Text> The{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> shall take all
            reasonable steps to ensure the reliability of any third-party staff,
            employee, agent or sub-contractor who may be granted access to the
            data and shall inform them of the nature of the data.
          </Text>
          <Text>
            <Text style={styles.bold}>12.7.</Text> The USER and the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> shall each ensure
            their compliance with the Nigerian Data Protection Act 2023.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>13. NON–EXCLUSIVITY </Text>
          </Text>
          <Text>
            Nothing in this Agreement shall prohibit and/or restrict the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> from providing the
            Solution or any Services similar to those provided under this
            Agreement to any other entity.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>14. ASSIGNMENT </Text>
          </Text>
          <Text>
            The rights, benefits or obligations under this Agreement may not be
            assigned or otherwise transferred in whole or in part without the
            prior written consent of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> but shall be
            binding upon and inure to the benefit of both Parties and, where so
            permitted, their assigns or other transferees.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>15. ENTIRE AGREEMENT </Text>
          </Text>
          <Text>
            This Agreement contains the full and complete understanding of the
            Parties with respect to the subject matter hereof and supersedes all
            prior representations and understandings, whether oral or written.
            This Agreement may be modified only by a written instrument that
            specifically purports to do so and that is signed by a duly
            authorised Representative of each Party.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>16. WAIVER </Text>
          </Text>
          <Text>
            The respective rights of the Parties (whether arising under this
            Agreement or under the applicable law) shall not be capable of being
            waived or varied otherwise than by an express waiver or variation in
            writing; and in particular any failure to exercise or any delay in
            exercising any of such rights shall not operate as a waiver or
            variation of that or any other such right; any defective or partial
            exercise of any of such right shall not preclude any other or
            further exercise of that or any other such right; and no act or
            course of conduct or negotiation on the part of either Party shall
            preclude them from exercising any such right or constitute a
            suspension or variation of such right.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>17. SEVERANCE </Text>
          </Text>
          <Text>
            In the event of any one or more of the provisions of this Agreement
            being held for any reason to be invalid, illegal or unenforceable in
            any aspect, such invalidity, illegality or unenforceability shall
            not affect any other provision of this Agreement, and this Agreement
            shall be construed as if such invalid, illegal and unenforceable
            provision was not a part of this Agreement, and the Agreement shall
            be carried out as nearly as possible in accordance with its original
            terms and intent.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>18. NOTICES </Text>
          </Text>
          <Text>
            <Text style={styles.bold}>18.1.</Text> Any notice required or
            permitted by this Agreement to be given to either Party by the other
            shall be given either by personal delivery to such Party or by
            email, and addressed to:
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColForTwo}>
                <Text style={styles.tableCell}>SERVICE PROVIDER </Text>
              </View>
              <View style={styles.tableColForTwo}>
                <Text style={styles.tableCell}>USER</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColForTwo}>
                <Text style={styles.tableCell}>
                  Address: 7, Tema Street, Wuse Zone 6, Abuja, FCT, Nigeria{" "}
                  <Text style={styles.space}></Text>
                  Adressee: [to be inserted] <Text style={styles.space}></Text>
                  Telephone: [to be inserted] <Text style={styles.space}></Text>
                  Email: [to be inserted]
                </Text>
              </View>
              <View style={styles.tableColForTwo}>
                <Text style={styles.tableCell}>
                  <Text>Adress:</Text>
                  <Text>Addressee:</Text> <Text style={styles.space}></Text>
                  <Text>Telephone:</Text> <Text style={styles.space}></Text>
                  <Text>Email:</Text>
                </Text>
              </View>
            </View>
          </View>
          {/* Table */}
          <Text>
            <Text style={styles.bold}>18.2.</Text> Any notice shall be deemed to
            have been given on the day of delivery if hand delivered, and upon
            receipt of a delivery note that such email has been delivered if
            sent by email.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>19. ANTI BRIBERY AND CORRUPTION </Text>
          </Text>
          <Text>
            Each Party shall comply with all applicable anti-bribery and
            anti-corruption, anti-money laundering, combating financing of
            terrorism Laws in Nigeria.
          </Text>
          <Text>
            <Text style={styles.bold}>20. NON-CIRCUMVENTION </Text>
          </Text>
          <Text>
            The Parties acknowledge and agree that neither Party will use this
            Agreement, nor any information provided as a result of this
            Agreement to circumvent the other Party’s relationship with any of
            its partners, clients, customers, suppliers or any third parties
            whatsoever.
          </Text>
          <Text>
            <Text style={styles.bold}>21. GOVERNING LAW </Text>
          </Text>
          <Text>
            This Agreement will be governed by and construed in accordance with
            the laws in force in the Federal Republic of Nigeria as may be
            amended from time to time and all disputes, actions and other
            matters relating thereto will be determined in accordance with the
            laws of the Federal Republic of Nigeria.{" "}
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>22. DISPUTE RESOLUTION </Text>
          </Text>
          <Text>
            Any dispute, claim or disagreement (“Dispute”) arising out of, in
            connection with, or in relation to this agreement shall be resolved
            by means of good faith discussion/negotiation by the Parties. Where
            the Parties fail, or are otherwise unable, to resolve their Dispute
            within 60 (sixty) days from the date that either Party sends a
            notification of Dispute to the other Party (which period may be
            extended by mutual consent of the Parties) such Dispute shall be
            referred to and shall be finally settled by arbitration in
            accordance with the provisions of the Arbitration and Mediation Act
            2023. The arbitral tribunal shall comprise 1 arbitrator who shall be
            appointed in writing on the agreement of the Parties. Where the
            Parties are unable to agree on the choice of the sole arbitrator
            within 15 (fifteen) days after the respondent’s receipt of the
            request for Arbitration, either Party may request the president, for
            the time being, of the FCT High Court Multi Door Court House to make
            such appointment. The Arbitral award shall be final and binding on
            the parties. Parties shall jointly bear the cost of the Arbitral
            proceedings including the cost of the arbitrator/s fees. No
            inconsequential or indirect or punitive costs or orders or damages
            shall be made in the Arbitral award or in the Arbitral proceedings.{" "}
          </Text>
          {/* Move center */}
          <Text>SCHEDULE 1 – SERVICES, SERVICE LEVELS AND SUPPORT</Text>
          <Text>
            <Text style={styles.bold}>1. SCOPE OF SERVICES </Text>
          </Text>
          <Text>The following Services are covered by this Agreement:</Text>
          <Text>
            a. <Text style={styles.bold}>USER</Text> Onboarding
          </Text>
          <Text>
            b. Installation of device on <Text style={styles.bold}>USER’S</Text>{" "}
            facility
          </Text>
          <Text>
            c. Real time transmission of carbon emission data through the device
          </Text>
          <Text>
            d. Access to the <Text style={styles.bold}>USER</Text> Dashboard on
            the CloseCarbon Platform
          </Text>
          <Text>
            e. CCRT Issuance upon verification of the carbon emission data
          </Text>
          <Text>f. CCRT Exchange</Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>2. SOLUTION INSTALLATION </Text>
          </Text>
          <Text>
            A project team will be set up by the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> to handle the
            installation of the Solution, working closely with the
            implementation team of the <Text style={styles.bold}>USER</Text>.
            Installation timelines will largely depend on the responsiveness of
            the <Text style={styles.bold}>USER</Text> and availability of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text>.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>3. SERVICE AVAILABILITY </Text>
          </Text>
          <Text>
            The <Text style={styles.bold}>SERVICE PROVIDER</Text> aims to
            maintain availability at a 99.9% uptime level and for the USER to
            access the CloseCarbon Platform.
          </Text>
          <Text style={styles.space}></Text>

          <Text>4. SUPPORT MANAGEMENT</Text>
          <Text>
            The <Text style={styles.bold}>SERVICE PROVIDER</Text> will provide
            the required support to the <Text style={styles.bold}>USER</Text> to
            ensure that all enquiries and issues are appropriately responded to
            and resolved. Coverage parameters specific to the service(s) covered
            in this Agreement are as follows:
          </Text>
          <Text>
            i. Web Support (9:00 A.M. to 4:00 P.M. (WAT) Monday – Friday): Best
            efforts will be made to respond to web support requests received out
            of office hours however, no action can be guaranteed until the next
            business day.
          </Text>
          <Text>
            ii. Email support (9:00 A.M. to 4:00 P.M. (WAT) Monday – Friday):
            Emails received outside of office hours will be collected, however,
            no action can be guaranteed until the next business day. All emails
            should be sent to{" "}
            {/* <a href="mailto:support@closecarbon.com"> */}
              support@closecarbon.com.
            {/* </a> */}
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>5. SERVICE REQUESTS </Text>
          </Text>
          <Text>
            In support of services outlined in this Agreement, The{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> will respond to
            and resolve service-related incidents and/or requests submitted by
            the <Text style={styles.bold}>USER</Text> within the following time
            frames:
          </Text>
          {/* Table */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>ISSUES</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>
                  DEFINITION BASED ON SEVERITY
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>RESPONSE TIME</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>RESOLUTION TIME</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>CRITICAL</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>
                  Severe impact on data transmission from the USER's facility
                  (leading to data/financial losses) if immediate action is not
                  taken.
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>0-2 HOURS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>2 BUSINESS DAYS</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>MAJOR </Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>
                  Moderate impact on data transmission from the USER’s facility
                  (likely to lead to data/financial losses) is likely if action
                  is not taken within a reasonable time.{" "}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>6 HOURS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>2 BUSINESS DAYS</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>MINOR</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>
                  Minimal impact on data transmission with no potential losses
                  if action is not taken within a reasonable time.{" "}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>24 HOURS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>5 BUSINESS DAYS</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>LOW</Text>
              </View>
              <View style={styles.tableColLarge}>
                <Text style={styles.tableCell}>
                  Least impact on data transmission with no potential losses if
                  action is not taken.{" "}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>48 HOURS</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>5 BUSINESS DAYS</Text>
              </View>
            </View>
          </View>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>6.</Text> SCHEDULED MAINTENANCE is an
            event for purposes such as the CloseCarbon Platform maintenance,
            and/or maintenance/upgrade of the Device. The{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> shall give the
            USER 72 hours (three days’) notice prior to such maintenance being
            performed.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>7. EXCLUSIONS </Text>
          </Text>
          <Text>
            The following exclusions apply to all obligations of the{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text> contained in this
            Agreement in so far as it pertains to the performance of the
            Services:
          </Text>
          <Text>
            Any act or omission on the part of the{" "}
            <Text style={styles.bold}>USER</Text>, its contractors or vendors,
            or any other entity over which the USER exercises control or has the
            right to exercise control.
          </Text>
          <Text>
            Unavailability of service or performance issues related to
            non-Netzence provided equipment, service or infrastructure,
            including but not limited to the cloud{" "}
            <Text style={styles.bold}>SERVICE PROVIDER</Text>, web host, network
            service and payment service providers.
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            <Text style={styles.bold}>IN WITNESS WHEREOF</Text> the Parties have
            executed this Agreement in the manner below.{" "}
          </Text>
          <Text style={styles.space}></Text>

          <Text>
            SIGNED ON BEHALF OF{" "}
            <Text style={styles.bold}>NETZENCE SUSTAINABILITY LIMITED</Text>
          </Text>
          <Text></Text>
          <Text></Text>
          <Text></Text>
          <Text></Text>
          <Text></Text>
          <Text></Text>
        </View>
        <View style={styles.signatureFlex}>
          <View style={styles.signatureSection}>
            <View style={styles.signatureSectionInner}></View>
            <Text>Director</Text>
            <Text>Name: Director 1</Text>
          </View>
          <View style={styles.signatureSection}>
            <View style={styles.signatureSectionInner}></View>
            <Text>Director</Text>
            <Text>Name: Director 2/Company secretary</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text>
            SIGNED ON BEHALF OF <Text style={styles.bold}>USER</Text>
          </Text>
        </View>
        <View style={styles.signatureFlex}>
          <View style={styles.signatureSection}>
            <Image style={styles.image} source={firstSignature} />
            <View style={styles.signatureSectionInner}></View>
            <Text>Authorised Signatory</Text>
            <Text>Name:</Text>
          </View>
          <View style={styles.signatureSection}>
            <Image style={styles.image} source={secondSignature} />
            <View style={styles.signatureSectionInner}></View>
            <Text>Authorised Signatory</Text>
            <Text>Name:</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text>Date:</Text>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    width: "100%",
    height: "100vh",
    // alignItems: "center",
    // justifyContent: "center",
    padding: "10px 20px",
  },
  section: {
    margin: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    // border: "1px solid #000",
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "500",
    marginBottom: "25px",
    textAlign: "justify",
    // lineHeight: "80px"
    // padding: "10px 300px",
  },
  bold: {
    fontSize: "15px",
    fontWeight: "700",
  },
  largeLineHeight: {
    fontSize: "15px",
    fontWeight: "700",
    // lineHeight: "90px"
  },
  image: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    marginBottom: "5px",
  },
  h1: {
    fontSize: "36px",
    textAlign: "center",
    fontWeight: "900",
    // fontFamily: "Arial"
  },
  h1btm: {
    marginBottom: "25px",
  },
  h2: {
    fontSize: "21px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "28px",
  },
  h3: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "500",
    marginBottom: "25px",
  },

  signatureItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flex: 1, // Add this line
  },
  signatureItemH3: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "600",
  },
  signatureSignature: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "400",
    marginTop: "25px",
    marginBottom: "5px",
    // paddingBottom: "15px",
    borderBottom: "1px solid #000",
    width: "100%",
    fontFamily: "comicSansO",
  },

  signatureFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "30px",
    flexWrap: "wrap",
    flexDirection: "row",
    // backgroundColor: "green",
    // padding: "10px 300px",
  },
  signatureSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    width: "250px",
    height: "100px",
    // flex: 1, // Add this line
    fontSize: "16px",
    // backgroundColor: "blue"
  },

  signatureSectionInner: {
    width: "250px",
    height: "2px",
    backgroundColor: "#000",
    marginBottom: "5px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "10px",
    marginBottom: "10px",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColLarge: {
    width: "55%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  tableColForTwo: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  space: {
    width: "100%",
    height: "1px",
    marginBottom: "5px",
    marginTop: "5px",
  },
});

export default SlaAgreement;
