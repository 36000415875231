import Footer from "../../../components/shared/Footer";
import Header from "../../../components/shared/Header";
import Image from '../../../images/mnt.png';
import '../../../styles/shared/ProjectAuthenticated.scss'

export default function Monitoring() {
    return (
        <div>
        <div className="project_auth">
            <Header />
            <img className="project_image" src={Image} />
            <div className="project_title">Carbon Monitoring </div>
            <div className="project_subtitle">
            This service is offered for organizations that only want to quantify and monitor their carbon emissions. It involves Identifying their emission sources, establishing baselines, tracking, quantifying their emissions and reporting it on the CloseCarbon platform. This helps users easily track their progress, set emission reduction goals, and make informed decisions towards their emission reduction efforts.              </div>
            </div>
            <Footer />
        </div>
    )
}