import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useEffect } from "react";
import comicSansFont from '../../fonts/Handlee-Regular.ttf';
import moment from "moment";

const DonationPdf = ({companyName, ccrt, date, }) => {
  useEffect(() => {
    Font.register({ family: "comicSansO", src: comicSansFont });
  }, []);
  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
        // orientation="portrait"
      >
        <View style={styles.section}>
          <Image
            source="https://res.cloudinary.com/thewebplug/image/upload/v1719353491/image_3_orxymt.png"
            style={styles.image}
          />
          <Text style={styles.h1}>Netzence Certificate of</Text>
          <Text style={{...styles.h1, ...styles.h1btm}}>Donation</Text>
          <Text style={styles.h2}>
            {companyName}, {ccrt} CCRT
          </Text>
          <Text style={styles.h3}>
            This certifies that {companyName},
            donated {ccrt} CCRT <br />on {moment
            (date).format('DD-MM-YYYY')}.
          </Text>
          <View style={styles.signatureSection}>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureItemH3}>Donated by</Text>
              <Text style={styles.signatureSignature}>{companyName}</Text>
              <Text style={styles.signatureItemH3}>{companyName}</Text>
            </View>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureItemH3}>Received by</Text>
              <Text style={styles.signatureSignature}>CloseCarbon</Text>
              <Text style={styles.signatureItemH3}>CloseCarbon</Text>
            </View>
            <View style={styles.signatureItem}>
              <Text style={styles.signatureItemH3}>Issued by</Text>
              <Text style={styles.signatureSignature}>Netzence</Text>
              <Text style={styles.signatureItemH3}>Netzence</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
  },
  section: {
    margin: 10,
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #000",
    width: "100%",
  },
  image: {
    width: "100px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    marginBottom: "20px",
  },
  h1: {
    fontSize: "36px",
    textAlign: "center",
    fontWeight: "900",
    // fontFamily: "Arial"
  },
  h1btm: {
    marginBottom: "25px",
  },
  h2: {
    fontSize: "21px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "28px",
  },
  h3: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "500",
    marginBottom: "25px",
  },
  signatureSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "30px",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  signatureItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flex: 1, // Add this line
  },
  signatureItemH3: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "600",
  },
  signatureSignature: {
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "400",
    marginTop: "25px",
    marginBottom: "5px",
    paddingBottom: "15px",
    borderBottom: "1px solid #000",
    width: "100%",
    fontFamily: "comicSansO"
  },
});

export default DonationPdf;
