import "../../styles/shared/Collaborators.scss";
import Partner1 from "../../images/image 67.png";
import Partner2 from "../../images/Cadbury-Logo.wine.png";
import Partner3 from "../../images/ng gov.jpg";
import Partner4 from "../../images/REA.jpg";
import Partner5 from "../../images/axxella logo-1.jpg";
import Partner6 from "../../images/azura logo-1.jpg";
import Partner7 from "../../images/mainstream logo.jpg";
import Partner8 from "../../images/united nation logo.jpg";
import Partner9 from "../../images/ACMI logo small.png";
import Partner10 from "../../images/sebore.jpg";
import Partner11 from "../../images/SEforALL_organization_logo.jpg";
import Partner12 from "../../images/ehcon logo-1.jpg";
import Partner13 from "../../images/oracle logo-1.jpg";
import Partner14 from "../../images/efc.png";
import Partner15 from "../../images/CYREUM.png";
import Partner16 from "../../images/cyber-234 logos.jpg";
import Partner17 from "../../images/zamfara.jpg";
import Partner18 from "../../images/abu.jpg";
import Partner19 from "../../images/sydney.png";
import Partner20 from "../../images/unsw.png";
import Partner21 from "../../images/perdue.png";
import Partner22 from "../../images/CPRDS-logo 1.png";

export default function Collaborators() {
    return (
        <div className="partnership">
<div className="title">
  <span>Collaborations</span> 
</div>
<div className="partners_container">
  <div
    className="partners pointer"
    // onClick={() => {
    //   navigate("/partners");
    //   window.scrollTo(0, 0);
    // }}
  >
    <img src={Partner1} alt="" />
    <img src={Partner2} alt="" />
    <img src={Partner3} alt="" />
    <img src={Partner4} alt="" />
    <img src={Partner5} alt="" />
    <img src={Partner6} alt="" />
    <img src={Partner7} alt="" />
    <img src={Partner8} alt="" />
    <img src={Partner9} alt="" />
    <img src={Partner10} alt="" />
    <img src={Partner11} alt="" />
    <img src={Partner12} alt="" />
    <img src={Partner13} alt="" />
    <img src={Partner14} alt="" />
    <img src={Partner15} alt="" />
    <img src={Partner16} alt="" />
    <img src={Partner17} alt="" />
    <img src={Partner18} alt="" />
    <img src={Partner19} alt="" />
    <img src={Partner20} alt="" />
    <img src={Partner21} alt="" />
    <img src={Partner22} alt="" />
    <img src={Partner1} alt="" />
    <img src={Partner2} alt="" />
    <img src={Partner3} alt="" />
    <img src={Partner4} alt="" />
    <img src={Partner5} alt="" />
    
    
  </div>
</div>
</div>
    )
}