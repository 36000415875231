import "../../styles/shared/Resources.scss";
import Header from "../../components/shared/Header";
import SectionImage from "../../images/image 76.png";
import SectionImage2 from "../../images/image 77.png";
import SectionImage3 from "../../images/image 78.png";
import SectionImage4 from "../../images/image 79.png";
import Feedback from "../../components/shared/Feedback";
import Footer from "../../components/shared/Footer";
import { useNavigate } from "react-router-dom";
import glossary from '../../components/shared/glossary'
const Resources = () => {


  const navigate = useNavigate();
  return (
    <div className="resources">
      <Header />
      <div className="hero">
        <div className="title">
        Your Source for Environmental Information<br /> and Wisdom
        </div>
        <div className="subtitle">
        Explore a wealth of valuable information and knowledge on environmental topics right here. 
        </div>
      </div>

      
      <div className="resources_section">
        <div className="title">Glossary</div>
        <div className="section_group">
          
           {glossary.map((item, index) => (
            <div className="box" onClick={() => {
              window.scrollTo(0, 0)
              navigate(`/glossary/${index}`)
            }}>
            <div className="image">
              <img src={SectionImage4} alt="" />
            </div>
            <div className="content">
              <div className="title">{item?.title}</div>
              <div className="subtitle">
                <div>
                 {item?.contentBody[0]?.bodyContent}
                </div>
                <div>
                  Read more
                 

                </div>
              </div>
            </div>
            <div className="shadow"></div>
          </div>
           )) }
            
      
        </div>
      </div>
      <div className="resources_section">
        <div className="title">Webinars</div>
        <div className="section_group">
          <div>
            <div className="box box2">
              <div className="image image2">
                <img src={SectionImage} alt="" />
              </div>
              <div className="content content2">
                <div className="title">Trends in Sustainable Living</div>
                <div className="subtitle">
                  <div>
                    Lorem ipsum dolor sit amet consectetur. Quis odio eget in
                    fringilla. Viverra sodales aliquam montes gravida vitae
                    enim. Semper tortor vivamus vestibulum imperdiet. Lacus
                    tellus at eu justo a. Eget egestas amet quam eget diam
                    adipiscing.
                  </div>
                  <div>
                    Watch Now
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.375 9.75C21.375 10.0484 21.2565 10.3345 21.0455 10.5455C20.8345 10.7565 20.5484 10.875 20.25 10.875C19.9516 10.875 19.6655 10.7565 19.4545 10.5455C19.2435 10.3345 19.125 10.0484 19.125 9.75V6.46875L13.5459 12.0478C13.3346 12.2592 13.0479 12.3779 12.7491 12.3779C12.4502 12.3779 12.1635 12.2592 11.9522 12.0478C11.7408 11.8365 11.6221 11.5498 11.6221 11.2509C11.6221 10.9521 11.7408 10.6654 11.9522 10.4541L17.5312 4.875H14.25C13.9516 4.875 13.6655 4.75647 13.4545 4.5455C13.2435 4.33452 13.125 4.04837 13.125 3.75C13.125 3.45163 13.2435 3.16548 13.4545 2.9545C13.6655 2.74353 13.9516 2.625 14.25 2.625H20.25C20.5484 2.625 20.8345 2.74353 21.0455 2.9545C21.2565 3.16548 21.375 3.45163 21.375 3.75V9.75ZM17.25 12C16.9516 12 16.6655 12.1185 16.4545 12.3295C16.2435 12.5405 16.125 12.8266 16.125 13.125V19.125H4.875V7.875H10.875C11.1734 7.875 11.4595 7.75647 11.6705 7.5455C11.8815 7.33452 12 7.04837 12 6.75C12 6.45163 11.8815 6.16548 11.6705 5.9545C11.4595 5.74353 11.1734 5.625 10.875 5.625H4.5C4.00272 5.625 3.52581 5.82254 3.17417 6.17417C2.82254 6.52581 2.625 7.00272 2.625 7.5V19.5C2.625 19.9973 2.82254 20.4742 3.17417 20.8258C3.52581 21.1775 4.00272 21.375 4.5 21.375H16.5C16.9973 21.375 17.4742 21.1775 17.8258 20.8258C18.1775 20.4742 18.375 19.9973 18.375 19.5V13.125C18.375 12.8266 18.2565 12.5405 18.0455 12.3295C17.8345 12.1185 17.5484 12 17.25 12Z" fill="#0D924F"/>
</svg>

                  </div>
                </div>
              </div>
              <div className="shadow"></div>
            </div>
          </div>
          <div>
            <div className="box box2">
              <div className="image image2">
                <img src={SectionImage2} alt="" />
              </div>
              <div className="content content2">
                <div className="title">Charting a Sustainable Course</div>
                <div className="subtitle">
                  <div>
                    Lorem ipsum dolor sit amet consectetur. Quis odio eget in
                    fringilla. Viverra sodales aliquam montes gravida vitae
                    enim. Semper tortor vivamus vestibulum imperdiet. Lacus
                    tellus at eu justo a. Eget egestas amet quam eget diam
                    adipiscing.
                  </div>
                  <div>
                    Watch Now
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.375 9.75C21.375 10.0484 21.2565 10.3345 21.0455 10.5455C20.8345 10.7565 20.5484 10.875 20.25 10.875C19.9516 10.875 19.6655 10.7565 19.4545 10.5455C19.2435 10.3345 19.125 10.0484 19.125 9.75V6.46875L13.5459 12.0478C13.3346 12.2592 13.0479 12.3779 12.7491 12.3779C12.4502 12.3779 12.1635 12.2592 11.9522 12.0478C11.7408 11.8365 11.6221 11.5498 11.6221 11.2509C11.6221 10.9521 11.7408 10.6654 11.9522 10.4541L17.5312 4.875H14.25C13.9516 4.875 13.6655 4.75647 13.4545 4.5455C13.2435 4.33452 13.125 4.04837 13.125 3.75C13.125 3.45163 13.2435 3.16548 13.4545 2.9545C13.6655 2.74353 13.9516 2.625 14.25 2.625H20.25C20.5484 2.625 20.8345 2.74353 21.0455 2.9545C21.2565 3.16548 21.375 3.45163 21.375 3.75V9.75ZM17.25 12C16.9516 12 16.6655 12.1185 16.4545 12.3295C16.2435 12.5405 16.125 12.8266 16.125 13.125V19.125H4.875V7.875H10.875C11.1734 7.875 11.4595 7.75647 11.6705 7.5455C11.8815 7.33452 12 7.04837 12 6.75C12 6.45163 11.8815 6.16548 11.6705 5.9545C11.4595 5.74353 11.1734 5.625 10.875 5.625H4.5C4.00272 5.625 3.52581 5.82254 3.17417 6.17417C2.82254 6.52581 2.625 7.00272 2.625 7.5V19.5C2.625 19.9973 2.82254 20.4742 3.17417 20.8258C3.52581 21.1775 4.00272 21.375 4.5 21.375H16.5C16.9973 21.375 17.4742 21.1775 17.8258 20.8258C18.1775 20.4742 18.375 19.9973 18.375 19.5V13.125C18.375 12.8266 18.2565 12.5405 18.0455 12.3295C17.8345 12.1185 17.5484 12 17.25 12Z" fill="#0D924F"/>
</svg>

                  </div>
                </div>
              </div>
              <div className="shadow"></div>
            </div>
          </div>
          <div>
            <div className="box box2">
              <div className="image image2">
                <img src={SectionImage3} alt="" />
              </div>
              <div className="content content2">
                <div className="title">GreenHorizon Insights</div>
                <div className="subtitle">
                  <div>
                    Lorem ipsum dolor sit amet consectetur. Quis odio eget in
                    fringilla. Viverra sodales aliquam montes gravida vitae
                    enim. Semper tortor vivamus vestibulum imperdiet. Lacus
                    tellus at eu justo a. Eget egestas amet quam eget diam
                    adipiscing.
                  </div>
                  <div>
                    Watch Now
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.375 9.75C21.375 10.0484 21.2565 10.3345 21.0455 10.5455C20.8345 10.7565 20.5484 10.875 20.25 10.875C19.9516 10.875 19.6655 10.7565 19.4545 10.5455C19.2435 10.3345 19.125 10.0484 19.125 9.75V6.46875L13.5459 12.0478C13.3346 12.2592 13.0479 12.3779 12.7491 12.3779C12.4502 12.3779 12.1635 12.2592 11.9522 12.0478C11.7408 11.8365 11.6221 11.5498 11.6221 11.2509C11.6221 10.9521 11.7408 10.6654 11.9522 10.4541L17.5312 4.875H14.25C13.9516 4.875 13.6655 4.75647 13.4545 4.5455C13.2435 4.33452 13.125 4.04837 13.125 3.75C13.125 3.45163 13.2435 3.16548 13.4545 2.9545C13.6655 2.74353 13.9516 2.625 14.25 2.625H20.25C20.5484 2.625 20.8345 2.74353 21.0455 2.9545C21.2565 3.16548 21.375 3.45163 21.375 3.75V9.75ZM17.25 12C16.9516 12 16.6655 12.1185 16.4545 12.3295C16.2435 12.5405 16.125 12.8266 16.125 13.125V19.125H4.875V7.875H10.875C11.1734 7.875 11.4595 7.75647 11.6705 7.5455C11.8815 7.33452 12 7.04837 12 6.75C12 6.45163 11.8815 6.16548 11.6705 5.9545C11.4595 5.74353 11.1734 5.625 10.875 5.625H4.5C4.00272 5.625 3.52581 5.82254 3.17417 6.17417C2.82254 6.52581 2.625 7.00272 2.625 7.5V19.5C2.625 19.9973 2.82254 20.4742 3.17417 20.8258C3.52581 21.1775 4.00272 21.375 4.5 21.375H16.5C16.9973 21.375 17.4742 21.1775 17.8258 20.8258C18.1775 20.4742 18.375 19.9973 18.375 19.5V13.125C18.375 12.8266 18.2565 12.5405 18.0455 12.3295C17.8345 12.1185 17.5484 12 17.25 12Z" fill="#0D924F"/>
</svg>

                  </div>
                </div>
              </div>
              <div className="shadow"></div>
            </div>
          </div>
        </div>
      </div>
      <Feedback />
      <Footer />
    </div>
  );
};

export default Resources;
