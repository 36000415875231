import Footer from "../../../components/shared/Footer";
import Header from "../../../components/shared/Header";
import Image from '../../../images/auth.png';
import '../../../styles/shared/ProjectAuthenticated.scss'

export default function Green() {
    return (
        <div>
        <div className="project_auth">
            <Header />
            <img className="project_image" src={Image} />
            <div className="project_title">Renewable Energy / Green Projects</div>
            <div className="project_subtitle">
            Credit can be earned when renewable energy projects such as wind farms, solar, hydro, or geothermal power plants generate clean energy. This reduces the need for fossil fuel-based power generation, which in turn reduces greenhouse gas emissions. The project can then earn carbon incentives based on the amount of emissions it has avoided.
            </div>
            </div>
            <Footer />
        </div>
    )
}