import { ethers } from "ethers";
import BasicGreenProject from "../Abi/BasicGreenProject.json";
import BasicInfrastructure from "../Abi/BasicInfrastructure.json";
import GovernanceGreenProject from "../Abi/GovernanceGreenProject.json";
import GovernanceInfrastructure from "../Abi/GovernanceInfrastructureChange.json";
import GovernanceCarbonMonitoring from "../Abi/GovernanceCarbonMonitoring.json";
import GreenProjectNFT from "../Abi/GreenProjectNFT.json";
import InfrastructureChangeNFT from "../Abi/InfrastructureChangeNFT.json";
import CloseCarbonToken from "../Abi/CloseCarbonToken.json";
import DonationCertificate from "../Abi/DonationCertificate.json";
import dotenv from "dotenv";
import { NETWORKS } from "./config";

dotenv.config();

const chainID = 137;

const network = NETWORKS[chainID];
const REACT_APP_PRIVATE_KEY = '174683e4b432151f73ebc8c0dcf8140271004cfaa011fea12d57391b6889576a';
const REACT_APP_MUMBAI_URL = 'https://polygon-mainnet.infura.io/v3/b455056c0c134223a663258c63b9f284';

async function getContracts() {
  if (!REACT_APP_PRIVATE_KEY) {
    throw new Error("DEPLOYER_PRIVATE_KEY is undefined");
  }
  // Get signer
  const deployer = new ethers.Wallet(REACT_APP_PRIVATE_KEY);
  const provider = new ethers.JsonRpcProvider(REACT_APP_MUMBAI_URL);
  // const provider = deployer.connect(provider);
  const signer = deployer.connect(provider);
  // const userSigner = new ethers.Wallet(privateKey, provider);

  // Get contract instances
  const basicGreenContractInstance = new ethers.Contract(
    network.BASIC_GREEN_PROJECT,
    BasicGreenProject.abi,
    signer
  );
  const basicInfrastructureContractInstance = new ethers.Contract(
    network.BASIC_INFRASTRUCTURE_CHANGE,
    BasicInfrastructure.abi,
    signer
  );
  const governanceGreenContractInstance = new ethers.Contract(
    network.GOVERNANCE_GREEN_PROJECT,
    GovernanceGreenProject.abi,
    signer
  );
  const governanceinfrastructureContractInstance = new ethers.Contract(
    network.GOVERNANCE_INFRASTRUCTURE_CHANGE,
    GovernanceInfrastructure.abi,
    signer
  );
  const governanceCarbonMonitoringInstance = new ethers.Contract(
    network.GOVERNANCE_CARBON_MONITORING,
    GovernanceCarbonMonitoring.abi,
    signer
  );
  const greenProjectNFTContractInstance = new ethers.Contract(
    network.GREEN_PROJECT_NFT,
    GreenProjectNFT.abi,
    signer
  );
  const infrastructureChangeNFTContractInstance = new ethers.Contract(
    network.INFRASTRUCTURE_CHANGE_NFT,
    InfrastructureChangeNFT.abi,
    signer
  );
  const closeCarbonTokenContractInstance = new ethers.Contract(
    network.CLOSE_CARBON_TOKEN,
    CloseCarbonToken.abi,
    signer
  );
  const donationCertificateContractInstance = new ethers.Contract(
    network.DONATION_CERTIFICATE,
    DonationCertificate.abi,
    signer
  );


  return {
    signer,
    basicGreenContractInstance,
    basicInfrastructureContractInstance,
    governanceGreenContractInstance,
    governanceinfrastructureContractInstance,
    governanceCarbonMonitoringInstance,
    greenProjectNFTContractInstance,
    infrastructureChangeNFTContractInstance,
    closeCarbonTokenContractInstance,
    donationCertificateContractInstance,
  };
}

export { getContracts };