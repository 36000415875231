import axios from "axios";

export const facilityValidate = async (
  userId,
  walletAddress,
  facilityCategory,
  subscriptionPlan,
  token
) => {
  console.log({
    userId,
  walletAddress,
  facilityCategory,
  subscriptionPlan,
  token
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/web3/v1/validate-plant`,
      {
        userId,
        walletAddress,
        facilityCategory,
        subscriptionPlan,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const facilityReject = async (reasonForRejection,
  userId, token) => {
    console.log({
      reasonForRejection,
  userId, token
    });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/web3/v1/reject-validation`,
      {
        reasonForRejection,
        userId
    },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const facilityVerify = async (
  facilityCategory,
  subscriptionPlan,
  walletAddress,
  token
) => {
  console.log({
    facilityCategory,
  subscriptionPlan,
  walletAddress,
  token
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    console.log({
      facilityCategory,
  subscriptionPlan,
  walletAddress,
  token
    });
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/web3/v1/verify-and-mint`,
      {
        facilityCategory,
        subscriptionPlan,
        walletAddress,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const issueCarbonCredit = async (
  walletAddress,
  facilityCategory,
  subscriptionPlan,
  token
) => {

  console.log({
    walletAddress,
  facilityCategory,
  subscriptionPlan,
  token
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/web3/v1/issue-carbon-credit`,
      {
        walletAddress,
        facilityCategory,
        subscriptionPlan,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
