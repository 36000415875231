import { useEffect, useRef, useState } from "react";
import "../../styles/ThirdParty/Kyc.scss";
import { InputLabel, OutlinedInput, Tooltip, Typography } from "@mui/material";
import { submitKyc } from "../../apis/ThirdPartyDashboard/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getUserInfo, makereq } from "../../apis/General/users";

const Kyc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenTwo, setModalOpenTwo] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [dateOfIncorporation, setDateOfIncorporation] = useState("");
  const [contactInformation, setContactInformation] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");
  const [authorizedName, setAuthorizedName] = useState("");
  const [authorizedPhoneNumber, setAuthorizedPhoneNumber] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [experience, setExperience] = useState("");
  const [certified, setCertified] = useState("");
  const [misappropriation, setMisappropriation] = useState("");
  const [protocols, setProtocols] = useState("");
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [loading, setLoading] = useState(false);
  const [uplaodLoading, setUploadLoading] = useState(false);
  const [documentType, setDocumentType] = useState("");
  const [certificateOfAccreditation, setCertificateOfAccreditation] =
    useState("");
  const [accExp, setAccExp] = useState("");
  const [accBody, setAccBody] = useState("");
  const [images, setImages] = useState([]);

  const mediaRef = useRef(null);

  const fileUpload = (e, documentTypeSent) => {
    console.log("nice11");

    setUploadLoading(true);

    let files = e;
    let allUploadedFiles = images;
    console.log("nice1");

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    console.log("nice2");

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            console.log("result", result);
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                {
                  media_type: "pdf",
                  folder: "documents",
                  user_id: auth?.userInfo?.id,
                  contents: [result],
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth ? auth.token : ""}`,
                  },
                }
              )
              .then((response) => {
                console.log("merddian", response);
                if (response?.data?.body?.success === true) {
                  if (documentTypeSent === "certificateOfAccreditation") {
                    setCertificateOfAccreditation(
                      response?.data?.body?.data[0]
                    );
                  } else {
                    toast.error("document type not specified");
                  }
                }
                setUploadLoading(false);
              })
              .catch((err) => {
                console.log("dave", err);
                setUploadLoading(false);
              });
          }
        });
      }
    }
  };

  const clickFileUpload = (e, documentTypeSent) => {
    setUploadLoading(true);

    let files = e.target.files;
    let allUploadedFiles = images;

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            console.log("meridian", result);
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                {
                  media_type: "pdf",
                  folder: "documents",
                  user_id: auth?.userInfo?.id,
                  contents: [result],
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth ? auth.token : ""}`,
                  },
                }
              )
              .then((response) => {
                console.log("response", response);
                if (response?.data?.body?.success === true) {
                  if (documentTypeSent === "certificateOfAccreditation") {
                    console.log("herre00!!");
                    setCertificateOfAccreditation(
                      response?.data?.body?.data[0]
                    );
                  } else {
                    toast.error("document type not specified");
                  }
                }
                setUploadLoading(false);
              })
              .catch((err) => {
                console.log("error", err);
                setUploadLoading(false);
              });
          }
        });
      }
    }
  };

  useEffect(() => {
    let dropAreas = document.querySelectorAll("#drag_drop");
    console.log("dropAreasdqew", dropAreas);
    dropAreas.forEach((dropArea) => {
      console.log("dropArea", dropArea);
      let uploadArea = document.querySelector(".admin_upload-area");
      ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });

      function preventDefaults(e) {
        e.preventDefault();
        e.stopPropagation();
      }

      ["dragenter", "dragover"].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight, false);
      });
      ["dragleave", "drop"].forEach((eventName) => {
        dropArea.addEventListener(eventName, unhighlight, false);
      });

      function highlight(e) {
        dropArea.classList.add("highlight");
        console.log("hunm", e);
        console.log("dropArea?.dataset", dropArea?.dataset);
      }

      function unhighlight(e) {
        dropArea.classList.remove("highlight");
      }

      dropArea.addEventListener("drop", handleDrop, false);

      function handleDrop(e) {
        if (dropArea?.dataset?.doctype === "certificateOfAccreditation") {
          fileUpload(e.dataTransfer.files, "certificateOfAccreditation");
          setDocumentType("certificateOfAccreditation");
        }
      }
    });
  }, [modalOpen]);

  // useEffect(() => {
  //   if (auth?.userInfo?.kycStatus === "N/A") {
  //     setModalOpen(true);
  //   }
  // }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("thirdparty-kyc_modal_container")) {
      setModalOpen(false);
    }
  };

  const handleModalOpenTwo = () => {
    setModalOpenTwo(true);
  };

  const handleModalCloseTwo = (e) => {
    if (e.target.classList.contains("thirdparty-kyc_modal_container")) {
      setModalOpenTwo(false);
    }
  };

  const handleKycSubmit = async (e) => {
    e.preventDefault();
    if(Date.now() > Date.parse(accExp)) {
      alert("Date of expiration of accreditation can not be in the past");
      return;
    }else if(certificateOfAccreditation === "") {
      alert("Please provide your certificate of accreditation");
      return;
    }
    setLoading(true);
    const response1 = await getUserInfo(auth?.userInfo?.id, auth?.token);
    console.log('getUserInfo', response1);
    const response = await submitKyc(
      "verifier",
      auth?.userInfo?.verifierType,
      authorizedName,
      accBody,
      accExp,
      certificateOfAccreditation,
      auth?.token
    );

    console.log("third-party kyc", response);

    if (response?.data?.statusCode === 200) {
      toast.success(response?.data?.message);
      localStorage.removeItem("token");
      localStorage.setItem("token", response?.data?.token);
      dispatch({
        type: "USER_LOGIN_SUCCESS",
        payload: {
          token: response.data.token,
        },
      });
      setModalOpen(false);
      setModalOpenTwo(false);
    } else if (response?.data?.message === "KYC already submmitted") {
      toast.error(response?.data?.message);
    } else {
      toast.error("Something went wrong");
    }

    setLoading(false);
  };

  

  return (
    <>
      {modalOpen && (
        <div
          className="thirdparty-kyc_modal_container"
          // onClick={handleModalClose}
        >
          <form className="kyc_modal" onSubmit={handleKycSubmit}>
            <div className="title">Step into the future...</div>
            <div className="subtitle">
              Complete this KYC step, your safety our priority
            </div>
            <div className="thirdparty_kyc_grid">
              <div>
                <InputLabel className="kyc_label" htmlFor="organizationName">
                  Authorized name
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        A breif description of what your organisation is all
                        about
                      </Typography>
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </InputLabel>

                <OutlinedInput
                  className="thirdparty_kyc_input"
                  required
                  id="organizationName"
                  type="text"
                  placeholder="Full name of point of contact in relation to verification"
                  name="organizationName"
                  value={authorizedName}
                  onChange={(e) =>
                    setAuthorizedName(e.target.value)
                  }
                />
              </div>

              <div>
                <InputLabel
                  className="kyc_label"
                  htmlFor="authorizedPhoneNumber"
                >
                  Name of accreditation body
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        The name of your accredited organisation
                      </Typography>
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </InputLabel>

                <OutlinedInput
                  className="thirdparty_kyc_input"
                  required
                  id="authorizedPhoneNumber"
                  type="text"
                  placeholder="Accreditation body name"
                  name="authorizedPhoneNumber"
                  value={accBody}
                  onChange={(e) => setAccBody(e.target.value)}
                />
              </div>

              <div>
                <InputLabel
                  className="kyc_label"
                  htmlFor="authorizedPhoneNumber"
                >
                  Date of expiration of accreditation
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        The date your organisation's accreditation expires
                      </Typography>
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </InputLabel>

                <input
                  className="thirdparty_kyc_input thirdparty_kyc_input-date"
                  required
                  id="authorizedPhoneNumber"
                  type="date"
                  name="authorizedPhoneNumber"
                  value={accExp}
                  onChange={(e) => {
                    // if(Date.now() < Date.parse(e.target.value)) {
                    setAccExp(e.target.value);
                    // }else {
                    //   alert('Expiry date must be later than today')
                    // }
                  }}
                />
              </div>
              <div>
                <InputLabel className="kyc_label" htmlFor="typeofFuel">
                  Certificate of accreditation
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        Your organisation's accreditation certificate
                      </Typography>
                    }
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="-0.5 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                        <path
                          d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z"
                          fill="#000000"
                        ></path>{" "}
                      </g>
                    </svg>
                  </Tooltip>
                </InputLabel>

                {certificateOfAccreditation?.length <= 0 && (
                  <label className="kyc_input_flex">
                    <div className="thirdparty_kyc_input">
                      {uplaodLoading &&
                      documentType === "certificateOfAccreditation"
                        ? "Loading..."
                        : "Click to choose file to upload"}
                    </div>
                    <div className="kyc_upload_button">Browse Files</div>
                    <input
                      type="file"
                      multiple
                      // accept='video/*'
                      accept="application/pdf"
                      hidden
                      name="typeofFuel"
                      id="typeofFuel"
                      onChange={(e) => {
                        setDocumentType("certificateOfAccreditation");
                        clickFileUpload(e, "certificateOfAccreditation");
                      }}
                      ref={mediaRef}
                    />
                  </label>
                )}

                <div
                  className="drag_drop"
                  id="drag_drop"
                  data-docType="certificateOfAccreditation"
                >
                  {certificateOfAccreditation?.length > 0 ? (
                    <svg
                      width="51"
                      height="50"
                      viewBox="0 0 51 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="25.5" cy="25" r="25" fill="#B5E3C4" />
                      <circle cx="25.5" cy="25" r="20" fill="#91D6A8" />
                      <circle cx="25.5" cy="25" r="9.5" stroke="#5FC381" />
                      <path
                        d="M20.25 25.75L23.75 29.25L30.75 21.75"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_135_4259)">
                        <path
                          d="M38.7 20.08C38.0282 16.6754 36.1952 13.6097 33.5141 11.4065C30.8331 9.20327 27.4702 7.99922 24 8C18.22 8 13.2 11.28 10.7 16.08C7.76047 16.3977 5.04201 17.7904 3.06698 19.9906C1.09194 22.1909 -0.000343404 25.0434 8.09855e-08 28C8.09855e-08 34.62 5.38 40 12 40H38C43.52 40 48 35.52 48 30C48 24.72 43.9 20.44 38.7 20.08ZM28 26V34H20V26H14L23.3 16.7C23.7 16.3 24.32 16.3 24.72 16.7L34 26H28Z"
                          fill="#667185"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_135_4259">
                          <rect width="48" height="48" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <div>
                    {uplaodLoading &&
                    documentType === "certificateOfAccreditation"
                      ? "Loading..."
                      : certificateOfAccreditation?.length > 0
                      ? "Document uploaded successfully"
                      : "Drag and drop files here"}
                  </div>
                </div>
                {/* <OutlinedInput
                  className="kyc_input"
                  required
                  id="email"
                  type="text"
                  // placeholder="A brief description of company"
                  name="email"
                  value={typeofFuel}
                  onChange={(e) => setTypeofFuel(e.target.value)}
                /> */}
              </div>
            </div>
            <button className="kyc_submit" type="submit">
              {loading ? "Loading..." : "Continue"}
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default Kyc;
