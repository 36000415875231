import { useEffect, useState } from 'react';
import SideBar from '../../components/AdminDashboard/SideBar';
import '../../styles/AdminDashboard/Validators.scss'
import { getUsers, getValidators } from '../../apis/AdminDashboard/users';
import { useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Search from '../../components/AdminDashboard/Search';
import { useNavigate } from 'react-router-dom';

const Validators = () => {
  const navigate = useNavigate();

        const auth = useSelector((state) => state.auth);
        const [loading, setLoading] = useState(false);
        const [userType, setUserType] = useState("");

  const [users, setUsers] = useState([]);

  const listUsers = async () => {
    setLoading(true)

    const response = await getValidators(auth?.token);
    let tmp;
    console.log('response?.data?.message validators', response?.data?.message);
    if(!!userType) {
      if(userType === "CCV") {
        tmp = response?.data?.message?.filter((item) => item?.role === "thirdParty"
        )
      }else if(userType === "Sub-CCV") {
        tmp = response?.data?.message?.filter((item) => item?.role === "verifiers"
        )
      }

      console.log('tmpppp', tmp);

      setUsers(tmp);

    }else {
      setUsers(response?.data?.message);
    }
    setLoading(false)

  };

  useEffect(() => {
    listUsers();
  }, [userType]);
  return (
    <div className="admin_validators">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="title">Verifiers</div>
        <div className="subtitle">Verifiers summary</div>
        <div className="validators-admin_card_group">
          <div className="card">
            <div className="title">CloseCarbon Verifiers (CCV)</div>
            <div className="value_group">
              <div className="value">8</div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.125 23.1113C9.9954 23.1112 9.86802 23.0777 9.75525 23.0138L3.38025 19.4025C3.26482 19.3371 3.16881 19.2423 3.102 19.1276C3.0352 19.013 3 18.8827 3 18.75V11.25C3 11.1174 3.0352 10.9871 3.102 10.8724C3.16881 10.7578 3.26482 10.6629 3.38025 10.5975L9.75525 6.98628C9.86803 6.92246 9.99541 6.88892 10.125 6.88892C10.2546 6.88892 10.382 6.92246 10.4948 6.98628L16.8698 10.5975C16.9852 10.6629 17.0812 10.7578 17.148 10.8724C17.2148 10.9871 17.25 11.1174 17.25 11.25V18.75C17.25 18.8827 17.2148 19.013 17.148 19.1276C17.0812 19.2423 16.9852 19.3371 16.8698 19.4025L10.4948 23.0138C10.382 23.0777 10.2546 23.1112 10.125 23.1113ZM4.5 18.3128L10.125 21.4988L15.75 18.3128V11.6873L10.125 8.50128L4.5 11.6865V18.3128Z" fill="#00AED9"/>
<path d="M20.9987 12.75H19.4987V5.68728L13.8737 2.50128L7.86841 5.90253L7.12891 4.59753L13.5039 0.986282C13.6167 0.922459 13.7441 0.888916 13.8737 0.888916C14.0032 0.888916 14.1306 0.922459 14.2434 0.986282L20.6184 4.59753C20.7338 4.66294 20.8299 4.7578 20.8967 4.87243C20.9635 4.98706 20.9987 5.11736 20.9987 5.25003V12.75Z" fill="#00AED9"/>
</svg>

            </div>
          </div>
          <div className="card">
            <div className="title">Sub-CloseCarbon Verifiers (Sub- CCV)</div>
            <div className="value_group">
              <div className="value">0</div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.1325 13.2675L17.6325 10.605C17.5125 10.5375 17.385 10.5 17.25 10.5C17.115 10.5 16.9875 10.5375 16.8675 10.605L12.3675 13.2675C12.1425 13.4025 12 13.65 12 13.9125V19.0875C12 19.35 12.1425 19.5975 12.3675 19.7325L16.8675 22.395C16.9875 22.4625 17.115 22.5 17.25 22.5C17.385 22.5 17.5125 22.4625 17.6325 22.395L22.1325 19.7325C22.3575 19.5975 22.5 19.35 22.5 19.0875V13.9125C22.5 13.65 22.3575 13.4025 22.1325 13.2675ZM21 18.66L17.25 20.88L13.5 18.66V14.34L17.25 12.12L21 14.34V18.66Z" fill="#00AED9"/>
<path d="M17.5503 3.3525C17.0628 3.3525 16.5903 3.42 16.1403 3.555C15.7502 3.05064 15.2503 2.64191 14.6784 2.35988C14.1066 2.07786 13.4779 1.92998 12.8403 1.9275C11.9628 1.9275 11.1303 2.1975 10.4253 2.7C9.58885 1.93052 8.49441 1.50238 7.35784 1.5H7.35034C6.13534 1.5 4.99534 1.9725 4.13284 2.835C3.56434 3.40007 3.15796 4.10738 2.95612 4.8831C2.75427 5.65883 2.76436 6.47451 2.98534 7.245C2.52164 7.62498 2.14844 8.1035 1.89286 8.64579C1.63728 9.18808 1.50575 9.78051 1.50784 10.38C1.50784 12.6075 3.30034 14.4225 5.51284 14.4225H5.72284C6.08819 15.097 6.62859 15.6606 7.2872 16.0539C7.9458 16.4472 8.69823 16.6557 9.46534 16.6575C9.81784 16.6575 10.1703 16.6125 10.5003 16.53V14.9475C10.1778 15.0825 9.82534 15.1575 9.46534 15.1575C8.31784 15.1575 7.27534 14.43 6.88534 13.3575L6.66034 12.75L6.03034 12.8775C5.85784 12.915 5.68534 12.93 5.51284 12.93C4.13284 12.93 3.00784 11.7825 3.00784 10.38C3.00784 9.48 3.48784 8.6325 4.26784 8.175L4.84534 7.8375L4.57534 7.2225C4.40284 6.84 4.32034 6.435 4.32034 6.015C4.32034 5.2125 4.62784 4.4625 5.19784 3.8925C5.77534 3.315 6.54034 3 7.35034 3C8.28784 3 9.19534 3.45 9.76534 4.1925L10.2153 4.785L11.1153 4.065C11.6028 3.6525 12.2103 3.4275 12.8403 3.4275C13.8078 3.4275 14.7078 3.9675 15.1953 4.83L15.5328 5.43L16.1553 5.145C16.5903 4.95 17.0628 4.8525 17.5503 4.8525C19.4553 4.8525 21.0003 6.42 21.0003 8.34C20.999 9.10968 20.7402 9.8568 20.2653 10.4625L21.5778 11.22C22.1756 10.3788 22.4955 9.37191 22.4928 8.34C22.4928 5.5875 20.2728 3.3525 17.5428 3.3525H17.5503Z" fill="#00AED9"/>
</svg>

            </div>
          </div>
        </div>

        <div className="validators-table">
          <div className="validators-table__header">
            <div>
              <button className={userType === "CCV" && "active"}
              onClick={() => setUserType("CCV")}
              >CCV</button>
              {!userType && <div></div>}
              <button
              className={userType === "Sub-CCV" && "active"}
              onClick={() => setUserType("Sub-CCV")}
              >Sub-CCV</button>
            </div>
            <div>
            <button
              className={!userType && "active"}
              onClick={() => setUserType(null)}
              >View all</button>
            </div>
          </div>

          <div className="validators-table__label">
            <div>Email</div>
            <div>Name</div>
            <div>Category</div>
            <div>Location</div>
            <div>Verified</div>
          </div>

          {loading &&
          
          <>
          <div
                className="validators-table__item"
              > <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />

                </div>
          <div
                className="validators-table__item"
              > <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />

                </div>
          <div
                className="validators-table__item"
              > <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />

                </div>
          <div
                className="validators-table__item"
              > <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />
              <Skeleton width="60%" height={15} borderRadius={4} />

                </div>
          </>
          }
            {users?.map((user) => (
              <div
                className="validators-table__item"
                onClick={() => navigate(`/admin/users/${user?.id}`)}
              >
                <div>{user?.email}</div>
                <div>{user?.firstName}{user?.lastName}</div>
                <div>
                  {user?.subscriptionPlan === "verifiers" ? "verifier" : "validator"}
                </div>
                <div>{user?.country}</div>
                <div>5</div>
              </div>
            ))}
        </div>

       
      </div>
    </div>
  );
};

export default Validators;
