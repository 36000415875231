import "../../styles/UserDashboard/Resource.scss";
import Search from "./Search";
import SideBar from "./SideBar";
import glossary from "../../components/shared/glossary";
import { useParams } from "react-router-dom";
export default function Resource() {
  const pathname = useParams();

  return (
    <div className="user-resource">
      <div className="sidebar_container">
        <SideBar />
      </div>
      <div className="main">
        <Search />
        <div className="main__title">{glossary[pathname?.index]?.title}</div>
        <div className="main__body">
          {glossary[pathname?.index]?.contentBody?.map((item) => (
            <>
              <h2>{item?.bodyTitle}</h2>
              <p>{item?.bodyContent}</p>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
