import Header from "../../components/shared/Header";
import "../../styles/shared/Blog.scss";
import Image1 from "../../images/Frame 86.png";
import Image2 from "../../images/image 1.png";
import Footer from "../../components/shared/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { getBlog } from "../../apis/General/users";
import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
export default function Blog() {
  const navigate = useNavigate();
  const pathname = useParams();

  const [blogPost, setBlogPost] = useState(null);

  const handleGetBlog = async () =>  {
    const response = await getBlog(pathname?.id);
    console.log('response', response);
    if(response?.data?.statusCode === 200) {
      setBlogPost(response?.data?.message)
    }else {
      toast.error(response?.data?.message)
    }
  }

  useEffect(() => {
    handleGetBlog();
  }, [])
  return (
    <div className="blog">
      <Header />
      <div className="blog_inner">
        <div className="return pointer" onClick={() => navigate('/blogs')}>Return to blog</div>

        <div className="title">
          <span>{blogPost?.title}</span>
        </div>
        <div className="thumbnail">
          <img src={Image1} alt="" />
          <div>
            {" "}
            <div className="title">Reshaw Yomi</div>
            <div className="time">
            {moment(parseInt(blogPost?.createdAt)).format("DD MMM YYYY")}{" "}
              <svg
                width="3"
                height="3"
                viewBox="0 0 3 3"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1.5" cy="1.5" r="1.5" fill="#B1B1B1" />
              </svg>
              7 min read
            </div>
          </div>
        </div>

        <img src={Image2} className="banner" alt="" />
        <div className="blogSubTitle" 
        dangerouslySetInnerHTML={{ __html: blogPost?.content }}
        >
        </div>
        <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
              <div className="button">ClimateAction</div>
              <div className="button">Sustainability</div>
            </div>
      </div>
      <Footer />
    </div>
  );
}
