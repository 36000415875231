import Header from "../../components/shared/Header";
import "../../styles/shared/Plans.scss";
import HeroImage from "../../images/image 113.png";
import SolutionImage3 from "../../images/Frame 1618868515.png";
import Focus from "../../images/Frame 1618868460.png";
import { useState } from "react";
import GreenLogo from "../../images/Group 32.png";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/shared/Footer";
import ContactUs from "../../components/shared/ContactUs";


export default function Verifiers() {
  const navigate = useNavigate();
  const [type, setType] = useState("verification");
  const [planVideo, setplanVideo] = useState(false);
  const [openFaq, setOpenFaq] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);


  const handleModalOPen = () => {
    setModalOpen(true);
  };


  return (
    <div className="plans">
      <Header />
      <div className="hero">
        <div className="hero__inner">
          <div className="hero__inner__title">
            CloseCarbon <span>Verifiers</span>
          </div>
          <div className="hero__inner__subtitle">
          CloseCarbon token. Verification. Trading. 
          </div>
          <div className="hero__inner__price">
            Starting at NGN0 <span>Free</span>
          </div>
          <div className="hero__inner__button-group">
            <button
              onClick={() => navigate('/template/verifier')}
            >Choose plan</button>
            <button
             onClick={() => {
              window.open("https://youtu.be/xK5PMog-SMA?si=OPK0_Ee9H5uERYy4", "_blank", "noopener,noreferrer");
            }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.155 13.7501C3.96313 13.7497 3.77466 13.6994 3.60812 13.6041C3.23312 13.3916 3 12.9791 3 12.5313V3.46881C3 3.01974 3.23312 2.60849 3.60812 2.39599C3.77863 2.298 3.97232 2.24761 4.16896 2.25009C4.36559 2.25256 4.55796 2.30781 4.72594 2.41006L12.4712 7.04631C12.6327 7.14752 12.7657 7.28807 12.858 7.45479C12.9502 7.6215 12.9986 7.8089 12.9986 7.99943C12.9986 8.18996 12.9502 8.37736 12.858 8.54408C12.7657 8.71079 12.6327 8.85134 12.4712 8.95256L4.72469 13.5901C4.55278 13.694 4.35588 13.7493 4.155 13.7501Z"
                  fill="#0FA958"
                />
              </svg>
              Watch now
            </button>
          </div>

          <img className="hero__inner__img" src={HeroImage} alt="" />
        </div>
      </div>
      <div className="solution">
        <div className="solution__title">
          The <span>solution</span> for verifiers
        </div>
        <div className="solution__subtitle">
          A seamless user experience, an intuitive dashboard, and innovative
          features that keep you aligned with your sustainability goals{" "} <br />
          <span>- LeadGravel</span>
        </div>
        <div className="solution__grid">
          <div className="solution__grid__nav">
            <div
              className={
                type === "verification"
                  ? "solution__grid__nav__item solution__grid__nav__item-active"
                  : "solution__grid__nav__item"
              }
              onClick={() => setType("verification")}
            >
              <div className="solution__grid__nav__item__title">
                Verification
              </div>
              {type === "verification" && (
                <div className="solution__grid__nav__item__subtitle">
                  Verification of emission data is carried out to ensure its accuracy, reliability and compliance with established carbon methodologies and standards.
                </div>
              )}
            </div>
          </div>
          {type === "verification" && (
            <img src={SolutionImage3} className="solution__grid__img" />
          )}
        </div>
      </div>

      <div className="seamless seamless-verifier">
        <div className="seamless__card1">
          <div className="seamless__card1__title">
          Seamless guide to becoming a verifier
          </div>
          <div className="seamless__card1__subtitle">
          Whether you're just beginning your sustainability journey or aiming to enhance your commitment, the verifier plan offers a pivotal role in ensuring the accuracy and integrity of emission data. 
<br />
<br />
Get our CCT from decentralized market like (Uniswap, Pancakeswap)
          </div>
          <div className="seamless__card1__button-group">
            <button
            onClick={() => navigate('/template/verifier')}
            >Choose plan</button>
            <button
            onClick={handleModalOPen}
            >
              Contact us
            </button>
          </div>
        </div>
        <div className="seamless__card2">
          <div className="seamless__card2__group">
            <div>1</div>
            <div>
              <div>Fill out the provided template</div>
              <div>Complete the template provided on the website with your relevant details. This information will be used to verify your identity and qualifications as a verifier.</div>
            </div>
          </div>
          <div className="seamless__card2__group">
            <div>2</div>
            <div>
              <div>Sign up</div>
              <div>Register and sign up to finalize the process. Upon completion, you'll officially become a verifier and be ready to start verifying emission data for CloseCarbon users.</div>
            </div>
          </div>
          <div className="seamless__card2__group">
            <div>3</div>
            <div>
              <div>Purchase CloseCarbon tokens</div>
              <div>Obtain CCT (CloseCarbon Tokens) from the decentralized market. These tokens will be used for staking and participating in the verification process.</div>
            </div>
          </div>
          <div className="seamless__card2__group">
            <div>4</div>
            <div>
              <div>Stake your CloseCarbon tokens</div>
              <div>Stake your CCT tokens to demonstrate your commitment and involvement in the verification process. Staking helps ensure the integrity of the verification system.</div>
            </div>
          </div>
        </div>
      </div>

      <div className="unlock">
        <div className="unlock__card1">
          <div className="unlock__card1__title">
          Unlock the potential of Verifiers plan
          </div>
          <div className="unlock__card1__subtitle">
          Whether you're new to verifying emissions or looking to enhance your role, the verifier plan offers the groundwork for making impactful strides in environmental accountability.
          </div>
          <img className="unlock__card1__img" src={Focus} alt="" />
        </div>
        <div className="unlock__card2">
          <div className="unlock__card2__flex">
            <img src={GreenLogo} />
            <div>
              <div>Verifiers</div>
              <div>CloseCarbon token</div>
            </div>
          </div>
          <div className="unlock__card2__divider"></div>
          <div className="unlock__card2__price">
            <div>NGN 0</div>
            <div>Free</div>
          </div>
          <div className="unlock__card2__divider"></div>
          <div className="unlock__card2__more-info">
            <div className="unlock__card2__more-info__title">Access to:</div>
             {/* <div className="unlock__card2__more-info__flex">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#E9F9D2" />
                    <path
                      d="M2 6.27757L4.38766 8.73575L10 3"
                      stroke="#0D924F"
                      stroke-width="1.09252"
                    />
                  </svg>

                  <div>
                  CloseCarbon token, which will be staked for a period of time.  Easy to trade in decentralized market.
                  </div>
                </div> */}
                <div className="unlock__card2__more-info__flex">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#E9F9D2" />
                    <path
                      d="M2 6.27757L4.38766 8.73575L10 3"
                      stroke="#0D924F"
                      stroke-width="1.09252"
                    />
                  </svg>

                  <div>
                  Verification of basic users' carbon reduction data to establish accuracy and ensure that the process meets the required standard.
                  </div>
                </div>
               
            <button
              className="unlock__card2__more-info__button"
              onClick={() => setplanVideo(true)}
            >
              Book a call
            </button>
          </div>
        </div>
      </div>

      <div className="faq">
        <div className="faq__inner">
          <div className="faq__inner__card1">
            <div className="faq__inner__card1__title">
              Frequently <br /> asked <br /> <span>questions</span>
            </div>
          </div>
          <div className="faq__inner__card2">
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 0 ? setOpenFaq(null) : setOpenFaq(0))}
            >
              <div className="card_inner">
                <div>What is the role of a verifier in CloseCarbon?</div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 0 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 0 && (
                  <div className="subtitle">
Verifiers play a crucial role in ensuring the accuracy and integrity of emission data submitted by users. They validate and confirm the information provided before users can earn carbon incentives.                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 0 ? setOpenFaq(null) : setOpenFaq(1))}
            >
              <div className="card_inner">
                <div>
                How do I become a verifier?
                </div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 1 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 1 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 2 ? setOpenFaq(null) : setOpenFaq(2))}
            >
              <div className="card_inner">
                <div>
                What qualifications do I need to become a verifier?
                </div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 2 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 2 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 3 ? setOpenFaq(null) : setOpenFaq(3))}
            >
              <div className="card_inner">
                <div>
                What is the verification process like?
                </div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 3 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 3 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
            <div
              className="faq__inner__card2__faq-card"
              onClick={() => (openFaq === 4 ? setOpenFaq(null) : setOpenFaq(4))}
            >
              <div className="card_inner">
                <div>Can I participate in other activities while being a verifier?</div>
                <div className="plus">
                  <div className="item"></div>
                  <div
                    className={
                      openFaq === 4 ? "item item2 itemOpaque" : "item item2"
                    }
                  ></div>
                </div>
              </div>
              <div>
                {openFaq === 4 && (
                  <div className="subtitle">
                    CloseCarbon's Basic plan offers essential tools for
                    monitoring carbon emissions, setting reduction goals, and
                    accessing basic verification services. Users can track their
                    carbon footprint in real-time, receive customizable reports,
                    and access a simplified verification process.
                  </div>
                )}
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactUs
       handleModalOPen={handleModalOPen}
       setModalOpen={setModalOpen}
       modalOpen={modalOpen}
      />

      <Footer />
      
    </div>
  );
}
