import { ethers } from "ethers";
import axios from "axios";
// import dotenv from "dotenv";
import pinataSDK from "@pinata/sdk";
import fs from "fs";
import { getContracts } from "../utils/getContracts";
import pinJSONToIPFS from "@pinata/sdk/types/commands/pinning/pinJSONToIPFS";

// dotenv.config();

const pinata = new pinataSDK({
  pinataJWTKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI2NDgyZjkxZS0zNjNlLTQ5OWYtYjQ1ZC0wNzNmYzA4NmQxYTUiLCJlbWFpbCI6InRlaGlsYWZhdm91cml0ZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiNTczMGRiYjgwNjhiZjFjYjdkYTUiLCJzY29wZWRLZXlTZWNyZXQiOiI1MTkyMmJmNDM1ZGUwYWM5YWMwN2UzYmE4NzQ1OGZiYzU5N2FjOTI3MzJhOTJjMGFlNjQyYmQ1OWNhZWRkMzk4IiwiaWF0IjoxNzAwMjA4NzQzfQ.t850Nq1NQGZTnpw-f-NEhVUrk892-UBFUWOp6hkiqnk",
});

// const pinata = new pinataSDK({ pinataJWTKey: REACT_APP_pinataJWT });
const abiCoder = new ethers.AbiCoder();

async function pinLinksToIPFS(link: string[]) {
  let links: any;

  links = {
    link: link,
  };

  let options: any;
  options = {
    pinataMetadata: {
      name: "Netzence",
      keyvalues: {
        customKey: "Netzence",
        customKey2: "Netzence",
      },
    },
    pinataOptions: {
      cidVersion: 0,
    },
  };
  console.log(links);

  const result = await pinata.pinJSONToIPFS(links, options);
  console.log(result);

  return result;
}

async function storeCertificateHash(hashToRecord: string) {
  const { signer, donationCertificateContractInstance } = await getContracts();

  const txKYC = await donationCertificateContractInstance
    // .connect(signer)
    .storeCertificateHash(hashToRecord);
  return {
    txKYC,
  };
}

async function getCertificateHash(donationId: number) {
  const { donationCertificateContractInstance } = await getContracts();

  const getCertificateHash =
    await donationCertificateContractInstance.getCertificateHash(donationId);
  return {
    getCertificateHash,
  };
}

async function verifyOwnership(donationId: number, ipfsHash: string) {
  const { donationCertificateContractInstance } = await getContracts();

  const isOwner = await donationCertificateContractInstance.verifyOwnership(
    donationId,
    ipfsHash
  );
  return {
    isOwner,
  };
}

async function getTotalDonations() {
  const { donationCertificateContractInstance } = await getContracts();

  const totalDonations =
    await donationCertificateContractInstance.getTotalDonations();
  return {
    totalDonations: totalDonations.toNumber(),
  };
}

async function transferOwnership(newOwner: string) {
  const { donationCertificateContractInstance } = await getContracts();

  const transferTx =
    await donationCertificateContractInstance.transferOwnership(newOwner);
  const receipt = await transferTx.wait();

  return {
    transactionHash: receipt.transactionHash,
    newOwner,
  };
}

async function renounceOwnership() {
  const { donationCertificateContractInstance } = await getContracts();

  const renounceTx =
    await donationCertificateContractInstance.renounceOwnership();
  const receipt = await renounceTx.wait();

  return {
    transactionHash: receipt.transactionHash,
    success: true,
  };
}

export {
  pinLinksToIPFS,
  storeCertificateHash,
  getCertificateHash,
  verifyOwnership,
  getTotalDonations,
  transferOwnership,
  renounceOwnership,
};

// storeCertificateHash("qxohdbbbbbevakae")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// getCertificateHash(0)
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

// verifyOwnership(0, "qxohddyerevakae")
//   .then((result) => {
//     console.log(result);
//   })
//   .catch((err) => {
//     console.log(err);
//   });

getTotalDonations()
  .then((result) => {
    console.log(result);
  })
  .catch((err) => {
    console.log(err);
  });
