import { useNavigate, useParams } from "react-router-dom";
import SideBar from "../../../components/AdminDashboard/WebsiteDashboard/SideBar";
import '../../../styles/AdminDashboard/WebsiteDashboard/TemplateSubmission.scss'
import { useEffect, useState } from "react";
import { getTemplateSubmission } from "../../../apis/AdminDashboard/users";
import { useSelector } from "react-redux";

export default function TemplateSubmission() {
    const navigate = useNavigate();
    const pathname = useParams();
    const auth = useSelector((state) => state.auth);
    const [data, setData] =useState(null);


    useEffect(() => {
      handleGetTemplateSubmission();
    }, [])

    const handleGetTemplateSubmission = async () => {
      const response = await getTemplateSubmission(pathname?.id, auth?.token);
      console.log('emoples', response);
      setData(response?.data?.message?.onboardingTemplate);
    }

    return (
        <div className="template-submission">
      <SideBar />

      <div className="main">
        <div className="main_inner">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="pointer"
            onClick={() =>{ navigate("/admin/website/support-hub")
            window.scrollTo(0, 0);
          }}
          >
            <path
              d="M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z"
              fill="#1A1A1A"
            />
            <path
              d="M5.55989 12L11.7804 18.219C11.9212 18.3598 12.0003 18.5508 12.0003 18.75C12.0003 18.9491 11.9212 19.1401 11.7804 19.281C11.6396 19.4218 11.4486 19.5009 11.2494 19.5009C11.0502 19.5009 10.8592 19.4218 10.7184 19.281L3.96839 12.531C3.89854 12.4613 3.84313 12.3785 3.80532 12.2874C3.76751 12.1963 3.74805 12.0986 3.74805 12C3.74805 11.9013 3.76751 11.8036 3.80532 11.7125C3.84313 11.6214 3.89854 11.5386 3.96839 11.469L10.7184 4.71897C10.8592 4.57814 11.0502 4.49902 11.2494 4.49902C11.4486 4.49902 11.6396 4.57814 11.7804 4.71897C11.9212 4.8598 12.0003 5.05081 12.0003 5.24997C12.0003 5.44913 11.9212 5.64014 11.7804 5.78097L5.55989 12Z"
              fill="#1A1A1A"
            />
          </svg>
          {/* {generalLoading && <div>
            <div className="input_container">
              <div><Skeleton width={160} height={20} borderRadius={8} /></div>
              <Skeleton width={520} height={60} borderRadius={12} />
            </div>
            <div className="input_container">
              <div><Skeleton width={160} height={20} borderRadius={8} /></div>
              <Skeleton width={520} height={60} borderRadius={12} />
            </div>
            <div className="input_container">
              <div><Skeleton width={160} height={20} borderRadius={8} /></div>
              <Skeleton width={520} height={60} borderRadius={12} />
            </div>
            <div className="input_container">
              <div><Skeleton width={160} height={20} borderRadius={8} /></div>
              <Skeleton width={520} height={60} borderRadius={12} />
            </div>
            <div className="input_container">
              <div><Skeleton width={160} height={20} borderRadius={8} /></div>
              <Skeleton width={520} height={60} borderRadius={12} />
            </div>
            <div className="input_container">
              <div><Skeleton width={160} height={20} borderRadius={8} /></div>
              <Skeleton width={520} height={60} borderRadius={12} />
            </div>
            <div className="input_container">
              <div><Skeleton width={160} height={20} borderRadius={8} /></div>
              <Skeleton width={520} height={60} borderRadius={12} />
            </div>
            
          </div>} */}
           <div>
            <div className="input_container">
              <div>Facility Name</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                disabled
                value={data?.facilityName}
              />
            </div>
            <div className="input_container">
              <div>Facility location</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                disabled
                value={data?.facilityLocation}
              />
            </div>
            <div className="input_container">
              <div>Facility start date</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                disabled
                value={data?.facilityStartDate}
              />
            </div>
            <div className="input_container">
              <div>Industry</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                disabled
                value={data?.industry}
              />
            </div>
            <div className="input_container">
              <div>Facility size</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.facilitySize}
                disabled
              />
            </div>
            <div className="input_container">
              <div>Fuel source</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.fuelSource}
                disabled
              />
            </div>
            <div className="input_container">
              <div>Facility category</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.facilityCategory
                }
                disabled
              />
            </div>
            <div className="input_container">
              <div>Green project name</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.greenProjectName}
                disabled
              />
            </div>
            <div className="input_container">
              <div>Green project location</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.
                  greenProjectLocation
                  }
                // disabled
              />
            </div>
           
            <div className="input_container">
              <div>Green project objectives</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.greenProjectObjective}
                disabled
              />
            </div>
            <div className="input_container">
              <div>Green project start date</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.
                  greenProjectStartDate}
                disabled
              />
            </div>
           
            <div className="input_container">
              <div>Green project end date</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.
                  greenProjectEndDate}
                disabled
              />
            </div>
            <div className="input_container">
              <div>Green project license/ID</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.
                  greenProjectLicenseId
                  }
                disabled
              />
            </div>
            <div className="input_container">
              <div>Green certification body</div>
              <input
                type="text"
                // placeholder="CloseCarbon"
                value={data?.greenCertificateBody}
                disabled
              />
            </div>
           
          </div>
        </div>
      </div>
    </div>
    )
}