import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SideBar from "../../../components/AdminDashboard/WebsiteDashboard/SideBar";
import "../../../styles/AdminDashboard/WebsiteDashboard/SupportHub.scss";
import { useNavigate } from "react-router-dom";
import { getDemoRequests, verifyDemoRequests } from "../../../apis/AdminDashboard/users";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import Search from "../../../components/AdminDashboard/Search";
export default function SupportHub() {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Request demo");
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredrequests] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);

  const handlePageChange = (event, value) => {
    setPage(value)
  }


  const listDemoRequests = async () => {
    setLoading(true)
    const response = await getDemoRequests(auth?.token, page);
    console.log("response", response);
    setRequests(response?.data?.message?.items);
    setFilteredrequests(response?.data?.message?.items);
    setPagination(response?.data?.message)
    setLoading(false)
  };

  useEffect(() => {
    listDemoRequests();
  }, [page]);

  const filterRequests = (tab) => {
    if(tab === "Request demo") {
      setFilteredrequests(requests)
    }else if(tab === "In progress") {
      setFilteredrequests(requests?.filter((request) => request?.approvalStatus === "pending"))
    }else if(tab === "Verify") {
      setFilteredrequests(requests?.filter((request) => request?.approvalStatus === "inprogress"))
    }else if(tab === "Completed") {
      setFilteredrequests(requests?.filter((request) => request?.approvalStatus === "approved"))
    }
  }

  const handeVerifyDemoRequests = async (id) => {
    setLoading(true);
    const response = await verifyDemoRequests(id, auth?.token);

    console.log('response34', response);

    if(response?.data?.statusCode === 200) {
      toast.success('Demo request approved');
    }else{
      toast.error('Something went wrong')
    }
    setLoading(false);
  }




  return (
    <div className="support-hub">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="support-hub__main">
        <div>
        <Search />
        <div className="support-hub__main-inner">
        <div className="support-hub__main-inner__table-header">
          <div
            className={activeTab === "Request demo" ? "active" : ""}
            onClick={() => {
              setActiveTab("Request demo")
              filterRequests("Request demo")
            }}
          >
            Request demo
          </div>
          <div
            className={activeTab === "In progress" ? "active" : ""}
            onClick={() => {
              setActiveTab("In progress")
              filterRequests("In progress")
            }}
          >
            In progress
          </div>
          <div
            className={activeTab === "Verify" ? "active" : ""}
            onClick={() => {
              setActiveTab("Verify")
              filterRequests("Verify")
            }}
          >
            Verify
          </div>
          <div
            className={activeTab === "Completed" ? "active" : ""}
            onClick={() => {
              setActiveTab("Completed")
              filterRequests("Completed")
            }}
          >
            Completed
          </div>
        </div>
        {loading && (
          <>
            <div className="support-hub__main-inner__table-body">
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
              
            </div>
            <div className="support-hub__main-inner__table-body">
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
              
            </div>
            <div className="support-hub__main-inner__table-body">
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
                <Skeleton width="100%" height={30} borderRadius={4} />
              
            </div>
          
          </>
        )}
        {filteredRequests?.map((request) => (
          !loading && <div className="support-hub__main-inner__table-body" 
          onClick={() => navigate(`/admin/website/support-hub/${request?.id}`)}
          >
            <div>{request?.firstName}</div>
            <div>{request?.lastName}</div>
            <div>{request?.email}</div>
            <div>{request?.phoneNumber}</div>
            <div>{request?.organization}</div>
            <div>
              {request?.approvalStatus === "approved" ? <button className="table-body__transaprent">Completed</button> : request?.approvalStatus === "pending" ? <button
                className="table-body__filled"
                onClick={() => navigate(`/admin/website/send-demo/${request?.email}`)}
              >
                Mail
              </button> : <button
                className="table-body__filled"
                onClick={() => handeVerifyDemoRequests(request?.id)}
              >
                {loading ? 'Loading...' : 'Verify'}
              </button>}
            </div>
          </div>
        ))}
        {/* <div className="support-hub__main-inner__table-body">
          <div>Oluwatimilehin</div>
          <div>Grace</div>
          <div>oladegrace.ot@gmail.com</div>
          <div>09026981200</div>
          <div>netzence</div>
          <div>
            <button className="table-body__transaprent">Completed</button>
          </div>
        </div>
        <div className="support-hub__main-inner__table-body">
          <div>Oluwatimilehin</div>
          <div>Grace</div>
          <div>oladegrace.ot@gmail.com</div>
          <div>09026981200</div>
          <div>netzence</div>
          <div>
            <button className="table-body__filled" onClick={() => navigate('/admin/website/send-demo')}>Mail</button>
          </div>
        </div>
        <div className="support-hub__main-inner__table-body">
          <div>Oluwatimilehin</div>
          <div>Grace</div>
          <div>oladegrace.ot@gmail.com</div>
          <div>09026981200</div>
          <div>netzence</div>
          <div>
            <button className="table-body__transaprent">Completed</button>
          </div>
        </div>
        <div className="support-hub__main-inner__table-body">
          <div>Oluwatimilehin</div>
          <div>Grace</div>
          <div>oladegrace.ot@gmail.com</div>
          <div>09026981200</div>
          <div>netzence</div>
          <div>
            <button className="table-body__filled" onClick={() => navigate('/admin/website/send-demo')}>Mail</button>
          </div>
        </div>
        <div className="support-hub__main-inner__table-body">
          <div>Oluwatimilehin</div>
          <div>Grace</div>
          <div>oladegrace.ot@gmail.com</div>
          <div>09026981200</div>
          <div>netzence</div>
          <div>
            <button className="table-body__transaprent">Completed</button>
          </div>
        </div> */}
        </div>
        </div>
      <Pagination
              // count={Object?.keys(pagination)?.length}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
      </div>

    </div>
  );
}
