import Header from "../../components/shared/Header";
import "../../styles/shared/Blogs.scss";
import MainBlog from "../../images/image 1.png";
import Blog1 from "../../images/image 30 (2).png";
import Blog2 from "../../images/image 2 (2).png";
import Blog3 from "../../images/image 5.png";
import Blog4 from "../../images/image 6.png";
import Blog5 from "../../images/image 14.png";
import Blog6 from "../../images/image 17.png";
import Blog7 from "../../images/image 11.png";
import Blog8 from "../../images/image 20.png";
import Blog9 from "../../images/image 22.png";
import Blog10 from "../../images/image 24.png";
import Blog11 from "../../images/image 25.png";
import Blog12 from "../../images/image 26.png";
import Footer from "../../components/shared/Footer";
import { useNavigate } from "react-router-dom";
import { getBlogPosts } from "../../apis/AdminDashboard/users";
import { useEffect, useState } from "react";
import moment from "moment";
export default function Blogs() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(false);


  const loadBlogPosts = async () => {
    setLoading(true);
    const response = await getBlogPosts();
    console.log('response', response);
    setBlogPosts(response?.data?.message?.items);
    setLoading(false);
  }

  useEffect(() => {
    loadBlogPosts()
  }, [])

  const navigate = useNavigate();
  return (
    <div className="blog">
      <Header />
      <div className="hero">
        <div className="title">
          <span>CloseCarbon</span>: Blogs, Interviews and Stories
        </div>
        <div className="subtitle">
          Subscribe to stay updated on latest carbon reduction initiatives,
          sustainable solutions, and environmental innovations.
        </div>
        <div className="group">
          <input placeholder="Enter email address" type="text" />
          <button>Subscribe</button>
        </div>
      </div>
      <div className="blogPosts">
        <div className="mainPost pointer">
          <div  onClick={() => {
            navigate(`/blog/${blogPosts[0]?.id}`)
            window.scroll(0, 0)
            }}>
            <div className="title">Blog Post</div>
            <div className="subtitle_group">
              <div className="subtitle">
                <span>CloseCarbon Chronicles</span> : {moment(parseInt(blogPosts[0]?.createdAt)).format("DD MMM YYYY")}
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                  fill="#006633"
                />
              </svg>
            </div>
            <div className="second_title">
              {blogPosts[0]?.title}
            </div>
            <div className="second_subtitle">
            {blogPosts[0]?.intro}...
            </div>
            <div className="time">7 min read</div>
            <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div>
          </div>

          <img src={MainBlog} alt="" />
        </div>

        <div className="gridMain grid1">
          <div className="pointer" onClick={() => {
            navigate(`/blog/${blogPosts[1]?.id}`)
            window.scroll(0, 0)
            }}>
            <img src={Blog2} alt="" />
           <div>
           <div className="subtitle_group">
              <div className="subtitle">
                <span>CloseCarbon Chronicles</span> : {moment(parseInt(blogPosts[1]?.createdAt)).format("DD MMM YYYY")}
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                  fill="#006633"
                />
              </svg>
            </div>
            <div className="second_title">
            {blogPosts[1]?.title}
            </div>
            <div className="second_subtitle">
            {blogPosts[1]?.intro}
            </div>
            <div className="time">7 min read</div>
            <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div>
           </div>
          </div>
          <div className="pointer" onClick={() => {
            navigate(`/blog/${blogPosts[2]?.id}`)
            window.scroll(0, 0)
            }}>
            <img src={Blog1} alt="" />
            <div>
            <div className="subtitle_group">
              <div className="subtitle">
                <span>CloseCarbon Chronicles</span> : {moment(parseInt(blogPosts[2]?.createdAt)).format("DD MMM YYYY")}
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                  fill="#006633"
                />
              </svg>
            </div>
            <div className="second_title">
            {blogPosts[2]?.title}
            </div>
            <div className="second_subtitle">
            {blogPosts[2]?.intro}
            </div>
            <div className="time">7 min read</div>
            <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div>
            </div>
          </div>
        </div>
        <div className="gridMain grid2">
          <div className="pointer" onClick={() => {
            navigate(`/blog/${blogPosts[3]?.id}`)
            window.scroll(0, 0)
            }}>
            <img src={Blog4} alt="" />
            <div>
            <div className="subtitle_group">
              <div className="subtitle">
                <span>CloseCarbon Chronicles</span> : {moment(parseInt(blogPosts[3]?.createdAt)).format("DD MMM YYYY")}
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                  fill="#006633"
                />
              </svg>
            </div>
            <div className="second_title">
            {blogPosts[3]?.title}
            </div>
            <div className="second_subtitle">
            {blogPosts[3]?.intro}...
            </div>
            <div className="time">10 min read</div>
            <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div>
            </div>
          </div>
          <div className="pointer" onClick={() => {
            navigate(`/blog/${blogPosts[4]?.id}`)
            window.scroll(0, 0)
            }}>
            <img src={Blog3} alt="" />

            <div>
            <div className="subtitle_group">
              <div className="subtitle">
                <span>CloseCarbon Chronicles</span> : {moment(parseInt(blogPosts[4]?.createdAt)).format("DD MMM YYYY")}
              </div>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                  fill="#006633"
                />
              </svg>
            </div>
            <div className="second_title">
            {blogPosts[4]?.title}
            </div>
            <div className="second_subtitle">
            {blogPosts[4]?.intro}...
            </div>
            <div className="time">7 min read</div>
            <div className="button_group">
              <div className="button">Ecofriendly</div>
              <div className="button">GreenTech</div>
              <div className="button">RenewableEnergy</div>
            </div>
            </div>
          </div>
        </div>
        <div className="interviews">
          <div className="title">Interviews</div>
          <div className="interviewGrid gridMain">
          {blogPosts?.map((blogPost) => 
            <div className="pointer" onClick={() => {
              navigate(`/blog/${blogPost?.id}`)
              window.scroll(0, 0)
              }}>
              <div>
                <img src={Blog5} alt="" />
                <div>
                  <div className="subtitle_group">
                    <div className="subtitle">
                      <span>CloseCarbon Chronicles</span> : 02 November 2023
                    </div>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                        fill="#006633"
                      />
                    </svg>
                  </div>
                  <div className="second_title">
                  {blogPost?.title}
                  </div>
                  <div className="second_subtitle">
                  {blogPost?.intro}...
                  </div>
                  <div className="time">7 min read</div>
                  <div className="button_group interviweButtonGroup">
                    <div className="button interviweButton">Ecofriendly</div>
                    <div className="button interviweButton">GreenTech</div>
                    <div className="button interviweButton">RenewableEnergy</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {blogPosts?.map((blogPost) => 
            <div className="pointer" onClick={() => {
              navigate(`/blog/${blogPost?.id}`)
              window.scroll(0, 0)
              }}>
              <div>
                <img src={Blog5} alt="" />
                <div>
                  <div className="subtitle_group">
                    <div className="subtitle">
                      <span>CloseCarbon Chronicles</span> : 02 November 2023
                    </div>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                        fill="#006633"
                      />
                    </svg>
                  </div>
                  <div className="second_title">
                  {blogPost?.title}
                  </div>
                  <div className="second_subtitle">
                  {blogPost?.intro}...
                  </div>
                  <div className="time">7 min read</div>
                  <div className="button_group interviweButtonGroup">
                    <div className="button interviweButton">Ecofriendly</div>
                    <div className="button interviweButton">GreenTech</div>
                    <div className="button interviweButton">RenewableEnergy</div>
                  </div>
                </div>
              </div>
            </div>
          )}
           
          </div>
        </div>
        <div className="stories">
          <div className="title">Stories</div>
          <div className="storiesGrid gridMain">
            {blogPosts?.map((blogPost) => 
            <div className="pointer" onClick={() => {
              navigate(`/blog/${blogPost?.id}`)
              window.scroll(0, 0)
              }}>
            <div>
              <img src={Blog9} alt="" />
              <div>
              <div className="subtitle_group">
                <div className="subtitle">
                  <span>CloseCarbon Chronicles</span> : {moment(parseInt(blogPost?.createdAt)).format("DD MMM YYYY")}
                </div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                    fill="#006633"
                  />
                </svg>
              </div>
              <div className="second_title">
              {blogPost?.title}
              </div>
              <div className="second_subtitle">
              {blogPost?.intro}...
              </div>
              <div className="time">7 min read</div>
              <div className="button_group">
                <div className="button">Ecofriendly</div>
                <div className="button">GreenTech</div>
                <div className="button">RenewableEnergy</div>
              </div>
              </div>
            </div>
          </div>
            )}
            {blogPosts?.map((blogPost) => 
            <div className="pointer" onClick={() => {
              navigate(`/blog/${blogPosts[0]?.id}`)
              window.scroll(0, 0)
              }}>
            <div>
              <img src={Blog9} alt="" />
              <div>
              <div className="subtitle_group">
                <div className="subtitle">
                  <span>CloseCarbon Chronicles</span> : {moment(parseInt(1703984400)).format("DD MMM YYYY")}
                </div>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                    fill="#006633"
                  />
                </svg>
              </div>
              <div className="second_title">
              {blogPost?.title}
              </div>
              <div className="second_subtitle">
              {blogPost?.intro}...
              </div>
              <div className="time">7 min read</div>
              <div className="button_group">
                <div className="button">Ecofriendly</div>
                <div className="button">GreenTech</div>
                <div className="button">RenewableEnergy</div>
              </div>
              </div>
            </div>
          </div>
            )}
            {/* <div className="pointer" onClick={() => {
              navigate(`/blog/${blogPosts[0]?.id}`)
            window.scroll(0, 0)
            }}>
              <div>
                <img src={Blog10} alt="" />
                <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> : 02 November 2023
                  </div>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                      fill="#006633"
                    />
                  </svg>
                </div>
                <div className="second_title">
                  The Role of Technology in Carbon Reduction
                </div>
                <div className="second_subtitle">
                  Discuss the ways technology and innovation are helping to
                  lower carbon emissions, from smart energy management to data
                  analytics...
                </div>
                <div className="time">7 min read</div>
                <div className="button_group">
                  <div className="button">Ecofriendly</div>
                  <div className="button">GreenTech</div>
                  <div className="button">RenewableEnergy</div>
                </div>
                </div>
              </div>
            </div>
            <div className="pointer" onClick={() => {
              navigate(`/blog/${blogPosts[0]?.id}`)
            window.scroll(0, 0)
            }}>
              <div>
                <img src={Blog12} alt="" />
                <div>
                <div className="subtitle_group">
                  <div className="subtitle">
                    <span>CloseCarbon Chronicles</span> : 02 November 2023
                  </div>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.721 1.52134C13.721 0.967864 13.2724 0.519186 12.7189 0.519185L3.69954 0.519186C3.14607 0.519186 2.69739 0.967863 2.69739 1.52134C2.69739 2.07481 3.14607 2.52349 3.69954 2.52349H11.7167V10.5407C11.7167 11.0942 12.1654 11.5428 12.7189 11.5428C13.2724 11.5428 13.721 11.0942 13.721 10.5407L13.721 1.52134ZM2.08949 13.568L13.4275 2.22996L12.0103 0.812709L0.672232 12.1507L2.08949 13.568Z"
                      fill="#006633"
                    />
                  </svg>
                </div>
                <div className="second_title">
                  The Role of Technology in Carbon Reduction
                </div>
                <div className="second_subtitle">
                  Discuss the ways technology and innovation are helping to
                  lower carbon emissions, from smart energy management to data
                  analytics...
                </div>
                <div className="time">7 min read</div>
                <div className="button_group">
                  <div className="button">Ecofriendly</div>
                  <div className="button">GreenTech</div>
                  <div className="button">RenewableEnergy</div>
                </div>
                </div>
              </div>
            </div> */}
           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
