import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import "../../styles/shared/Demo.scss";
import RequestVideo from "../../images/request_video.mp4";
import { useEffect, useLayoutEffect, useState } from "react";
import { toast } from "react-toastify";
import { demoRequest } from "../../apis/General/users";
import { useSelector } from "react-redux";
export default function Demo() {

  const auth = useSelector((state) => state.auth);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(false);


  useLayoutEffect(() => {
    const video = document.getElementById("video");
    const playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => {
        // console.log('video played!');
      })
      .catch(error => {
        console.log('error', error);
      });
    }
    // video.pause();
    // video.currentTime = 0;
    // setTimeout(function () {
    //   video.play();
    // }, 150);
  }, []);

  const handleDemoRequest = async (e) => {
    setLoading(true)
    e.preventDefault();
    const response = await demoRequest(
      firstName,
      lastName,
      email,
      phone,
      companyName,
    );
    console.log('response', response);
    if(response?.data?.statusCode === 200) {
    toast.success("Request sent. We'll be in touch soon.");
    
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setCompanyName("");
    }else {
      toast.error(response?.data?.message)
    }
    setLoading(false)

  };
  return (
    <div className="demo">
      <Header />
      <div className="banner">
        <video
          id="video"
          // autoplay
          // loop
          muted
          playsinline
          src={RequestVideo}
        ></video>
        Book your CloseCarbon Demo Today!
      </div>

      <form onSubmit={handleDemoRequest}>
        <div className="input_group">
          <label htmlFor="firstName">
            First Name<span>*</span>
          </label>
          <div className="input_group_inner">
            <svg
              width="16"
              height="21"
              viewBox="0 0 16 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 21C0 16.5817 3.58172 13 8 13C12.4183 13 16 16.5817 16 21H14C14 17.6863 11.3137 15 8 15C4.68629 15 2 17.6863 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
                fill="#B1B1B1"
              />
            </svg>
            <div className="divider"></div>
            <input
              required
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Enter your first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <label htmlFor="lastName">
            Last Name<span>*</span>
          </label>
          <div className="input_group_inner">
            <svg
              width="16"
              height="21"
              viewBox="0 0 16 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 21C0 16.5817 3.58172 13 8 13C12.4183 13 16 16.5817 16 21H14C14 17.6863 11.3137 15 8 15C4.68629 15 2 17.6863 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
                fill="#B1B1B1"
              />
            </svg>
            <div className="divider"></div>
            <input
              required
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Enter your last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <label htmlFor="emailAddress">
            Email Address<span>*</span>
          </label>
          <div className="input_group_inner">
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1 2C14.0344 2.32311 14 2.65753 14 3C14 3.34247 14.0344 3.67689 14.1 4H2.51146L10.0619 10.662L15.1098 6.14141C15.5363 6.66888 16.0679 7.10787 16.6728 7.42652L10.0718 13.338L2 6.21594V18H18V7.89998C18.3231 7.96557 18.6575 8 19 8C19.3425 8 19.6769 7.96557 20 7.89998V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V3C0 2.44772 0.44772 2 1 2H14.1ZM19 0C20.6569 0 22 1.34315 22 3C22 4.65685 20.6569 6 19 6C17.3431 6 16 4.65685 16 3C16 1.34315 17.3431 0 19 0Z"
                fill="#B1B1B1"
              />
            </svg>

            <div className="divider"></div>
            <input
              required
              type="email"
              id="email"
              name="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <label htmlFor="phoneNumber">
            Phone Number<span>*</span>
          </label>
          <div className="input_group_inner">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.9994 15.9201V18.9201C21.0006 19.1986 20.9435 19.4743 20.832 19.7294C20.7204 19.9846 20.5567 20.2137 20.3515 20.402C20.1463 20.5902 19.904 20.7336 19.6402 20.8228C19.3764 20.912 19.0968 20.9452 18.8194 20.9201C15.7423 20.5857 12.7864 19.5342 10.1894 17.8501C7.77327 16.3148 5.72478 14.2663 4.18945 11.8501C2.49942 9.2413 1.44769 6.27109 1.11944 3.1801C1.09446 2.90356 1.12732 2.62486 1.21595 2.36172C1.30457 2.09859 1.44702 1.85679 1.63421 1.65172C1.82141 1.44665 2.04925 1.28281 2.30324 1.17062C2.55722 1.05843 2.83179 1.00036 3.10945 1.0001H6.10945C6.59475 0.995321 7.06524 1.16718 7.43321 1.48363C7.80118 1.80008 8.04152 2.23954 8.10944 2.7201C8.23607 3.68016 8.47089 4.62282 8.80945 5.5301C8.94399 5.88802 8.97311 6.27701 8.89335 6.65098C8.8136 7.02494 8.62831 7.36821 8.35944 7.6401L7.08945 8.9101C8.513 11.4136 10.5859 13.4865 13.0894 14.9101L14.3594 13.6401C14.6313 13.3712 14.9746 13.1859 15.3486 13.1062C15.7225 13.0264 16.1115 13.0556 16.4694 13.1901C17.3767 13.5286 18.3194 13.7635 19.2794 13.8901C19.7652 13.9586 20.2088 14.2033 20.526 14.5776C20.8431 14.9519 21.0116 15.4297 20.9994 15.9201Z"
                stroke="#B1B1B1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="divider"></div>
            <input
              required
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Enter your phone number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <label htmlFor="companyName">
          Organisation<span>*</span>
          </label>
          <div className="input_group_inner">
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 18.8995L13.9497 13.9497C16.6834 11.2161 16.6834 6.78392 13.9497 4.05025C11.2161 1.31658 6.78392 1.31658 4.05025 4.05025C1.31658 6.78392 1.31658 11.2161 4.05025 13.9497L9 18.8995ZM9 21.7279L2.63604 15.364C-0.87868 11.8492 -0.87868 6.15076 2.63604 2.63604C6.15076 -0.87868 11.8492 -0.87868 15.364 2.63604C18.8787 6.15076 18.8787 11.8492 15.364 15.364L9 21.7279ZM9 11C10.1046 11 11 10.1046 11 9C11 7.89543 10.1046 7 9 7C7.8954 7 7 7.89543 7 9C7 10.1046 7.8954 11 9 11ZM9 13C6.79086 13 5 11.2091 5 9C5 6.79086 6.79086 5 9 5C11.2091 5 13 6.79086 13 9C13 11.2091 11.2091 13 9 13Z"
                fill="#B1B1B1"
              />
            </svg>

            <div className="divider"></div>
            <input
              required
              type="text"
              id="companyName"
              name="companyName"
              placeholder="Enter your company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
        </div>
        <button type="submit" disabled={loading}>{loading ? 'Loading...' : 'Send Request'}</button>
      </form>

      <Footer />
    </div>
  );
}
