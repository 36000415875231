import "../../styles/ThirdParty/SideBar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Kyc from "./Kyc";
import Logo from "../../images/closecarbon_logo_1-removebg-preview 1.png";
import { useEffect, useState } from "react";

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);


  const { pathname } = useLocation();

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT_SUCCESS",
    });
    localStorage.removeItem("token");
    navigate("/third-party/login");
    window.scrollTo(0, 0);
  };

  const handeMobileMenuClose = () => {
    dispatch({
      type: "SET_MENU_VISIBLE",
      payload: false,
    });
  };

  useEffect(() => {
    handeMobileMenuClose();
  }, [pathname]);


  return (
    <div className="thirdparty_sidebar">
      <div className="logo_container">
        <div>
          <img src={Logo} alt="" className="logo pointer" onClick={() => navigate("/third-party/dashboard")} />
        </div>
      </div>

      <svg
        className="sidebar__ipad-pro"
        onClick={handeMobileMenuClose}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.8999 1.50004C15.2999 1.65004 17.5499 2.85004 19.1999 4.50004C21.1499 6.60004 22.1999 9.15004 22.1999 12.15C22.1999 14.55 21.2999 16.8 19.7999 18.75C18.2999 20.55 16.1999 21.9 13.7999 22.35C11.3999 22.8 8.99994 22.5 6.89994 21.3C4.79994 20.1 3.14994 18.3 2.24994 16.05C1.34994 13.8 1.19994 11.25 1.94994 9.00004C2.69994 6.60004 4.04994 4.65004 6.14994 3.30004C8.09994 1.95004 10.4999 1.35004 12.8999 1.50004ZM13.6499 20.85C15.5999 20.4 17.3999 19.35 18.7499 17.7C19.9499 16.05 20.6999 14.1 20.5499 12C20.5499 9.60004 19.6499 7.20004 17.9999 5.55004C16.4999 4.05004 14.6999 3.15004 12.5999 3.00004C10.6499 2.85004 8.54994 3.30004 6.89994 4.50004C5.24994 5.70004 4.04994 7.35004 3.44994 9.45004C2.84994 11.4 2.84994 13.5 3.74994 15.45C4.64994 17.4 5.99994 18.9 7.79994 19.95C9.59994 21 11.6999 21.3 13.6499 20.85ZM11.8499 11.25L15.4499 7.50004L16.4999 8.55004L12.8999 12.3L16.4999 16.05L15.4499 17.1L11.8499 13.35L8.24994 17.1L7.19994 16.05L10.7999 12.3L7.19994 8.55004L8.24994 7.50004L11.8499 11.25Z"
          fill="#667185"
        />
      </svg>
      <div className="menu">
        {/* <div
          className={
            pathname.includes("dashboard")
              ? "menu_item menu_active"
              : "menu_item"
          }
          onClick={() =>{ navigate("/third-party/dashboard")
          window.scrollTo(0, 0);
        }}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 12.5V17H3V5H8.25V3.5H3C2.60218 3.5 2.22064 3.65804 1.93934 3.93934C1.65804 4.22064 1.5 4.60218 1.5 5V17C1.5 17.3978 1.65804 17.7794 1.93934 18.0607C2.22064 18.342 2.60218 18.5 3 18.5H9V21.5H6V23H18V21.5H15V18.5H21C21.3978 18.5 21.7794 18.342 22.0607 18.0607C22.342 17.7794 22.5 17.3978 22.5 17V12.5H21ZM13.5 21.5H10.5V18.5H13.5V21.5Z"
              fill="#667185"
            />
            <path
              d="M13.5 14.0001H13.4925C13.3309 13.9984 13.1742 13.9445 13.0456 13.8466C12.9171 13.7486 12.8237 13.6117 12.7792 13.4563L11.4345 8.75008H8.25V7.25008H12C12.163 7.24999 12.3216 7.30302 12.4518 7.40113C12.582 7.49924 12.6767 7.6371 12.7215 7.79383L13.527 10.6138L15.7838 3.27883C15.8324 3.12662 15.9278 2.99361 16.0564 2.8987C16.185 2.80378 16.3402 2.75178 16.5 2.75008C16.658 2.74771 16.8126 2.79633 16.9408 2.88874C17.069 2.98115 17.164 3.11242 17.2118 3.26308L18.54 7.25008H22.5V8.75008H18C17.8425 8.75012 17.689 8.70059 17.5613 8.60851C17.4335 8.51644 17.338 8.38649 17.2882 8.23708L16.5285 5.95708L14.217 13.4706C14.1698 13.6239 14.0747 13.7581 13.9457 13.8534C13.8166 13.9487 13.6604 14.0001 13.5 14.0001Z"
              fill="#667185"
            />
          </svg>

          <div>Monitoring</div>
        </div> */}
        {/* {auth?.userInfo?.role === "thirdParty" && <div
          className={
            pathname.includes("validation")
              ? "menu_item menu_active"
              : "menu_item"
          }
          onClick={() =>{ navigate("/third-party/validation")
          window.scrollTo(0, 0);
        }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.125 23.1113C9.9954 23.1112 9.86802 23.0777 9.75525 23.0138L3.38025 19.4025C3.26482 19.3371 3.16881 19.2423 3.102 19.1276C3.0352 19.013 3 18.8827 3 18.75V11.25C3 11.1174 3.0352 10.9871 3.102 10.8724C3.16881 10.7578 3.26482 10.6629 3.38025 10.5975L9.75525 6.98628C9.86803 6.92246 9.99541 6.88892 10.125 6.88892C10.2546 6.88892 10.382 6.92246 10.4948 6.98628L16.8698 10.5975C16.9852 10.6629 17.0812 10.7578 17.148 10.8724C17.2148 10.9871 17.25 11.1174 17.25 11.25V18.75C17.25 18.8827 17.2148 19.013 17.148 19.1276C17.0812 19.2423 16.9852 19.3371 16.8698 19.4025L10.4948 23.0138C10.382 23.0777 10.2546 23.1112 10.125 23.1113ZM4.5 18.3128L10.125 21.4988L15.75 18.3128V11.6873L10.125 8.50128L4.5 11.6865V18.3128Z"
              fill="#667185"
            />
            <path
              d="M21.0001 12.75H19.5001V5.68728L13.8751 2.50128L7.86987 5.90253L7.13037 4.59753L13.5054 0.986282C13.6182 0.922459 13.7455 0.888916 13.8751 0.888916C14.0047 0.888916 14.1321 0.922459 14.2449 0.986282L20.6199 4.59753C20.7353 4.66294 20.8313 4.7578 20.8981 4.87243C20.9649 4.98706 21.0001 5.11736 21.0001 5.25003V12.75Z"
              fill="#667185"
            />
          </svg>

          <div>Validation</div>
        </div>} */}
        <div
          className={
            pathname.includes("verification")
              ? "menu_item menu_active"
              : "menu_item"
          }
          onClick={() =>{ navigate("/third-party/verification")
          window.scrollTo(0, 0);
        }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18 21H22.5V22.5H18V21Z" fill="#667185" />
            <path
              d="M15.75 22.5C16.1642 22.5 16.5 22.1642 16.5 21.75C16.5 21.3358 16.1642 21 15.75 21C15.3358 21 15 21.3358 15 21.75C15 22.1642 15.3358 22.5 15.75 22.5Z"
              fill="#667185"
            />
            <path
              d="M21.75 19.5C22.1642 19.5 22.5 19.1642 22.5 18.75C22.5 18.3358 22.1642 18 21.75 18C21.3358 18 21 18.3358 21 18.75C21 19.1642 21.3358 19.5 21.75 19.5Z"
              fill="#667185"
            />
            <path
              d="M19.5 19.5H15V18H19.5V19.5ZM18 15H22.5V16.5H18V15Z"
              fill="#667185"
            />
            <path
              d="M15.75 16.5C16.1642 16.5 16.5 16.1642 16.5 15.75C16.5 15.3358 16.1642 15 15.75 15C15.3358 15 15 15.3358 15 15.75C15 16.1642 15.3358 16.5 15.75 16.5Z"
              fill="#667185"
            />
            <path
              d="M16.1198 10.5974L9.74475 6.98615C9.63199 6.92226 9.5046 6.88867 9.375 6.88867C9.2454 6.88867 9.11801 6.92226 9.00525 6.98615L2.63025 10.5974C2.51482 10.6628 2.41881 10.7577 2.352 10.8723C2.2852 10.9869 2.25 11.1172 2.25 11.2499V18.7499C2.25 18.8826 2.2852 19.0129 2.352 19.1275C2.41881 19.2421 2.51482 19.337 2.63025 19.4024L9.00525 23.0136L9.74475 21.7087L3.75 18.3126V11.6872L9.375 8.50115L15 11.6872V13.4999H16.5V11.2499C16.5 11.1172 16.4648 10.9869 16.398 10.8723C16.3312 10.7577 16.2352 10.6628 16.1198 10.5974Z"
              fill="#667185"
            />
            <path
              d="M18.7501 11.2499H20.2501V5.2499C20.2501 5.11723 20.2149 4.98693 20.1481 4.8723C20.0813 4.75767 19.9853 4.66281 19.8699 4.5974L13.4949 0.98615C13.3821 0.922256 13.2547 0.888672 13.1251 0.888672C12.9955 0.888672 12.8681 0.922256 12.7554 0.98615L6.38037 4.5974L7.11987 5.9024L13.1251 2.5004L18.7501 5.68715V11.2499Z"
              fill="#667185"
            />
          </svg>

          <div>Verification</div>
        </div>
        {/* <div
          className={
            pathname.includes("carbon-credit-issuance")
              ? "menu_item menu_active"
              : "menu_item"
          }
          onClick={() =>{
            navigate("/third-party/carbon-credit-issuance")
            window.scrollTo(0, 0);
            }
          }
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.1318 13.2668L17.6318 10.6043C17.5162 10.5358 17.3843 10.4997 17.2499 10.4999C17.1155 10.5 16.9837 10.5363 16.8682 10.605L12.3682 13.2675C12.2561 13.3337 12.1631 13.428 12.0985 13.5411C12.0339 13.6543 12 13.7823 12 13.9125V19.0875C12 19.353 12.1402 19.5983 12.3682 19.7325L16.8682 22.395C16.9836 22.4643 17.1155 22.5008 17.25 22.5008C17.3845 22.5008 17.5164 22.4643 17.6318 22.395L22.1318 19.7325C22.2439 19.6664 22.3369 19.5721 22.4015 19.4589C22.4661 19.3458 22.5 19.2178 22.5 19.0875V13.9125C22.5 13.7822 22.4661 13.6541 22.4015 13.5408C22.3369 13.4276 22.244 13.3331 22.1318 13.2668ZM21 18.66L17.25 20.8785L13.5 18.66V14.34L17.25 12.1215L21 14.34V18.66Z"
              fill="#667185"
            />
            <path
              d="M4.5 5.25C4.91421 5.25 5.25 4.91421 5.25 4.5C5.25 4.08579 4.91421 3.75 4.5 3.75C4.08579 3.75 3.75 4.08579 3.75 4.5C3.75 4.91421 4.08579 5.25 4.5 5.25Z"
              fill="#667185"
            />
            <path
              d="M6.75 5.25C7.16421 5.25 7.5 4.91421 7.5 4.5C7.5 4.08579 7.16421 3.75 6.75 3.75C6.33579 3.75 6 4.08579 6 4.5C6 4.91421 6.33579 5.25 6.75 5.25Z"
              fill="#667185"
            />
            <path
              d="M21 1.5H3C2.17275 1.5 1.5 2.17275 1.5 3V18C1.5 18.8272 2.17275 19.5 3 19.5H9.75V18H3V7.5H21V10.5H22.5V3C22.5 2.17275 21.8272 1.5 21 1.5ZM3 6V3H21V6H3Z"
              fill="#667185"
            />
          </svg>

          <div>CCRT</div>
        </div> */}

        <div
          className={
            pathname.includes("iot") ? "menu_item active_iot" : "menu_item"
          }
          onClick={() =>{ navigate("/third-party/iot")
          window.scrollTo(0, 0);
        }}
        >
          <svg
            className="stroke"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V4M17 14V15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17H14M4 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V14M1 4V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H4M9 15V17M1 9H3M9 1V3M17 9H15M6 9C6 9.79565 6.31607 10.5587 6.87868 11.1213C7.44129 11.6839 8.20435 12 9 12C9.79565 12 10.5587 11.6839 11.1213 11.1213C11.6839 10.5587 12 9.79565 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79565 6 9 6C8.20435 6 7.44129 6.31607 6.87868 6.87868C6.31607 7.44129 6 8.20435 6 9Z"
              stroke="#667185"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div>IoT Devices</div>
        </div>
      </div>

      <div className="logout pointer" onClick={handleLogout}>
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 4L13.59 5.41L16.17 8H6V10H16.17L13.59 12.58L15 14L20 9L15 4ZM2 2H10V0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H10V16H2V2Z"
            fill="#667185"
          />
        </svg>

        <div>Log out</div>
      </div>
      
      <Kyc />
    </div>
  );
};

export default SideBar;
