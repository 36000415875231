import '../../styles/shared/Feedback.scss'
export default function Feedback() {
    return (
        <div className="aboutFeedback">
    <div className="title">We’d love to hear your thoughts or feedback!</div>
    <div className="subtitle">Feel free to reach out by writing to us.</div>
    <form>
    <div className="input_group">
          
          <label className="first_label" htmlFor="emailAddress">
            Email Address<span>*</span>
          </label>
          <div className="input_group_inner">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.1 3C16.0344 3.32311 16 3.65753 16 4C16 4.34247 16.0344 4.67689 16.1 5H4.51146L12.0619 11.662L17.1098 7.14141C17.5363 7.66888 18.0679 8.10787 18.6728 8.42652L12.0718 14.338L4 7.21594V19H20V8.89998C20.3231 8.96557 20.6575 9 21 9C21.3425 9 21.6769 8.96557 22 8.89998V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H16.1ZM21 1C22.6569 1 24 2.34315 24 4C24 5.65685 22.6569 7 21 7C19.3431 7 18 5.65685 18 4C18 2.34315 19.3431 1 21 1Z" fill="#B1B1B1"/>
</svg>

            <div className="divider"></div>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="Enter email address"
            />
          </div>
          <label htmlFor="firstName">
            Message<span>*</span>
          </label>
          <div className="input_group_inner inner_max">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.76282 17H20V5H4V18.3851L5.76282 17ZM6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455Z" fill="#B1B1B1"/>
</svg>

            <div className="divider"></div>
            <textarea
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Text"
            />
          </div>
         
        </div>
        <button type="submit">Submit Message</button>

    </form>
      </div>
    )
}