import "../../styles/UserDashboard/ForgotPassword.scss";
import {
  Box,
  Grid,
  Typography,
  Container,
  Paper,
  Button,
  Hidden,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Checkbox,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  forgotPassword,
  resetPassword,
  verifyEmail,
} from "../../apis/UserDashboard/authentication";
import { useDispatch } from "react-redux";

const ForgotPassword = () => {
  const pathname = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [stage, setStage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState(pathname?.email || "");
  const [maskedEmail, setMaskedEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [password, setPassword] = useState("");
  const [reset, setReset] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };

  const handleCodeSent = () => {
    setModalOpen(false);
    setStage(0);
  };

  const handleModalOpen = () => {
    setStage(4);
    setModalOpen(true);
  };

  useEffect(() => {
    const otpInputs = document.querySelectorAll(".otp_input");

    otpInputs.forEach((input, index) => {
      input.addEventListener("input", (event) => {
        const inputValue = event.target.value;

        if (inputValue && index < otpInputs.length - 1) {
          otpInputs[index + 1].focus();
        }
      });

      input.addEventListener("keydown", (event) => {
        if (event.key === "Backspace" && index > 0 && !input.value) {
          otpInputs[index - 1].focus();
          event.preventDefault();
        }
      });
    });
  }, [reset]);

  const handleResendEmail = (e) => {
    e.preventDefault();
    if (email === "") {
      alert("Please input your email");
    } else {
      setMaskedEmail(maskEmail(email));
      setStage(2);
    }
  };

  const handleEmailSend = async () => {
    setLoading(true);
    const response = await forgotPassword(email);
    if (response?.data?.statusCode === 200) {
      setStage(3);
    } else {
      alert(response?.data?.message);
    }
    setLoading(false);
  };

  const handleOTPSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    // const response = await verifyEmail(otp, email);
    // console.log('response', response);
    // if (response?.data?.statusCode === 200) {
      setReset(true);
    // } else if (response?.data?.statusCode === 401) {
      // setOtpError(true);
    // }
    setLoading(false);
  };

  const handlePasswordSet = async (e) => {
    e.preventDefault();
    let regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;


    if (!regex.test(password)) {
      alert(
        "Your password must be at least 8 characters long and contain at least one upper and lower case letter, a number and a symbol"
      );
    } else {
      const otp = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`;
      setLoading(true)
      const response = await resetPassword(email, password, otp);
      console.log('response', response);

      if (response?.data?.statusCode === 200) {
        localStorage.setItem("token", response.data.message);
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: {
            token: response.data.message,
          },
        });
        navigate("/dashboard");
        window.scrollTo(0, 0);
      } else {
        alert(response?.data?.message)
      }
      setLoading(false)
    }
  };

  function maskEmail(email) {
    const atIndex = email.indexOf("@");
    if (atIndex > 1) {
      const firstPart = email.substring(0, 1);
      const lastPart = email.substring(atIndex);
      const maskedFirstPart = firstPart + "*".repeat(atIndex - 1);
      return maskedFirstPart + lastPart;
    }
    return email;
  }

  return (
    <div className="fgs">
      <div className="fgs_inner">
        <div className="title">Redefining Carbon Emission Solutions...</div>

        {!reset && (
          <form className="box" onSubmit={handleOTPSubmit}>
            <svg
              className="fgs_back pointer"
              onClick={() =>{ navigate("/login")
              window.scrollTo(0, 0);
            }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 17L9 12L14 7"
                stroke="#667185"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="box_title">Enter confirmation code</div>
            <div className="box_subtitle">
              Please check your email and enter the <br />
              confirmation code that was sent to you
            </div>
            <div className="otp_box_group">
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp1}
                  onChange={(e) => {
                    setOtp1(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>

              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp2}
                  onChange={(e) => {
                    setOtp2(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp3}
                  onChange={(e) => {
                    setOtp3(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp4}
                  onChange={(e) => {
                    setOtp4(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp5}
                  onChange={(e) => {
                    setOtp5(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
              <div className="otp_box">
                <input
                  className="otp_input"
                  type="text"
                  maxlength="1"
                  required
                  value={otp6}
                  onChange={(e) => {
                    setOtp6(e.target.value);
                    setOtpError(false);
                  }}
                />
              </div>
            </div>
            {otpError && (
              <div className="otp_error_text">Invalid or Expired OTP</div>
            )}

            <button className="fgs_button" type="submit" disabled={loading}>
              <div>{loading ? "Loading..." : "Continue"}</div>
            </button>

            <div className="resend pointer" onClick={handleModalOpen}>
              Didn’t get the code? <span>Resend code</span>
            </div>

            {modalOpen && (
              <>
                {/* Email Sent Modal */}
                {stage === 3 && (
                  <Box className="email_modal">
                    <svg
                      className="confirm_svg"
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M42 20.16V22C41.9975 26.3128 40.601 30.5093 38.0187 33.9636C35.4363 37.4179 31.8066 39.9449 27.6707 41.1678C23.5349 42.3906 19.1145 42.2438 15.0689 40.7491C11.0234 39.2545 7.56931 36.4922 5.22192 32.8741C2.87453 29.256 1.75958 24.9761 2.04335 20.6726C2.32712 16.3691 3.99441 12.2726 6.79656 8.99412C9.59871 5.71561 13.3856 3.43074 17.5924 2.48026C21.7992 1.52979 26.2005 1.96465 30.14 3.71997"
                        stroke="#099137"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M42 6L22 26.02L16 20.02"
                        stroke="#099137"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="modal_title">Sent</div>
                    <div className="modal_subtitle">
                      A code has been sent to your email, please confirm.
                    </div>
                    <button className="modal_button" onClick={handleCodeSent}>
                      Yeah, Got it!
                    </button>
                    <div
                      className="error didnt_get pointer"
                      onClick={() => setStage(4)}
                    >
                      Nah, I didn’t get the code
                    </div>
                  </Box>
                )}
                {/* Automated Email Sent Modal */}
                {stage === 2 && (
                  <Box className="email_modal">
                    <svg
                      className="confrirm_svg"
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_179_49)">
                        <path
                          d="M34.666 16.4533C33.2368 15.5538 32.0592 14.3065 31.2431 12.8282C30.4269 11.3498 29.999 9.68866 29.9993 8H5.22595C4.98668 8.00369 4.74899 8.03956 4.51929 8.10667L23.786 27.2933L34.666 16.4533Z"
                          fill="#00AED9"
                        />
                        <path
                          d="M40.0002 18C39.0979 17.9922 38.2008 17.8621 37.3335 17.6133L25.7202 29.1867C25.2205 29.6833 24.5446 29.9621 23.8402 29.9621C23.1357 29.9621 22.4598 29.6833 21.9602 29.1867L2.66682 10C2.60767 10.2174 2.57631 10.4414 2.57349 10.6667V37.3333C2.57349 38.0406 2.85444 38.7189 3.35453 39.219C3.85463 39.719 4.53291 40 5.24015 40H42.5735C43.2807 40 43.959 39.719 44.4591 39.219C44.9592 38.7189 45.2402 38.0406 45.2402 37.3333V16.52C43.6654 17.4918 41.8506 18.0044 40.0002 18ZM7.06682 37.3333H5.21349V35.4267L14.9068 25.8133L16.7868 27.6933L7.06682 37.3333ZM42.5468 37.3333H40.6802L30.9602 27.6933L32.8402 25.8133L42.5335 35.4267L42.5468 37.3333Z"
                          fill="#00AED9"
                        />
                        <path
                          d="M39.9994 14.6666C43.6813 14.6666 46.6661 11.6818 46.6661 7.99992C46.6661 4.31802 43.6813 1.33325 39.9994 1.33325C36.3175 1.33325 33.3328 4.31802 33.3328 7.99992C33.3328 11.6818 36.3175 14.6666 39.9994 14.6666Z"
                          fill="#00AED9"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_179_49">
                          <rect width="48" height="48" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <div className="modal_title">Recover via email</div>
                    <div className="modal_subtitle">
                      We will send a password recovery code to
                    </div>
                    <div className="modal_email">{maskedEmail}</div>
                    <button
                      className="modal_button"
                      onClick={handleEmailSend}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Send"}
                    </button>
                  </Box>
                )}
                {/* Not getting code Modal */}
                {stage === 4 && (
                  <form className="email_modal">
                    <div className="modal_subtitle bold">
                      Why are you not getting the code:
                    </div>

                    <ul>
                      <li>Check your spam folder.</li>
                      <li>Incorrect email address</li>
                    </ul>
                    <InputLabel className="modal_label" htmlFor="email">
                      Re-enter email address
                    </InputLabel>

                    <OutlinedInput
                      // className={
                      //   errorPresent ? "login-inputs login-error" : "login-inputs"
                      // }
                      className="modal_input"
                      required
                      id="email"
                      type="text"
                      value={email}
                      placeholder="Enter email address"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <button
                      className="modal_button"
                      onClick={handleResendEmail}
                    >
                      Resend Code
                    </button>
                  </form>
                )}
              </>
            )}
          </form>
        )}

        {reset && (
          <form className="box" onSubmit={handlePasswordSet}>
            <svg
              className="fgs_back pointer"
              onClick={() => setReset(false)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 17L9 12L14 7"
                stroke="#667185"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div className="box_title">Enter New Password</div>

            <InputLabel className="fgs_label" htmlFor="password">
              Password
            </InputLabel>
            <OutlinedInput
              className="fgs_input"
              required
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <button className="fgs_button" type="submit" disabled={loading}>
              <div>{loading ? "Loading..." : "Submit"}</div>
            </button>
          </form>
        )}
      </div>
      {modalOpen && <div className="modal" onClick={handleModalClose}></div>}
    </div>
  );
};

export default ForgotPassword;
