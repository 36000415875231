import axios from "axios";

export const registerEmail = async (email) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/register-email`,
        { email }
      );
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  export const verifyEmail = async (otp, email) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-email`,
        { otp, email }
      );
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  export const signUp = async (
    firstName,
    lastName,
    organization,
    email,
    idNumber,
    country,
    address,
    phoneNumber,
    password,
    role,
    countryCode
  ) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/sign-up`, {
        firstName,
    lastName,
    organization,
    email,
    idNumber,
    country,
    address,
    phoneNumber,
    password,
    role,
    countryCode
      });
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  export const login = async (
    email,
    password
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/open/v1/log-in`,

         {
        email,
        password
      });
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  
  export const forgotPassword = async (
    email
    ) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/forget-password`, {
        email
      });
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  
  export const resetPassword = async (
    email,
    password
    ) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/forget-password-reset`, {
        email,
        password
      });
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  export const resendOtp = async (email) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/resend-otp`,
        { email }
      );
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };