import { ethers } from "ethers";
import axios from "axios";
// import dotenv from "dotenv";
import pinataSDK from "@pinata/sdk";
import fs from "fs";
import { getContracts } from "../utils/getContracts";
import pinJSONToIPFS from "@pinata/sdk/types/commands/pinning/pinJSONToIPFS";

// dotenv.config();

const pinata = new pinataSDK({
  pinataJWTKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI2NDgyZjkxZS0zNjNlLTQ5OWYtYjQ1ZC0wNzNmYzA4NmQxYTUiLCJlbWFpbCI6InRlaGlsYWZhdm91cml0ZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiNTczMGRiYjgwNjhiZjFjYjdkYTUiLCJzY29wZWRLZXlTZWNyZXQiOiI1MTkyMmJmNDM1ZGUwYWM5YWMwN2UzYmE4NzQ1OGZiYzU5N2FjOTI3MzJhOTJjMGFlNjQyYmQ1OWNhZWRkMzk4IiwiaWF0IjoxNzAwMjA4NzQzfQ.t850Nq1NQGZTnpw-f-NEhVUrk892-UBFUWOp6hkiqnk",
});

// const pinata = new pinataSDK({ pinataJWTKey: REACT_APP_pinataJWT });
const abiCoder = new ethers.AbiCoder();

/// @notice Gets the length of the emission data array for a plant
/// @param _plant The address of the plant
/// @return The length of the emission data array
async function getAllUserEmissionLength(address: string) {
  const { basicInfrastructureContractInstance } = await getContracts();

  const allUserEmissionLength =
    await basicInfrastructureContractInstance.getAllUserEmissionLength(address);
  return {
    allUserEmissionLength: ethers.formatEther(
      allUserEmissionLength.toString()
    ),
  };
}

async function dynamicAttributes(address: string, index: number) {
  const { basicInfrastructureContractInstance } = await getContracts();

  const dynamicAttributes =
    await basicInfrastructureContractInstance.dynamicAttributes(address, index);
  const conditions = await basicInfrastructureContractInstance.conditions(
    address,
    index
  );
  return {
    reportingPeriod: dynamicAttributes.reportingPeriod,
    directStationaryEmissionsValue: ethers.formatEther(
      dynamicAttributes.directStationaryEmissionsValue.toString()
    ),
    totalGHGEmissions: ethers.formatEther(
      dynamicAttributes.totalGHGEmissions.toString()
    ),
    quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
      dynamicAttributes.quantifiedGHGEmissionsInBaselineScenario.toString()
    ),
  };
}

async function userEmission(address: string) {
    const { basicInfrastructureContractInstance } = await getContracts();
  
    // Get the length of emission data for the user
    const emissionDataLength: number = await basicInfrastructureContractInstance.getAllPlantEmissionLength(
      address
    );
  
    // Retrieve all emission data for the user
    const allEmissionData: any[] = [];
  
    for (let i = 0; i < emissionDataLength; i++) {
      const allEmission = await basicInfrastructureContractInstance.allEmission(
        address,
        i
      );
      
      
  
      allEmissionData.push({
        directStationaryEmissionsValue: ethers.formatEther(
          allEmission.directStationaryEmissionsValue.toString()
        ),
        quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
          allEmission.quantifiedGHGEmissionsInBaselineScenario.toString()
        ),
        reportingPeriod: allEmission.reportingPeriod,
        totalGHGEmission: ethers.formatEther(
          allEmission.totalGHGEmissions.toString()
        ),
        isVerified: allEmission.isVerified,
      });
    }
  
    return allEmissionData;
  }

async function allEmission(address: string, index: number) {
  const { basicInfrastructureContractInstance } = await getContracts();
  const allEmission = await basicInfrastructureContractInstance.allEmission(
    address,
    index
  );
  return {
    reportingPeriod: allEmission.reportingPeriod,
    directStationaryEmissionsValue: ethers.formatEther(
      allEmission.directStationaryEmissionsValue.toString()
    ),
    totalGHGEmissions: ethers.formatEther(
      allEmission.totalGHGEmissions.toString()
    ),
    quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
      allEmission.quantifiedGHGEmissionsInBaselineScenario.toString()
    ),
    isVerified: allEmission.isVerified,
  };
}

// async function isPlantRecorded(address: string) {
//   const { basicInfrastructureContractInstance } = await getContracts();

//   const isRecorded = await basicInfrastructureContractInstance.isPlantRecorded(
//     address
//   );
//   return {
//     isRecorded,
//   };
// }

async function plantInfo(address: string) {
  const { basicInfrastructureContractInstance } = await getContracts();

  const plantInfo = await basicInfrastructureContractInstance.plantInfo(
    address
  );
  return {
    address: plantInfo.plantAddress,
    name: plantInfo.name,
    scenario: plantInfo.scenario,
  };
}

async function getAllPlantsLength() {
  const { basicInfrastructureContractInstance } = await getContracts();

  const allPlantsLength =
    await basicInfrastructureContractInstance.getAllPlantsLength();
  return {
    allPlantsLength: ethers.formatEther(allPlantsLength.toString()),
  };
}

async function getPlantEmissionData(address: string) {
  const { basicInfrastructureContractInstance } = await getContracts();

  const plantEmissionData =
    await basicInfrastructureContractInstance.getPlantEmissionData(address);
  return {
    plantEmissionData,
  };
}

async function getAllPlantEmissionLength(address: string) {
  const { basicInfrastructureContractInstance } = await getContracts();
  const allPlantEmissionLength =
    await basicInfrastructureContractInstance.getAllPlantEmissionLength(
      address
    );
  return {
    allPlantEmissionLength: ethers.formatEther(
      allPlantEmissionLength.toString()
    ),
  };
}

async function balanceOf(address: string) {
  const { closeCarbonTokenContractInstance } = await getContracts();
  const balance = await closeCarbonTokenContractInstance.balanceOf(address);
  return balance;
}

async function allEmissionValues(address: string) {
  const { basicInfrastructureContractInstance } = await getContracts();
  const allEmissionValues =
    await basicInfrastructureContractInstance.allEmissionValues(address);
  return {
    reportingPeriod: allEmissionValues.reportingPeriod,
    directStationaryEmissionsValue: ethers.formatEther(
      allEmissionValues.directStationaryEmissionsValue.toString()
    ),
    totalGHGEmissions: ethers.formatEther(
      allEmissionValues.totalGHGEmissions.toString()
    ),
    quantifiedGHGEmissionsInBaselineScenario: ethers.formatEther(
      allEmissionValues.quantifiedGHGEmissionsInBaselineScenario.toString()
    ),
    isVerified: allEmissionValues.isVerified,
  };
}

async function getStaticAttributes(address: string) {
  const { basicInfrastructureContractInstance } = await getContracts();

  const staticAttributes =
    await basicInfrastructureContractInstance.getStaticAttributes(address);
  return {
    staticAttributes,
  };
}

async function getAllPlantsData() {
  const { basicInfrastructureContractInstance } = await getContracts();

  const getAllInfo =
    await basicInfrastructureContractInstance.getAllPlantsData();
  return {
    getAllInfo,
  };
}

async function getPlantsData(start: number, count: number) {
  const { basicInfrastructureContractInstance } = await getContracts();

  const getAllInfo =
    await basicInfrastructureContractInstance.getPlantsData(start, count);
    return {
      getAllInfo,
    };
}

export {
  getPlantEmissionData,
  getAllUserEmissionLength,
  getAllPlantsLength,
  balanceOf,
  allEmissionValues,
  getStaticAttributes,
  // getAllPlantsData,
  allEmission,
  getAllPlantEmissionLength,
  plantInfo,
  dynamicAttributes,
  userEmission,
  getPlantsData
};
