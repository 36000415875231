import Footer from "../../../components/shared/Footer";
import Header from "../../../components/shared/Header";
import Image from "../../../images/inf.png";
import "../../../styles/shared/ProjectAuthenticated.scss";

export default function Infrastructure() {
  return (
    <div>
      <div className="project_auth">
        <Header />
        <img className="project_image" src={Image} />
        <div className="project_title">Non-green / Infrastructure change</div>
        <div className="project_subtitle">
          Our Non-green projects refer to initiatives that aim to reduce carbon
          emissions by investing in and implementing sustainable infrastructure
          solutions. These projects focus on upgrading or replacing existing
          infrastructure/equipment/fuel with more energy-efficient, low-carbon
          alternatives. The project can then earn carbon incentives based on the
          amount of emissions it has reduced.
          <br />
          <br />
          This is done by:
          <ol>
            <li>
              Implementing energy-saving measures in industries, buildings, and
              transportation systems to reduce fuel consumption and associated
              emissions. 
            </li>
            <li>
              Improving industrial process design/activities to reduce
              emissions.
            </li>
            <li>
              Transitioning from high-carbon fossil fuels to low-carbon or
              carbon-neutral alternatives. 
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
}
