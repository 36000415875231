import { useEffect, useRef, useState } from "react";
import "../../styles/UserDashboard/OnboardingTemplate.scss";
import {
  submitTemplate
} from "../../apis/General/users";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import projectDscDocument from "../../documents/CLOSECARBON PROJECT DESCRIPTION TEMPLATE.docx";
import baseYearInventoryDocument from "../../documents/Organization Base-year GHG Inventory Template.xlsx";

export default function BasicOnboardingTemplates() {
  const auth = useSelector((state) => state.auth);
  const pathname = useLocation();
  const navigate = useNavigate();
  const [stage, setStage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [facilityName, setFacilityName] = useState("");
  const [industry, setIndustry] = useState("");
  const [fuelSource, setFuelSource] = useState("nil");
  const [facilityCategory, setFacilityCategory] = useState("");
  const [greenProjectName, setGreenProjectName] = useState("");
  const [greenProjectLocation, setGreenProjectLocation] = useState("");
  const [greenProjectObjective, setGreenProjectObjective] = useState("");
  const [greenProjectStartDate, setGreenProjectStartDate] = useState("");
  const [greenProjectEndDate, setGreenProjectEndDate] = useState("");
  const [greenProjectLicenseId, setGreenProjectLicenseId] = useState("");
  const [greenCertificateBody, setGreenCertificateBody] = useState("");
  const [renewableEnergySolutions, setRenewableEnergySolutions] = useState("");
  const [facilityCapacity, setFacilityCapacity] = useState("");
  const [sustainabilitySolution, setSustainabilitySolution] = useState("");
  const [facilitySystemCapacity, setFacilitySystemCapacity] = useState("nil");
  const [walletAddress, setWalletAddress] = useState("");
  const [filled, setFilled] = useState(false);
  const [projectDescriptionReport, setProjectDescriptionReport] = useState("");
  const [quantifiedBaseYearInventory, setQuantifiedBaseYearInventory] =
    useState("");
  const [documentType, setDocumentType] = useState("");
  const [uplaodLoading, setUploadLoading] = useState(false);
  const mediaRef = useRef(null);
  const [images, setImages] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorText, setErrorText] = useState("hello");
  const [errorTitle, setErrorTitle] = useState("hello");
  const [errorButtonAction, setErrorButtonAction] = useState("login");
  const [userId, setUserId] = useState("");
  const [facilityCapacityValue, setFacilityCapacityValue] = useState("");

  useEffect(() => {
    if (
      facilityCategory === "non-green" &&
      companyName?.length > 0 &&
      industry?.length > 0 &&
      fuelSource?.length > 0 &&
      facilityCategory?.length > 0
    ) {
      setFilled(true);
    } else if (
      facilityCategory === "green" &&
      companyName?.length > 0 &&
      industry?.length > 0 &&
      fuelSource?.length > 0 &&
      facilityCategory?.length > 0 &&
      greenProjectName?.length > 0 &&
      greenProjectLocation?.length > 0 &&
      greenProjectObjective?.length > 0 &&
      greenProjectStartDate?.length > 0 &&
      greenProjectEndDate?.length > 0 &&
      greenProjectLicenseId?.length > 0 &&
      greenCertificateBody?.length > 0
    ) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [
    companyName,
    industry,
    fuelSource,
    facilityCategory,
    greenProjectName,
    greenProjectLocation,
    greenProjectObjective,
    greenProjectStartDate,
    greenProjectEndDate,
    greenProjectLicenseId,
    greenCertificateBody,
  ]);

  const handleShowError = (title, body, action = "ok") => {
    setErrorTitle(title);
    setErrorText(body);
    setErrorButtonAction(action);
    handleErrorModalOpen();
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    if (!/^(0x)[0-9a-f]{40}$/i.test(walletAddress)) {
      alert("Invalid wallet address");
      setLoading(false);
      return false;
    } else {
      let convertedCapacity = facilityCapacityValue;
      if(facilitySystemCapacity === "Mega watts (Mw)") {
        convertedCapacity = facilityCapacityValue * 1000
      }else if(facilitySystemCapacity === "Giga watts (Gw)") {
        convertedCapacity = facilityCapacityValue * 1000000
      }
      const response = await submitTemplate(
        facilityCategory,
        pathname?.pathname?.split('/')[2],
    firstName,
    lastName,
    email,
    companyName,
    industry,
    convertedCapacity,
    walletAddress
      );

      if (response?.data?.statusCode === 200) {
        // toast.success(response?.data?.message);
        navigate(`/signup/${response.data.message}`);
        localStorage.setItem("demosent", "true");
      } else if (
        response?.data?.message ===
        "This email has been registered. Kindly login"
      ) {
        handleShowError(
          "Oops email already registered",
          "It appears this email has already been registered. If you’ve forgotten your password, click “Forgot Password” on the login screen.",
          "login"
        );
      } else if (
        response?.data?.message === "KYC submitted. Kindly verify your email."
      ) {
        handleShowError(
          "Oops email already existed",
          "This email already exists. Kindly click the button below to input the otp sent to your email to activate or request for a new OTP",
          "otp"
        );
        setUserId(response?.data?.userId);
      } else if (
        response?.data?.message ===
        "This email has been registered. Kindly complete the sign up process"
      ) {
        handleShowError(
          "Oops email already registered",
          "It appears this email has already been registered.  Kindly click the button below to complete the sign up process",
          "register"
        );
        setUserId(response?.data?.userId);
      } else {
        toast.error(response?.data?.message);
      }

      console.log("response", response);
    }

    setLoading(false);
  };


 

  const clickFileUpload = (e, documentTypeSent) => {
    setUploadLoading(true);

    let files = e.target.files;
    let allUploadedFiles = images;

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            console.log("result", result);
            if (documentTypeSent === "projectDescriptionReport") {
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                  {
                    media_type: "docx",
                    folder: "word_documents",
                    user_id: auth?.userInfo?.id,
                    contents: [result],
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${auth ? auth.token : ""}`,
                    },
                  }
                )
                .then((response) => {
                  console.log("response file uploaded", response);
                  if (response?.data?.body?.success === true) {
                    if (documentTypeSent === "projectDescriptionReport") {
                      setProjectDescriptionReport(
                        response?.data?.body?.data[0]
                      );
                    } else if (
                      documentTypeSent === "quantifiedBaseYearInventory"
                    ) {
                      setQuantifiedBaseYearInventory(
                        response?.data?.body?.data[0]
                      );
                    } else {
                      toast.error("document type not specified");
                    }
                  }
                  setUploadLoading(false);
                })
                .catch((err) => {
                  setUploadLoading(false);
                });
            } else if (documentTypeSent === "quantifiedBaseYearInventory") {
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                  {
                    media_type: "xlsx",
                    folder: "spreadsheets",
                    user_id: auth?.userInfo?.id,
                    contents: [result],
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${auth ? auth.token : ""}`,
                    },
                  }
                )
                .then((response) => {
                  console.log("response file uploaded", response);
                  if (response?.data?.body?.success === true) {
                    if (documentTypeSent === "projectDescriptionReport") {
                      setProjectDescriptionReport(
                        response?.data?.body?.data[0]
                      );
                    } else if (
                      documentTypeSent === "quantifiedBaseYearInventory"
                    ) {
                      setQuantifiedBaseYearInventory(
                        response?.data?.body?.data[0]
                      );
                    } else {
                      toast.error("document type not specified");
                    }
                  }
                  setUploadLoading(false);
                })
                .catch((err) => {
                  setUploadLoading(false);
                });
            }
          }
        });
      }
    }
  };

  const handleErrorModalClose = (e) => {
    if (e.target.classList.contains("errorModal")) {
      setErrorModalOpen(false);
    }
  };

  const handleErrorModalOpen = () => {
    setErrorModalOpen(true);
  };

  const handleProjectDescriptionDownload = () => {
    const link = document.createElement("a");
    link.href = projectDscDocument;
    link.download = "CLOSECARBON PROJECT DESCRIPTION TEMPLATE.docx"; // Set the desired file name for the download
    link.click();
  };
  const handleDownloadbaseYearInventoryDocument = () => {
    const link = document.createElement("a");
    link.href = baseYearInventoryDocument;
    link.download = "Organisation Base-year GHG Inventory Template.xlsx"; // Set the desired file name for the download
    link.click();
  };


  return (
    <div className="onboarding-template">
      <div className="onboarding-template__title">
        You’re a step closer to CloseCarbon!
      </div>
      <div className="onboarding-template__subtitle">
        Fill this simple form to enable us learn about your facility.
      </div>
      <div className="steps">
        <div className="circle circle_active">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.75 8.75L6.25 12.25L13.25 4.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div className="line"></div>
        <div className={filled ? "circle circle_active" : "circle"}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.75 8.75L6.25 12.25L13.25 4.75"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      {stage === 1 && (
        <form onSubmit={handleSubmit}>
          <label className="onboarding-template__input-label" htmlFor="name">
            First name
          </label>

          <input
            className="onboarding-template__input"
            required
            id="name"
            type="text"
            placeholder="Enter your first name"
            name="name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <label className="onboarding-template__input-label" htmlFor="name">
            Last name
          </label>

          <input
            className="onboarding-template__input"
            required
            id="name"
            type="text"
            placeholder="Enter your last name"
            name="name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <label className="onboarding-template__input-label" htmlFor="email">
            Email
          </label>

          <input
            className="onboarding-template__input"
            required
            id="email"
            type="email"
            placeholder="Enter email address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
       
          <label
            className="onboarding-template__input-label"
            htmlFor="lastName"
          >
            Company name
          </label>

          <input
            className="onboarding-template__input"
            required
            id="companyName"
            type="text"
            placeholder="Your facility name"
            name="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
         
         
         
          <label
            className="onboarding-template__input-label __input-label-no-imp"
            htmlFor="industry"
          >
            Industry
          </label>

          <input
            className="onboarding-template__input"
            id="industry"
            type="text"
            placeholder="e.g food industry, oil and gas"
            name="industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
          />
         
         {/* {sustainabilitySolution !== "monitoring" && ( */}
                <label
                  className="onboarding-template__input-label"
                  htmlFor="lastName"
                >
                  Facility system capacity
                </label>
              {/* )} */}

              {/* {sustainabilitySolution !== "monitoring" && ( */}
                <select
                  className="onboarding-template__input onboarding-template__input-select"
                  required
                  value={facilitySystemCapacity}
                  onChange={(e) => setFacilitySystemCapacity(e.target.value)}
                >
                  <option value="">Input facility system capacity</option>
                <option value="Giga watts (Gw)">Giga watts (Gw)</option>
                <option value="Mega watts (Mw)">Mega watts (Mw)</option>
                <option value="Kilo watts (Kw)">Kilo watts (Kw)</option>
                </select>
              {/* )} */}

<label
                className="onboarding-template__input-label"
                htmlFor="greenCertificateBody"
              >
                Facility capacity
              </label>

              <input
                className="onboarding-template__input"
                required
                id="greenCertificateBody"
                type="number"
                placeholder="Enter your facility's capacity"
                name="greenCertificateBody"
                value={facilityCapacityValue}
                onChange={(e) => setFacilityCapacityValue(e.target.value)}
              />

          <label
            className="onboarding-template__input-label"
            htmlFor="facilityCategory"
          >
            Facility category
          </label>

          <select
            className="onboarding-template__input onboarding-template__input-select"
            required
            value={facilityCategory}
            onChange={(e) => setFacilityCategory(e.target.value)}
          >
            <option value="">Select category</option>
            <option value="green">Green</option>
            <option value="non-green">Non-green</option>
          </select>

        
          <label className="onboarding-template__input-label" htmlFor="phone">
            Wallet address
          </label>

          <div className="onboarding-template__info">
            You can create a wallet and get an address on the <a href="https://metamask.io/" target="_blank" >MetaMask</a> website
          </div>

          <input
            className="onboarding-template__input"
            required
            id="walletAddress"
            type="text"
            placeholder="Enter your Ethereum Wallet Address (e.g., 0x1234...abcd)"
            name="walletAddress"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
          />
          <div className="onboarding-template__input-text">
            Note: Do not disclose your private key, please enter your Ethereum Wallet Address
          </div>
      

          <button
            className="onboarding-template__button"
            type="submit"
            disabled={loading}
          >
            <div>{loading ? "Loading..." : "Next"}</div>
          </button>
        </form>
      )}
    
    

      {errorModalOpen && (
        <div className="errorModal" onClick={handleErrorModalClose}></div>
      )}
      {errorModalOpen && (
        <div className="error_modal">
          <div className="modal_title">{errorTitle}</div>

          <div className="modal_subtitle">{errorText}</div>
          {errorButtonAction === "login" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate("/login")}
              disabled={loading}
            >
              {loading ? "Loading..." : "Login"}
            </button>
          ) : errorButtonAction === "register" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate(`/signup/${userId}/3`)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Okay"}
            </button>
          ) : errorButtonAction === "otp" ? (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => navigate(`/signup/${userId}`)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Okay"}
            </button>
          ) : (
            <button
              className="error_modal_button"
              type="submit"
              onClick={() => setErrorModalOpen(false)}
              disabled={loading}
            >
              {loading ? "Loading..." : "Okay"}
            </button>
          )}
        </div>
      )}
    </div>
  );
}
