import { useEffect, useState } from "react";
import SideBar from "../../components/AdminDashboard/SideBar";
import "../../styles/AdminDashboard/History.scss";
import Chart from "chart.js/auto";
import { useSelector } from "react-redux";
import { getEmissionHistory, getEmissionHistoryByFacility } from "../../apis/AdminDashboard/users";
import moment from "moment";
import {
  Box,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Pagination
} from "@mui/material";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPlantsData as greenBasicGetAllEmissionData,
} from "../../instantiateTransaction/basicGreenProject";
import {
  getPlantsData as infraBasicGetAllEmissionData,
} from "../../instantiateTransaction/basicInfrastructureChange";
import {
  getPlantsData as greenGovernanceGetAllEmissionData,
} from "../../instantiateTransaction/governanceGreenProject";

import {
  getPlantsData as governanceInfraGetAllEmissionData,
} from "../../instantiateTransaction/gorvernanceInfrastructureChange";

import {
  getPlantsData as governanceMonitoringGetAllEmissionData,
} from "../../instantiateTransaction/governanceCarbonMonitoring";import { ethers } from "ethers";
import Search from "../../components/AdminDashboard/Search";

const History = () => {
  const auth = useSelector((state) => state.auth);
  const [history, setHistory] = useState([]);
  const [nextEvaluatedKey, setNextEvaluatedKey] = useState(null);
  const [prevEvaluatedKey, setPrevEvaluatedKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prevBatch, setPrevBatch] = useState(null);
  const [disabled, setDisabled] = useState("previous");
  const [activeitem, setActiveItem] = useState("compliance");
  const [anchorEl, setAnchorEl] = useState(null);
  const [emissions, setEmissions] = useState([]);
  const [emissionLoading, setEmissionLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);
  const [dataEmission, setDataEmission] = useState([]);
  const [dateRange, setDataRange] = useState("week");
  const open = Boolean(anchorEl);

  const getEmissions = async () => {
    setEmissionLoading(true)
    const temp = [];

    const response6 = await greenBasicGetAllEmissionData(0, 20);
      console.log("response6", response6);
      temp.push(...response6?.getAllInfo);
      setEmissions([...temp]);

      const response7 = await infraBasicGetAllEmissionData(0, 20);
      console.log("response7", response7);
      temp.push(...response7?.getAllInfo);
      setEmissions([...temp]);

      const response8 = await greenGovernanceGetAllEmissionData(0, 20);
      console.log("response8", response8);
      temp.push(...response8?.getAllInfo);
      setEmissions([...temp]);

      const response9 = await governanceInfraGetAllEmissionData(0, 20);
      console.log("response9", response9);
      temp.push(...response9?.getAllInfo);
      setEmissions([...temp]);

      const response10 = await governanceMonitoringGetAllEmissionData(0, 20);
      temp.push(...response10?.getAllInfo);
      setEmissions([...temp]);
      setEmissionLoading(false)
  };
//saleem
  useEffect(() => {
    getEmissions();
  }, []);

  const listEmissionHistory = async () => {
    setLoading(true);
    const response = await getEmissionHistory(page, auth?.token);
    setPagination(response?.data?.message)
    console.log("cantona", response);
    setHistory(response?.data?.message?.emissionItems);
    setLoading(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  //saleem
  useEffect(() => {
    listEmissionHistory()
  }, [page])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changePage = async (action) => {
    const keySubmit =
      action === "forward" ? nextEvaluatedKey : prevEvaluatedKey;

    if (action === "forward") {
      setDisabled("");
      const response = await getEmissionHistory(auth?.token, keySubmit);
      setPrevBatch(history);
      setPrevEvaluatedKey(nextEvaluatedKey);

      if (
        response?.data?.data[response?.data?.data?.length - 1]?.LastEvaluatedKey
      ) {
        const key = response?.data?.data?.pop();
        setNextEvaluatedKey(key?.LastEvaluatedKey);
      } else {
        setDisabled("next");
      }

      setHistory(response?.data?.data);
    } else if (action === "backward") {
      setNextEvaluatedKey(prevEvaluatedKey);
      setHistory(prevBatch);
      setDisabled("");
    }
  };

  //saleem
  // useEffect(() => {
  //   listEmissionHistory();
  // }, []);

  //saleem

  useEffect(() => {
   

    const ctx = document.getElementById("emission");

    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: dataLabels,
        datasets: [
          {
            label: "Emission History",
            data: dataEmission,
            borderWidth: 1,
            backgroundColor: [
              "#00AED9",
              "#063", 
              "#02A4CC",
              "#7BC216",
              "#9400D9",
              "#D9004E",
              "#C8D900",
              "#D99C00",
              "#D94E00",
              "#00D9CC",
            ],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem?.label}`;
              },
              label: function (tooltipItem) {
                return `${tooltipItem?.formattedValue}kg`;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

   
    return () => {
      myChart.destroy();
    };
  }, [dataLabels, dataEmission]);

  useEffect(() => {
   
   
    const ctx2 = document.getElementById("myChart");

    const myChart2 = new Chart(ctx2, {
      type: "line",
      data: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        datasets: [
          {
            label: "",
            data: [12, 19, 3, 5, 2, 3, 25, 9],
            borderWidth: 1,
            backgroundColor: "#7BC216",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem?.label}`;
              },
              label: function (tooltipItem) {
                return `${tooltipItem?.formattedValue}tCO2`;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });
    

    return () => {
      myChart2.destroy();
    };
  }, []);
  useEffect(() => {
    

   
    const ctx3 = document.getElementById("analytics_graph");

    const myChart3 = new Chart(ctx3, {
      type: "line",
      data: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jun"],
        datasets: [
          {
            label: "",
            data: [7, 5, 17, 12, 18, 15, 20],
            borderWidth: 1,
            backgroundColor: "#0F973D",
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem?.label}`;
              },
              label: function (tooltipItem) {
                return `${tooltipItem?.formattedValue} users`;
              },
            },
          },
          legend: {
            labels: {
              color: "#FFF",
            },
          },
        },
        scales: {
          y: {
            ticks: {
              color: "#fff",
            },
            grid: {
              color: "#fff",
            },
            beginAtZero: true,
          },
          x: {
            ticks: {
              color: "#fff",
            },
            grid: {
              color: "#fff",
            },
          },
        },
      },
    });

    return () => {
      myChart3.destroy();
    };
  }, []);

  const handleGetEmissionHistoryByFacility = async () => {
    const response = await getEmissionHistoryByFacility(auth?.token, dateRange);
    console.log('shoko', response);
    setDataLabels(response?.data?.message?.map((item) => item?.companyName))
    setDataEmission(response?.data?.message?.map((item) => item?.emissionValue))
  }

  useEffect(() => {
    handleGetEmissionHistoryByFacility()
  }, [dateRange])
  return (
    <div className="admin_history">
      <div className="sidebar_container">
        <SideBar />
      </div>
      <div className="main">
        <Search />
        <div className="history_grid">
          <div className="emission_history">
            <Select
              className="chart_select"
              inputProps={{ "aria-label": "Without label" }}
              onChange={(e) => setDataRange(e.target.value)}
              value={dateRange}
            >
              <MenuItem value="week">Last Week</MenuItem>
              <MenuItem value="day">Today</MenuItem>
              <MenuItem value="month">Last Month</MenuItem>
              <MenuItem value="anual">Year</MenuItem>
            </Select>

            <canvas id="emission"></canvas>
          </div>
          <div className="emission_history">
            <div className="title">Users Engagement</div>
            <svg
              className="menu_click pointer"
              onClick={handleClick}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4.875C12.6213 4.875 13.125 4.37132 13.125 3.75C13.125 3.12868 12.6213 2.625 12 2.625C11.3787 2.625 10.875 3.12868 10.875 3.75C10.875 4.37132 11.3787 4.875 12 4.875Z"
                stroke="#98A2B3"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z"
                stroke="#98A2B3"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 21.375C12.6213 21.375 13.125 20.8713 13.125 20.25C13.125 19.6287 12.6213 19.125 12 19.125C11.3787 19.125 10.875 19.6287 10.875 20.25C10.875 20.8713 11.3787 21.375 12 21.375Z"
                stroke="#98A2B3"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className="menu_container">
                <MenuItem className="">
                  <div style={{ fontSize: "12px" }}>Today</div>
                </MenuItem>

                <MenuItem className="">
                  <div style={{ fontSize: "12px" }}>Last Week</div>
                </MenuItem>
                <MenuItem className="">
                  <div style={{ fontSize: "12px" }}>Last Month</div>
                </MenuItem>
                <MenuItem className="">
                  <div style={{ fontSize: "12px" }}>Year</div>
                </MenuItem>
              </div>
            </Menu>
            <div className="emission_grid">
              <div>
                <div className="subtitle">Users</div>
                <div className="title">10.2k</div>
                <div className="analytics">
                  {" "}
                  <svg
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.35355 0.646446C4.15829 0.451184 3.84171 0.451184 3.64645 0.646446L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646446ZM4.5 10L4.5 1H3.5L3.5 10H4.5Z"
                      fill="#0F973D"
                    />
                  </svg>
                  <span>10% </span> vs last month
                </div>
              </div>
              <div>
                <canvas id="analytics_graph"></canvas>
              </div>
            </div>
            <div className="view">
              <div>View report</div>
            </div>
          </div>
          {/* <div className="emission_history">
            <div className="history_table">
              <div className="history_table_group history_table_header">
                <div>Organisation</div>
                <div>CO2 Tracked</div>
                <div>Co2 Offset</div>
                <div>Validation</div>
                <div>Verification</div>
              </div>
              <div className="table_body_container">
              {emissionLoading && <>
                <div className="history_table_group history_table_body">
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   
                  </div>
                  <div className="history_table_group history_table_body">
                  <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   
                  </div>
                  <div className="history_table_group history_table_body">
                  <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   
                  </div>
                  <div className="history_table_group history_table_body">
                  <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   
                  </div>
                  <div className="history_table_group history_table_body">
                  <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   
                  </div>
                  <div className="history_table_group history_table_body">
                  <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   
                  </div>
                  <div className="history_table_group history_table_body">
                  <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   <Skeleton width="100%" height={12} borderRadius={8} />
                   
                  </div>
                 
              </>}
                {emissions?.map((emission) => (
                  <div className="history_table_group history_table_body">
                    <div>{emission?.plantInfo?.organizationName}</div>
                    <div>
                      {" "}
                      {emission?.emissionData?.length > 0 &&
                        Number(
                          ethers.formatEther(
                            emission?.emissionData[
                              emission?.emissionData?.length - 1
                            ]?.totalGHGEmissions ? emission?.emissionData[
                              emission?.emissionData?.length - 1
                            ]?.totalGHGEmissions :  emission?.emissionData[
                              emission?.emissionData?.length - 1
                            ]?.totalGHGEmission
                          )
                        ).toFixed(0)}
                      tCO2e
                    </div>
                    <div>
                      {" "}
                      {emission?.emissionData?.length > 0 && emission?.emissionData[
                            emission?.emissionData?.length - 1
                          ]?.quantifiedGHGEmissionsInBaselineScenario &&
                      Number (
                        ethers.formatEther(
                          emission?.emissionData[
                            emission?.emissionData?.length - 1
                          ]?.quantifiedGHGEmissionsInBaselineScenario
                        ) - ethers.formatEther(
                          emission?.emissionData[
                            emission?.emissionData?.length - 1
                          ]?.totalGHGEmissions ? emission?.emissionData[
                            emission?.emissionData?.length - 1
                          ]?.totalGHGEmissions : emission?.emissionData[
                            emission?.emissionData?.length - 1
                          ]?.totalGHGEmission
                        )
                      ).toFixed(0)
                          }
                      tCO2
                    </div>
                    <div>
                      {!!emission.conditions[emission?.conditions?.length - 1]
                        ?.isRejected
                        ? "Failed"
                        : !!emission.conditions[
                            emission?.conditions?.length - 1
                          ]?.isValidated
                        ? "Completed"
                        : "Pending"}
                    </div>
                    <div>
                      {!!emission.conditions[emission?.conditions?.length - 1]
                        ?.isRejected
                        ? "Failed"
                        : !!emission.conditions[
                            emission?.conditions?.length - 1
                          ]?.isVerified
                        ? "Completed"
                        : "Pending"}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
          <div className="security">
            <div className="security_header_group">
              <div
                className={
                  activeitem === "compliance"
                    ? "security_header active"
                    : "security_header"
                }
                onClick={() => setActiveItem("compliance")}
              >
                <div className="bar"></div>
                Compliance & Standards <br />
                26K
              </div>
              <div
                className={
                  activeitem === "security"
                    ? "security_header active"
                    : "security_header"
                }
                onClick={() => setActiveItem("security")}
              >
                <div className="bar"></div>
                Security Metrics <br />0
              </div>
              <div
                className={
                  activeitem === "error"
                    ? "security_header active"
                    : "security_header"
                }
                onClick={() => setActiveItem("error")}
              >
                <div className="bar"></div>
                Error Rates <br />
                08
              </div>
              <div
                className={
                  activeitem === "speed"
                    ? "security_header active"
                    : "security_header"
                }
                onClick={() => setActiveItem("speed")}
              >
                <div className="bar"></div>
                Speed & Efficiency <br />
                6.7K
              </div>
            </div>
            <div className="chart_container">
              <canvas id="myChart"></canvas>
            </div>
          </div> */}
        </div>

    

        <div className="carbon-credit-table">
          <div className="carbon-credit-table__header">CCRT History</div>

          <div className="carbon-credit-table__label">
          <div>Company Name</div>
            <div>CO2 Tracked</div>
            <div>CO2 Offset</div>
            <div>CO2 Credit issued</div>
            <div>Date</div>
          </div>

          {loading && 
          
        <>
          <div className="carbon-credit-table__item">
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
        </div>
          <div className="carbon-credit-table__item">
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
        </div>
          <div className="carbon-credit-table__item">
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
          <Skeleton width="60%" height={15} borderRadius={4} />
        </div>
        </>
          }
          {history?.map((item) => (
          <div className="carbon-credit-table__item">
            <div>{item?.companyName}</div>
              <div>{Number(item?.totalGhgEmission)}tCO2</div>
              <div>{Number(item?.offset)}tCO2</div>
              <div>{Number(item?.offset)}tCO2</div>
              {/* <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.63964 16.2L5.35099 12.7C5.26499 12.6074 5.16257 12.5338 5.04966 12.4836C4.93676 12.4334 4.81562 12.4076 4.69325 12.4076C4.57089 12.4076 4.44975 12.4334 4.33684 12.4836C4.22394 12.5338 4.12152 12.6074 4.03552 12.7C3.94848 12.7915 3.87937 12.9005 3.8322 13.0207C3.78503 13.1409 3.76074 13.2698 3.76074 13.4C3.76074 13.5302 3.78503 13.6592 3.8322 13.7793C3.87937 13.8995 3.94848 14.0085 4.03552 14.1L7.97251 18.29C8.33896 18.68 8.93092 18.68 9.29737 18.29L19.2573 7.70001C19.3443 7.60848 19.4135 7.49948 19.4606 7.37932C19.5078 7.25916 19.5321 7.13023 19.5321 7.00001C19.5321 6.86978 19.5078 6.74085 19.4606 6.62069C19.4135 6.50053 19.3443 6.39153 19.2573 6.30001C19.1713 6.20737 19.0689 6.13382 18.956 6.08362C18.8431 6.03342 18.7219 6.00757 18.5996 6.00757C18.4772 6.00757 18.3561 6.03342 18.2432 6.08362C18.1303 6.13382 18.0278 6.20737 17.9418 6.30001L8.63964 16.2Z"
                fill="#0F973D"
              />
            </svg> */}
              <div>
                {moment(parseInt(item?.createdAt)).format("Do MMM YYYY")}
              </div>
          </div>))}
        </div>
      </div>
    </div>
  );
};

export default History;
