import "../../styles/AdminDashboard/Login.scss";
import {
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  Input,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";
import {
  forgotPassword,
  login,
} from "../../apis/AdminDashboard/authentication";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotError, setForgotError] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("modal")) {
      setModalOpen(false);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPassword = () => {
    setStage(1);
    handleModalOpen();
  };
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setMaskedEmail(maskEmail(email));

    setStage(2);
  };
  const handleEmailSend = async () => {
    setLoading(true);
    const response = await forgotPassword(email);

    if (response?.data?.statusCode === 200) {
      setStage(3);
    } else {
      alert(response?.data?.message);
    }
    setLoading(false);
  };
  const handleResendEmail = (e) => {
    e.preventDefault();
    setStage(2);
  };

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    const response = await login(email, password);
console.log('response', response);
    if (response?.data?.statusCode === 200) {
      const tokenDecoded = jwt_decode(response.data.message);

      if (
        tokenDecoded?.role === "user" ||
        tokenDecoded?.role === "thirdParty"
      ) {
        toast.error("Please log in with a admin account");
      } else {
        localStorage.setItem("token", response.data.message);
        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: {
            token: response.data.message,
          },
        });
        if(email === "cc.admin@netzence.com.ng") {
          navigate("/admin/dashboard");
        }else {
          navigate("/admin/validation");
        }
        window.scrollTo(0, 0);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };

  function maskEmail(email) {
    const atIndex = email.indexOf("@");
    if (atIndex > 1) {
      const firstPart = email.substring(0, 1);
      const lastPart = email.substring(atIndex);
      const maskedFirstPart = firstPart + "*".repeat(atIndex - 1);
      return maskedFirstPart + lastPart;
    }
    return email;
  }

  return (
    <div className="login">
      <div className="admin_login_inner">
        <div className="title">Redefining Carbon Emission Solutions...</div>

        <div className="admin_box">
          {/* <div className="signup_link">
            Don’t have an account?{" "}
            <span
              className="pointer"
              onClick={() => navigate("/admin/signup")}
            >
              Sign Up
            </span>
          </div> */}
          <div className="box_title">Login</div>

          <form onSubmit={handleLogin}>
            <Input
              id="standard-adornment-password"
              className="admin_login_input"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error && (
              <div className="login_error_text">
                Incorrect email or password
              </div>
            )}
            <Input
              id="standard-adornment-password"
              className="admin_login_input"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            {/* <div className="login_error_text">Incorrect password</div> */}

            {/* <div className="login_flex">
              <div className="login_remember">
                <input className="checkbox" type="checkbox" name="" id="" />
                <div>Remember me</div>
              </div>
              <div className="forgot pointer" onClick={handleForgotPassword}>Forgot password?</div>
            </div> */}

            <button className="login_submit" type="submit" disabled={loading}>
              <div>{loading ? "Loading..." : "Login"}</div>
              {!loading && (
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5459 10.7959L8.79594 14.5459C8.58459 14.7573 8.29795 14.876 7.99906 14.876C7.70018 14.876 7.41353 14.7573 7.20219 14.5459C6.99084 14.3346 6.87211 14.0479 6.87211 13.7491C6.87211 13.4502 6.99084 13.1635 7.20219 12.9522L9.03125 11.125H1.25C0.951631 11.125 0.665483 11.0065 0.454505 10.7955C0.243526 10.5845 0.125 10.2984 0.125 10C0.125 9.70163 0.243526 9.41548 0.454505 9.2045C0.665483 8.99353 0.951631 8.875 1.25 8.875H9.03125L7.20406 7.04594C7.09942 6.94129 7.0164 6.81706 6.95977 6.68033C6.90314 6.5436 6.87399 6.39706 6.87399 6.24906C6.87399 5.95018 6.99272 5.66353 7.20406 5.45219C7.41541 5.24084 7.70205 5.12211 8.00094 5.12211C8.29982 5.12211 8.58647 5.24084 8.79781 5.45219L12.5478 9.20219C12.6526 9.30683 12.7356 9.43112 12.7923 9.56793C12.8489 9.70474 12.8779 9.85139 12.8778 9.99945C12.8776 10.1475 12.8482 10.2941 12.7912 10.4308C12.7343 10.5675 12.6509 10.6915 12.5459 10.7959ZM17 0.625H11.75C11.4516 0.625 11.1655 0.743526 10.9545 0.954505C10.7435 1.16548 10.625 1.45163 10.625 1.75C10.625 2.04837 10.7435 2.33452 10.9545 2.5455C11.1655 2.75647 11.4516 2.875 11.75 2.875H16.625V17.125H11.75C11.4516 17.125 11.1655 17.2435 10.9545 17.4545C10.7435 17.6655 10.625 17.9516 10.625 18.25C10.625 18.5484 10.7435 18.8345 10.9545 19.0455C11.1655 19.2565 11.4516 19.375 11.75 19.375H17C17.4973 19.375 17.9742 19.1775 18.3258 18.8258C18.6775 18.4742 18.875 17.9973 18.875 17.5V2.5C18.875 2.00272 18.6775 1.52581 18.3258 1.17417C17.9742 0.822544 17.4973 0.625 17 0.625Z"
                    fill="white"
                  />
                </svg>
              )}
            </button>
          </form>

          {modalOpen && (
            <>
              {/* Email Sent Modal */}
              {stage === 3 && (
                <Box className="admin_email_modal">
                  <svg
                    className="confirm_svg"
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M42 20.16V22C41.9975 26.3128 40.601 30.5093 38.0187 33.9636C35.4363 37.4179 31.8066 39.9449 27.6707 41.1678C23.5349 42.3906 19.1145 42.2438 15.0689 40.7491C11.0234 39.2545 7.56931 36.4922 5.22192 32.8741C2.87453 29.256 1.75958 24.9761 2.04335 20.6726C2.32712 16.3691 3.99441 12.2726 6.79656 8.99412C9.59871 5.71561 13.3856 3.43074 17.5924 2.48026C21.7992 1.52979 26.2005 1.96465 30.14 3.71997"
                      stroke="#099137"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42 6L22 26.02L16 20.02"
                      stroke="#099137"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="modal_title">Sent</div>
                  <div className="modal_subtitle">
                    A code has been sent to your email, please confirm.
                  </div>
                  <button
                    className="modal_button"
                    onClick={() =>
                      navigate(
                        `/admin/forgot-password/${email}`
                      )
                    }
                  >
                    Yeah, Got it!
                  </button>
                  <div
                    className="error didnt_get pointer"
                    onClick={() => setStage(4)}
                  >
                    Nah, I didn’t get the code
                  </div>
                </Box>
              )}
              {/* Automated Email Sent Modal */}
              {stage === 2 && (
                <Box className="admin_email_modal">
                  <svg
                    className="confrirm_svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_179_49)">
                      <path
                        d="M34.666 16.4533C33.2368 15.5538 32.0592 14.3065 31.2431 12.8282C30.4269 11.3498 29.999 9.68866 29.9993 8H5.22595C4.98668 8.00369 4.74899 8.03956 4.51929 8.10667L23.786 27.2933L34.666 16.4533Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M40.0002 18C39.0979 17.9922 38.2008 17.8621 37.3335 17.6133L25.7202 29.1867C25.2205 29.6833 24.5446 29.9621 23.8402 29.9621C23.1357 29.9621 22.4598 29.6833 21.9602 29.1867L2.66682 10C2.60767 10.2174 2.57631 10.4414 2.57349 10.6667V37.3333C2.57349 38.0406 2.85444 38.7189 3.35453 39.219C3.85463 39.719 4.53291 40 5.24015 40H42.5735C43.2807 40 43.959 39.719 44.4591 39.219C44.9592 38.7189 45.2402 38.0406 45.2402 37.3333V16.52C43.6654 17.4918 41.8506 18.0044 40.0002 18ZM7.06682 37.3333H5.21349V35.4267L14.9068 25.8133L16.7868 27.6933L7.06682 37.3333ZM42.5468 37.3333H40.6802L30.9602 27.6933L32.8402 25.8133L42.5335 35.4267L42.5468 37.3333Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M39.9994 14.6666C43.6813 14.6666 46.6661 11.6818 46.6661 7.99992C46.6661 4.31802 43.6813 1.33325 39.9994 1.33325C36.3175 1.33325 33.3328 4.31802 33.3328 7.99992C33.3328 11.6818 36.3175 14.6666 39.9994 14.6666Z"
                        fill="#00AED9"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_179_49">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <div className="modal_title">Recover via email</div>
                  <div className="modal_subtitle">
                    We will send a password recovery code to
                  </div>
                  <div className="modal_email">{maskedEmail}</div>
                  <button className="modal_button" onClick={handleEmailSend}>
                    {loading ? "Loading..." : "Send"}
                  </button>
                </Box>
              )}
              {/* Not getting code Modal */}
              {stage === 4 && (
                <form
                  className="admin_email_modal"
                  onSubmit={handleResendEmail}
                >
                  <div className="modal_subtitle bold">
                    Why are you not getting the code:
                  </div>

                  <ul>
                    <li>Check your spam folder.</li>
                    <li>Incorrect email address</li>
                  </ul>
                  {/* <InputLabel className="modal_label" htmlFor="email">
        Re-enter email address
            </InputLabel> */}

                  <Input
                    id="standard-adornment-password"
                    className="admin_modal_input"
                    type="text"
                    placeholder="Re-enter email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button className="modal_button">Resend Code</button>
                </form>
              )}

              {stage === 1 && (
                <form
                  className="admin_email_modal"
                  onSubmit={handleEmailSubmit}
                >
                  <svg
                    className="confrirm_svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_179_49)">
                      <path
                        d="M34.666 16.4533C33.2368 15.5538 32.0592 14.3065 31.2431 12.8282C30.4269 11.3498 29.999 9.68866 29.9993 8H5.22595C4.98668 8.00369 4.74899 8.03956 4.51929 8.10667L23.786 27.2933L34.666 16.4533Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M40.0002 18C39.0979 17.9922 38.2008 17.8621 37.3335 17.6133L25.7202 29.1867C25.2205 29.6833 24.5446 29.9621 23.8402 29.9621C23.1357 29.9621 22.4598 29.6833 21.9602 29.1867L2.66682 10C2.60767 10.2174 2.57631 10.4414 2.57349 10.6667V37.3333C2.57349 38.0406 2.85444 38.7189 3.35453 39.219C3.85463 39.719 4.53291 40 5.24015 40H42.5735C43.2807 40 43.959 39.719 44.4591 39.219C44.9592 38.7189 45.2402 38.0406 45.2402 37.3333V16.52C43.6654 17.4918 41.8506 18.0044 40.0002 18ZM7.06682 37.3333H5.21349V35.4267L14.9068 25.8133L16.7868 27.6933L7.06682 37.3333ZM42.5468 37.3333H40.6802L30.9602 27.6933L32.8402 25.8133L42.5335 35.4267L42.5468 37.3333Z"
                        fill="#00AED9"
                      />
                      <path
                        d="M39.9994 14.6666C43.6813 14.6666 46.6661 11.6818 46.6661 7.99992C46.6661 4.31802 43.6813 1.33325 39.9994 1.33325C36.3175 1.33325 33.3328 4.31802 33.3328 7.99992C33.3328 11.6818 36.3175 14.6666 39.9994 14.6666Z"
                        fill="#00AED9"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_179_49">
                        <rect width="48" height="48" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <div className="modal_title">Input email address</div>

                  {/* <InputLabel className="modal_label" htmlFor="email">
        Email address
            </InputLabel> */}

                  <Input
                    id="standard-adornment-password"
                    className="admin_modal_input"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button type="submit" className="modal_button">
                    Submit
                  </button>
                </form>
              )}
            </>
          )}
        </div>
      </div>
      {modalOpen && <div className="modal" onClick={handleModalClose}></div>}
    </div>
  );
};

export default Login;
