import { useEffect, useRef, useState } from "react";
import SideBar from "../../components/ThirdPartyDashboard/SideBar";
import "../../styles/ThirdParty/Home.scss";
import Search from "../../components/ThirdPartyDashboard/Search";
import MonitoringCard from "../../components/ThirdPartyDashboard/MonitoringCard";
import { useSelector } from "react-redux";
import { getAllEmissionDataWithInfo } from "../../instantiateTransaction/carbonMonitoringV1";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Illustration from "../../images/9264828.jpg";
import { toast } from "react-toastify";
import "../../styles/ThirdParty/MonitoringCard.scss";
import Kyc from "../../components/ThirdPartyDashboard/Kyc";


const Home = () => {
  const [facilties, setFacilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const getEmissionData = async () => {
    setLoading(true);
    try {
      const response = await getAllEmissionDataWithInfo();
      // console.log("openai", response);
      setFacilities(response);
      setLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Something went wrong");
    }
    setLoading(false);
  };
  // useEffect(() => {
  //   getEmissionData();
  // }, []);
  const auth = useSelector((state) => state.auth);
  return (
    <div className="thirdparty_home">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="title">Monitoring</div>
        {!loading && facilties?.length === 0 && (
          <div className="empty_list">
            <img src={Illustration} alt="" />
            <div>No emission data here yet</div>
          </div>
        )}
        {loading ? (
          <>
            <div className="monitoring_card">
              <div className="monitoring_grid">
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                
              </div>

              {/* <div className="chart_container">
        <div className="chart_label">CO2 emission (tCO2)</div>
        <div className="chart_inner">
          <canvas id={chartId}></canvas>
        </div>
      </div> */}
            </div>
            <div className="monitoring_card">
              <div className="monitoring_grid">
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                
              </div>

              {/* <div className="chart_container">
        <div className="chart_label">CO2 emission (tCO2)</div>
        <div className="chart_inner">
          <canvas id={chartId}></canvas>
        </div>
      </div> */}
            </div>
            <div className="monitoring_card">
              <div className="monitoring_grid">
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Skeleton
                      width={"50%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                  <div>
                    <Skeleton
                      width={"100%"}
                      height={20}
                      borderRadius={8}
                      style={{ marginBottom: "8px" }}
                    />
                  </div>
                </div>
                
              </div>

              {/* <div className="chart_container">
        <div className="chart_label">CO2 emission (tCO2)</div>
        <div className="chart_inner">
          <canvas id={chartId}></canvas>
        </div>
      </div> */}
            </div>
            
          </>
        ) : (
          <>
            {facilties?.map((facility, key) => (
              <MonitoringCard
                facility={facility}
                chartId={key}
                chartData={[12, 19, 3, 5, 2, 3, 25]}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
