import axios from 'axios';

export const createApplicant = async (first_name, last_name, email, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc-applicant-id`,
      {
        first_name,
        last_name,
        email
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response;
  }
};

export const getAuthToken = async (applicant_id, workflow_id, token) => {

  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc-token`,
      {
        applicant_id,
        workflow_id
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const createCheck = async (applicant_id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc-check`,
      {
        applicant_id,
        report_names: ["document", "facial_similarity_photo"]
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response;
  }
};

export const getCheck = async (check_id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/check-kyc-status`,
      {
        check_id
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
    return error?.response;
  }
};
    
