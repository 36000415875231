import Header from "../../components/shared/Header";
import "../../styles/shared/Solutions.scss";
import Vent from '../../images/image 44.png'
import Monitoring from '../../images/image 46.png'
import Validation from '../../images/image 47.png'
import Verification from '../../images/image 48.png'
import Credit from '../../images/image 89.png'
import Sustainability from '../../images/image 50.png'
import Footer from "../../components/shared/Footer";
export default function Solutions() {
  return (
    <div className="solutions">
      <Header />
      <div className="hero">
        <div>
          Fuel your impact with{" "}
          <span>CloseCarbon's sustainability solutions</span>
        </div>
      </div>
      <div className="ourSolution">
        <div className="title">Our Solution</div>
        <div className="group">
          <a href="#carbonTracking" className="box">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 17.25H18.75V13.5H17.25V17.25H13.5V18.75H17.25V22.5H18.75V18.75H22.5V17.25Z"
                fill="#00AED9"
              />
              <path
                d="M18.0945 8.87976L18.9802 9.03951C19.698 9.15622 20.3749 9.45124 20.949 9.8975C21.4837 10.3224 21.9028 10.8751 22.1676 11.5047C22.4323 12.1343 22.5343 12.8204 22.464 13.4998H23.9865C24.0792 12.1194 23.6601 10.7532 22.8092 9.66231C21.9584 8.5714 20.7354 7.83223 19.374 7.586C19.0899 6.11262 18.3707 4.75822 17.3092 3.69765C16.2477 2.63709 14.8926 1.91906 13.419 1.63625C12.4511 1.44922 11.4559 1.45479 10.4902 1.65264C9.52443 1.85049 8.60718 2.23674 7.79083 2.7893C6.97448 3.34187 6.27505 4.04992 5.73252 4.87297C5.18999 5.69602 4.81499 6.61794 4.62898 7.586C3.21498 7.84173 1.95232 8.62857 1.09976 9.78526C0.247193 10.942 -0.130713 12.3809 0.0434833 13.8073C0.242718 15.1934 0.93983 16.4595 2.00456 17.3691C3.06928 18.2787 4.42877 18.7695 5.82898 18.7498H10.4992V17.2498H5.77273C4.79031 17.2652 3.83322 16.9377 3.06622 16.3236C2.29922 15.7095 1.77029 14.8473 1.57048 13.8853C1.47046 13.3501 1.47724 12.8004 1.59045 12.2679C1.70365 11.7354 1.92104 11.2305 2.23011 10.7823C2.53918 10.3341 2.93383 9.95141 3.39134 9.65631C3.84886 9.36121 4.36021 9.15949 4.89598 9.06275L5.90773 8.879L6.06823 8.0465C6.29356 6.79569 6.9006 5.64531 7.80599 4.75336C8.71137 3.8614 9.87069 3.27162 11.1247 3.065C11.8776 2.95332 12.6448 2.98641 13.3852 3.1625C14.5272 3.44554 15.563 4.05234 16.3684 4.90997C17.1737 5.7676 17.7142 6.83956 17.925 7.997L18.0945 8.87976Z"
                fill="#00AED9"
              />
            </svg>
            <div className="title">Carbon Tracking</div>
            <div className="subtitle">
              CloseCarbon's carbon tracking feature allows users to monitor and
              analyse their carbon emissions in real-time, providing a
              comprehensive overview of their environmental impact. Users can
              easily track their progress, set reduction goals, and make
              informed decisions to contribute towards a more sustainable
              future.
            </div>
            <div className="shadow"></div>
          </a>
          {/* <a href="#carbonMonitoring" className="box">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 17.25H18.75V13.5H17.25V17.25H13.5V18.75H17.25V22.5H18.75V18.75H22.5V17.25Z"
                fill="#00AED9"
              />
              <path
                d="M18.0945 8.87976L18.9802 9.03951C19.698 9.15622 20.3749 9.45124 20.949 9.8975C21.4837 10.3224 21.9028 10.8751 22.1676 11.5047C22.4323 12.1343 22.5343 12.8204 22.464 13.4998H23.9865C24.0792 12.1194 23.6601 10.7532 22.8092 9.66231C21.9584 8.5714 20.7354 7.83223 19.374 7.586C19.0899 6.11262 18.3707 4.75822 17.3092 3.69765C16.2477 2.63709 14.8926 1.91906 13.419 1.63625C12.4511 1.44922 11.4559 1.45479 10.4902 1.65264C9.52443 1.85049 8.60718 2.23674 7.79083 2.7893C6.97448 3.34187 6.27505 4.04992 5.73252 4.87297C5.18999 5.69602 4.81499 6.61794 4.62898 7.586C3.21498 7.84173 1.95232 8.62857 1.09976 9.78526C0.247193 10.942 -0.130713 12.3809 0.0434833 13.8073C0.242718 15.1934 0.93983 16.4595 2.00456 17.3691C3.06928 18.2787 4.42877 18.7695 5.82898 18.7498H10.4992V17.2498H5.77273C4.79031 17.2652 3.83322 16.9377 3.06622 16.3236C2.29922 15.7095 1.77029 14.8473 1.57048 13.8853C1.47046 13.3501 1.47724 12.8004 1.59045 12.2679C1.70365 11.7354 1.92104 11.2305 2.23011 10.7823C2.53918 10.3341 2.93383 9.95141 3.39134 9.65631C3.84886 9.36121 4.36021 9.15949 4.89598 9.06275L5.90773 8.879L6.06823 8.0465C6.29356 6.79569 6.9006 5.64531 7.80599 4.75336C8.71137 3.8614 9.87069 3.27162 11.1247 3.065C11.8776 2.95332 12.6448 2.98641 13.3852 3.1625C14.5272 3.44554 15.563 4.05234 16.3684 4.90997C17.1737 5.7676 17.7142 6.83956 17.925 7.997L18.0945 8.87976Z"
                fill="#00AED9"
              />
            </svg>
            <div className="title">Carbon Monitoring</div>
            <div className="subtitle">
            CloseCarbon's carbon monitoring feature is conducted with the assurance of accuracy and reliability, as it is carried out by verified third parties. These trusted entities validate and authenticate the carbon data, ensuring transparency and precision in the monitoring process for users.
            </div>
            <div className="shadow"></div>
          </a> */}
          <a href="#validation" className="box">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 17.25H18.75V13.5H17.25V17.25H13.5V18.75H17.25V22.5H18.75V18.75H22.5V17.25Z"
                fill="#00AED9"
              />
              <path
                d="M18.0945 8.87976L18.9802 9.03951C19.698 9.15622 20.3749 9.45124 20.949 9.8975C21.4837 10.3224 21.9028 10.8751 22.1676 11.5047C22.4323 12.1343 22.5343 12.8204 22.464 13.4998H23.9865C24.0792 12.1194 23.6601 10.7532 22.8092 9.66231C21.9584 8.5714 20.7354 7.83223 19.374 7.586C19.0899 6.11262 18.3707 4.75822 17.3092 3.69765C16.2477 2.63709 14.8926 1.91906 13.419 1.63625C12.4511 1.44922 11.4559 1.45479 10.4902 1.65264C9.52443 1.85049 8.60718 2.23674 7.79083 2.7893C6.97448 3.34187 6.27505 4.04992 5.73252 4.87297C5.18999 5.69602 4.81499 6.61794 4.62898 7.586C3.21498 7.84173 1.95232 8.62857 1.09976 9.78526C0.247193 10.942 -0.130713 12.3809 0.0434833 13.8073C0.242718 15.1934 0.93983 16.4595 2.00456 17.3691C3.06928 18.2787 4.42877 18.7695 5.82898 18.7498H10.4992V17.2498H5.77273C4.79031 17.2652 3.83322 16.9377 3.06622 16.3236C2.29922 15.7095 1.77029 14.8473 1.57048 13.8853C1.47046 13.3501 1.47724 12.8004 1.59045 12.2679C1.70365 11.7354 1.92104 11.2305 2.23011 10.7823C2.53918 10.3341 2.93383 9.95141 3.39134 9.65631C3.84886 9.36121 4.36021 9.15949 4.89598 9.06275L5.90773 8.879L6.06823 8.0465C6.29356 6.79569 6.9006 5.64531 7.80599 4.75336C8.71137 3.8614 9.87069 3.27162 11.1247 3.065C11.8776 2.95332 12.6448 2.98641 13.3852 3.1625C14.5272 3.44554 15.563 4.05234 16.3684 4.90997C17.1737 5.7676 17.7142 6.83956 17.925 7.997L18.0945 8.87976Z"
                fill="#00AED9"
              />
            </svg>
            <div className="title">Validation</div>
            <div className="subtitle">
            Validation is the initial step in CloseCarbon’s crediting process. The purpose of validation is to determine whether a project meets the requirements for generating carbon incentives. The validation is conducted by our in-house validator who assesses the eligibility and compliance of a project against specific standards or methodologies. 
            </div>
            <div className="shadow"></div>
          </a>
          <a href="#verification" className="box">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 17.25H18.75V13.5H17.25V17.25H13.5V18.75H17.25V22.5H18.75V18.75H22.5V17.25Z"
                fill="#00AED9"
              />
              <path
                d="M18.0945 8.87976L18.9802 9.03951C19.698 9.15622 20.3749 9.45124 20.949 9.8975C21.4837 10.3224 21.9028 10.8751 22.1676 11.5047C22.4323 12.1343 22.5343 12.8204 22.464 13.4998H23.9865C24.0792 12.1194 23.6601 10.7532 22.8092 9.66231C21.9584 8.5714 20.7354 7.83223 19.374 7.586C19.0899 6.11262 18.3707 4.75822 17.3092 3.69765C16.2477 2.63709 14.8926 1.91906 13.419 1.63625C12.4511 1.44922 11.4559 1.45479 10.4902 1.65264C9.52443 1.85049 8.60718 2.23674 7.79083 2.7893C6.97448 3.34187 6.27505 4.04992 5.73252 4.87297C5.18999 5.69602 4.81499 6.61794 4.62898 7.586C3.21498 7.84173 1.95232 8.62857 1.09976 9.78526C0.247193 10.942 -0.130713 12.3809 0.0434833 13.8073C0.242718 15.1934 0.93983 16.4595 2.00456 17.3691C3.06928 18.2787 4.42877 18.7695 5.82898 18.7498H10.4992V17.2498H5.77273C4.79031 17.2652 3.83322 16.9377 3.06622 16.3236C2.29922 15.7095 1.77029 14.8473 1.57048 13.8853C1.47046 13.3501 1.47724 12.8004 1.59045 12.2679C1.70365 11.7354 1.92104 11.2305 2.23011 10.7823C2.53918 10.3341 2.93383 9.95141 3.39134 9.65631C3.84886 9.36121 4.36021 9.15949 4.89598 9.06275L5.90773 8.879L6.06823 8.0465C6.29356 6.79569 6.9006 5.64531 7.80599 4.75336C8.71137 3.8614 9.87069 3.27162 11.1247 3.065C11.8776 2.95332 12.6448 2.98641 13.3852 3.1625C14.5272 3.44554 15.563 4.05234 16.3684 4.90997C17.1737 5.7676 17.7142 6.83956 17.925 7.997L18.0945 8.87976Z"
                fill="#00AED9"
              />
            </svg>
            <div className="title">Verification</div>
            <div className="subtitle">
            Verification is the process of confirming the accuracy and reliability of the carbon incentives generated by a project. It is carried out by an independent third-party verifier who assesses the project’s compliance with the relevant standards and methodologies. 
            </div>
            <div className="shadow"></div>
          </a>
          <a href="#carbonCrediting" className="box">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 17.25H18.75V13.5H17.25V17.25H13.5V18.75H17.25V22.5H18.75V18.75H22.5V17.25Z"
                fill="#00AED9"
              />
              <path
                d="M18.0945 8.87976L18.9802 9.03951C19.698 9.15622 20.3749 9.45124 20.949 9.8975C21.4837 10.3224 21.9028 10.8751 22.1676 11.5047C22.4323 12.1343 22.5343 12.8204 22.464 13.4998H23.9865C24.0792 12.1194 23.6601 10.7532 22.8092 9.66231C21.9584 8.5714 20.7354 7.83223 19.374 7.586C19.0899 6.11262 18.3707 4.75822 17.3092 3.69765C16.2477 2.63709 14.8926 1.91906 13.419 1.63625C12.4511 1.44922 11.4559 1.45479 10.4902 1.65264C9.52443 1.85049 8.60718 2.23674 7.79083 2.7893C6.97448 3.34187 6.27505 4.04992 5.73252 4.87297C5.18999 5.69602 4.81499 6.61794 4.62898 7.586C3.21498 7.84173 1.95232 8.62857 1.09976 9.78526C0.247193 10.942 -0.130713 12.3809 0.0434833 13.8073C0.242718 15.1934 0.93983 16.4595 2.00456 17.3691C3.06928 18.2787 4.42877 18.7695 5.82898 18.7498H10.4992V17.2498H5.77273C4.79031 17.2652 3.83322 16.9377 3.06622 16.3236C2.29922 15.7095 1.77029 14.8473 1.57048 13.8853C1.47046 13.3501 1.47724 12.8004 1.59045 12.2679C1.70365 11.7354 1.92104 11.2305 2.23011 10.7823C2.53918 10.3341 2.93383 9.95141 3.39134 9.65631C3.84886 9.36121 4.36021 9.15949 4.89598 9.06275L5.90773 8.879L6.06823 8.0465C6.29356 6.79569 6.9006 5.64531 7.80599 4.75336C8.71137 3.8614 9.87069 3.27162 11.1247 3.065C11.8776 2.95332 12.6448 2.98641 13.3852 3.1625C14.5272 3.44554 15.563 4.05234 16.3684 4.90997C17.1737 5.7676 17.7142 6.83956 17.925 7.997L18.0945 8.87976Z"
                fill="#00AED9"
              />
            </svg>
            <div className="title">Carbon Incentives</div>
            <div className="subtitle">
            Carbon incentive programs incentivise users to actively reduce their carbon footprint by offering credits for sustainable practices. By participating in these programs, individuals and businesses can contribute to global environmental efforts while being rewarded for their commitment to carbon reduction.
            </div>
            <div className="shadow"></div>
          </a>
          {/* <a href="#carbonTokenization" className="box">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 17.25H18.75V13.5H17.25V17.25H13.5V18.75H17.25V22.5H18.75V18.75H22.5V17.25Z"
                fill="#00AED9"
              />
              <path
                d="M18.0945 8.87976L18.9802 9.03951C19.698 9.15622 20.3749 9.45124 20.949 9.8975C21.4837 10.3224 21.9028 10.8751 22.1676 11.5047C22.4323 12.1343 22.5343 12.8204 22.464 13.4998H23.9865C24.0792 12.1194 23.6601 10.7532 22.8092 9.66231C21.9584 8.5714 20.7354 7.83223 19.374 7.586C19.0899 6.11262 18.3707 4.75822 17.3092 3.69765C16.2477 2.63709 14.8926 1.91906 13.419 1.63625C12.4511 1.44922 11.4559 1.45479 10.4902 1.65264C9.52443 1.85049 8.60718 2.23674 7.79083 2.7893C6.97448 3.34187 6.27505 4.04992 5.73252 4.87297C5.18999 5.69602 4.81499 6.61794 4.62898 7.586C3.21498 7.84173 1.95232 8.62857 1.09976 9.78526C0.247193 10.942 -0.130713 12.3809 0.0434833 13.8073C0.242718 15.1934 0.93983 16.4595 2.00456 17.3691C3.06928 18.2787 4.42877 18.7695 5.82898 18.7498H10.4992V17.2498H5.77273C4.79031 17.2652 3.83322 16.9377 3.06622 16.3236C2.29922 15.7095 1.77029 14.8473 1.57048 13.8853C1.47046 13.3501 1.47724 12.8004 1.59045 12.2679C1.70365 11.7354 1.92104 11.2305 2.23011 10.7823C2.53918 10.3341 2.93383 9.95141 3.39134 9.65631C3.84886 9.36121 4.36021 9.15949 4.89598 9.06275L5.90773 8.879L6.06823 8.0465C6.29356 6.79569 6.9006 5.64531 7.80599 4.75336C8.71137 3.8614 9.87069 3.27162 11.1247 3.065C11.8776 2.95332 12.6448 2.98641 13.3852 3.1625C14.5272 3.44554 15.563 4.05234 16.3684 4.90997C17.1737 5.7676 17.7142 6.83956 17.925 7.997L18.0945 8.87976Z"
                fill="#00AED9"
              />
            </svg>
            <div className="title">Carbon Tokenization</div>
            <div className="subtitle">
            CloseCarbon supports the digitalization of carbon incentives into tokens. Each NFT corresponds to a specific emission reduction, ensuring the traceability and uniqueness of each carbon incentive. The platform also allows users to trade their tokens on the platform or any other platform of their choice.
            </div>
            <div className="shadow"></div>
          </a> */}
        </div>
      </div>

      <div className="solution_feature" id="carbonTracking">
        <div className="title">Carbon Tracking</div>
        <div className="subtitle">CloseCarbon's carbon tracking feature allows users to monitor and analyse their carbon emissions in real-time, providing a comprehensive overview of their environmental impact. Users can easily track their progress, set reduction goals, and make informed decisions to contribute towards a more sustainable future.</div>
        <div className="button_group">
        <div>ISO-14064-1</div>
        <div>ISO-14064-2</div>
      </div>

      <img src={Vent} alt="" />
      </div>
{/* <div className="solution_feature" id="carbonMonitoring">
    <div className="title">Carbon Monitoring</div>
    <div className="subtitle">CloseCarbon's carbon monitoring feature is conducted with the assurance of accuracy and reliability, as it is carried out by verified third parties. These trusted entities validate and authenticate the carbon data, ensuring transparency and precision in the monitoring process for users.</div>
    <div className="button_group">
    <div>ISO-14064-1</div>
        <div>ISO-14064-2</div>
      </div>

      <img src={Monitoring} alt="" />
</div> */}
<div className="solution_feature" id="validation">
    <div className="title">Validation</div>
    <div className="subtitle"> Validation is the initial step in CloseCarbon’s crediting process. The purpose of validation is to determine whether a project meets the requirements for generating carbon incentives. The validation is conducted by our in-house validator who assesses the eligibility and compliance of a project against specific standards or methodologies.</div>
    <div className="button_group">
        <div>Verra’s VCS</div>
        <div>ISO-14064-3</div>
      </div>

      <img src={Validation} alt="" />
</div>
<div className="solution_feature" id="verification">
    <div className="title">Verification</div>
    <div className="subtitle">Verification is the process of confirming the accuracy and reliability of the carbon incentives generated by a project. It is carried out by an independent third-party verifier who assesses the project’s compliance with the relevant standards and methodologies. </div>
    <div className="button_group">
    <div>Verra’s VCS</div>
        <div>ISO-14064-3</div>
      </div>

      <img src={Verification} alt="" />
</div>
<div className="solution_feature" id="carbonCrediting">
    <div className="title">Carbon Incentivising</div>
    <div className="subtitle">Carbon incentive programs incentivise users to actively reduce their carbon footprint by offering credits for sustainable practices. By participating in these programs, individuals and businesses can contribute to global environmental efforts while being rewarded for their commitment to carbon reduction.</div>
    <div className="button_group">
    <div>Verra’s VCS</div>
        <div>ISO-14064-1</div>
        <div>ISO-14064-2</div>
        <div>ISO-14064-3</div>
      </div>

      <img src={Credit} alt="" />
</div>
{/* <div className="solution_feature" id="carbonTokenization">
    <div className="title">Carbon Tokenization</div>
    <div className="subtitle">CloseCarbon supports the digitalization of carbon incentives into tokens. Each NFT corresponds to a specific emission reduction, ensuring the traceability and uniqueness of each carbon incentive. The platform also allows users to trade their tokens on the platform or any other platform of their choice</div>
    <div className="button_group">
        <div>ERC721</div>
        <div>ERC20</div>
      </div>

      <img src={Sustainability} alt="" />
</div> */}

<div className="end"></div>
     <Footer />
    </div>
  );
}
