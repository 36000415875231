import ReactQuill from "react-quill";
import Search from "../../components/UserDashboard/Search";
import SideBar from "../../components/UserDashboard/SideBar";
import "../../styles/UserDashboard/Ndc.scss";
import { useEffect, useState } from "react";
import { getNdcStatus, submitNdcRequest } from "../../apis/General/users";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Ndc() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
    const [content, setContent] = useState("");
    const [ndcTrue, setNdcTrue] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);

    const quilModules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link"],
          ["clean"],
        ],
      };
    
      const quilFormats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link"
      ];
    

    
    const handleSubmitNdcRequest = async () => {
      setLoading(true)
      if(content?.length < 1) {
        alert("Please state your reason for requesting access to NDCs-S values");
      }
      else if(!ndcTrue) {
        alert("Please accept the Terms and Conditions of accessing NDCs-S details");
      }
      else {
        const response = await submitNdcRequest(content, auth?.token);
        console.log('submitNdcRequest',response);

        if(response?.data?.statusCode === 200) {
          toast.success(response?.data?.message);
          setContent("")
          setNdcTrue(false)
        }else {
          toast.error(response?.data?.message);
        }
      }
      setLoading(false)
    }

    const handleGetNdcStatus = async () => {
      setStatusLoading(true)
      if(auth?.userInfo?.ndcValueAccess === "approved") {
        navigate('/ndc-list')
      }else {
        const response = await getNdcStatus(auth?.token);

      console.log('ndc status', response);
      if (response?.data?.statusCode === 200) {
        localStorage.removeItem("token");
        localStorage.setItem("token", response?.data?.token);

        dispatch({
          type: "USER_LOGIN_SUCCESS",
          payload: {
            token: response?.data?.token,
          },
        });
        console.log("auth", auth, "auth?.userInfo?.ndcValueAccess", auth?.userInfo?.ndcValueAccess, auth?.userInfo?.ndcValueAccess === "approved");
        if(auth?.userInfo?.ndcValueAccess === "approved") {
          navigate('/ndc-list')
        }
      } else {
        toast.error(response?.data?.message);
      }

      }
      setStatusLoading(false)
    }

    useEffect(() => {
      handleGetNdcStatus();
    }, [])

  return (
    <div className="ndc">
      <div className="sidebar_container">
        <SideBar />
      </div>
      <div className="ndc_main">
        <Search />
        <div className="ndc-main-inner">

<div className="ndc_title">
Nationally Determined Contributions Support (NDCs-S) Value
</div>

<div className="ndc_subtitle">
 Nationally Determined Contributions Support (NDCs-S) are commitments made by
 countries under the Paris Agreement to reduce greenhouse gas emissions
 and adapt to climate change. These commitments are tailored to each
 country's circumstances and priorities, outlining their specific
 targets and actions.
</div>
<div className="ndc_paragraph">
CloseCarbon offers access to this information, but users are required to submit a request and provide justification for accessing these specifics.
</div>
<div className="ndc_subtitle">
Reason for request
</div>
<ReactQuill
   theme="snow"
   value={content}
   onChange={setContent}
   modules={quilModules}
   formats={quilFormats}
   style={{ height: "235px", marginBottom: "64px", marginTop: "16px" }}
 />

 <div className="ndc_terms">

 {!ndcTrue && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setNdcTrue(true)}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg> }  

{ ndcTrue &&<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setNdcTrue(false)}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18037)" stroke-width="1.33333"/>
<path d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325" stroke="url(#paint1_linear_2889_18037)" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_2889_18037" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
<linearGradient id="paint1_linear_2889_18037" x1="5.5" y1="6.948" x2="12.5203" y2="11.8039" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}
 
   <div>I agree to the <span>Terms and Conditions</span> of accessing NDCs-S details</div>
 </div>
 <button className="ndc_button" type="submit"
 onClick={handleSubmitNdcRequest}
 >{loading ? "Loading..." : "Submit Request"}</button>

 {/* <div className="ndc_main__empty">
 <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.1758 31.9901L32.0038 29.1631L44.0228 41.1821L41.1948 44.0091L29.1758 31.9901Z" fill="#616161"/>
<path d="M20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z" fill="#616161"/>
<path d="M32.4492 35.3397L35.2762 32.5117L43.9722 41.2077L41.1442 44.0357L32.4492 35.3397Z" fill="#37474F"/>
<path d="M20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7C12.8203 7 7 12.8203 7 20C7 27.1797 12.8203 33 20 33Z" fill="#00AED9"/>
<path d="M26.9003 14.2C25.2003 12.2 22.7003 11 20.0003 11C17.3003 11 14.8003 12.2 13.1003 14.2C12.7003 14.6 12.8003 15.3 13.2003 15.6C13.6003 16 14.3003 15.9 14.6003 15.5C16.0003 13.9 17.9003 13 20.0003 13C22.1003 13 24.0003 13.9 25.4003 15.5C25.6003 15.7 25.9003 15.9 26.2003 15.9C26.4003 15.9 26.7003 15.8 26.8003 15.7C27.2003 15.3 27.2003 14.6 26.9003 14.2Z" fill="#BBDEFB"/>
</svg>
<div>Once NDCs-S request as been confirmed you’ll be able <br /> to view the information here</div>
 </div> */}
        </div>
        
      </div>
    </div>
  );
}
