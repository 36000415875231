import { useState } from "react";
import SideBar from "../../../components/AdminDashboard/WebsiteDashboard/SideBar";
import "../../../styles/AdminDashboard/WebsiteDashboard/CreateBlog.scss";
import { TextField } from "@mui/material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { createBlogPost } from "../../../apis/AdminDashboard/users";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Search from "../../../components/AdminDashboard/Search";

const CreateBlog = () => {
  const auth = useSelector((state) => state.auth);  
  const [title, setTitle] = useState("");
  const [intro, setIntro] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const quilModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const quilFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  const handleCreateBlog = async (e) => {
    setLoading(true)
    e.preventDefault();
    if(content.trim() === "") {
      alert("Please provide the body text for your blog post")
    }
    const response = await createBlogPost(title, intro, content, auth?.token);

    if(response?.data?.statusCode === 200) {
      toast.success(response?.data?.message);
      navigate('/admin/website/blog-list');
    }
    console.log('response', response);
    setLoading(false)
  }

  return (
    <div className="create-blog">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="create-blog_main">
        <Search />
        <form className="create-blog_form" onSubmit={handleCreateBlog}>
          <div className="create-blog_grid">
            <div>
              <label htmlFor="title">
                Title <span>*</span>
              </label>

              <input
                className="create-blog_input"
                name="title"
                id="title"
                type="text"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="title">
                Intro <span>*</span>
              </label>

              <input
                className="create-blog_input"
                name="title"
                id="title"
                type="text"
                required
                value={intro}
                onChange={(e) => setIntro(e.target.value)}
              />
            </div>
          </div>
          <label htmlFor="title">
            Body text <span>*</span>
          </label>
          <ReactQuill theme="snow" value={content} onChange={setContent} modules={quilModules} formats={quilFormats} style={{ height: '550px', marginBottom: '36px', marginTop: '12px' }} />
          
          <div className="create-blog_button-group">
            <button
              className="button-group_trans"
              type="submit"
              disabled={loading}
            >
              {loading ? "Loading..." : "Save to draft"}
            </button>
            <button
              className="button-group_filled"
              type="submit"
              disabled={loading}
            >
              {loading ? "Loading..." : "Upload Blog"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateBlog;
