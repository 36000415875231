// import Search from "../../components/ThirdPartyDashboard/Search";
// import SideBar from "../../components/ThirdPartyDashboard/SideBar";
// import "../../styles/ThirdParty/CarbonCredit.scss";
// import {
//   getAllEmissionValues,
//   carbonCredits as greenCarbonCredits,
//   balanceOf,
//   getAllPlantsData as greenGetAllEmissionData,
// } from "../../instantiateTransaction/basicGreenProject";
// // import {
// //   getAllEmissionValues as infastructureGetAllEmissionValues,
// //   // issueCarbonCredits as infastructureissueCarbonCredit,
// //   userEmission as infastructureUserEmission,
// //   getAllEmissionDataWithInfo as infrastructureGetAllEmissionData,
// // } from "../../instantiateTransaction/infrastructureChange";
// import { useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import { ethers } from "ethers";
// import { getDatabase, ref, onValue } from "firebase/database";
// import moment from "moment";
// import { useSelector } from "react-redux";
// import { issueCarbonCredit } from "../../apis/General/web3/shared";
// import Skeleton from "react-loading-skeleton";
// import "react-loading-skeleton/dist/skeleton.css";
// import Illustration from "../../images/9264828.jpg";

const CarbonCredit = () => {
  // const auth = useSelector((state) => state.auth);
  // const [totalEmission, setTotalEmission] = useState(0);
  // const [reportingPeriod, setReportingPeriod] = useState(0);
  // const [baseline, setBaseline] = useState(0);
  // const [energyConsumption, setEnergyConsumption] = useState(0);
  // const [loading, setLoading] = useState(false);
  // const [generalLoading, setGeneralLoading] = useState(false);
  // const [greenFacilities, setGreenFacilities] = useState([]);
  // const [infrastructureChange, setInfrastructureChange] = useState([]);
  // const [activities, setActivities] = useState([]);

  // const getValidationData = async () => {
  //   setGeneralLoading(true);
  //   try {
  //     const response6 = await greenGetAllEmissionData();
  //     setGreenFacilities(response6);

  //     // const response7 = await infrastructureGetAllEmissionData();
  //     // setInfrastructureChange(response7);
  //   } catch (error) {
  //     console.log("ERROR", error);
  //     toast.error("Something went wrong");
  //   }
  //   setGeneralLoading(false);
  // };

  // const getActivities = async () => {
  //   // setGeneralLoading(true)
  //   const database = getDatabase();

  //   const newRef = ref(database, "events");
  //   await onValue(newRef, (snapshot) => {
  //     const data = snapshot.val();
  //     if (data) {
  //       setActivities(Object.values(data));
  //     }
  //     // setGeneralLoading(false);
  //   });
  // };

  // useEffect(() => {
  //   getValidationData();
  //   getActivities();
  // }, []);

  // const getLastActivity = (address) => {
  //   const filteredData = activities?.filter(
  //     (item) =>
  //       item?.addressToRecord === address &&
  //       item?.type === "Carbon Credits Transferred"
  //   );
  //   const lastData = filteredData[filteredData.length - 1];
  //   if (filteredData?.length > 0) {
  //     return `${lastData?.type}, by ${lastData?.addressToRecord} on ${moment(
  //       parseInt(lastData?.timestamp) * 1000
  //     ).format("Do MMM YYYY")} at ${moment(
  //       parseInt(lastData?.timestamp) * 1000
  //     ).format("hh:mm A")}`;
  //   } else {
  //     return "No Carbon Credit has been issued for this facility";
  //   }
  // };

  // const handleGreenCarbonCreditIssuance = async (facility) => {
  //   setLoading(true);
  //   try {
  //     if (
  //       facility?.conditions[facility?.conditions?.length - 1]
  //         ?.isCarbonCreditIssued
  //     ) {
  //       alert(
  //         "No new data available for carbon credit issuance for this facility"
  //       );
  //     } else if (
  //       !facility?.conditions[facility?.conditions?.length - 1]?.isValidated
  //     ) {
  //       alert("Validation has not been carried out for this Facility's data");
  //     } else if (
  //       !facility?.conditions[facility?.conditions?.length - 1]?.isVerified
  //     ) {
  //       alert("Verification has not been carried out for this Facility's data");
  //     } else if (
  //       facility?.conditions[facility?.conditions?.length - 1]?.isRejected
  //     ) {
  //       alert("This Facility's data failed Validation or Verification");
  //     } else {
  //       const response = await issueCarbonCredit(
  //         "green-project",
  //         facility?.plantInfo?.plantAddress,
  //         auth?.token
  //       );
  //       // console.log('response', response);
  //       if (response?.data?.statusCode === 200) {
  //         toast.success("CCRT Issued Successfully");
  //       } else if (response?.data?.errorMessage) {
  //         toast.error(response?.data?.errorMessage);
  //       } else {
  //         toast.error("Something went wrong");
  //       }
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //     toast.error("Something went wrong");
  //   }
  //   setLoading(false);
  // };
  // const handleInfrastructureCarbonCreditIssuance = async (facility) => {
  //   setLoading(true);
  //   try {
  //     if (
  //       facility?.conditions[facility?.conditions?.length - 1]
  //         ?.isCarbonCreditIssued
  //     ) {
  //       alert(
  //         "No new data available for carbon credit issuance for this facility"
  //       );
  //     } else if (
  //       !facility?.conditions[facility?.conditions?.length - 1]?.isValidated
  //     ) {
  //       alert("Validation has not been carried out for this Facility's data");
  //     } else if (
  //       !facility?.conditions[facility?.conditions?.length - 1]?.isVerified
  //     ) {
  //       alert("Verification has not been carried out for this Facility's data");
  //     } else if (
  //       facility?.conditions[facility?.conditions?.length - 1]?.isRejected
  //     ) {
  //       alert("This Facility's data failed Validation or Verification");
  //     } else {
  //       const response = await issueCarbonCredit(
  //         "infrastructure-change",
  //         facility?.plantInfo?.plantAddress,
  //         auth?.token
  //       );

  //       if (response?.data?.statusCode === 200) {
  //         toast.success("CCRT Issued Successfully");
  //       } else if (response?.data?.errorMessage) {
  //         toast.error(response?.data?.errorMessage);
  //       } else {
  //         toast.error("Something went wrong");
  //       }
  //     }
  //   } catch (error) {
  //     console.log("ERROR", error);
  //     toast.error("Something went wrong");
  //   }
  //   setLoading(false);
  // };

  return (
    // <div className="thirdparty_carboncredit">
    //   <div className="sidebar_container">
    //   <SideBar />
    //   </div>

    //   <div className="main">
    //     <Search />
    //     <div className="title">CCRT Issuance</div>
    //     {!generalLoading &&
    //       greenFacilities?.length === 0 &&
    //       infrastructureChange?.length === 0 && (
    //         <div className="empty_list">
    //           <img src={Illustration} alt="" />
    //           <div>No CCRT data here yet</div>
    //         </div>
    //       )}
    //     <div className="issuance">
    //       {greenFacilities?.length > 0 && (
    //         <div className="category">Green Project</div>
    //       )}
    //       {greenFacilities?.map((facility) => (
    //         <div className="issuance_card">
    //           <div className="title">
    //             Organizational Name - {facility?.plantInfo?.organizationName}
    //           </div>
    //           <div className="title_divider"></div>
    //           <div className="issuance_flex">
    //             <div>CO2 Tracked:</div>
    //             <div>
    //               {facility?.emissionData?.length > 0 &&
    //                 ethers.formatEther(
    //                   facility?.emissionData[
    //                     facility?.emissionData?.length - 1
    //                   ]?.totalGHGEmission?.toString()
    //                 )}
    //               tCO2e
    //             </div>
    //           </div>
    //           {/* <div className="divider"></div>
    //         <div className="issuance_flex">
    //           <div>CO2 Offset:</div>
    //           <div>0.42tCO2e</div>
    //         </div> */}
    //           <div className="divider"></div>
    //           <div className="issuance_flex">
    //             <div>CO2 Validation:</div>
    //             <div>
    //               {facility?.conditions[facility?.conditions?.length - 1]
    //                 ?.isValidated
    //                 ? "Completed"
    //                 : "Pending"}
    //             </div>
    //           </div>
    //           <div className="divider"></div>
    //           <div className="issuance_flex">
    //             <div>CO2 Verification:</div>
    //             <div>
    //               {facility?.conditions[facility?.conditions?.length - 1]
    //                 ?.isVerified
    //                 ? "Completed"
    //                 : "Pending"}
    //             </div>
    //           </div>
    //           <div className="divider"></div>
    //           <div className="issuance_flex">
    //             <div>Compliance with standard:</div>
    //             <div>
    //               {facility?.conditions[facility?.conditions?.length - 1]
    //                 ?.isRejected
    //                 ? "No"
    //                 : "Yes"}
    //             </div>
    //           </div>

    //           <button
    //             className="issuance_button"
    //             onClick={() => handleGreenCarbonCreditIssuance(facility)}
    //             disabled={loading}
    //           >
    //             {loading ? "Loading..." : "Issue CCRT"}
    //           </button>

    //           <div className="log">
    //             <span>Last Activity:</span>{" "}
    //             {getLastActivity(facility?.plantInfo?.plantAddress)}
    //           </div>
    //         </div>
    //       ))}

    //       {infrastructureChange?.length > 0 && (
    //         <div className="category">Infrastructure Change</div>
    //       )}
    //       {infrastructureChange?.map((facility) => (
    //         <div className="issuance_card">
    //           <div className="title">
    //             Organizational Name - {facility?.plantInfo?.organizationName}
    //           </div>
    //           <div className="title_divider"></div>
    //           <div className="issuance_flex">
    //             <div>CO2 Tracked:</div>
    //             <div>
    //               {facility?.emissionData?.length > 0 &&
    //                 ethers.formatEther(
    //                   facility?.emissionData[
    //                     facility?.emissionData?.length - 1
    //                   ]?.totalGHGEmissions?.toString()
    //                 )}
    //               tCO2e
    //             </div>
    //           </div>
    //           {/* <div className="divider"></div>
    //         <div className="issuance_flex">
    //           <div>CO2 Offset:</div>
    //           <div>0.42tCO2e</div>
    //         </div> */}
    //           <div className="divider"></div>
    //           <div className="issuance_flex">
    //             <div>CO2 Validation:</div>
    //             <div>
    //               {facility?.conditions[facility?.conditions?.length - 1]
    //                 ?.isValidated
    //                 ? "Completed"
    //                 : "Pending"}
    //             </div>
    //           </div>
    //           <div className="divider"></div>
    //           <div className="issuance_flex">
    //             <div>CO2 Verification:</div>
    //             <div>
    //               {facility?.conditions[facility?.conditions?.length - 1]
    //                 ?.isVerified
    //                 ? "Completed"
    //                 : "Pending"}
    //             </div>
    //           </div>
    //           <div className="divider"></div>
    //           <div className="issuance_flex">
    //             <div>Compliance with standard:</div>
    //             <div>
    //               {facility?.conditions[facility?.conditions?.length - 1]
    //                 ?.isRejected
    //                 ? "No"
    //                 : "Yes"}
    //             </div>
    //           </div>

    //           <button
    //             className="issuance_button"
    //             onClick={() =>
    //               handleInfrastructureCarbonCreditIssuance(facility)
    //             }
    //             disabled={loading}
    //           >
    //             {loading ? "Loading..." : "Issue CCRT"}
    //           </button>
    //           <div className="log">
    //             <span>Last Activity:</span>{" "}
    //             {getLastActivity(facility?.plantInfo?.plantAddress)}
    //           </div>
    //         </div>
    //       ))}
    //       {generalLoading && (
    //         <>
    //           <div className="category">
    //             <Skeleton
    //               width={120}
    //               height={20}
    //               borderRadius={8}
    //               style={{ marginBottom: "8px" }}
    //             />
    //           </div>
    //           <div className="issuance_card">
    //             <div className="title">
    //               <Skeleton
    //                 width={240}
    //                 height={20}
    //                 borderRadius={8}
    //                 style={{ marginBottom: "8px" }}
    //               />
    //             </div>
    //             <div className="title_divider"></div>
    //             <div className="issuance_flex">
    //               <div>
    //                 <Skeleton
    //                   width={80}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //               <div>
    //                 <Skeleton
    //                   width={140}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //             </div>
    //             {/* <div className="divider"></div>
    //         <div className="issuance_flex">
    //           <div>CO2 Offset:</div>
    //           <div>0.42tCO2e</div>
    //         </div> */}
    //             <div className="divider"></div>
    //             <div className="issuance_flex">
    //               <div>
    //                 <Skeleton
    //                   width={80}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //               <div>
    //                 <Skeleton
    //                   width={140}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //             </div>
    //             <div className="divider"></div>
    //             <div className="issuance_flex">
    //               <div>
    //                 <Skeleton
    //                   width={80}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //               <div>
    //                 <Skeleton
    //                   width={140}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //             </div>
    //             <div className="divider"></div>
    //             <div className="issuance_flex">
    //               <div>
    //                 <Skeleton
    //                   width={80}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //               <div>
    //                 <Skeleton
    //                   width={140}
    //                   height={20}
    //                   borderRadius={8}
    //                   style={{ marginBottom: "8px" }}
    //                 />
    //               </div>
    //             </div>

    //             {/* <div className="log"> */}
    //               {/* <Skeleton
    //                 width={"100%"}
    //                 height={20}
    //                 borderRadius={8}
    //                 style={{ marginBottom: "8px" }}
    //               /> */}
    //             {/* </div> */}
    //           </div>
    //         </>
    //       )}
    //     </div>
    //   </div>
    // </div>

    <div></div>
  );
};

export default CarbonCredit;
