import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SwipeableDrawer } from "@mui/material";
import SideBar from "./SideBar";
import { useParams } from "react-router-dom";

export default function MobileMenu() {
  const dispatch = useDispatch();
  const mobileMenu = useSelector((state) => state.mobileMenu);

  const pathname = useParams();




  const [right, setRight] = React.useState(true);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setRight(open);
  };






  return (
    <div>
      <SwipeableDrawer
        anchor="left"
        open={mobileMenu}
        onClose={() =>
          dispatch({
            type: "SET_MENU_VISIBLE",
            payload: false,
          })
        }
        onOpen={toggleDrawer(true)}
      >
        <SideBar />
      </SwipeableDrawer>
    </div>
  );
}
