import OpenAI from "openai";
import { useEffect } from "react";



function ChatApp() {
    const openai = new OpenAI({ apiKey: 'sk-proj-y9uBkRXwSbOT3bIEnRWpT3BlbkFJxlPQco3lTUP7XOiub077', dangerouslyAllowBrowser: true });

    const  main = async () => {
        const completion = await openai.chat.completions.create({
          messages: [{ role: "system", content: "You are a helpful assistant." }],
          model: "gpt-3.5-turbo",
        });
      
        console.log("yeah!!", completion.choices[0]);
      }

    useEffect(() => {
        main();
    }, [])

    return (
        <div>
            <div>
                {/* {messages.map((message, index) => (
                    <div key={index} className={message.isUser ? 'user-message' : 'bot-message'}>
                        {message.text}
                    </div>
                ))} */}
            </div>
            <form 
            // onSubmit={handleSubmit}
            >
                <input type="text" name="userInput" />
                <button type="submit">Send</button>
            </form>
        </div>
    );
}

export default ChatApp;
