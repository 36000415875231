import { useState } from "react";
import Search from "../../components/ThirdPartyDashboard/Search";
import SideBar from "../../components/ThirdPartyDashboard/SideBar";
import'../../styles/shared/Suggestion.scss'
import { toast } from "react-toastify";
import { submitSuggestion } from "../../apis/General/users";

const Suggestion = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    const response = await submitSuggestion(title, message);
    // console.log("response", response);
    if (response?.data?.statusCode === 200) {
      toast.success("Thank you for your suggestion");

      setTitle("");
      setMessage("");
    } else {
      toast.error(response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className="suggestion">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="suggestion_main">
        <Search />

        <form className="suggestion_form" onSubmit={handleSubmit}>
          <label htmlFor="title">Title</label>
          <input
            className="suggestion_input"
            name="title"
            id="title"
            type="text"
            placeholder="Title of your suggestion"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <label htmlFor="title">Message</label>
          <textarea
            name="message"
            id="message"
            placeholder="Give as many details as possible"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit" disabled={loading}>{loading ? 'Loading...' : 'Send'}</button>
        </form>
      </div>
    </div>
  );
};

export default Suggestion;
