import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import MyPDF from './MyPdf';



export default function TestPdf () {
    return (
      <div >
        <PDFViewer style={{width: "100vw", backgroundColor: "yellow", height: "500px"}}>
        <MyPDF />
        </PDFViewer>
      <PDFDownloadLink document={<MyPDF />} fileName="somename.pdf">
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
      </PDFDownloadLink>
    </div>
    )
}