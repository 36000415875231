import Header from "../../components/shared/Header";
import "../../styles/shared/Partners.scss";
import Partner1 from "../../images/image 67.png";
import Partner2 from "../../images/image 69.png";
import Partner3 from "../../images/image 70.png";
import Partner4 from "../../images/image 71.png";
import Partner5 from "../../images/image 80.png";
import Partner6 from "../../images/image 68.png";
import Footer from "../../components/shared/Footer";
const Partners = () => {
  return (
    <div className="partners">
      <Header />
      <div className="partners_inner">
        <img src={Partner1} alt="" />
        <div>
          <div className="title">United Nations</div>
          <div className="subtitle">
            Lorem ipsum dolor sit amet consectetur. Quis odio eget in fringilla.
            Viverra sodales aliquam montes gravida vitae enim. Semper tortor
            vivamus vestibulum imperdiet. Lacus tellus at eu justo a. Eget
            egestas amet quam eget diam adipiscing. Lorem ipsum dolor sit amet
            consectetur. Quis odio eget in fringilla. Viverra sodales aliquam
            montes gravida vitae enim. Semper tortor vivamus vestibulum
            imperdiet. Lacus tellus at eu justo a. Eget egestas amet quam eget
            diam adipiscing.
          </div>
        </div>
        <img src={Partner5} alt="" />
        <div>
          <div className="title">E.C.A</div>
          <div className="subtitle">
            Lorem ipsum dolor sit amet consectetur. Quis odio eget in fringilla.
            Viverra sodales aliquam montes gravida vitae enim. Semper tortor
            vivamus vestibulum imperdiet. Lacus tellus at eu justo a. Eget
            egestas amet quam eget diam adipiscing. Lorem ipsum dolor sit amet
            consectetur. Quis odio eget in fringilla. Viverra sodales aliquam
            montes gravida vitae enim. Semper tortor vivamus vestibulum
            imperdiet. Lacus tellus at eu justo a. Eget egestas amet quam eget
            diam adipiscing.
          </div>
        </div>
        <img src={Partner2} alt="" />
        <div>
          <div className="title">Africa Climate Ventures</div>
          <div className="subtitle">
            Lorem ipsum dolor sit amet consectetur. Quis odio eget in fringilla.
            Viverra sodales aliquam montes gravida vitae enim. Semper tortor
            vivamus vestibulum imperdiet. Lacus tellus at eu justo a. Eget
            egestas amet quam eget diam adipiscing. Lorem ipsum dolor sit amet
            consectetur. Quis odio eget in fringilla. Viverra sodales aliquam
            montes gravida vitae enim. Semper tortor vivamus vestibulum
            imperdiet. Lacus tellus at eu justo a. Eget egestas amet quam eget
            diam adipiscing.
          </div>
        </div>
        <img src={Partner4} alt="" />
        <div>
          <div className="title">Verra</div>
          <div className="subtitle">
            Lorem ipsum dolor sit amet consectetur. Quis odio eget in fringilla.
            Viverra sodales aliquam montes gravida vitae enim. Semper tortor
            vivamus vestibulum imperdiet. Lacus tellus at eu justo a. Eget
            egestas amet quam eget diam adipiscing. Lorem ipsum dolor sit amet
            consectetur. Quis odio eget in fringilla. Viverra sodales aliquam
            montes gravida vitae enim. Semper tortor vivamus vestibulum
            imperdiet. Lacus tellus at eu justo a. Eget egestas amet quam eget
            diam adipiscing.
          </div>
        </div>
        <img src={Partner3} alt="" />
        <div>
          <div className="title">Climate Investment Funds</div>
          <div className="subtitle">
            Lorem ipsum dolor sit amet consectetur. Quis odio eget in fringilla.
            Viverra sodales aliquam montes gravida vitae enim. Semper tortor
            vivamus vestibulum imperdiet. Lacus tellus at eu justo a. Eget
            egestas amet quam eget diam adipiscing. Lorem ipsum dolor sit amet
            consectetur. Quis odio eget in fringilla. Viverra sodales aliquam
            montes gravida vitae enim. Semper tortor vivamus vestibulum
            imperdiet. Lacus tellus at eu justo a. Eget egestas amet quam eget
            diam adipiscing.
          </div>
        </div>
        <img src={Partner6} alt="" />
        <div>
          <div className="title">Vertree</div>
          <div className="subtitle">
            Lorem ipsum dolor sit amet consectetur. Quis odio eget in fringilla.
            Viverra sodales aliquam montes gravida vitae enim. Semper tortor
            vivamus vestibulum imperdiet. Lacus tellus at eu justo a. Eget
            egestas amet quam eget diam adipiscing. Lorem ipsum dolor sit amet
            consectetur. Quis odio eget in fringilla. Viverra sodales aliquam
            montes gravida vitae enim. Semper tortor vivamus vestibulum
            imperdiet. Lacus tellus at eu justo a. Eget egestas amet quam eget
            diam adipiscing.
          </div>
        </div>
        
      </div>

      <Footer />
    </div>
  );
};

export default Partners;
