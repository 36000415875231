import { useSelector } from 'react-redux';
import '../../styles/IoT/UpdateFirmware.scss'
import axios from 'axios';
import { useRef } from 'react';
import { useState } from 'react';
import { getIotStatus, updateIotStatus } from '../../apis/Iot';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export default function UpdateFirmware() {
    const auth = useSelector((state) => state.auth);
    const [uplaodLoading, setUploadLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firmware, setFirmware] = useState("");
    const [iotStatus, setIotStatus] = useState("");
    const mediaRef = useRef(null);


    const fileUpload = (e, documentTypeSent) => {
        setUploadLoading(true);
    
        let files = e.target.files;
    
        const fileToUri = (file, cb) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            cb(null, reader.result);
          };
          reader.onerror = function (error) {
            cb(error, null);
          };
        };
    
        if (files) {
          for (let i = 0; i < files.length; i++) {
            fileToUri(files[0], (err, result) => {
              if (result) {
                axios
                  .post(
                    `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                    {
                      media_type: "bin",
                      folder: "documents",
                      user_id: auth?.userInfo?.id,
                      contents: [result],
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${auth ? auth.token : ""}`,
                      },
                    }
                  )
                  .then((response) => {
                    setFirmware(response?.data?.body?.data[0]);
                    alert("Firmware updated")
    
                    setUploadLoading(false);
                  })
                  .catch((err) => {
                    setUploadLoading(false);
                  });
              }
            });
          }
        }
      };

      const handleUpdateIotStatus = async () => {
        setLoading(true)
        if(iotStatus === "") {
          alert("IoT status can not be empty")
          setLoading(false)
          return;
        }
        const response = await updateIotStatus(iotStatus === "Active" ? true : false, auth?.token);
        console.log('response', response);
        if(response?.data?.statusCode === 200) {
          toast.success("Status updated successfully");
        }
        setLoading(false)
      } 

      const handleGetIotStatus = async () => {
        const response = await getIotStatus();

        console.log('getIotStatus', response);
        if(response?.status === 200) {
          response?.data === true ? setIotStatus("Active") : setIotStatus("Inactive")
        }
      }

      useEffect (() => {
        handleGetIotStatus();
      }, [])
    return (
        <div className="update-firmware">
            
            <label>
                <div className="update-firmware__title">
<div>
<svg width="45" height="44" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.2983 10.9514C14.1083 10.9514 13.9183 10.8814 13.7683 10.7314C13.4783 10.4414 13.4783 9.96141 13.7683 9.67141L21.9683 1.47141C22.2583 1.18141 22.7383 1.18141 23.0283 1.47141C23.3183 1.76141 23.3183 2.24141 23.0283 2.53141L14.8283 10.7314C14.6883 10.8714 14.4983 10.9514 14.2983 10.9514Z" fill="#009B07"/>
<path d="M18.33 11.7519H13.5C13.09 11.7519 12.75 11.4119 12.75 11.0019V6.17188C12.75 5.76188 13.09 5.42188 13.5 5.42188C13.91 5.42188 14.25 5.76188 14.25 6.17188V10.2519H18.33C18.74 10.2519 19.08 10.5919 19.08 11.0019C19.08 11.4119 18.74 11.7519 18.33 11.7519Z" fill="#009B07"/>
<path d="M15.5 22.75H9.5C4.07 22.75 1.75 20.43 1.75 15V9C1.75 3.57 4.07 1.25 9.5 1.25H11.5C11.91 1.25 12.25 1.59 12.25 2C12.25 2.41 11.91 2.75 11.5 2.75H9.5C4.89 2.75 3.25 4.39 3.25 9V15C3.25 19.61 4.89 21.25 9.5 21.25H15.5C20.11 21.25 21.75 19.61 21.75 15V13C21.75 12.59 22.09 12.25 22.5 12.25C22.91 12.25 23.25 12.59 23.25 13V15C23.25 20.43 20.93 22.75 15.5 22.75Z" fill="#009B07"/>
</svg>
</div>
<h1>Upload firmware file to update</h1>
                </div>

                <input
                        type="file"
                        multiple
                        // accept='video/*'
                        accept=".bin"
                        hidden
                        onChange={fileUpload}
                        ref={mediaRef}
                      />
                      <button className="update-firmware__button" disabled={uplaodLoading}>{uplaodLoading ? "Loading..." : "Update"}</button>
            </label>
<label htmlFor="" className="update-firmware__label"><h1>Update firmware status</h1></label>

<div className="update-firmware__input">
            <select name="" id="" value={iotStatus} onChange={(e) => setIotStatus(e.target.value)}>
<option value="">Select status</option>
<option value="Active">Active</option>
<option value="Inactive">Inactive</option>
            </select>

            <button
            onClick={handleUpdateIotStatus}
            disabled={loading}
            >{loading ? "Loading..." : "Update status"}</button>
</div>


            
        </div>
    )
}