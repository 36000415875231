import axios from "axios";

export const updateIotStatus = async (
    status,
    token
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/iot_device/v1/update`,
        {
         status
        },
        config
      );
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };

export const getIotStatus = async (
    token
  ) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/iot_device/v1/get`,
       
        config
      );
  
      return res;
    } catch (error) {
      console.log("ERROR", error);
    }
  };