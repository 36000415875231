import Search from "./Search";
import SideBar from "./SideBar";
import '../../styles/UserDashboard/Resources.scss';
import { useNavigate } from "react-router-dom";

export default function UserResources() {
  const navigate = useNavigate();
  return (
    <div className="user-resources">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="user-resources__grid">
          <div className="user-resources__grid__card"
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/user/resource/0`)
          }}
          >
            <div className="grid-card__title">Emissions Management</div>
            <div className="grid-card__content">
              <div className="grid-card__content-title">Baseline emissions: </div>
              <div className="grid-card__content-subtitle">The reference level of greenhouse gas emissions used as a benchmark against which emission reductions or increases are measured. </div>
              <div className="grid-card__content-divider"></div>
              <div className="grid-card__content-title">Project emissions: </div>
              <div className="grid-card__content-subtitle">The reference level of greenhouse gas emissions used as a benchmark against which emission reductions or increases are measured. </div>
            </div>
              <div className="grid-card__content-bottomtitle">
                <div>View all emissions management resources</div>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66663 7.50005C2.53402 7.50005 2.40684 7.55273 2.31307 7.6465C2.2193 7.74026 2.16663 7.86744 2.16663 8.00005C2.16663 8.13266 2.2193 8.25983 2.31307 8.3536C2.40684 8.44737 2.53402 8.50005 2.66663 8.50005H8.83329V12C8.83338 12.0989 8.86275 12.1955 8.9177 12.2776C8.97264 12.3597 9.0507 12.4238 9.14201 12.4616C9.23332 12.4994 9.33378 12.5093 9.43072 12.49C9.52765 12.4708 9.6167 12.4232 9.68663 12.3534L13.6866 8.35338C13.7803 8.25963 13.8329 8.13255 13.8329 8.00005C13.8329 7.86755 13.7803 7.74047 13.6866 7.64672L9.68663 3.64672C9.6167 3.57688 9.52765 3.52933 9.43072 3.51007C9.33378 3.49082 9.23332 3.50072 9.14201 3.53852C9.0507 3.57633 8.97264 3.64035 8.9177 3.72249C8.86275 3.80464 8.83338 3.90122 8.83329 4.00005V7.50005H2.66663Z" fill="white"/>
</svg>

              </div>
          </div>
          <div className="user-resources__grid__card"
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/user/resource/1`)
          }}
          >
            <div className="grid-card__title">Verification & validation</div>
            <div className="grid-card__content">
              <div className="grid-card__content-title">Verification:</div>
              <div className="grid-card__content-subtitle">The independent and systematic examination of greenhouse gas emission reductions or removals claimed by a project to ensure their accuracy.</div>
              <div className="grid-card__content-divider"></div>
              <div className="grid-card__content-title">Validation:</div>
              <div className="grid-card__content-subtitle">The process of assessing and confirming that a proposed project or program is in accordance with predefined criteria.</div>
            </div>
              <div className="grid-card__content-bottomtitle">
                <div>View all verification & validation resources</div>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66663 7.50005C2.53402 7.50005 2.40684 7.55273 2.31307 7.6465C2.2193 7.74026 2.16663 7.86744 2.16663 8.00005C2.16663 8.13266 2.2193 8.25983 2.31307 8.3536C2.40684 8.44737 2.53402 8.50005 2.66663 8.50005H8.83329V12C8.83338 12.0989 8.86275 12.1955 8.9177 12.2776C8.97264 12.3597 9.0507 12.4238 9.14201 12.4616C9.23332 12.4994 9.33378 12.5093 9.43072 12.49C9.52765 12.4708 9.6167 12.4232 9.68663 12.3534L13.6866 8.35338C13.7803 8.25963 13.8329 8.13255 13.8329 8.00005C13.8329 7.86755 13.7803 7.74047 13.6866 7.64672L9.68663 3.64672C9.6167 3.57688 9.52765 3.52933 9.43072 3.51007C9.33378 3.49082 9.23332 3.50072 9.14201 3.53852C9.0507 3.57633 8.97264 3.64035 8.9177 3.72249C8.86275 3.80464 8.83338 3.90122 8.83329 4.00005V7.50005H2.66663Z" fill="white"/>
</svg>

              </div>
          </div>
          <div className="user-resources__grid__card"
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/user/resource/2`)
          }}
          >
            <div className="grid-card__title">Carbon programs & initiatives</div>
            <div className="grid-card__content">
              <div className="grid-card__content-title">Carbon programme: </div>
              <div className="grid-card__content-subtitle">A comprehensive and organized set of activities, projects, and methodologies aimed at achieving greenhouse gas emission reductions.</div>
              <div className="grid-card__content-divider"></div>
              <div className="grid-card__content-title">Carbon project:</div>
              <div className="grid-card__content-subtitle">An initiative specifically designed to reduce greenhouse gas emissions, with the intention of generating carbon incentives.</div>
            </div>
              <div className="grid-card__content-bottomtitle">
                <div>View all carbon programs & initiatives resources</div>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66663 7.50005C2.53402 7.50005 2.40684 7.55273 2.31307 7.6465C2.2193 7.74026 2.16663 7.86744 2.16663 8.00005C2.16663 8.13266 2.2193 8.25983 2.31307 8.3536C2.40684 8.44737 2.53402 8.50005 2.66663 8.50005H8.83329V12C8.83338 12.0989 8.86275 12.1955 8.9177 12.2776C8.97264 12.3597 9.0507 12.4238 9.14201 12.4616C9.23332 12.4994 9.33378 12.5093 9.43072 12.49C9.52765 12.4708 9.6167 12.4232 9.68663 12.3534L13.6866 8.35338C13.7803 8.25963 13.8329 8.13255 13.8329 8.00005C13.8329 7.86755 13.7803 7.74047 13.6866 7.64672L9.68663 3.64672C9.6167 3.57688 9.52765 3.52933 9.43072 3.51007C9.33378 3.49082 9.23332 3.50072 9.14201 3.53852C9.0507 3.57633 8.97264 3.64035 8.9177 3.72249C8.86275 3.80464 8.83338 3.90122 8.83329 4.00005V7.50005H2.66663Z" fill="white"/>
</svg>

              </div>
          </div>
          <div className="user-resources__grid__card"
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/user/resource/3`)
          }}
          >
            <div className="grid-card__title">Project types</div>
            <div className="grid-card__content">
              <div className="grid-card__content-title">Fuel change project:</div>
              <div className="grid-card__content-subtitle">A structured program that certifies and validates the environmental performance of projects in terms of their greenhouse gas emission reductions or sustainability practices.</div>
              <div className="grid-card__content-divider"></div>
              <div className="grid-card__content-title">Non-green/ Infrastructure change projects:</div>
              <div className="grid-card__content-subtitle">Projects involving modifications, improvements often with the aim of enhancing efficiency or reducing environmental impacts.</div>
            </div>
              <div className="grid-card__content-bottomtitle">
                <div>View all project types resources</div>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66663 7.50005C2.53402 7.50005 2.40684 7.55273 2.31307 7.6465C2.2193 7.74026 2.16663 7.86744 2.16663 8.00005C2.16663 8.13266 2.2193 8.25983 2.31307 8.3536C2.40684 8.44737 2.53402 8.50005 2.66663 8.50005H8.83329V12C8.83338 12.0989 8.86275 12.1955 8.9177 12.2776C8.97264 12.3597 9.0507 12.4238 9.14201 12.4616C9.23332 12.4994 9.33378 12.5093 9.43072 12.49C9.52765 12.4708 9.6167 12.4232 9.68663 12.3534L13.6866 8.35338C13.7803 8.25963 13.8329 8.13255 13.8329 8.00005C13.8329 7.86755 13.7803 7.74047 13.6866 7.64672L9.68663 3.64672C9.6167 3.57688 9.52765 3.52933 9.43072 3.51007C9.33378 3.49082 9.23332 3.50072 9.14201 3.53852C9.0507 3.57633 8.97264 3.64035 8.9177 3.72249C8.86275 3.80464 8.83338 3.90122 8.83329 4.00005V7.50005H2.66663Z" fill="white"/>
</svg>

              </div>
          </div>
          <div className="user-resources__grid__card"
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/user/resource/4`)
          }}
          >
            <div className="grid-card__title">Measurement & tracking</div>
            <div className="grid-card__content">
              <div className="grid-card__content-title">Methodology:</div>
              <div className="grid-card__content-subtitle">A systematic and standardized set of rules, procedures, and calculations used to quantify and verify greenhouse gas emissions or removals associated with a particular project or activity. </div>
              <div className="grid-card__content-divider"></div>
              <div className="grid-card__content-title">Carbon tracking:</div>
              <div className="grid-card__content-subtitle">The systematic monitoring, recording, and reporting of an entity's greenhouse gas emissions over time.</div>
            </div>
              <div className="grid-card__content-bottomtitle">
                <div>View all measurement & tracking resources</div>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66663 7.50005C2.53402 7.50005 2.40684 7.55273 2.31307 7.6465C2.2193 7.74026 2.16663 7.86744 2.16663 8.00005C2.16663 8.13266 2.2193 8.25983 2.31307 8.3536C2.40684 8.44737 2.53402 8.50005 2.66663 8.50005H8.83329V12C8.83338 12.0989 8.86275 12.1955 8.9177 12.2776C8.97264 12.3597 9.0507 12.4238 9.14201 12.4616C9.23332 12.4994 9.33378 12.5093 9.43072 12.49C9.52765 12.4708 9.6167 12.4232 9.68663 12.3534L13.6866 8.35338C13.7803 8.25963 13.8329 8.13255 13.8329 8.00005C13.8329 7.86755 13.7803 7.74047 13.6866 7.64672L9.68663 3.64672C9.6167 3.57688 9.52765 3.52933 9.43072 3.51007C9.33378 3.49082 9.23332 3.50072 9.14201 3.53852C9.0507 3.57633 8.97264 3.64035 8.9177 3.72249C8.86275 3.80464 8.83338 3.90122 8.83329 4.00005V7.50005H2.66663Z" fill="white"/>
</svg>

              </div>
          </div>
          <div className="user-resources__grid__card"
          onClick={() => {
            window.scrollTo(0, 0)
            navigate(`/user/resource/4`)
          }}
          >
            <div className="grid-card__title">CCRT issuance</div>
            <div className="grid-card__content">
              <div className="grid-card__content-title">CCRT:</div>
              <div className="grid-card__content-subtitle">A tradable certificate or permit representing the right to emit one ton of carbondioxide or its equivalent. Carbon incentives are often generated by projects that reduce greenhouse gas emissions.</div>
              <div className="grid-card__content-divider"></div>
              <div className="grid-card__content-title">Credit issued:</div>
              <div className="grid-card__content-subtitle">The formal recognition and issuance of carbon incentives or other environmental certificates to a project that has successfully reduced or removed greenhouse gas emissions.</div>
            </div>
              <div className="grid-card__content-bottomtitle">
                <div>View all carbon incentivising resources</div>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.66663 7.50005C2.53402 7.50005 2.40684 7.55273 2.31307 7.6465C2.2193 7.74026 2.16663 7.86744 2.16663 8.00005C2.16663 8.13266 2.2193 8.25983 2.31307 8.3536C2.40684 8.44737 2.53402 8.50005 2.66663 8.50005H8.83329V12C8.83338 12.0989 8.86275 12.1955 8.9177 12.2776C8.97264 12.3597 9.0507 12.4238 9.14201 12.4616C9.23332 12.4994 9.33378 12.5093 9.43072 12.49C9.52765 12.4708 9.6167 12.4232 9.68663 12.3534L13.6866 8.35338C13.7803 8.25963 13.8329 8.13255 13.8329 8.00005C13.8329 7.86755 13.7803 7.74047 13.6866 7.64672L9.68663 3.64672C9.6167 3.57688 9.52765 3.52933 9.43072 3.51007C9.33378 3.49082 9.23332 3.50072 9.14201 3.53852C9.0507 3.57633 8.97264 3.64035 8.9177 3.72249C8.86275 3.80464 8.83338 3.90122 8.83329 4.00005V7.50005H2.66663Z" fill="white"/>
</svg>

              </div>
          </div>
         
        </div>
      </div>
    </div>
  );
}
