import Header from "../../components/shared/Header";
import "../../styles/shared/Glossary.scss";
import Footer from "../../components/shared/Footer";
import { useParams } from "react-router-dom";
import glossary from '../../components/shared/glossary'
export default function Glossary() {
  const pathname = useParams();


  return (
    <div className="glossary">
      <Header />
      <div className="hero"></div>
      <div className="content">
        <div className="content_title">{glossary[pathname?.index]?.title}</div>
        <div className="content_body">
          {glossary[pathname?.index]?.contentBody?.map((item) => (
            <>
              <div className="body_title">{item?.bodyTitle}</div>
              <div className="body_content">
                {item?.bodyContent}
              </div>
            </>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}
