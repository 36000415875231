import { useEffect, useState } from "react";
import { getUserInfo } from "../../apis/General/users";
import "../../styles/AdminDashboard/Search.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notifications from "../shared/Notifications";
import Logo from "../../images/closecarbon_logo_1-removebg-preview 1.png";

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

 

  const handeMobileMenuOpen = () => {
    console.log("hello");
    dispatch({
      type: "SET_MENU_VISIBLE",
      payload: true,
    });
  };

  return (
    <div className="admin_search_container">
      <div>
        <div className="mobile_menu">
          <svg
            onClick={handeMobileMenuOpen}
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1H17"
              stroke="#667185"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M1 5H17"
              stroke="#667185"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M1 9H17"
              stroke="#667185"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>

          <img
            src={Logo}
            alt=""
            className="logo pointer"
            onClick={() => navigate("/dashboard")}
          />
        </div>
      </div>
     

    

    </div>
  );
};

export default Search;
