import axios from "axios";
export const updateOffsetGoal = async (offsetGoal, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/core/v1/update-user-info?update=offsetGoal`,
      { offsetGoal },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getTotalOffset = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/core/v1/total-offset`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const getLatestWeekOffset = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/core/v1/weekly-emission`,
      config
    );

    // console.log('response from api', res);

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const submitKycBasicGreen = async (
  facilityCategory,
  subscriptionPlan,
  greenProjectName,
  greenProjectDescription,
  greenProjectLocation,
  greenProjectLicenseId,
  greenProjectStartDate,
  greenProjectEndDate,
  renewableEnergySolutions,
  baseLineScenario,
  baseLineEnergySource,
  additionalInfomation,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc`,
      {
        facilityCategory,
        subscriptionPlan,
        greenProjectName,
        greenProjectDescription,
        greenProjectLocation,
        greenProjectLicenseId,
        greenProjectStartDate,
        greenProjectEndDate,
        renewableEnergySolutions,
        baseLineScenario,
        baseLineEnergySource,
        additionalInfomation
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitKycBasicNongreen = async (
  facilityCategory,
    subscriptionPlan,
    facilityName,
    facilityDescription,
    facilityLocation,
    facilityStartDate,
    facilityEndDate,
    projectFuelSource,
    baseLineScenario,
    baseLineFuelSource,
    additionalInfomation,
    sustainabilitySolution,
  token
) => {
  console.log({
    facilityCategory,
    subscriptionPlan,
    facilityName,
    facilityDescription,
    facilityLocation,
    facilityStartDate,
    facilityEndDate,
    projectFuelSource,
    baseLineScenario,
    baseLineFuelSource,
    additionalInfomation,
    sustainabilitySolution,
  });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc`,
      {
        facilityCategory,
    subscriptionPlan,
    facilityName,
    facilityDescription,
    facilityLocation,
    facilityStartDate,
    facilityEndDate,
    projectFuelSource,
    baseLineScenario,
    baseLineFuelSource,
    additionalInfomation,
    sustainabilitySolution
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitKycGovernanceMonitoring = async (
  facilityCategory,
    subscriptionPlan,
    facilityName,
    facilityDescription,
    facilityLocation,
    facilityStartDate,
    facilityEndDate,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc`,
      {
        facilityCategory,
    subscriptionPlan,
    facilityName,
    facilityDescription,
    facilityLocation,
    facilityStartDate,
    facilityEndDate
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitKycGovernanceNongreen = async (
  facilityCategory,
    subscriptionPlan,
    facilityName,
    facilityDescription,
    facilityLocation,
    facilityStartDate,
    facilityEndDate,
    baseLineScenario,
    baseLineFuelSource,
    additionalInfomation,
    sustainabilitySolution,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc`,
      {
        facilityCategory,
    subscriptionPlan,
    facilityName,
    facilityDescription,
    facilityLocation,
    facilityStartDate,
    facilityEndDate,
    baseLineScenario,
    baseLineFuelSource,
    additionalInfomation,
    sustainabilitySolution
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const submitKycGovernanceGreen = async (
  facilityCategory,
  subscriptionPlan,
  greenProjectName,
  greenProjectDescription,
  greenProjectLocation,
  greenProjectLicenseId,
  greenProjectStartDate,
  greenProjectEndDate,
  renewableEnergySolutions,
  baseLineScenario,
  baseLineEnergySource,
  additionalInfomation,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-kyc`,
      {
        facilityCategory,
        subscriptionPlan,
        greenProjectName,
        greenProjectDescription,
        greenProjectLocation,
        greenProjectLicenseId,
        greenProjectStartDate,
        greenProjectEndDate,
        renewableEnergySolutions,
        baseLineScenario,
        baseLineEnergySource,
        additionalInfomation
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const emissionDataInRange = async (from, to, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/core/v1/emission-in-range?from=1708349212000&end=1708350486000`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const getAvailableDates = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/iot/v1/get-iot-dates`,
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const bookDate = async (date, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/iot/v1/update-iot-installation-dates?update=booking&date_value=${date}`,
      {
        date,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const exchnageCcrt = async (ccrtValue, token) => {
  console.log("ccrtValue", ccrtValue, typeof ccrtValue);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/ccrt/v1/user/trade`,
      {
        ccrtValue,
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const requestVerificationToken = async (applicant_id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("electric birds", {
    applicant_id, token
  });
  
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/onfido/request-token`,
      {
        applicant_id,
        referrer: "closecarbon.com/dashboard"
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
