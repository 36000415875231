import { useEffect, useState } from "react";
import SideBar from "../../components/AdminDashboard/SideBar";
import "../../styles/AdminDashboard/NdcRequests.scss";
import Search from "../../components/AdminDashboard/Search";
import { getNdcRequests, updateNdcRequest } from "../../apis/AdminDashboard/users";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getDatabase, ref, push } from "firebase/database";


export function NdcRequests() {
  const auth = useSelector((state) => state.auth);
  const [reasonType, setReasonType] = useState("pending");
  const [selectedReason, setSelectedReason] = useState(10);
  const [reasons, setReasons] = useState([]);
  const [approvedReasons, setApprovedReasons] = useState([]);
  const [rejectedReasons, setRejectedReasons] = useState([]);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const db = getDatabase();


  const getRequest = async () => {
    setLoading(true)
    const response = await getNdcRequests(auth?.token);
    console.log('response getRequests', response);
    const tempApproved = response?.data?.message?.filter((reason) => reason?.status === "approved");
    setApprovedReasons(tempApproved);
    const tempRejected = response?.data?.message?.filter((reason) => reason?.status === "rejected");
    setRejectedReasons(tempRejected);
    const tempPending = response?.data?.message?.filter((reason) => reason?.status === "pending");
    setReasons(tempPending);
    setLoading(false)

  }

  useEffect(() => {
    getRequest()
  }, [])

  const handleApproveRequest = async (id, user) => {
    setAcceptLoading(true)
    const response = await updateNdcRequest("approved", id, user, auth?.token);
    console.log('response updateNdcRequest', response);

    if(response?.data?.statusCode === 200) {
      toast.success(response?.data?.message);
      push(ref(db, "notifications"), {
        type: "NDC request approved",
        read: false,
        user,
        timestamp: Date.now(),
      });
      getRequest();
    }else {
      toast.error("Something went wrong")
    }
    setAcceptLoading(false)
  }
  
  const handleRejectRequest = async (id, user) => {
    setRejectLoading(true)
    const response = await updateNdcRequest("reject", id, user, auth?.token);
    console.log('response updateNdcRequest', response);

    if(response?.data?.statusCode === 200) {
      toast.success(response?.data?.message)
      getRequest();
    }else {
      toast.error("Something went wrong")
    }
    setRejectLoading(false)
  }


console.log('approvedReasons', approvedReasons);


  return (
    <div className="ndc-requests">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="title">NDCs-S</div>

        <div className="requestNdc-table">
          <div className="requestNdc-table__header">
            <div>
              <button
                className={reasonType === "pending" && "active"}
                onClick={() => setReasonType("pending")}
              >
                Request
              </button>
              {!reasonType && <div></div>}
              <button
                className={reasonType === "approved" && "active"}
                onClick={() => setReasonType("approved")}
              >
                Accepted
              </button>
              {!reasonType && <div></div>}
              <button
                className={reasonType === "rejected" && "active"}
                onClick={() => setReasonType("rejected")}
              >
                Denied
              </button>
            </div>
            {/* <div>
              <button
                className={!reasonType && "active"}
                onClick={() => setReasonType(null)}
              >
                View all
              </button>
            </div> */}
          </div>

         {loading ? <>
          <div className="requestNdc-table__card">
          <Skeleton width="100%" height={20} borderRadius={4} />
          </div>
          <div className="requestNdc-table__card">
          <Skeleton width="100%" height={20} borderRadius={4} />
          </div>
          <div className="requestNdc-table__card">
          <Skeleton width="100%" height={20} borderRadius={4} />
          </div>
          <div className="requestNdc-table__card">
          <Skeleton width="100%" height={20} borderRadius={4} />
          </div>
         </> : ""}

          {reasonType === "pending" && reasons?.map((reason, index) => 
        <div className="requestNdc-table__card">
        <div className="requestNdc-table__card__flex">
          <div>Organisation Name - {reason?.facilityName}</div>
          <svg className={selectedReason === index &&"requestNdc-table__card__flex__svg"} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
          onClick={() => setSelectedReason(index)}
          >
<circle cx="12" cy="12" r="12" transform="matrix(1 0 0 -1 0 24)" fill="#D0D5DD"/>
<path d="M8.66732 13.3333L12.0007 10L15.334 13.3333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        </div>

        <div className="requestNdc-table__card__divider"></div>

        {selectedReason === index && <div className="requestNdc-table__card__reason">
        <div className="requestNdc-table__card__reason__title">
        Reason for request:
        </div>
        <div className="requestNdc-table__card__reason__subtitle"
        dangerouslySetInnerHTML={{ __html: reason?.requestPurpose }}
        >
        </div>
        <div className="requestNdc-table__card__reason__button-group">
          <button onClick={() => handleRejectRequest(reason?.id, reason?.user)}>{rejectLoading ? "Loading..." : "Deny"}</button>
          <button onClick={() => handleApproveRequest(reason?.id, reason?.user)}>{acceptLoading ? "Loading..." : "Accept"}</button>
        </div>
        </div>}
        </div>
        )}
          {reasonType === "approved" && approvedReasons?.map((reason, index) => 
        <div className="requestNdc-table__card">
        <div className="requestNdc-table__card__flex">
          <div>Organisation Name - {reason?.facilityName}</div>
          
          <button className="requestNdc-table__card__flex__accepted">Accepted</button>


        </div>

      
        </div>
        )}
          {reasonType === "rejected" && rejectedReasons?.map((reason, index) => 
        <div className="requestNdc-table__card">
        <div className="requestNdc-table__card__flex">
          <div>Organisation Name - {reason?.facilityName}</div>
          <button className="requestNdc-table__card__flex__denied">Denied</button>


        </div>


        
        </div>
        )}
        </div>
      </div>
    </div>
  );
}
