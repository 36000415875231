// const NETWORKS = {
//   11155111: {
//     CARBON_MONITORING: "",
//   },
//   80001: {
//     /* Mumbai addresses */
//     CARBON_MONITORING_V1: "0x9E1591390BEB1CdFa92E01Bc6602d6a78cA11a7a",
//     CARBON_MONITORING_NFT: "0x2f02d4D40694532EB80AD94455C5abC39f10C991",
//     GREEN_PROJECT: "0x5B6C460046147aF8bD35860Cd8f91b3b2B494a82",
//     INFRASTRUCTURE_CHANGE: "0xE398A7039A2CA5780b91311cEb844ac047196631",
//     GREEN_PROJECT_NFT: "0x2a8A6ab48630406727CF9a2665Ba44deFB23d57F",
//     INFRASTRUCTURE_CHANGE_NFT: "0x9cf9EC9a69C260f93003419d3A36ac66a5227792",
//     CLOSE_CARBON_TOKEN: "0xCd85a96bB3D5Af7092AaC9a381edF849371cB629",
//   },
//   1: {
//     /* Mainnet addresses */ CARBON_MONITORING: "",
//   },
// };

// export { NETWORKS };

const NETWORKS = {
  137: {
    BASIC_GREEN_PROJECT: "0xbf983A8094289120bc65D8d93D5974bEF078cB7f",
    BASIC_INFRASTRUCTURE_CHANGE: "0xF8c8DBAF8BaAd7A5171A3B8a4109D4716Fb8860a",
    GOVERNANCE_GREEN_PROJECT: "0xeeEB6faede309C4aB989f67F7c77815E1298d9A8",
    GOVERNANCE_INFRASTRUCTURE_CHANGE: "0xc4220E90aB6144B08209A8f0e37E0504B25ff315",
    GOVERNANCE_CARBON_MONITORING: "0xF7f008A1e5A827C326fA6284c97c59c3Fe660B06",
    GREEN_PROJECT_NFT: "0x39733AFe18884CE417975F532237fDA84bB8978A",
    INFRASTRUCTURE_CHANGE_NFT: "0x5cD2f2DA60B3E278472595d98e82013aFCB221F3",
    CLOSE_CARBON_TOKEN: "0x4e9240604452d4454153bF59dE204E341186CC83",
    DONATION_CERTIFICATE: "0xaA1D0B0029D8a4169ef42514E6B8c3B93578fDaf",
  },
  // 11155111: {
  //   /* Mumbai addresses */
  //   BASIC_GREEN_PROJECT: "0xeAbda578a46eEa688610D26Cf89fA6B7722f2399",
  //   BASIC_INFRASTRUCTURE_CHANGE: "0xBe500405c664EdeFac9dc5c8ADc7E6897a64F2DB",
  //   GOVERNANCE_GREEN_PROJECT: "0x3747Ed19E136A23658Bf6f8295d8022e774afa34",
  //   GOVERNANCE_INFRASTRUCTURE_CHANGE: "0xbf9F2C46d1a46d8eC719Fa891e30e5CFdFFC50EE",
  //   GOVERNANCE_CARBON_MONITORING: "0xbBD17f5D20A595e59bBA105B117a824Ac2329e03",
  //   GREEN_PROJECT_NFT: "0x09f6ea0aa0709c9F8AFEe8D9D8cac6689EC8A079",
  //   INFRASTRUCTURE_CHANGE_NFT: "0x260e3bE0bAAFd3E0CEAeFa2b1edD4bA67023a994",
  //   CLOSE_CARBON_TOKEN: "0x45c56139BAD27d977a086Be611CfF5405E217A33",
  //   DONATION_CERTIFICATE: "0xd99Ab2D998194B6e1520Fd76F8868f530E5D23e9",
  // },
  1: {
    /* Mainnet addresses */ CARBON_MONITORING: "",
  },
};

export { NETWORKS };

// 0xcB08c7549Fe7b429a4133ed46Cb2b738D05815e9