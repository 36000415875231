import { useEffect } from "react";
import "../../styles/ThirdParty/MonitoringCard.scss";
import Chart from "chart.js/auto";
import { ethers } from "ethers";

const MonitoringCard = ({ chartId, chartData, facility }) => {
  // useEffect(() => {
  //   const today = new Date();
  //   const lastSevenDays = [];

  //   for (let i = 6; i >= 0; i--) {
  //     const currentDate = new Date(today);
  //     currentDate.setDate(today.getDate() - i);
  //     const month = currentDate.toLocaleString("default", { month: "short" });
  //     const day = currentDate.getDate();
  //     const formattedDate = `${month} ${day}`;
  //     lastSevenDays.push(formattedDate);
  //   }

  //   const ctx = document.getElementById(chartId);

  //   const myChart = new Chart(ctx, {
  //     type: "bar",
  //     data: {
  //       labels: lastSevenDays,
  //       datasets: [
  //         {
  //           label: "Emission Tracked",
  //           data: chartData,
  //           borderWidth: 1,
  //           backgroundColor: "#006633",
  //         },
  //       ],
  //     },
  //     options: {
  //       plugins: {
  //         tooltip: {
  //           callbacks: {
  //             label: function (tooltipItem) {
  //               return `${tooltipItem?.label}`;
  //             },
  //             label: function (tooltipItem) {
  //               return `${tooltipItem?.formattedValue}tCO2`;
  //             },
  //           },
  //         },
  //       },
  //       scales: {
  //         y: {
  //           beginAtZero: true,
  //         },
  //       },
  //     },
  //   });

  //   return () => myChart.destroy();
  // }, []);
  return (
    <div className="monitoring_card">
      <div className="monitoring_grid">
        <div>
          <div>Organisation Name:</div>
          <div>{facility?.plantInfo?.organizationName}</div>
        </div>
        <div>
          <div>CO2 tracked:</div>
          <div>
            {facility?.emissionData?.length > 0 &&
            ethers.formatEther(
              facility?.emissionData[
                facility?.emissionData?.length - 1
              ]?.totalGHGEmissions?.toString()
            )
            }
            tCO2
          </div>
        </div>
        <div>
          <div>CO2 Offset:</div>
          <div>0CO2</div>
        </div>
        <div>
          <div>Energy Consumption:</div>
          <div>
            { facility?.emissionData?.length > 0 && 
            ethers.formatEther(
              facility?.emissionData[
                facility?.emissionData?.length - 1
              ]?.energyConsumption?.toString()
            )}
            kwh
          </div>
        </div>
        <div>
          <div>Reporting period:</div>
          <div>
            { facility?.emissionData?.length > 0 && 
            facility?.emissionData[
              facility?.emissionData?.length - 1
            ]?.reportingPeriod?.toString()}
          </div>
        </div>
      </div>

      {/* <div className="chart_container">
        <div className="chart_label">CO2 emission (tCO2)</div>
        <div className="chart_inner">
          <canvas id={chartId}></canvas>
        </div>
      </div> */}
    </div>
  );
};

export default MonitoringCard;
