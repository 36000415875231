import { useEffect, useRef } from "react";
import axios from "axios";
import SideBar from "../../components/AdminDashboard/SideBar";
import "../../styles/AdminDashboard/UserDetails.scss";
import { getKyc, getUserInfo } from "../../apis/General/users";
import { useSelector } from "react-redux";
import { useState } from "react";
import { updateUserKyc } from "../../apis/AdminDashboard/users";
import { toast } from "react-toastify";
import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  equalTo,
  query,
  update,
} from "firebase/database";
import { pinFileToIPFSAsync as monitoringpinFileToIPFSAsync } from "../../instantiateTransaction/carbonMonitoringV1";

import { pinLinksToIPFS as infastructurepinFileToIPFSAsync } from "../../instantiateTransaction/basicInfrastructureChange";

import { pinLinksToIPFS as greenpinFileToIPFSAsync } from "../../instantiateTransaction/basicGreenProject";
import { useParams, useNavigate } from "react-router-dom";
import streamifier from "streamifier";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Search from "../../components/AdminDashboard/Search";

const UserDetails = () => {
  const navigate = useNavigate();
  const pathname = useParams();
  const auth = useSelector((state) => state.auth);
  // console.log('auth', auth);
  const mediaRef = useRef(null);
  const [companyName, setCompanyName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [facilityType, setFacilityType] = useState("");
  const [scenario, setScenario] = useState("");
  const [sensorIds, setSensorIds] = useState([]);
  const [quantifiedBasedYearEmission, setQuantifiedBasedYearEmission] =
    useState("");
  const [publicKey, setPublicKey] = useState("");
  const [quantificationMethodology, setQuantificationMethodology] =
    useState("");
  const [fuelSource, setFuelSource] = useState("");
  const [location, setLocation] = useState("");
  const [personResponsible, setPersonResponsible] = useState("");
  const [organizationBoundary, setOrganizationBoundary] = useState("");
  const [reportingBoundary, setReportingBoundary] = useState("");
  const [historicalInventory, setHistoricalInventory] = useState("");
  const [organizationDescription, setOrganizationDescription] = useState("");
  const [projectMonitoringReport, setProjectMonitoringReport] = useState("");
  const [projectDescriptionReport, setProjectDescriptionReport] = useState("");
  const [
    descriptionOfRenewableEnergySources,
    setDescriptionOfRenewableEnergySources,
  ] = useState("");
  const [uplaodLoading, setUploadLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [kycKeys, setKycKeys] = useState([]);
  const [governmentOrganization, setGovernmentOrganization] = useState("");
  const [regulatoryStatus, setRegulatoryStatus] = useState("");
  const [generalLoading, setGeneralLoading] = useState(false);
  const [subscription, setSubscription] = useState("");
  const [renewableEnergySolutions, setRenewableEnergySolutions] = useState("");
  const db = getDatabase();
  const tableRef = ref(db, "kycStatus");

  const fetchUserInfo = async () => {
    setLoading(true);
    setGeneralLoading(true);
    const response = await getUserInfo(pathname?.id, auth?.token);
    const response2 = await getKyc(pathname?.id, auth?.token);
    console.log("response", response);
    console.log("response2", response2);
    setCompanyName(response?.data?.message?.companyName);
    setAddress(response?.data?.message?.address);
    setPhone(response?.data?.message?.phoneNumber);
    setCountryCode(response?.data?.message?.countryCode);
    setSensorIds(response?.data?.message?.registeredDevices);
    setFuelSource(response2?.data?.message?.projectFuelSource);
    setPublicKey(response2?.data?.message?.walletAddress);
    setOrganizationBoundary(response2?.data?.message?.organizationBoundary);
    setReportingBoundary(response2?.data?.message?.reportingBoundary);
    setHistoricalInventory(response2?.data?.message?.historicalInventory);
    setProjectMonitoringReport(
      response2?.data?.message?.projectMonitoringReport
    );
    setProjectDescriptionReport(
      response2?.data?.message?.projectDescriptionDocument
    );
    setDescriptionOfRenewableEnergySources(
      response2?.data?.message?.descriptionOfRenewableEnergySources
    );
    setQuantificationMethodology(
      response2?.data?.message?.quantificationMethodology || ""
    );
    setQuantifiedBasedYearEmission(
      response2?.data?.message?.quantifiedBasedYearEmission
    );
    setOrganizationDescription(
      response2?.data?.message?.facilityCategory === "green" ? response2?.data?.message?.greenProjectDescription : response2?.data?.message?.facilityDescription
    );
    setLocation(response2?.data?.message?.facilityCategory === "green" ? response2?.data?.message?.greenProjectLocation : response2?.data?.message?.facilityLocation);
    setPersonResponsible(
      `${response?.data?.message?.firstName} ${response?.data?.message?.lastName}`
    );
    setFacilityType(response2?.data?.message?.facilityCategory
    );
    setSubscription(response2?.data?.message?.subscriptionPlan);
    setScenario(response2?.data?.message?.facilityCategory === "green" ? "green" : response2?.data?.message?.facilityCategory === "carbon-monitoring" ? "carbon-monitoring" : response2?.data?.message?.sustainabilitySolution);
    setGovernmentOrganization(response2?.data?.message?.governmentOrganization);
    setRegulatoryStatus(response2?.data?.message?.regulatoryStatus);
    setRenewableEnergySolutions(response2?.data?.message?.renewableEnergySolutions);
console.log('response2?.data?.message?.renewableEnergySolutions', response2?.data?.message?.renewableEnergySolutions);
    setLoading(false);
    setGeneralLoading(false);
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fileUpload = (e, documentTypeSent) => {
    setUploadLoading(true);

    let files = e.target.files;
    let allUploadedFiles = images;

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                {
                  media_type: "pdf",
                  folder: "documents",
                  user_id: auth?.userInfo?.id,
                  contents: [result],
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth ? auth.token : ""}`,
                  },
                }
              )
              .then((response) => {
                setQuantificationMethodology(response?.data?.body?.data[0]);

                setUploadLoading(false);
              })
              .catch((err) => {
                setUploadLoading(false);
              });
          }
        });
      }
    }
  };

  const handleUpdateKyc = async () => {
    setLoading(true);
    // let ipfsHash;

    // try {
    //   if (scenario === "green-project") {
    //     const fileResponse = await greenpinFileToIPFSAsync([
    //       organizationBoundary,
    //       historicalInventory,
    //       typeofFuel,
    //       quantificationMethodology,
    //       projectMonitoringReport,
    //       projectDescriptionReport,
    //       descriptionOfRenewableEnergySources,
    //     ]);
    //     ipfsHash = fileResponse?.IpfsHash;
    //   } else if (scenario === "carbon-monitoring") {
    //     const fileResponse = await infastructurepinFileToIPFSAsync([
    //       organizationBoundary,
    //       historicalInventory,
    //       typeofFuel,
    //       quantificationMethodology,
    //       projectMonitoringReport,
    //       projectDescriptionReport,
    //     ]);
    //     ipfsHash = fileResponse?.IpfsHash;
    //   } else if (scenario === "infrastructure-change") {
    //     const fileResponse = await infastructurepinFileToIPFSAsync([
    //       organizationBoundary,
    //       historicalInventory,
    //       typeofFuel,
    //       quantificationMethodology,
    //       projectMonitoringReport,
    //       projectDescriptionReport,
    //     ]);
    //     ipfsHash = fileResponse?.IpfsHash;
    //   } else {
    //     toast.error("Invalid Scenario");
    //   }
    // } catch (error) {
    //   console.log("ERROR", error);
    //   toast.error("Something went wrong");
    // }

    const response = await updateUserKyc(
      "perform kyc",
      subscription,
      companyName,
      publicKey,
      organizationDescription,
      location,
      facilityType,
      scenario,
      quantificationMethodology,
      fuelSource ? fuelSource : "fuelSource",
      renewableEnergySolutions ? renewableEnergySolutions : "renewableEnergySolutions",
      governmentOrganization ? governmentOrganization : "governmentOrganization",
      regulatoryStatus ? regulatoryStatus : "regulatoryStatus",
      pathname?.id,
      auth?.token
    );

    console.log("responseresponse", response);
    if (response?.data?.statusCode === 200) {
      update(ref(db, `kycStatus/${kycKeys[0]}`), {
        status: "completed",
      });
      toast.success("Kyc updated successfully");
    } else if (response?.data?.errorMessage) {
      toast.error(response?.data?.errorMessage);
    } else if (response?.status === 504) {
      toast.error(response?.data?.message);
    } else {
      toast.error("Something went wrong");
    }

    setLoading(false);
  };

  const handleGetKycUserStatus = () => {
    const eventsQuery = query(
      tableRef,
      orderByChild("user"),
      equalTo(pathname?.id)
      // equalTo(response?.data?.message?.publicKey)
    );
    return onValue(eventsQuery, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        console.log("bendiye", data);
        // const valueData = Object.values(data);
        const valueKeys = Object.keys(data);
        // setFirebaseStaticNotifications(valueData);
        setKycKeys(valueKeys);

        // const prevNotifications = notifications;
        // prevNotifications.push(...filteredData);
        // setFirebaseNotifications(valueData);
      } else {
        console.log(`User kyc status not found`);
      }
    });
  };

  useEffect(() => {
    handleGetKycUserStatus();
  }, []);
  return (
    <div className="userdetails">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="main_inner">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="pointer"
            onClick={() => {
              navigate("/admin/users");
              window.scrollTo(0, 0);
            }}
          >
            <path
              d="M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z"
              fill="#1A1A1A"
            />
            <path
              d="M5.55989 12L11.7804 18.219C11.9212 18.3598 12.0003 18.5508 12.0003 18.75C12.0003 18.9491 11.9212 19.1401 11.7804 19.281C11.6396 19.4218 11.4486 19.5009 11.2494 19.5009C11.0502 19.5009 10.8592 19.4218 10.7184 19.281L3.96839 12.531C3.89854 12.4613 3.84313 12.3785 3.80532 12.2874C3.76751 12.1963 3.74805 12.0986 3.74805 12C3.74805 11.9013 3.76751 11.8036 3.80532 11.7125C3.84313 11.6214 3.89854 11.5386 3.96839 11.469L10.7184 4.71897C10.8592 4.57814 11.0502 4.49902 11.2494 4.49902C11.4486 4.49902 11.6396 4.57814 11.7804 4.71897C11.9212 4.8598 12.0003 5.05081 12.0003 5.24997C12.0003 5.44913 11.9212 5.64014 11.7804 5.78097L5.55989 12Z"
              fill="#1A1A1A"
            />
          </svg>
          {generalLoading && (
            <div>
              <div className="input_container">
                  <Skeleton width="100%" height={20} borderRadius={8} />
                <Skeleton width="100%" height={60} borderRadius={12} />
              </div>
              <div className="input_container">
                  <Skeleton width="100%" height={20} borderRadius={8} />
                <Skeleton width="100%" height={60} borderRadius={12} />
              </div>
              <div className="input_container">
                  <Skeleton width="100%" height={20} borderRadius={8} />
                <Skeleton width="100%" height={60} borderRadius={12} />
              </div>
              <div className="input_container">
                  <Skeleton width="100%" height={20} borderRadius={8} />
                <Skeleton width="100%" height={60} borderRadius={12} />
              </div>
              <div className="input_container">
                  <Skeleton width="100%" height={20} borderRadius={8} />
                <Skeleton width="100%" height={60} borderRadius={12} />
              </div>
              <div className="input_container">
                  <Skeleton width="100%" height={20} borderRadius={8} />
                <Skeleton width="100%" height={60} borderRadius={12} />
              </div>
              <div className="input_container">
                  <Skeleton width="100%" height={20} borderRadius={8} />
                <Skeleton width="100%" height={60} borderRadius={12} />
              </div>
              
            </div>
          )}
          {!generalLoading && (
            <div>
              <div className="input_container">
                <div>Company Name</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  disabled
                  value={companyName}
                />
              </div>
              <div className="input_container">
                <div>Organisation Description</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  disabled
                  value={organizationDescription}
                />
              </div>
              <div className="input_container">
                <div>Location</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  disabled
                  value={location}
                />
              </div>
              <div className="input_container">
                <div>Person Responsible</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  disabled
                  value={personResponsible}
                />
              </div>
              <div className="input_container">
                <div>Phone Number</div>
                <div className="input_group">
                  <input
                    type="text"
                    // placeholder="Country Code"
                    value={countryCode}
                    required
                    disabled
                  />
                  <input
                    type="text"
                    // placeholder="Phone Number"
                    value={phone}
                    required
                    disabled
                  />
                </div>
              </div>
              <div className="input_container">
                <div>Postal Address</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  value={address}
                  disabled
                />
              </div>
              <div className="input_container">
                <div>Sensor ID</div>
                <div className="sensor_group">
                  {sensorIds?.map((item) => (
                    <div type="text" className="input">
                      {item?.deviceId}
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="input_container">
                <div>Quantified Base Year</div>
                <div className="input_flex">
                  <input
                    type="text"
                    // placeholder="CloseCarbon"
                    value={quantifiedBasedYearEmission}
                    onChange={(e) =>
                      setQuantifiedBasedYearEmission(e.target.value)
                    }
                  />
                  <div className="button_group">
                    <button className="transparent" disabled={loading}>
                      {loading ? "Loading..." : "Cancel"}
                    </button>
                    <button
                      className="filled"
                      disabled={loading}
                      onClick={handleUpdateKyc}
                    >
                      {loading ? "Loading..." : "Save changes"}
                    </button>
                  </div>
                </div>
              </div> */}
              <div className="input_container">
                <div>wallet Address</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  value={publicKey}
                  disabled
                />
              </div>
              <div className="input_container">
                <div>Quantification Methodology</div>
                <div className="input_flex">
                  <label className="kyc_input_flex">
                    <div
                      className="document input"
                      onClick={() =>
                        quantificationMethodology !== "" &&
                        window.open(quantificationMethodology)
                      }
                    >
                      <div>
                        {uplaodLoading
                          ? "Loading..."
                          : quantificationMethodology?.length > 0
                          ? "Document uploaded"
                          : "Choose file to upload"}
                      </div>
                      {quantificationMethodology?.length > 0 ? (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V19.5C4.5 20.2956 4.81607 21.0587 5.37868 21.6213C5.94129 22.1839 6.70435 22.5 7.5 22.5H16.5C17.2956 22.5 18.0587 22.1839 18.6213 21.6213C19.1839 21.0587 19.5 20.2956 19.5 19.5V8.121C19.4995 7.52446 19.2621 6.95255 18.84 6.531L14.4705 2.1585C14.2615 1.9496 14.0133 1.78393 13.7403 1.67094C13.4672 1.55795 13.1745 1.49987 12.879 1.5H7.5ZM6 4.5C6 4.10218 6.15804 3.72064 6.43934 3.43934C6.72064 3.15804 7.10218 3 7.5 3H12V6.75C12 7.34674 12.2371 7.91903 12.659 8.34099C13.081 8.76295 13.6533 9 14.25 9H18V19.5C18 19.8978 17.842 20.2794 17.5607 20.5607C17.2794 20.842 16.8978 21 16.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5V4.5ZM17.6895 7.5H14.25C14.0511 7.5 13.8603 7.42098 13.7197 7.28033C13.579 7.13968 13.5 6.94891 13.5 6.75V3.3105L17.6895 7.5Z"
                            fill="#667185"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_538_1886)">
                            <path
                              d="M19.35 10.04C19.0141 8.33772 18.0976 6.80486 16.7571 5.70325C15.4165 4.60163 13.7351 3.99961 12 4C9.11 4 6.6 5.64 5.35 8.04C3.88023 8.19883 2.52101 8.89521 1.53349 9.99532C0.545971 11.0954 -0.000171702 12.5217 4.04928e-08 14C4.04928e-08 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04ZM14 13V17H10V13H7L11.65 8.35C11.85 8.15 12.16 8.15 12.36 8.35L17 13H14Z"
                              fill="#667185"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_538_1886">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </div>
                    {quantificationMethodology === "" && (
                      <input
                        type="file"
                        multiple
                        // accept='video/*'
                        accept="application/pdf"
                        hidden
                        onChange={fileUpload}
                        ref={mediaRef}
                      />
                    )}
                  </label>
                  <div className="button_group">
                    <button
                      className="transparent"
                      onClick={() => setQuantificationMethodology("")}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Cancel"}
                    </button>
                    <button
                      className="filled"
                      disabled={loading}
                      onClick={handleUpdateKyc}
                    >
                      {loading ? "Loading..." : "Save changes"}
                    </button>
                  </div>
                </div>
              </div>
             
             

              <div className="input_container">
                <div>Quantified base year inventory</div>
                <div
                  className="document"
                  onClick={() => window.open(historicalInventory)}
                >
                  <div>Uploaded Document</div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V19.5C4.5 20.2956 4.81607 21.0587 5.37868 21.6213C5.94129 22.1839 6.70435 22.5 7.5 22.5H16.5C17.2956 22.5 18.0587 22.1839 18.6213 21.6213C19.1839 21.0587 19.5 20.2956 19.5 19.5V8.121C19.4995 7.52446 19.2621 6.95255 18.84 6.531L14.4705 2.1585C14.2615 1.9496 14.0133 1.78393 13.7403 1.67094C13.4672 1.55795 13.1745 1.49987 12.879 1.5H7.5ZM6 4.5C6 4.10218 6.15804 3.72064 6.43934 3.43934C6.72064 3.15804 7.10218 3 7.5 3H12V6.75C12 7.34674 12.2371 7.91903 12.659 8.34099C13.081 8.76295 13.6533 9 14.25 9H18V19.5C18 19.8978 17.842 20.2794 17.5607 20.5607C17.2794 20.842 16.8978 21 16.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5V4.5ZM17.6895 7.5H14.25C14.0511 7.5 13.8603 7.42098 13.7197 7.28033C13.579 7.13968 13.5 6.94891 13.5 6.75V3.3105L17.6895 7.5Z"
                      fill="#667185"
                    />
                  </svg>
                </div>
              </div>
              <div className="input_container">
                <div>Facility Type</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  value={facilityType}
                  disabled
                />
              </div>
              <div className="input_container">
                <div>Scenario</div>
                <input
                  type="text"
                  // placeholder="CloseCarbon"
                  value={scenario}
                  disabled
                />
              </div>
              <div className="input_container">
                <div>Project Description Report</div>
                <div
                  className="document"
                  onClick={() => window.open(projectDescriptionReport)}
                >
                  <div>Uploaded Document</div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V19.5C4.5 20.2956 4.81607 21.0587 5.37868 21.6213C5.94129 22.1839 6.70435 22.5 7.5 22.5H16.5C17.2956 22.5 18.0587 22.1839 18.6213 21.6213C19.1839 21.0587 19.5 20.2956 19.5 19.5V8.121C19.4995 7.52446 19.2621 6.95255 18.84 6.531L14.4705 2.1585C14.2615 1.9496 14.0133 1.78393 13.7403 1.67094C13.4672 1.55795 13.1745 1.49987 12.879 1.5H7.5ZM6 4.5C6 4.10218 6.15804 3.72064 6.43934 3.43934C6.72064 3.15804 7.10218 3 7.5 3H12V6.75C12 7.34674 12.2371 7.91903 12.659 8.34099C13.081 8.76295 13.6533 9 14.25 9H18V19.5C18 19.8978 17.842 20.2794 17.5607 20.5607C17.2794 20.842 16.8978 21 16.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5V4.5ZM17.6895 7.5H14.25C14.0511 7.5 13.8603 7.42098 13.7197 7.28033C13.579 7.13968 13.5 6.94891 13.5 6.75V3.3105L17.6895 7.5Z"
                      fill="#667185"
                    />
                  </svg>
                </div>
              </div>
              {/* <div className="input_container">
                <div>Project Monitoring Report</div>
                <div
                  className="document"
                  onClick={() => window.open(projectMonitoringReport)}
                >
                  <div>Uploaded Document</div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 1.5C6.70435 1.5 5.94129 1.81607 5.37868 2.37868C4.81607 2.94129 4.5 3.70435 4.5 4.5V19.5C4.5 20.2956 4.81607 21.0587 5.37868 21.6213C5.94129 22.1839 6.70435 22.5 7.5 22.5H16.5C17.2956 22.5 18.0587 22.1839 18.6213 21.6213C19.1839 21.0587 19.5 20.2956 19.5 19.5V8.121C19.4995 7.52446 19.2621 6.95255 18.84 6.531L14.4705 2.1585C14.2615 1.9496 14.0133 1.78393 13.7403 1.67094C13.4672 1.55795 13.1745 1.49987 12.879 1.5H7.5ZM6 4.5C6 4.10218 6.15804 3.72064 6.43934 3.43934C6.72064 3.15804 7.10218 3 7.5 3H12V6.75C12 7.34674 12.2371 7.91903 12.659 8.34099C13.081 8.76295 13.6533 9 14.25 9H18V19.5C18 19.8978 17.842 20.2794 17.5607 20.5607C17.2794 20.842 16.8978 21 16.5 21H7.5C7.10218 21 6.72064 20.842 6.43934 20.5607C6.15804 20.2794 6 19.8978 6 19.5V4.5ZM17.6895 7.5H14.25C14.0511 7.5 13.8603 7.42098 13.7197 7.28033C13.579 7.13968 13.5 6.94891 13.5 6.75V3.3105L17.6895 7.5Z"
                      fill="#667185"
                    />
                  </svg>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
