export default [
    {
      title: "Emissions Management",
      contentBody: [
        {
          bodyTitle: "Baseline emissions:",
          bodyContent:
            "The reference level of greenhouse gas emissions used as a benchmark against which emission reductions or increases are measured. It represents the anticipated emissions in the absence of any mitigation efforts or projects.",
        },
        {
          bodyTitle: "Project emissions:",
          bodyContent:
            "The total amount of greenhouse gas emissions associated with a specific project or activity. Project emissions are compared to the baseline emissions to determine the effectiveness of emission reduction initiatives.",
        },
        {
          bodyTitle: "Emission reductions:",
          bodyContent:
            "The strategy or set of actions implemented to prevent or reduce the release of greenhouse gas emissions into the atmosphere. Emission avoidance measures aim to minimize the environmental impact associated with specific activities or processes.",
        },
        {
          bodyTitle: "Emission avoidance:",
          bodyContent:
            "The quantifiable and verified decrease in greenhouse gas emissions achieved through the implementation of specific actions or projects. Emission reductions are typically measured against a predefined baseline.",
        },
        {
          bodyTitle: "Emission sources:",
          bodyContent:
            "Points or processes that release greenhouse gases into the atmosphere. Emission sources include industrial facilities, power plants, transportation, agriculture, and other activities contributing to climate-altering gases.",
        },
        {
          bodyTitle: "Fugitive emissions:",
          bodyContent:
            "Unintentional or accidental releases of greenhouse gases during the production, processing, transportation, or use of fossil fuels or other industrial processes.",
        },
        {
          bodyTitle: "Direct emissions:",
          bodyContent:
            "Greenhouse gas emissions released directly from a specific source, such as exhaust from industrial processes, combustion of fossil fuels, or emissions from manufacturing facilities.",
        },
  
        {
          bodyTitle: "Indirect emissions:",
          bodyContent:
            "Greenhouse gas emissions that result from activities or processes but occur at a different location, such as emissions from the generation of purchased electricity or transportation services.",
        },
        {
          bodyTitle: "Stationary combustion:",
          bodyContent:
            "The burning of fuels in fixed or stationary sources, such as power plants, industrial facilities, or residential heating systems.",
        },
        {
          bodyTitle: "Mobile combustion:",
          bodyContent:
            "The combustion of fuels in mobile sources, including vehicles, ships, aircraft, and other forms of transportation.",
        },
        {
          bodyTitle: "GHG emissions:",
          bodyContent:
            "Greenhouse Gas Emission. The release of gases, such as carbon dioxide, methane, and nitrous oxide, into the atmosphere that contribute to the greenhouse effect and global warming.",
        },
        {
          bodyTitle: "Fugitive emissions:",
          bodyContent:
            "Unintentional or accidental releases of greenhouse gases during the production, processing, transportation, or use of fossil fuels or other industrial processes.",
        },
        {
          bodyTitle: "Fossil-fuel based power generation:",
          bodyContent:
            "The process of generating electricity using fossil fuels such as coal, oil, or natural gas. This method involves burning these fuels to produce steam, which turns turbines to generate electrical power.",
        },
      ],
    },
  
    {
      title: "Verification & Validation",
      contentBody: [
        {
          bodyTitle: "Verification:",
          bodyContent:
            "The independent and systematic examination of greenhouse gas emission reductions or removals claimed by a project to ensure their accuracy, completeness, and compliance with established standards or methodologies.",
        },
        {
          bodyTitle: "Validation:",
          bodyContent:
            "The process of assessing and confirming that a proposed project or program is in accordance with predefined criteria, methodologies, and standards, often conducted by an independent third party.",
        },
        {
          bodyTitle: "Validator:",
          bodyContent:
            "An individual or organisation responsible for conducting the validation process, ensuring that a project meets the specified requirements and is eligible for carbon incentives or other environmental certifications. ",
        },
        {
          bodyTitle: "Verifier:",
          bodyContent:
            "An independent entity responsible for conducting the verification process to confirm the accuracy of reported greenhouse gas emission reductions or removals, typically after a project has been implemented. ",
        },
        {
          bodyTitle: "Third-Party regulatory bodies:",
          bodyContent:
            "External organizations or agencies independent of businesses or industries, responsible for overseeing and enforcing regulations, standards, and compliance in various sectors. In the context of carbon incentives, third-party regulatory bodies may validate and verify emission reduction projects. ",
        },
      ],
    },
    {
      title: "Carbon Programs & Initiatives",
      contentBody: [
        {
          bodyTitle: "Carbon programme:",
          bodyContent:
            "A comprehensive and organized set of activities, projects, and methodologies aimed at achieving greenhouse gas emission reductions or removals within a specific geographic area, sector, or industry",
        },
        {
          bodyTitle: "Carbon project:",
          bodyContent:
            "An initiative or activity specifically designed to reduce, avoid, or capture greenhouse gas emissions, with the intention of generating carbon incentives or contributing to overall climate change mitigation efforts.",
        },
        {
          bodyTitle: "Carbon certification programme:",
          bodyContent:
            "A structured program that certifies and validates the environmental performance of projects, initiatives, or organizations in terms of their greenhouse gas emission reductions or sustainability practices.",
        },
        {
          bodyTitle: "Low-carbon alternatives:",
          bodyContent:
            "Technologies, practices, or processes that generate energy or products with reduced carbon emissions compared to conventional methods. Low-carbon alternatives aim to mitigate the impact of human activities on climate change.",
        },
        {
          bodyTitle: "Carbon offset:",
          bodyContent:
            "A reduction, removal, or avoidance of greenhouse gas emissions to compensate for emissions occurring elsewhere. Carbon offsets are often used as a strategy to achieve carbon neutrality.",
        },
      ],
    },
    {
      title: "Projects Types",
      contentBody: [
        {
          bodyTitle: "Fuel change project:",
          bodyContent:
            "A structured program that certifies and validates the environmental performance of projects, initiatives, or organizations in terms of their greenhouse gas emission reductions or sustainability practices.",
        },
        {
          bodyTitle: "Non-green/ Infrastructure change projects:",
          bodyContent:
            "Projects involving modifications, improvements, or development of physical structures, facilities, or systems, often with the aim of enhancing efficiency, sustainability or reducing environmental impacts.",
        },
        {
          bodyTitle: "Green/ Renewable energy projects:",
          bodyContent:
            "Initiatives that focus on harnessing energy from renewable sources such as solar, wind, hydro, or geothermal. These projects aim to produce electricity with lower environmental impacts compared to traditional fossil fuel-based power generation.",
        },
        {
          bodyTitle: "Carbon offset initiative:",
          bodyContent:
            "Projects or actions designed to compensate for or offset carbon emissions by removing an equivalent amount of greenhouse gases from the atmosphere. This can involve activities such as reforestation, renewable energy projects, or methane capture initiatives.",
        },
      ],
    },
    {
      title: "Measurement & Tracking",
      contentBody: [
        {
          bodyTitle: "Methodology:",
          bodyContent:
            "A systematic and standardized set of rules, procedures, and calculations used to quantify and verify greenhouse gas emissions or removals associated with a particular project or activity. ",
        },
        {
          bodyTitle: "Carbon tracking:",
          bodyContent:
            "The systematic monitoring, recording, and reporting of an entity's greenhouse gas emissions over time, often involving the use of software, tools, or methodologies to track and manage carbon footprints.",
        },
        {
          bodyTitle: "Emission factor:",
          bodyContent:
            "A coefficient that quantifies the amount of greenhouse gas emissions produced per unit of activity, typically expressed as emissions per unit of energy produced or consumed. ",
        },
      ],
    },
    {
      title: "Carbon Incentivizing",
      contentBody: [
        {
          bodyTitle: "Carbon incentive:",
          bodyContent:
            "A tradable certificate or permit representing the right to emit one ton of carbon dioxide or its equivalent. Carbon incentives are often generated by projects that reduce or remove greenhouse gas emissions, and they can be bought and sold on the carbon market.",
        },
        {
          bodyTitle: "Credit issued:",
          bodyContent:
            "The formal recognition and issuance of carbon incentives or other environmental certificates to a project that has successfully reduced or removed greenhouse gas emissions, as verified and validated by relevant authorities.",
        },
        {
          bodyTitle: "Carbon certification programme:",
          bodyContent:
            "A structured program that certifies and validates the environmental performance of projects, initiatives, or organizations in terms of their greenhouse gas emission reductions or sustainability practices.",
        },
        {
          bodyTitle: "Voluntary carbon incentive:",
          bodyContent:
            "Tradable certificates representing a reduction or removal of greenhouse gas emissions that are not legally mandated. Individuals or organizations purchase voluntary carbon incentives to offset their own emissions or support environmental projects.",
        },
        {
          bodyTitle: "Compliance carbon incentives:",
          bodyContent:
            "Tradable certificates representing a reduction or removal of greenhouse gas emissions in compliance with legally mandated emission reduction targets or regulatory requirements. ",
        },
      ],
    },
  ];