import Header from "../../components/shared/Header";
import "../../styles/shared/About.scss";
import Illustration from "../../images/save-environment-icon-design-element-vector 2.png";
import Bulb from "../../images/image 56.png";
import Offer1 from "../../images/image 57.png";
import Offer2 from "../../images/image 54.png";
import Offer3 from "../../images/Group 1.png";
import Offer4 from "../../images/group_mobile.png";
import Footer from "../../components/shared/Footer";
import Feedback from "../../components/shared/Feedback";
import { useState } from "react";
import { joinUs } from "../../apis/General/users";
import { toast } from "react-toastify";

export default function About() {
  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    const response = await joinUs(fullName,
      email,
      phoneNumber,
      coverLetter);
      // console.log('response', response);
      if (response?.data?.statusCode === 200) {
        toast.success(response?.data?.message);
  
        setEmail("");
        setFullName("");
        setPhoneNumber("");
        setCoverLetter("");
      } else {
        toast.error(response?.data?.message);
      }
      setLoading(false);
  }

  const handleModalOPen = () => {
    setModalOpen(true);
  };

  const handleModalClose = (e) => {
    if (e.target.classList.contains("join_us_modal")) {
      setModalOpen(false);
    }
  };


  return (
    <div className="about">
      <Header />
      <div className="hero">
        <div>
          <div className="title">
            Pioneering <span>Sustainable Solutions</span> for a Greener Tomorrow
          </div>
          <div className="subtitle">
            Join us on a journey of environmental stewardship, where
            CloseCarbon's innovative solutions empower individuals and
            businesses to transform carbon emissions into positive, lasting
            impact.
          </div>
        </div>
        <img src={Illustration} alt="" />
      </div>

      <div className="who">
        <div className="who_inner">
        <div>
          <div className="title">Who Are We?</div>
          <div className="subtitle">
            CloseCarbon is a carbon tracking and emissions reduction company
            dedicated to combating climate change by making carbon emissions
            reduction accessible, actionable, and rewarding. We are founded on
            the belief that every entity, regardless of its current emissions
            status, can play a pivotal role in mitigating the effects of climate
            change.
          </div>
        </div>
        <img src={Bulb} alt="" />
        </div>
      </div>

      <div className="offer">
        <div>
            <img className="first_img" src={Offer1} alt="" />
            <div>
                <div className="title">Purpose Declaration</div>
                <div className="subtitle">We are on a mission to empower plant owners, manufacturing companies, and businesses of all types to not only track their carbon emissions but also convert those emissions into valuable carbon incentives. Our platform is designed for both green companies committed to zero emissions and non-green companies taking essential steps toward infrastructure change.</div>
            </div>
            <div>
                <div className="title">Our Vision</div>
                <div className="subtitle">Our vision at CloseCarbon is to create a world where every business, regardless of its environmental footprint, has the tools and motivation to transition to a sustainable, low-carbon future. We envision a global community united in the fight against climate change, where carbon emissions are not just tracked, but transformed into a currency for positive environmental impact. Our goal is to make a lasting difference by reducing carbon emissions, one tonne at a time.</div>
            </div>
            <img src={Offer2} alt="" />
            
        </div>
       
      </div>

      <div className="experience">
      <div className="experience_inner">
      <div>
                <div className="title">Experiences so Far</div>
                <div className="subtitle">At CloseCarbon, we are more than a company; we are a united force dedicated to driving change and advancing sustainability. Our team consists of passionate individuals with diverse backgrounds and skills, all committed to making a positive impact. Together, we are the heart and soul of our mission to turn carbon emissions into a brighter, greener future.</div>
                <div className="pointer" onClick={handleModalOPen}>Join the Team</div>
            </div>
            <img className="mobile_none" src={Offer3} alt="" />
            <img className="mobile" src={Offer4} alt="" />
      </div>
      </div>

      <Feedback />
      <Footer />
        {modalOpen && (
        <div className="join_us_modal" onClick={handleModalClose}>
          <div className="join_us">
            <div className="close pointer" onClick={() => setModalOpen(false)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"
                  fill="#141414"
                />
              </svg>
            </div>

            <div className="title">Join Us!</div>
            <div className="subtitle">
              Curious about the solutions our platform provides? Share a bit
              about yourself, and we'll link you up with our experts.
            </div>

            <div className="join_us_group">
              <div>A Forefront Environmental Technology Company in Africa</div>

              <div>
                <form onSubmit={handleSubmit}>
                  <div className="input_group">
                    <label htmlFor="fullName">
                      Full Name<span>*</span>
                    </label>
                    <div className="input_group_inner">
                      <svg
                        width="16"
                        height="21"
                        viewBox="0 0 16 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 21C0 16.5817 3.58172 13 8 13C12.4183 13 16 16.5817 16 21H14C14 17.6863 11.3137 15 8 15C4.68629 15 2 17.6863 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
                          fill="#B1B1B1"
                        />
                      </svg>
                      <div className="divider"></div>
                      <input
                        required
                        type="text"
                        id="fullName"
                        name="fullName"
                        placeholder="Enter your full name"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                    <label htmlFor="email">
                      Email Address<span>*</span>
                    </label>
                    <div className="input_group_inner">
                      <svg
                        width="22"
                        height="20"
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.1 2C14.0344 2.32311 14 2.65753 14 3C14 3.34247 14.0344 3.67689 14.1 4H2.51146L10.0619 10.662L15.1098 6.14141C15.5363 6.66888 16.0679 7.10787 16.6728 7.42652L10.0718 13.338L2 6.21594V18H18V7.89998C18.3231 7.96557 18.6575 8 19 8C19.3425 8 19.6769 7.96557 20 7.89998V19C20 19.5523 19.5523 20 19 20H1C0.44772 20 0 19.5523 0 19V3C0 2.44772 0.44772 2 1 2H14.1ZM19 0C20.6569 0 22 1.34315 22 3C22 4.65685 20.6569 6 19 6C17.3431 6 16 4.65685 16 3C16 1.34315 17.3431 0 19 0Z"
                          fill="#B1B1B1"
                        />
                      </svg>

                      <div className="divider"></div>
                      <input
                        required
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <label htmlFor="emailAddress">
                      Phone Number<span>*</span>
                    </label>
                    <div className="input_group_inner">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.9994 15.9201V18.9201C21.0006 19.1986 20.9435 19.4743 20.832 19.7294C20.7204 19.9846 20.5567 20.2137 20.3515 20.402C20.1463 20.5902 19.904 20.7336 19.6402 20.8228C19.3764 20.912 19.0968 20.9452 18.8194 20.9201C15.7423 20.5857 12.7864 19.5342 10.1894 17.8501C7.77327 16.3148 5.72478 14.2663 4.18945 11.8501C2.49942 9.2413 1.44769 6.27109 1.11944 3.1801C1.09446 2.90356 1.12732 2.62486 1.21595 2.36172C1.30457 2.09859 1.44702 1.85679 1.63421 1.65172C1.82141 1.44665 2.04925 1.28281 2.30324 1.17062C2.55722 1.05843 2.83179 1.00036 3.10945 1.0001H6.10945C6.59475 0.995321 7.06524 1.16718 7.43321 1.48363C7.80118 1.80008 8.04152 2.23954 8.10944 2.7201C8.23607 3.68016 8.47089 4.62282 8.80945 5.5301C8.94399 5.88802 8.97311 6.27701 8.89335 6.65098C8.8136 7.02494 8.62831 7.36821 8.35944 7.6401L7.08945 8.9101C8.513 11.4136 10.5859 13.4865 13.0894 14.9101L14.3594 13.6401C14.6313 13.3712 14.9746 13.1859 15.3486 13.1062C15.7225 13.0264 16.1115 13.0556 16.4694 13.1901C17.3767 13.5286 18.3194 13.7635 19.2794 13.8901C19.7652 13.9586 20.2088 14.2033 20.526 14.5776C20.8431 14.9519 21.0116 15.4297 20.9994 15.9201Z"
                          stroke="#B1B1B1"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <div className="divider"></div>
                      <input
                        required
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <label htmlFor="phoneNumber">
                      Cover Letter<span>*</span>
                    </label>
                    <div className="input_group_inner input_textarea">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.76282 14H18V2H2V15.3851L3.76282 14ZM4.45455 16L0 19.5V1C0 0.44772 0.44772 0 1 0H19C19.5523 0 20 0.44772 20 1V15C20 15.5523 19.5523 16 19 16H4.45455Z"
                          fill="#B1B1B1"
                        />
                      </svg>

                      <div className="divider"></div>
                      <textarea
                        required
                        type="text"
                        id="coverLetter"
                        name="coverLetter"
                        placeholder="Cover Letter"
                        value={coverLetter}
                        onChange={(e) => setCoverLetter(e.target.value)}
                      />
                    </div>
                  </div>
                  <button type="submit" disabled={loading}>{loading ? 'Loading...' : 'Submit'}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}
