import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

// Initialize GA4
ReactGA.initialize('G-1Z032EDX48'); 

const logError = (error, context) => {
  console.error(`GA4 Tracking Error (${context}):`, error);
};

const FIRST_VISIT_KEY = 'hasVisitedBefore';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    try {
      // Page View
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

      // Session Start
      ReactGA.event('session_start');

      // First Visit Check
      const hasVisitedBefore = localStorage.getItem(FIRST_VISIT_KEY);
      if (!hasVisitedBefore) {
        ReactGA.event('first_visit');
        localStorage.setItem(FIRST_VISIT_KEY, 'true');
      }
    } catch (error) {
      logError(error, 'Initial page tracking');
    }

    // Scroll Tracking
    const handleScroll = () => {
      try {
        const scrollPercentage = Math.round((window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)) * 100);
        if (scrollPercentage === 25 || scrollPercentage === 50 || scrollPercentage === 75 || scrollPercentage === 90) {
          ReactGA.event('scroll', { percent_scrolled: scrollPercentage });
        }
      } catch (error) {
        logError(error, 'Scroll tracking');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // User Engagement
    let engagementTimeout;
    const trackEngagement = () => {
      try {
        clearTimeout(engagementTimeout);
        engagementTimeout = setTimeout(() => {
          ReactGA.event('user_engagement', { engagement_time_msec: 5000 });
        }, 5000);
      } catch (error) {
        logError(error, 'User engagement tracking');
      }
    };

    ['click', 'scroll', 'keypress'].forEach(event => 
      window.addEventListener(event, trackEngagement)
    );

    // Form Start
    const handleFormFocus = (e) => {
      try {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
          ReactGA.event('form_start', { form_name: e.target.form?.name || 'unknown' });
        }
      } catch (error) {
        logError(error, 'Form start tracking');
      }
    };

    document.addEventListener('focus', handleFormFocus, true);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      ['click', 'scroll', 'keypress'].forEach(event => 
        window.removeEventListener(event, trackEngagement)
      );
      document.removeEventListener('focus', handleFormFocus, true);
    };
  }, [location]);
};


