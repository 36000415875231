import { useEffect, useState } from "react";
import { getCcrtTrades, getCCRTByMonth, getTotalCCRTBalance } from "../../apis/AdminDashboard/users";
import SideBar from "../../components/AdminDashboard/SideBar";
import "../../styles/AdminDashboard/LiquidityPool.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Search from "../../components/AdminDashboard/Search";
import { countries } from "../../data/countries";
import moment from "moment";
import { MenuItem, Pagination, Select } from "@mui/material";
import Chart from "chart.js/auto";

const LiquidityPool = () => {
  const auth = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("basic");
  const [totalUsers, setTotalUsers] = useState(0);
  const [balance, setBalance] = useState(0);

  const [dateRange, setDataRange] = useState("NGN");
  const [countryModal, setCountryModal] = useState(false);
  const [ndcCalendar, setNdcCalendar] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [monthsModal, setMonthsModal] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [bookedDates, setBookedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [rangeLoading, setRangeLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries);
  const [collectors, setCollectors] = useState([]);
  const [page, setPage] = useState(1);
  const [text, setText] = useState("");
  const [pagination, setPagination] = useState([]);
  const [ccrtByMonth, setCcrtByMonth] = useState({});
  const [totalCCRTInNaira, setTotalCCRTInNaira] = useState(0);

  const handlegetCcrtTrades = async () => {
    setLoading(true)
    const response = await getCcrtTrades(page, auth?.token);
    console.log("c", response);
    setCollectors(response?.data?.message?.tradeItems);
    setPagination(response?.data?.message);
    setLoading(false)
  };
  const handleGetCCRTByMonth = async () => {
    setLoading(true)
    const response = await getCCRTByMonth(auth?.token);
    console.log("getCCRTByMonth", response);
    console.log("Object.values", Object.values(response?.data?.message));
    setCcrtByMonth(response?.data?.message);
    // setPagination(response?.data?.message);
    setLoading(false)
  };

  const handleGetTotalCCRT = async () => {
    setLoading(true)
    const response = await getTotalCCRTBalance(auth?.token);
    console.log("getTotalCCRTBalance", response);
    // console.log("Object.values", Object.values(response?.data?.message));
    setTotalCCRTInNaira(response?.data?.message);
    // setPagination(response?.data?.message);
    setLoading(false)
  };

  

  useEffect(() => {
    handlegetCcrtTrades();
  }, [page]);

  useEffect(() => {
    handleGetCCRTByMonth();
    handleGetTotalCCRT();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleNdcCalendarClick = () => {
    setNdcCalendar(!ndcCalendar);
    setCountryModal(false);
    setFilterModal(false);
    setMonthsModal(false);
  };
  const handleCountryModalClick = () => {
    setCountryModal(!countryModal);
    setNdcCalendar(false);
    setFilterModal(false);
    setMonthsModal(false);
  };
  const handleFilterModalClick = () => {
    setFilterModal(!filterModal);
    setCountryModal(false);
    setNdcCalendar(false);
    setMonthsModal(false);
  };
  const handleMonthsModalClick = () => {
    setMonthsModal(!monthsModal);
    setCountryModal(false);
    setNdcCalendar(false);
    setFilterModal(false);
  };

  const clearDateSelection = () => {
    setFirstDate(null);
    setLastDate(null);
  };

  const handleIncrementMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const handleDecrementMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleDateClick = (date) => {
    if (!firstDate) {
      setFirstDate(date);
    } else if (firstDate && !lastDate) {
      if (parseInt(firstDate?.split("-")[2]) > parseInt(date?.split("-")[2])) {
        setLastDate(firstDate);
        setFirstDate(date);
      } else {
        setLastDate(date);
      }
    } else if (!!firstDate && !!lastDate) {
      setFirstDate(date);
      setLastDate(null);
    }
  };

  const renderCalendarGrid = () => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const startDay = new Date(currentYear, currentMonth, 1).getDay();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    const rows = [];
    let currentDay = 1;

    console.log("perimiter", new Date().getMonth());
    console.log("perimiter", new Date().getFullYear());

    // Create the calendar grid
    for (let i = 0; i < 6; i++) {
      const days = [];

      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < startDay) {
          // Render empty cells for days before the first of the month
          days.push(<div key={`empty-${j}`} className="calendar-cell"></div>);
        } else if (currentDay > daysInMonth) {
          // Finish rendering the calendar grid
          break;
        } else {
          // Render the date cell
          const dateString = `${currentYear}-${String(
            currentMonth + 1
          ).padStart(2, "0")}-${String(currentDay).padStart(2, "0")}`;
          const cellClassName = `calendar-cell ndc-calendar-date`;

          days.push(
            <div
              key={dateString}
              className={
                firstDate === dateString
                  ? `active-date ${cellClassName}`
                  : lastDate === dateString
                  ? `active-date ${cellClassName}`
                  : parseInt(firstDate?.split("-")[2]) <
                      parseInt(dateString?.split("-")[2]) &&
                    parseInt(lastDate?.split("-")[2]) >
                      parseInt(dateString?.split("-")[2])
                  ? `subsidiary-date ${cellClassName}`
                  : cellClassName
              }
              onClick={() => handleDateClick(dateString)}
            >
              {currentDay}
            </div>
          );
          currentDay++;
        }
      }

      rows.push(
        <div key={`row-${i}`} className="ndc-calendar-row">
          {days}
        </div>
      );
    }

    return (
      <div className="calendar-grid">
        <div className="ndc-calendar-row calendar-header">
          {daysOfWeek.map((day, index) => (
            <div key={`header-${index}`} className="calendar-cell">
              {day}
            </div>
          ))}
        </div>
        {rows}
      </div>
    );
  };

  function formatNumber(number) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }

  useEffect(() => {
    const ctx = document.getElementById("emission");
   const data =  Object.values(ccrtByMonth)
    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "CCRT out-flow",
            data,
            borderWidth: 1,
            backgroundColor: ["#0D924F"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem?.label}`;
              },
              label: function (tooltipItem) {
                return `${tooltipItem?.formattedValue}`;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, [ccrtByMonth]);

  useEffect(() => {
    const doughnut = document.getElementById("pieChart");
    const data =  Object.values(ccrtByMonth)

    const doughnutChart = new Chart(doughnut, {
      type: "pie",
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            data,
            borderWidth: 1,
            backgroundColor: ["#91D6A8", "#5FC381", "#40B869", "#0D924F", "#91D6A8", "#5FC381", "#40B869", "#0D924F", "#91D6A8", "#5FC381", "#40B869", "#0D924F"],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false // Hide the legend
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                const label = context.label || '';
                const value = context.formattedValue;
                return `${label}: ${value}`;
              }
            }
          }
        }
      }
    });

    return () => doughnutChart.destroy();
  }, []);

  return (
    <div className="donation-pool">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="main">
        <Search />
        <div className="title">CCRT exchanged</div>
        <div className="subtitle">
          <div>Overview</div>
          <div onClick={handleMonthsModalClick}>
            <div>June</div>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.16536 5.99967L8.4987 9.33301L11.832 5.99967"
                stroke="#98A2B3"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>

          {monthsModal && 
          <div className="subtitle__modal">
            <div className="subtitle__modal__flex">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>
              <div>January</div>
            </div>
         
<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>
  <label htmlFor="february-checkbox">February</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="march-checkbox">March</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="april-checkbox">April</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="may-checkbox">May</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="june-checkbox">June</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="july-checkbox">July</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="august-checkbox">August</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="september-checkbox">September</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="october-checkbox">October</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="november-checkbox">November</label>
</div>

<div className="subtitle__modal__flex">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"

>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>  <label htmlFor="december-checkbox">December</label>
</div>
          </div>
            } 
        </div>
        <div className="donation-admin_card_group">
          <div className="card">
            <div className="card__inner">
              <div className="title">Total CCRT exchanged</div>
              <div className="subtitle">
                CCRT <span>{formatNumber((totalCCRTInNaira / 1500) * 0.6)}</span>
              </div>
            </div>
            <div className="value_group">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.625 4.375V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V4.375C9.375 4.20924 9.44085 4.05027 9.55806 3.93306C9.67527 3.81585 9.83424 3.75 10 3.75C10.1658 3.75 10.3247 3.81585 10.4419 3.93306C10.5592 4.05027 10.625 4.20924 10.625 4.375Z"
                    fill="#0F973D"
                  />
                  <path
                    d="M9.99921 4.63389L4.81671 9.81764C4.69935 9.935 4.54018 10.0009 4.37421 10.0009C4.20824 10.0009 4.04907 9.935 3.93171 9.81764C3.81435 9.70028 3.74842 9.54111 3.74842 9.37514C3.74842 9.20917 3.81435 9.05 3.93171 8.93264L9.55671 3.30764C9.61477 3.24944 9.68374 3.20326 9.75967 3.17175C9.8356 3.14024 9.917 3.12402 9.99921 3.12402C10.0814 3.12402 10.1628 3.14024 10.2388 3.17175C10.3147 3.20326 10.3837 3.24944 10.4417 3.30764L16.0667 8.93264C16.1841 9.05 16.25 9.20917 16.25 9.37514C16.25 9.54111 16.1841 9.70028 16.0667 9.81764C15.9494 9.935 15.7902 10.0009 15.6242 10.0009C15.4582 10.0009 15.2991 9.935 15.1817 9.81764L9.99921 4.63389Z"
                    fill="#0F973D"
                  />
                </svg>
                24%
              </div>

              <div>Compared to last month</div>
            </div>
          </div>
          <div className="card">
            <div className="card__inner">
              <div className="title">Total CCRT exchanged (in NGN)</div>
              <div className="subtitle">
                CCRT <span>{formatNumber(totalCCRTInNaira)}</span>
              </div>
            </div>
            <div className="value_group">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.625 4.375V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V4.375C9.375 4.20924 9.44085 4.05027 9.55806 3.93306C9.67527 3.81585 9.83424 3.75 10 3.75C10.1658 3.75 10.3247 3.81585 10.4419 3.93306C10.5592 4.05027 10.625 4.20924 10.625 4.375Z"
                    fill="#0F973D"
                  />
                  <path
                    d="M9.99921 4.63389L4.81671 9.81764C4.69935 9.935 4.54018 10.0009 4.37421 10.0009C4.20824 10.0009 4.04907 9.935 3.93171 9.81764C3.81435 9.70028 3.74842 9.54111 3.74842 9.37514C3.74842 9.20917 3.81435 9.05 3.93171 8.93264L9.55671 3.30764C9.61477 3.24944 9.68374 3.20326 9.75967 3.17175C9.8356 3.14024 9.917 3.12402 9.99921 3.12402C10.0814 3.12402 10.1628 3.14024 10.2388 3.17175C10.3147 3.20326 10.3837 3.24944 10.4417 3.30764L16.0667 8.93264C16.1841 9.05 16.25 9.20917 16.25 9.37514C16.25 9.54111 16.1841 9.70028 16.0667 9.81764C15.9494 9.935 15.7902 10.0009 15.6242 10.0009C15.4582 10.0009 15.2991 9.935 15.1817 9.81764L9.99921 4.63389Z"
                    fill="#0F973D"
                  />
                </svg>
                24%
              </div>

              <div>Compared to last month</div>
            </div>
          </div>
          <div className="card">
            <div className="card__inner">
              <div className="title">Total CCRT exchanged (in USDT)</div>
              <div className="subtitle">
                USDT <span>0.00</span>
              </div>
            </div>
            <div className="value_group">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.625 4.375V16.875C10.625 17.0408 10.5592 17.1997 10.4419 17.3169C10.3247 17.4342 10.1658 17.5 10 17.5C9.83424 17.5 9.67527 17.4342 9.55806 17.3169C9.44085 17.1997 9.375 17.0408 9.375 16.875V4.375C9.375 4.20924 9.44085 4.05027 9.55806 3.93306C9.67527 3.81585 9.83424 3.75 10 3.75C10.1658 3.75 10.3247 3.81585 10.4419 3.93306C10.5592 4.05027 10.625 4.20924 10.625 4.375Z"
                    fill="#0F973D"
                  />
                  <path
                    d="M9.99921 4.63389L4.81671 9.81764C4.69935 9.935 4.54018 10.0009 4.37421 10.0009C4.20824 10.0009 4.04907 9.935 3.93171 9.81764C3.81435 9.70028 3.74842 9.54111 3.74842 9.37514C3.74842 9.20917 3.81435 9.05 3.93171 8.93264L9.55671 3.30764C9.61477 3.24944 9.68374 3.20326 9.75967 3.17175C9.8356 3.14024 9.917 3.12402 9.99921 3.12402C10.0814 3.12402 10.1628 3.14024 10.2388 3.17175C10.3147 3.20326 10.3837 3.24944 10.4417 3.30764L16.0667 8.93264C16.1841 9.05 16.25 9.20917 16.25 9.37514C16.25 9.54111 16.1841 9.70028 16.0667 9.81764C15.9494 9.935 15.7902 10.0009 15.6242 10.0009C15.4582 10.0009 15.2991 9.935 15.1817 9.81764L9.99921 4.63389Z"
                    fill="#0F973D"
                  />
                </svg>
                24%
              </div>

              <div>Compared to last month</div>
            </div>
          </div>
        </div>

        <div className="history_grid">
          <div className="emission_history">
            <Select
              className="chart_select"
              inputProps={{ "aria-label": "Without label" }}
              onChange={(e) => setDataRange(e.target.value)}
              value={dateRange}
              sx={{ fontSize: "5px" }}
            >
              <MenuItem value="NGN" sx={{ fontSize: "5px" }}>
                NGN
              </MenuItem>
            </Select>

            <canvas id="emission"></canvas>
          </div>
          <div className="emission_history">
            <div className="emission_history__title">Highest out-flow</div>
            <Select
              className="chart_select"
              inputProps={{ "aria-label": "Without label" }}
              onChange={(e) => setDataRange(e.target.value)}
              value={dateRange}
              sx={{ fontSize: "5px" }}
            >
              <MenuItem value="NGN" sx={{ fontSize: "5px" }}>
                NGN
              </MenuItem>
            </Select>

            <canvas id="pieChart"></canvas>
          </div>
        </div>

        <div className="liquidity-table">
          <div className="liquidity-table__header">
            CloseCarbon Reduction Tonnes (CCRT) table
          </div>

          <div className="liquidity-table__label">
            <div>Company Name</div>
            <div>CCRT issued</div>
            <div>Date</div>
            <div>Status</div>
          </div>

          {countryModal && (
            <div className="ndclist-modal adminndclist-modal">
              <div className="ndclist-modal__search">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.18016 12.2002C6.1873 12.2002 5.21673 11.9057 4.3912 11.3541C3.56566 10.8025 2.92224 10.0185 2.54228 9.10123C2.16233 8.18394 2.06292 7.17459 2.25662 6.20081C2.45031 5.22702 2.92842 4.33254 3.63048 3.63048C4.33254 2.92842 5.22702 2.45031 6.20081 2.25662C7.17459 2.06292 8.18394 2.16233 9.10123 2.54228C10.0185 2.92224 10.8025 3.56566 11.3541 4.3912C11.9057 5.21673 12.2002 6.1873 12.2002 7.18016C12.2002 7.83939 12.0703 8.49218 11.818 9.10123C11.5658 9.71028 11.196 10.2637 10.7298 10.7298C10.2637 11.196 9.71028 11.5658 9.10123 11.818C8.49218 12.0703 7.83939 12.2002 7.18016 12.2002ZM7.18016 3.16683C6.38903 3.16683 5.61567 3.40142 4.95788 3.84095C4.30008 4.28047 3.78739 4.90519 3.48464 5.63609C3.18189 6.367 3.10268 7.17126 3.25702 7.94719C3.41136 8.72311 3.79232 9.43584 4.35173 9.99525C4.91114 10.5547 5.62387 10.9356 6.3998 11.09C7.17572 11.2443 7.97999 11.1651 8.71089 10.8623C9.4418 10.5596 10.0665 10.0469 10.506 9.38911C10.9456 8.73131 11.1802 7.95795 11.1802 7.16683C11.1802 6.10596 10.7587 5.08854 10.0086 4.3384C9.25844 3.58825 8.24102 3.16683 7.18016 3.16683Z"
                    fill="#667185"
                  />
                  <path
                    d="M13.3327 13.8336C13.267 13.8339 13.2019 13.8211 13.1412 13.7959C13.0805 13.7708 13.0255 13.7337 12.9793 13.687L10.226 10.9336C10.1377 10.8389 10.0896 10.7135 10.0919 10.584C10.0942 10.4544 10.1466 10.3308 10.2382 10.2392C10.3299 10.1476 10.4534 10.0951 10.583 10.0929C10.7125 10.0906 10.8379 10.1386 10.9327 10.227L13.686 12.9803C13.7796 13.0741 13.8322 13.2011 13.8322 13.3336C13.8322 13.4661 13.7796 13.5932 13.686 13.687C13.6398 13.7337 13.5848 13.7708 13.5241 13.7959C13.4634 13.8211 13.3984 13.8339 13.3327 13.8336Z"
                    fill="#667185"
                  />
                </svg>

                <input
                  type="text"
                  placeholder="Search country"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>

              <div className="ndclist-modal__list">
                {filteredCountries?.map((country) => (
                  <div className="ndclist-modal__list__flex">
                       {selectedCountry !== country?.label && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setSelectedCountry(country?.label)}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}


{selectedCountry === country?.label && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setSelectedCountry("")}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18037)" stroke-width="1.33333"/>
<path d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325" stroke="url(#paint1_linear_2889_18037)" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_2889_18037" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
<linearGradient id="paint1_linear_2889_18037" x1="5.5" y1="6.948" x2="12.5203" y2="11.8039" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}
                    <div>{country?.label}</div>
                  </div>
                ))}
              </div>
            </div>
          )}

{filterModal && <div className="ndclist-filter-modal">
            <div className="ndclist-filter-modal__flex">


            {userType !== "active" && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setUserType("active")}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}


{userType === "active" && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setUserType("")}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18037)" stroke-width="1.33333"/>
<path d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325" stroke="url(#paint1_linear_2889_18037)" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_2889_18037" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
<linearGradient id="paint1_linear_2889_18037" x1="5.5" y1="6.948" x2="12.5203" y2="11.8039" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}


            
              <div>Active</div>
            </div>
            <div className="ndclist-filter-modal__flex">
            {userType !== "inactive" && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setUserType("inactive")}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18043)" stroke-width="1.33333"/>
<defs>
<linearGradient id="paint0_linear_2889_18043" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}


{userType === "inactive" && <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
onClick={() => setUserType("")}
>
<rect x="1" y="1" width="16" height="16" rx="4" stroke="url(#paint0_linear_2889_18037)" stroke-width="1.33333"/>
<path d="M5.5 9.49992L7.83333 11.8333L12.5 6.83325" stroke="url(#paint1_linear_2889_18037)" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_2889_18037" x1="1" y1="1.36719" x2="20.0689" y2="10.7886" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
<linearGradient id="paint1_linear_2889_18037" x1="5.5" y1="6.948" x2="12.5203" y2="11.8039" gradientUnits="userSpaceOnUse">
<stop stop-color="#006633"/>
<stop offset="1" stop-color="#02A4CC"/>
</linearGradient>
</defs>
</svg>}
              <div>Inactive</div>
            </div>
          </div>}

          {ndcCalendar && (
            <div className="calendar-modal">
              <div className="calendar-modal__flex">
                {`${moment(
                  `${currentYear}-${currentMonth + 1}`,
                  "YYYY-M"
                ).format("MMMM")} ${currentYear}`}

                <div>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="pointer"
                    onClick={handleDecrementMonth}
                  >
                    <rect
                      x="0.25"
                      y="0.25"
                      width="11.5"
                      height="11.5"
                      rx="1.75"
                      fill="white"
                    />
                    <rect
                      x="0.25"
                      y="0.25"
                      width="11.5"
                      height="11.5"
                      rx="1.75"
                      stroke="#E4E7EC"
                      stroke-width="0.5"
                    />
                    <path
                      d="M4.68888 6.18123L7.47538 8.83623C7.52187 8.88055 7.58364 8.90527 7.64788 8.90527C7.71211 8.90527 7.77388 8.88055 7.82038 8.83623L7.82338 8.83323C7.846 8.81174 7.86401 8.78588 7.87631 8.75721C7.88862 8.72854 7.89497 8.69767 7.89497 8.66648C7.89497 8.63528 7.88862 8.60441 7.87631 8.57574C7.86401 8.54708 7.846 8.52121 7.82338 8.49973L5.19938 5.99973L7.82338 3.50073C7.846 3.47924 7.86401 3.45338 7.87631 3.42471C7.88862 3.39604 7.89497 3.36517 7.89497 3.33398C7.89497 3.30278 7.88862 3.27191 7.87631 3.24324C7.86401 3.21458 7.846 3.18871 7.82338 3.16723L7.82038 3.16423C7.77388 3.1199 7.71211 3.09518 7.64788 3.09518C7.58364 3.09518 7.52187 3.1199 7.47538 3.16423L4.68888 5.81923C4.66437 5.84257 4.64486 5.87066 4.63153 5.90177C4.6182 5.93288 4.61133 5.96638 4.61133 6.00023C4.61133 6.03407 4.6182 6.06757 4.63153 6.09868C4.64486 6.1298 4.66437 6.15788 4.68888 6.18123Z"
                      fill="#667185"
                    />
                  </svg>

                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="pointer"
                    onClick={handleIncrementMonth}
                  >
                    <rect
                      x="0.25"
                      y="0.25"
                      width="11.5"
                      height="11.5"
                      rx="1.75"
                      fill="white"
                    />
                    <rect
                      x="0.25"
                      y="0.25"
                      width="11.5"
                      height="11.5"
                      rx="1.75"
                      stroke="#E4E7EC"
                      stroke-width="0.5"
                    />
                    <path
                      d="M7.31112 5.81877L4.52462 3.16377C4.47813 3.11945 4.41636 3.09473 4.35212 3.09473C4.28789 3.09473 4.22612 3.11945 4.17962 3.16377L4.17662 3.16677C4.154 3.18826 4.13599 3.21412 4.12369 3.24279C4.11138 3.27146 4.10503 3.30233 4.10503 3.33352C4.10503 3.36472 4.11138 3.39559 4.12369 3.42426C4.13599 3.45292 4.154 3.47879 4.17662 3.50027L6.80062 6.00027L4.17662 8.49927C4.154 8.52076 4.13599 8.54662 4.12369 8.57529C4.11138 8.60396 4.10503 8.63483 4.10503 8.66602C4.10503 8.69722 4.11138 8.72809 4.12369 8.75676C4.13599 8.78542 4.154 8.81129 4.17662 8.83277L4.17962 8.83577C4.22612 8.8801 4.28789 8.90482 4.35212 8.90482C4.41636 8.90482 4.47813 8.8801 4.52462 8.83577L7.31112 6.18077C7.33563 6.15743 7.35514 6.12934 7.36847 6.09823C7.3818 6.06712 7.38867 6.03362 7.38867 5.99977C7.38867 5.96593 7.3818 5.93243 7.36847 5.90132C7.35514 5.8702 7.33563 5.84212 7.31112 5.81877Z"
                      fill="#667185"
                    />
                  </svg>
                </div>
              </div>
              {renderCalendarGrid()}
              {firstDate && lastDate ? (
                <div className="calendar-modal__button-group">
                  <button onClick={clearDateSelection}>Cancel</button>
                  <button
                  // onClick={handleGetNdc}
                  >
                    {rangeLoading ? "Loading..." : "Confirm"}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="liquidity-table__inner">
           {loading && <>
            <div
              className="liquidity-table__inner__item"
              // onClick={() => navigate(`/admin/users/${user?.id}`)}
            >
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              </div>
              <div
              className="liquidity-table__inner__item"
              // onClick={() => navigate(`/admin/users/${user?.id}`)}
            >
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              </div>
              <div
              className="liquidity-table__inner__item"
              // onClick={() => navigate(`/admin/users/${user?.id}`)}
            >
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              <Skeleton width="45%" height={15} borderRadius={4} />
              </div>
           </>}
            {collectors?.map((user) => (
              <div
                className="liquidity-table__inner__item"
                // onClick={() => navigate(`/admin/users/${user?.id}`)}
              >
                <div>{user?.recipient}</div>
                <div>{user?.ccrtValue}</div>

                <div>{moment(parseInt(user?.createdAt)).format("DD/MM/YYYY")}</div>
                <button className="liquidity-table__inner__item__active">
                  {user?.status}
                </button>
              </div>
            ))}
          </div>

          <div className="liquidity-table__footer">
            <div className="liquidity-table__footer__pagination">
              <Pagination
                count={Object.keys(pagination).length}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiquidityPool;
