import axios from "axios";

export const registerEmail = async (email, role) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/register-email`,
      { email, role }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const verifyEmail = async (otp, email) => {
  console.log('otp', otp, 'email', email);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/verify-email`,
      { otp, email }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const resendOtp = async (email) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/resend-otp`,
      { email }
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const signUp = async (
  email,
  firstName,
  lastName,
  password,
  role,
  phoneNumber,
  country,
  countryCode,
  companyName,
  address
) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/sign-up`,
     {
      email,
      firstName,
      lastName,
      password,
      role,
      phoneNumber,
      country,
      countryCode,
      companyName,
      address
    });

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const login = async (
  email,
  password
) => {
  console.log({
    email, password
  });
  

  console.log("email", email, "password", password);
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/log-in`,
     {
      email,
      password
    });

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const forgotPassword = async (
  email
  ) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/forget-password`,
     {
      email
    });

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const resetPassword = async (
  email,
  password,
  otp
  ) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/open/v1/forgot-password-reset`,
     {
      email,
      password,
      otp
    });

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};


