import { useEffect, useState } from "react";
import SideBar from "../../../components/AdminDashboard/WebsiteDashboard/SideBar";
import "../../../styles/AdminDashboard/WebsiteDashboard/BlogList.scss";
import { toast } from "react-toastify";
import { getBlogPosts } from "../../../apis/AdminDashboard/users";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Pagination } from "@mui/material";
import Search from "../../../components/AdminDashboard/Search";


const BlogList = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState("All blog");
  const [blogPosts, setBlogPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);

  const loadBlogPosts = async () => {
    setLoading(true);
    const response = await getBlogPosts(page);
    console.log('response', response);
    setBlogPosts(response?.data?.message?.items);
    setPagination(response?.data?.message)
    setLoading(false);
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  useEffect(() => {
    loadBlogPosts()
  }, [page])


  return (
    <div className="blog-list">
      <div className="sidebar_container">
        <SideBar />
      </div>

      <div className="blog-list__main">
       <div>
       <Search />
        <div className="blog-list__main-inner">
        <div className="blog-list__tab">
          <div className={active === "All blog" && "active"} onClick={() => setActive("All blog")}>
            All blog <span className="unread">6</span>
          </div>
          <div className={active === "Uploaded" && "active"} onClick={() => setActive("Uploaded")}>
            Uploaded
          </div>
          <div className={active === "Draft" && "active"} onClick={() => setActive("Draft")}>
            Draft 
          </div>
        </div>
        {blogPosts?.map((blogPost) => !loading &&  <div className="blog-list__card">
          <svg
            width="16"
            height="4"
            viewBox="0 0 16 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="black" />
            <circle cx="8" cy="2" r="2" fill="black" />
            <circle cx="14" cy="2" r="2" fill="black" />
          </svg>

          <div>
            <div className="blog-card__flex">
              <div>Ecofriendly</div>
              <div>GreenTech</div>
              <div>RenewableEnergy</div>
            </div>
            <div className="blog-card__title">
              {blogPost?.title}
            </div>
            <div className="blog-card__subtitle">
            {blogPost?.intro}...
            </div>
            <div className="blog-card__bar">
              <div className="itemOne"></div>
            </div>
            <div className="blog-card__bar-flex">
              <div>In progress</div>
              <div>100%</div>
            </div>
          </div>
        </div>)}
        {loading && <>
        <div className="blog-list__card">
          <svg
            width="16"
            height="4"
            viewBox="0 0 16 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="black" />
            <circle cx="8" cy="2" r="2" fill="black" />
            <circle cx="14" cy="2" r="2" fill="black" />
          </svg>

          <div>
            <div className="blog-card__flex">
              <Skeleton width={80} height={20} borderRadius={8} style={{marginBottom: "8px", marginRight: "1.603rem"}} />
              <Skeleton width={80} height={20} borderRadius={8} style={{marginBottom: "8px", marginRight: "1.603rem"}} />
              <Skeleton width={80} height={20} borderRadius={8} style={{marginBottom: "8px", marginRight: "1.603rem"}} />
            </div>
            <div className="blog-card__title">
            <Skeleton width="50%" height={20} borderRadius={8}
            //  style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            </div>
            <div className="blog-card__subtitle">
            <Skeleton width="70%" height={30} borderRadius={8}
            //  style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            </div>
            <Skeleton width="100%" height={10} borderRadius={8}
             style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            <Skeleton width={120} height={10} borderRadius={8}
            //  style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            
          </div>
        </div>
        <div className="blog-list__card">
          <svg
            width="16"
            height="4"
            viewBox="0 0 16 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="black" />
            <circle cx="8" cy="2" r="2" fill="black" />
            <circle cx="14" cy="2" r="2" fill="black" />
          </svg>

          <div>
            <div className="blog-card__flex">
              <Skeleton width={80} height={20} borderRadius={8} style={{marginBottom: "8px", marginRight: "1.603rem"}} />
              <Skeleton width={80} height={20} borderRadius={8} style={{marginBottom: "8px", marginRight: "1.603rem"}} />
              <Skeleton width={80} height={20} borderRadius={8} style={{marginBottom: "8px", marginRight: "1.603rem"}} />
            </div>
            <div className="blog-card__title">
            <Skeleton width="50%" height={20} borderRadius={8}
            //  style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            </div>
            <div className="blog-card__subtitle">
            <Skeleton width="70%" height={30} borderRadius={8}
            //  style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            </div>
            <Skeleton width="100%" height={10} borderRadius={8}
             style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            <Skeleton width={120} height={10} borderRadius={8}
            //  style={{marginBottom: "8px", marginRight: "1.603rem"}}
             />
            
          </div>
        </div>
        </>}

        </div>
       </div>
        <Pagination
              count={Object.keys(pagination).length}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
        
      </div>
    </div>
  );
};

export default BlogList;
