import axios from "axios";

export const submitKyc = async (
  subscriptionPlan,
verifierType,
authorizedName,
accreditationBody,
accreditationExpDate,
accreditationCertificate,
  token
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/kyc/v1/create-third-party-kyc`,
      {
        subscriptionPlan,
verifierType,
authorizedName,
accreditationBody,
accreditationExpDate,
accreditationCertificate
      },
      config
    );

    return res;
  } catch (error) {
    console.log("ERROR", error);
  }
};
