import SideBar from "../../components/AdminDashboard/SideBar";
import "../../styles/AdminDashboard/Settings.scss";
import ProfileImg from "../../images/Ellipse 57.png";
import {
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Modal,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import Resizer from "react-image-file-resizer";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useRef, useState } from "react";
import { deactivateUser, getUserInfo, updateAdminInfo, updatePassword } from "../../apis/General/users";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin, getAdmins } from "../../apis/AdminDashboard/users";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Search from "../../components/AdminDashboard/Search";

const PasswordSettings = () => {
  const auth = useSelector((state) => state.auth);  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [tab, setTab] = useState("account");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [uplaodLoading, setUploadLoading] = useState(false);
  const [images, setImages] = useState([]);

  const [admins, setAdmins] = useState([]);
  const mediaRef = useRef(null);


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const fetchUserInfo = async () => {
    setLoading(true)
    const response = await getUserInfo(auth?.userInfo?.id, auth?.token);
    // console.log('userDetails', response);
    setFirstName(response?.data?.message?.firstName);
    setLastName(response?.data?.message?.lastName);
    setEmail(response?.data?.message?.email);
    setLoading(false)

    
  };

  const fetchAdmins = async () => {
    const response = await getAdmins(auth?.token);
    setAdmins(response?.data?.message)
  }

  useEffect(() => {
    fetchUserInfo();
    fetchAdmins()
  }, []);

  const handleUpdatePassword = async(e) => {
    setLoading(true)
    e.preventDefault();
    if(newPassword === newPasswordConfirm) {
      const response = await updatePassword(oldPassword, newPassword, auth?.token);
    console.log('response909056', response);
    if(response?.data?.statusCode === 200) {
      toast.success('Password updated successfully')
      setOldPassword("")
    setNewPassword("")
    setNewPasswordConfirm("")
    } else {
      toast.error(response?.data?.message)
    }
    
    } else {
      alert('Passwords do not match')
    }
    setLoading(false)

  }

  const handleDeleteUser = async () => {
    setLoading(true)
    const response = await deactivateUser(auth?.userInfo?.id, auth?.token);
    // console.log('response', response);
    if(response?.data?.statusCode === 200) {
      toast.success(response?.data?.message)
      dispatch({
        type: "LOGOUT_SUCCESS",
      });
      localStorage.removeItem("token");
      navigate("/login");
      window.scrollTo(0, 0);
    }else {
      toast.error(response?.data?.message)

    }
    setLoading(false)
  }

  const handleRemoveAdmin = async (id) => {
    setLoading(true);
    // console.log('id', id);
    const response = await deleteAdmin(id, auth?.token);
    console.log('response', response);
    if(response?.data?.statusCode === 200) {
      toast.success(response?.data?.message)
    }else {
      toast.error(response?.data?.message)

    }
    // console.log('response', response);
    setLoading(false)
  }
  const handleUpdateAdmin = async (e) => {
    setLoading(true);
    e.preventDefault()
    const response = await updateAdminInfo(firstName, lastName, email, "", auth?.token)
    if(response?.data?.statusCode === 200) {
      toast.success(response?.data?.message)
    }else {
      toast.error(response?.data?.message)

    }
    // console.log('response', response);
    setLoading(false)
  }

  const fileUpload = (e) => {
    setUploadLoading(true);

    let files = e.target.files;
    let allUploadedFiles = images;

    const fileToUri = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(null, reader.result);
      };
      reader.onerror = function (error) {
        cb(error, null);
      };
    };

    if (files) {
      for (let i = 0; i < files.length; i++) {
        fileToUri(files[0], (err, result) => {
          if (result) {
            // console.log('mourinho', result);
            axios
              .post(
                `${process.env.REACT_APP_API_URL}/open/v1/upload`,
                {
                  media_type: "image",
                  folder: "documents",
                  user_id: auth?.userInfo?.id,
                  contents: [result],
                },
                {
                  headers: {
                    Authorization: `Bearer ${auth ? auth.token : ""}`,
                  },
                }
              )
              .then((response) => {
                console.log('wenger', response);
                // if (response?.data?.body?.success === true) {
                //   if (documentTypeSent === "organizationBoundary") {
                //     setOrganizationBoundary(response?.data?.body?.data[0]);
                //   } else if (documentTypeSent === "reportingBoundary") {
                //     setReportingBoundary(response?.data?.body?.data[0]);
                //   } else if (documentTypeSent === "historicalInventory") {
                //     setHistoricalInventory(response?.data?.body?.data[0]);
                //   } else if (documentTypeSent === "typeofFuel") {
                //     setTypeofFuel(response?.data?.body?.data[0]);
                //   } else if (
                //     documentTypeSent === "descriptionOfRenewableEnergySources"
                //   ) {
                //     setDescriptionOfRenewableEnergySources(
                //       response?.data?.body?.data[0]
                //     );
                //   } else if (documentTypeSent === "projectMonitoringReport") {
                //     setProjectMonitoringReport(response?.data?.body?.data[0]);
                //   } else if (documentTypeSent === "projectDescriptionReport") {
                //     setProjectDescriptionReport(response?.data?.body?.data[0]);
                //   } else {
                //     toast.error("document type not specified");
                //   }
                // }
                setUploadLoading(false);
              })
              .catch((err) => {
                setUploadLoading(false);
              });
          }
        });
      }
    }
  };

  return (
    <div className="admin_settings">
       <div className="sidebar_container">
        <SideBar />
      </div>


      <div className="main">
        <Search />
        <div className="title">Settings</div>
        <div className="main_inners">
            <form className="tab" onSubmit={handleUpdatePassword}>
              <div className="tab_flex header">
                <div>
                <div className="title">Password</div>
                  <div className="subtitle">
                  Update your password
                  </div>
                </div>
                <div className="button_group">
                  <button className="transparent" disabled={loading}>Cancel</button>
                  <button className="filled" type="submit" disabled={loading}>{loading ? "Loading..." : "Save changes"}</button>
                </div>
              </div>
              <div className="tab_divider"></div>

              <div className="label">
                <label htmlFor="fristName">Current password</label>
              </div>
              <OutlinedInput
                className="input"
                required
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div className="label">
                <label htmlFor="fristName">New password</label>
              </div>
              <OutlinedInput
                className="input"
                required
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div className="label">
                <label htmlFor="fristName">Confirm password</label>
              </div>
              <OutlinedInput
                className="input"
                required
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter password"
                value={newPasswordConfirm}
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {/* <div className="tab_divider"></div>

<div className="tab_flex header">
  <div>
    <div className="title">Switch Account</div>
    <div className="subtitle">
    Switch to CloseCarbon website admin
    </div>
  </div>
  <div className="button_group">
    <button className="transparent">Cancel</button>
    <button className="filled" onClick={() => navigate('/admin/website/support-hub')} disabled={loading}>{loading ? 'Loading...' : 'Switch'}</button>
  </div>
</div> */}
              <div className="tab_divider"></div>

              <div className="tab_flex header">
                <div>
                  <div className="title error">Delete My Account</div>
                  <div className="subtitle">
                    Permanently delete the account and remove all access from
                    all workspace
                  </div>
                </div>
                <div className="button_group">
                  <button className="transparent">Cancel</button>
                  <button className="filled" onClick={handleDeleteUser} disabled={loading}>{loading ? 'Loading...' : 'Delete'}</button>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordSettings;
